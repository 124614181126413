import React from 'react';
import { withTheme } from 'common/styling/theme';

import AllTasksTable from '../AllTasksTable';

import { staticStyles } from './style';

const AllTasks = () => (
  <div className="AllTasks">
    <div className="AllTasks__name">All Tasks</div>
    <AllTasksTable />
    <style jsx>{staticStyles}</style>
  </div>
);

export default withTheme()(AllTasks);
