import React from 'react';
import { BaseTable } from 'crm/_components';
import classNames from 'classnames';
import { staticStyles } from './style';
import testData from './testData';
import iconBadClient from './images/iconBadClient.svg';
import iconNotCompleted from './images/iconNotCompleted.svg';
import iconNotSuccess from './images/iconNotSuccess.svg';
import iconSuccess from './images/iconSuccess.svg';

const AllTasksTableMails = () => {
  /* eslint-disable */
  const columns = [
    {
      accessor: 'contact',
      Header: 'crmContact',
      Cell: ({
        row: {
          original: { contact },
        },
      }) => <div className="contact">{contact}</div>,
    },
    {
      accessor: 'lastMail',
      Header: 'crmLastMail',
      Cell: ({
        row: {
          original: { lastMail },
        },
      }) => <div className="last-mail">{lastMail}</div>,
    },
    {
      accessor: 'mailStatus',
      Header: 'crmMailStatus',
      Cell: ({
        row: {
          original: { mailStatus },
        },
      }) => (
        <div
          className={classNames('mail-status', {
            'mail-status--not-success': mailStatus === 'Not success',
            'mail-status--success': mailStatus === 'Success',
            'mail-status--not-completed': mailStatus === 'Not completed',
            'mail-status--bad-client': mailStatus === 'Bad client',
          })}>
          <div className="mail-status__icon">
            {mailStatus === 'Not success' && <img src={iconNotSuccess} alt={mailStatus} />}
            {mailStatus === 'Success' && <img src={iconSuccess} alt={mailStatus} />}
            {mailStatus === 'Not completed' && <img src={iconNotCompleted} alt={mailStatus} />}
            {mailStatus === 'Bad client' && <img src={iconBadClient} alt={mailStatus} />}
          </div>
          <div className="mail-status__text">{mailStatus}</div>
        </div>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="AllTasksTableMails">
      {testData.length && <BaseTable columns={columns} data={testData} onRowClick={() => {}} />}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default AllTasksTableMails;
