import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.NewCRMTable__Body-Row) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(1):before {
      background: #c2c7ce;
    }
  }
  :global(.NewCRMTable__Body-Row--success) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(1):before {
      background: #04eb84;
    }
  }
  :global(.NewCRMTable__Body-Row--notSuccess) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(1):before {
      background: #fdff89;
    }
  }
  :global(.NewCRMTable__Body-Row--badClient) {
    :global(.NewCRMTable__Cell--Tbody):nth-child(1):before {
      background: #ff4085;
    }
  }

  :global(.NewCRMTable__Cell--Tbody):nth-child(1):before {
    content: '';
    position: absolute;
    width: 6px;
    bottom: 0px;
    left: 0;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 1;
  }

  .PageContentPreloader {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  :global(.StatusCell--Open) {
    color: green;
  }
  :global(.StatusCell--Closed) {
    color: red;
  }
  :global(.NewCRMTable__wrapper) {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #0aa1e2;
    }
  }
  :global(.NewCRMTable) {
    min-width: 1850px;
  }
  :global(.NewCRMTable__Head-Row),
  :global(.NewCRMTable__Body-Row) {
    display: grid;
    grid-template-columns: 1.5fr 1.7fr 1.3fr 1.5fr 2.4fr 1fr 1fr 1fr 1fr 2fr 80px;
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
