/* eslint-disable camelcase */
import React from 'react';
import ReactApexChart from 'react-apexcharts';

import UnderChartInfo from '../UnderChartInfo';

import { staticStyles } from './style';

const RightChart = ({ dayDates, allDayData }) => {
  const series = [
    {
      name: 'Deposit',
      data: allDayData.map(({ deposited_amount }) => deposited_amount),
    },
    {
      name: 'Withdrawal',
      data: allDayData.map(({ withdrawn_amount }) => withdrawn_amount),
    },
  ];
  const options = {
    colors: ['#0FFF50', '#FF3131'],
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [5, 5],

      curve: 'smooth',
      dashArray: [0, 0],
    },
    title: {
      text: 'IN/OUT',
      align: 'left',
    },
    legend: {
      show: false,
      tooltipHoverFormatter(val, opts) {
        return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: dayDates,
    },
    tooltip: {
      y: [
        {
          title: {
            formatter(val) {
              return `${val} USD`;
            },
          },
        },
        {
          title: {
            formatter(val) {
              return `${val} USD`;
            },
          },
        },
        {
          title: {
            formatter(val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  const depositedAmount = allDayData.reduce((acc, rec) => acc + rec.deposited_amount, 0);

  const withdrawnAmount = allDayData.reduce((acc, rec) => acc + rec.withdrawn_amount, 0);

  return (
    <div id="chart" className="ClientsPageAnalytics__Chart">
      <ReactApexChart options={options} series={series} type="line" height={350} />

      <UnderChartInfo
        textId="crmTotalForSelectedPeriod"
        values={[
          { textId: 'crmDepositedAmount', value: depositedAmount, color: '#0FFF50' },
          { textId: 'crmWithdrawnAmount', value: withdrawnAmount, color: '#FF3131' },
          { textId: 'justDelta', value: depositedAmount - withdrawnAmount, color: 'black' },
        ]}
      />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

export default RightChart;
