import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.ReportsTable) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  :global(.NewCRMTable__wrapper) {
    @include respond-to(xl) {
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #0aa1e2;
      }
    }
  }
  :global(.PageContentPreloader) {
    padding: 20px 30px 0 11px;
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  :global(.NewCRMTable) {
    @include respond-to(xl) {
      width: 1539px;
    }
  }
  :global(.NewCRMTable__Head-Row),
  :global(.NewCRMTable__Body-Row) {
    display: grid;
    grid-template-columns: 40px 0.3fr 0.5fr 0.6fr 0.3fr;
  }

  :global(.DateCell) {
    display: flex;
    gap: 5px;
  }

  :global(.BaseButton) {
    height: 100%;
    display: flex !important;
    align-items: center !important;
  }
`;

export { staticStyles };
