import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { ActionsButton, DefaultButton, ReactDataTable } from '../../index';

import BaseTable from '../BaseTable';
import { staticStyles, getDynamicStyles } from './style';

const pageSizeDelimiters = [5, 10, 20, 25, 50, 100];

const PaginationTable = ({
  data,
  columns,
  getTrProps,
  params,
  theme,
  isNewTable,
  noResponsive,
  withoutSort,
  loading,
  ...props
}) => {
  const { history, location, getList } = props;
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const [currentPage, setCurrentPage] = useState(params.search.page || 1);
  const [pageSize, setPageSize] = useState(params.search.page_size || 10);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isLoading, setIsLoading] = useState(loading);
  const tableData = useMemo(() => data.results, [data.results]);
  const pagesCount = useMemo(() => Math.ceil(data.count / pageSize), [data.count, pageSize]);

  const currentPageInputLength = useMemo(() => {
    const pagesCountLength = pagesCount.toString().length;
    if (pagesCountLength < 3) {
      return '33px';
    }
    return `${pagesCountLength * 11}px`;
  }, [pagesCount]);

  useEffect(() => {
    if (pagesCount === 0) {
      setCurrentPage(0);
    } else if (pagesCount !== 0 && currentPage <= 0) {
      setCurrentPage(1);
    }
  }, [setCurrentPage, pagesCount, currentPage]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (pagesCount === 0) {
      setCurrentPage(0);
    } else if (pagesCount !== 0 && currentPage <= 0) {
      setCurrentPage(1);
    }
  }, [setCurrentPage, pagesCount, currentPage]);

  const handleChange = (queryName, pageParam) => {
    history.replace({
      ...location,
      search: queryString.stringify({
        ...params.search,
        [queryName]: pageParam,
      }),
    });
    getList({
      search: { page_size: pageSize, ...params.search, [queryName]: pageParam },
    });
    if (queryName === 'page') {
      setCurrentPage(pageParam);
    } else if (queryName === 'page_size') {
      setCurrentPage(1);
      setPageSize(pageParam);
    }
  };

  /* eslint-disable */
  const handleSorting = useCallback(
    (columnName, desc) => {
      const ordering = desc ? '' : '-';

      history.replace({
        ...location,
        search: queryString.stringify({
          ...params.search,
          page: 1,
          page_size: pageSize,
          ordering: `${ordering}${columnName}`,
        }),
      });

      setIsLoading(true);

      // if (!isNewTable) {
      getList({
        search: {
          ...params.search,
          page: 1,
          page_size: pageSize,
          ordering: `${ordering}${columnName}`,
        },
      });
      // }
    },
    [pageSize, params.search, history]
  );
  /* eslint-enable */

  const handleInputChange = ({ target }) => {
    if (target.value === '' || (target.value >= 1 && target.value <= pagesCount)) {
      setCurrentPage(target.value);
    }
  };

  columns.forEach(column => {
    column.sortable = !!column.accessor;
  });

  // If page is too high -> show last page
  if (params.search.page > pagesCount) {
    props.history.replace({
      ...props.location,
      search: queryString.stringify({ ...params.search, page: pagesCount }),
    });
  }

  const handleNewTableSort = useCallback(
    columns => {
      if (columns && columns[0]) {
        const { id, desc } = columns[0];
        handleSorting(id, desc);
      }
    },
    [handleSorting]
  );

  return (
    <div className="PaginationTable">
      {isNewTable ? (
        <ReactDataTable
          {...props}
          columns={columns}
          getTrProps={getTrProps}
          data={tableData}
          isLoading={isLoading}
          onChangeSort={handleNewTableSort}
          noResponsive={noResponsive}
          withoutSort={withoutSort}
        />
      ) : (
        <BaseTable
          {...props}
          data={data.results}
          rowAmount={data.count}
          columns={columns}
          getTrProps={getTrProps}
          pages={pagesCount}
          page={currentPage - 1}
          pageSize={Number(pageSize)}
          manual
          showPagination
          params={params}
          onPageChange={pageIndex => handleChange('page', pageIndex + 1)}
          onPageSizeChange={pageSize => handleChange('page_size', pageSize)}
          onSortedChange={newSorted => handleSorting(newSorted[0].id, newSorted[0].desc)}
        />
      )}
      {((props.isResponsive && isTabletOrMobile) || isNewTable) && (
        <div className="PaginationTable__pagination">
          <div className="PaginationTable__select-wrap">
            <select
              onChange={({ target }) => handleChange('page_size', target.value)}
              value={pageSize}
              className="PaginationTable__select">
              {pageSizeDelimiters.map(delimiter => (
                <option value={delimiter} key={delimiter}>
                  {delimiter}{' '}
                </option>
              ))}
            </select>
          </div>
          <div className="PaginationTable__pagination-nav">
            <div className="PaginationTable__nav-info">
              {isTabletOrMobile && (
                <>
                  <ActionsButton
                    onClickFunc={() => handleChange('page', Number(currentPage) - 1)}
                    disabled={currentPage <= 1}
                  />
                  <ActionsButton
                    onClickFunc={() => handleChange('page', Number(currentPage) + 1)}
                    disabled={currentPage >= pagesCount}
                  />
                </>
              )}
              <FormattedMessage id="justPage">{txt => <span>{txt}</span>}</FormattedMessage>
              <input
                className="PaginationTable__nav-input"
                type="number"
                style={{ width: currentPageInputLength }}
                value={currentPage}
                onChange={handleInputChange}
                onBlur={() => handleChange('page', currentPage)}
              />
              <FormattedMessage id="justOf">
                {txt => (
                  <span>
                    {txt} {pagesCount}
                  </span>
                )}
              </FormattedMessage>
            </div>
            {!isTabletOrMobile && (
              <>
                <DefaultButton
                  textId="justPrevious"
                  onClick={() => handleChange('page', Number(currentPage) - 1)}
                  disabled={currentPage <= 1}
                  previous
                />
                <DefaultButton
                  textId="justNext"
                  onClick={() => handleChange('page', Number(currentPage) + 1)}
                  disabled={currentPage >= pagesCount}
                  next
                />
              </>
            )}
          </div>
        </div>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

PaginationTable.propTypes = {
  data: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  getTrProps: PropTypes.func,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  isResponsive: PropTypes.bool,
  isNewTable: PropTypes.bool,
  noResponsive: PropTypes.bool,
  withoutSort: PropTypes.bool,
  loading: PropTypes.bool,
  theme: PropTypes.object,
};

PaginationTable.defaultProps = {
  getTrProps: () => ({ onClick: () => null }),
  isNewTable: false,
  isResponsive: false,
  noResponsive: false,
  withoutSort: false,
  loading: false,
  theme: {},
};

export default withTheme()(PaginationTable);
