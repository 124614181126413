import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { editCrmCustomFilter, getCrmFilter, deleteCrmCustomFilter } from 'crm/contacts/_redux';
import { getCRMFiltersOptions, getCRMFiltersList, createCRMCustomFilter } from 'crm/_redux/actions';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import {
  AddContact,
  BasicCRMModal,
  CustomFilterSelect,
  CRMModals,
  BaseButton,
  BaseStaticSelect,
  SearchBar,
} from 'crm/_components';

import { FilterCreate, FilterEdit } from 'crm/_newComponents';

import { staticStyles } from './style';

const ActionsTab = ({
  filtersOptions,
  filtersOptionsPending,
  crmFilter,
  crmFilterIsLoaded,
  getCRMFiltersOptions,
  getCrmFilter,
  areThereAnySelectedContacts,
  intl,
  history,
  location,
}) => {
  console.log({ getCRMFiltersOptions });
  const [crmModalType, setCrmModalType] = useState(null);
  const [addContact, setAddContact] = useState(false);
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const initialFilter = { value: '', label: intl.formatMessage({ id: 'crmCustomFilter' }) };
  const [filter, setFilter] = useState(initialFilter);
  const [filterId, setFilterId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilterId(queryString.parse(location.search).filterId);
  }, [location.search]);

  const toggleModal = () => {
    setIsOpen(isOpen => !isOpen);
  };
  const toggleEditModal = id => {
    setEditId(id);
    setIsEditOpen(isEditOpen => !isEditOpen);
  };
  const toggleDeleteModal = id => {
    setDeleteId(id);
    setIsDeleteOpen(isEditOpen => !isEditOpen);
  };
  const deleteCustomFilter = id => {
    deleteCrmCustomFilter(id, dispatch);
  };

  const handleFilterChange = value => {
    if (value.value === 'create') {
      toggleModal();
    } else {
      setFilter(value);
      history.replace({
        search: queryString.stringify({
          ...query,
          filterId: value.value,
        }),
      });
    }
  };

  const handleSearchChange = searchValue => {
    history.replace({
      search: queryString.stringify({
        ...query,
        search: searchValue,
      }),
    });
  };

  const handleResetFilter = () => {
    setFilter(initialFilter);
    history.replace('/crm/contacts/');
  };

  const actionOptions = [
    {
      label: intl.formatMessage({ id: 'justReassign' }),
      value: 'reassign',
    },
    {
      label: intl.formatMessage({ id: 'reassignManager' }),
      value: 'reassignManager',
    },
    {
      label: intl.formatMessage({ id: 'setPartnerCode' }),
      value: 'setPartnerCode',
    },
    {
      label: intl.formatMessage({ id: 'saveAs' }),
      value: 'saveAs',
    },
  ];

  return (
    <div className="ActionsTab">
      <div className="ActionsTab__filters-left">
        <BaseStaticSelect
          labelTextId="justActions"
          options={actionOptions}
          onSelect={value => {
            setCrmModalType(value);
          }}
          isDisabled={!areThereAnySelectedContacts}
        />

        <SearchBar onChange={handleSearchChange} />
      </div>
      <div className="ActionsTab__filters-right">
        <div className="ActionsTab__filter-select">
          <CustomFilterSelect
            getFilters={getCRMFiltersList}
            filtersSelector={state => state.crm.common.filtersList}
            onChange={handleFilterChange}
            toggleEditModal={toggleEditModal}
            toggleDeleteModal={toggleDeleteModal}
            onResetFilter={handleResetFilter}
            filter={filter}
            filterId={filterId}
          />
        </div>
        <div className="ActionsTab__contacts">
          <BaseButton textId="justUploadContacts" onClick={() => setAddContact(true)} plus />
        </div>
      </div>
      <AddContact isOpen={addContact} onRequestClose={() => setAddContact(false)} />
      {crmModalType && <CRMModals modalType={crmModalType} onRequestClose={() => setCrmModalType(null)} />}
      <BasicCRMModal isOpen={isOpen} captionId="crmCustomFilter" onRequestClose={toggleModal}>
        <FilterCreate
          onRequestClose={toggleModal}
          getFiltersOptions={getCRMFiltersOptions}
          filtersOptionsSelector={state => state.crm.common.filtersOptions}
          filtersOptionsPendingSelector={state => state.crm.common.filtersOptionsPending}
          createCustomFilterSubmitFunc={createCRMCustomFilter}
        />
      </BasicCRMModal>
      <BasicCRMModal isOpen={isEditOpen} captionId="crmEditCustomFilter" onRequestClose={toggleEditModal}>
        <FilterEdit
          editCrmCustomFilter={editCrmCustomFilter}
          getCRMFiltersOptions={getCRMFiltersOptions}
          getCrmFilter={getCrmFilter}
          filtersOptions={filtersOptions}
          filtersOptionsPending={filtersOptionsPending}
          crmFilter={crmFilter}
          crmFilterIsLoaded={crmFilterIsLoaded}
          onRequestClose={toggleEditModal}
          id={editId}
        />
      </BasicCRMModal>
      <BasicCRMModal isOpen={isDeleteOpen} captionId="confirmText" onRequestClose={toggleDeleteModal}>
        <FormattedMessage id="crmDeleteCustomFilter" />
        <div className="ActionsTab__ModalButtons">
          <BaseButton
            color="white"
            textId="justCancel"
            onClick={() => {
              setIsDeleteOpen(false);
            }}
          />
          <BaseButton
            color="red"
            textId="justDelete"
            onClick={() => {
              deleteCustomFilter(deleteId);
              setIsDeleteOpen(false);
              if (+filterId === +deleteId) {
                handleResetFilter();
              }
            }}
          />
        </div>
      </BasicCRMModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ActionsTab.propTypes = {
  filtersOptions: PropTypes.array.isRequired,
  filtersOptionsPending: PropTypes.bool.isRequired,
  crmFilter: PropTypes.object.isRequired,
  crmFilterIsLoaded: PropTypes.bool.isRequired,
  getCRMFiltersOptions: PropTypes.func.isRequired,
  getCrmFilter: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
  connect(
    state => ({
      areThereAnySelectedContacts: !!state.crm.contacts.selectedContactsIds.length,
      filtersOptions: state.crm.common.filtersOptions,
      filtersOptionsPending: state.crm.common.filtersOptionsPending,
      crmFilter: state.crm.contacts.crmFilter,
      crmFilterIsLoaded: state.crm.contacts.crmFilterIsLoaded,
    }),
    { getCRMFiltersOptions: () => getCRMFiltersOptions.request(), getCrmFilter: id => getCrmFilter.request(id) }
  )
)(ActionsTab);
