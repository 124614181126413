/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { InfoBlock } from '../_components';

import iconEmail from './images/iconEmail.svg';
import iconPhone from './images/iconPhone.svg';
import iconEdit from './images/iconEdit.svg';

import { staticStyles } from './style';

const ContactsInfoBlock = ({ setCrmModalType }) => {
  const { phone, email } = useSelector(state => state.crm.users.userData);

  const items = useMemo(
    () => [
      {
        titleId: 'justPhone',
        cell: phone ? (
          <a className="FieldWithButton" href={`tel:${phone || phone}`}>
            {phone || phone} <img src={iconPhone} alt="phone-icon" />
            <button type="button" onClick={() => setCrmModalType('editPhoneNumber')}>
              <img className="ClientPage__left-contacts-edit" src={iconEdit} alt="edit" />
            </button>
          </a>
        ) : (
          <p>----</p>
        ),
      },
      {
        titleId: 'justEmail',
        cell: email ? (
          <a className="FieldWithButton" href={`mailto:${email}`}>
            {email}
            <img src={iconEmail} alt="email-icon" />
            <button type="button" onClick={() => setCrmModalType('editEmail')}>
              <img className="ClientPage__left-contacts-edit" src={iconEdit} alt="edit" />
            </button>
          </a>
        ) : (
          <p>----</p>
        ),
      },
    ],
    [phone, email]
  );
  return (
    <>
      <InfoBlock title="justContacts" items={items} />

      <style jsx global>
        {staticStyles}
      </style>
    </>
  );
};

ContactsInfoBlock.propTypes = {
  setCrmModalType: PropTypes.func.isRequired,
};

export default ContactsInfoBlock;
