import axios from 'axios';

export const createPaymentDocument = (id, body) =>
  axios.post(`/backoffice/user/${id}/document/internal/upload/`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const getPaymentNoteDocument = (userId, documentId) =>
  axios.get(`/backoffice/user/${userId}/document/internal/${documentId}/`, { responseType: 'blob' });

export const removePaymentNoteDocument = (userId, documentId) =>
  axios.delete(`/backoffice/user/${userId}/document/internal/${documentId}/`);

export const deletePaymentNote = (id, noteId, paymentType) =>
  axios.delete(`/backoffice/${paymentType}/${id}/note/${noteId}/`);

export const editPaymentNote = (id, noteId, paymentType, data) =>
  axios.put(`/backoffice/${paymentType}/${id}/note/${noteId}/`, { ...data });

export const createPaymentNote = (id, paymentType, data) => axios.post(`/backoffice/${paymentType}/${id}/note/`, data);

export const getPaymentNotes = (id, paymentType) => axios.get(`/backoffice/${paymentType}/${id}/note/`);

export const getPartners = search => axios.get(`/backoffice/partnership/`, { params: { ...search } });
export const exportUserTableData = params => axios.get(`/backoffice/user/export/`, { responseType: 'blob', params });
export const getBackofficeCounters = () => axios.get(`/backoffice/common/counters/`, { validateStatus: false });
export const getFiltersList = () => axios.get(`/backoffice/filters/get_filters/?target=user`);
export const getBackofficeServers = () => axios.get('/backoffice/common/servers/');
