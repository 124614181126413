import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates-gte-react-17';
import { Field } from 'redux-form';
import Select from 'react-select';
import { injectIntl } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { DefaultButton } from '../../index';
import CalendarIcon from './CalendarIcon';
import { staticStyles, getDynamicStyles } from './style';

const today = moment();
const yesterday = moment().subtract(1, 'days');
const startOfWeek = moment().startOf('week');
const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');
const endOfLastWeek = moment().subtract(1, 'weeks').endOf('week');
const startOfMonth = moment().startOf('month');
const startOfLastMonth = moment().subtract(1, 'months').startOf('month');
const endOfLastMonth = moment().subtract(1, 'months').endOf('month');

const formatDate = (firstDate, secondDate) => `${firstDate.format('YYYY-MM-DD')}_${secondDate.format('YYYY-MM-DD')}`;

const monthOptions = moment.monthsShort().map((label, value) => ({ value, label }));
const yearsOptions = [...Array(20).keys()].map(i => ({
  value: moment().year() - i,
  label: moment().year() - i,
}));

const convertToDisplay = rangeDate => {
  const dates = rangeDate.split('_');
  const startDateStr = dates[0] ? moment(dates[0]).format('ll') : '';
  const endDateStr = dates[1] ? moment(dates[1]).format('ll') : '';
  return `${startDateStr} - ${endDateStr}`;
};

const DateRangeField = ({
  input,
  disabled,
  meta: { touched, error },
  areFutureDaysAvailable,
  yearsOptions,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState('startDate');
  const [dateStr, setDateStr] = useState(
    input.value ? convertToDisplay(input.value) : intl.formatMessage({ id: 'justPickDate' })
  );
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const startDateStr = startDate ? startDate.format('ll') : '';
    const endDateStr = endDate ? endDate.format('ll') : '';
    setDateStr(`${startDateStr} - ${endDateStr}`);
    if (startDate && endDate) {
      input.onChange(formatDate(startDate, endDate));
      setIsCalendarVisible(false);
    }
  };

  const handleFocusChange = focused => {
    setFocusedInput(focused || 'startDate');
  };

  const handlePresetClick = (firstDate, secondDate) => {
    setDateStr(`${firstDate.format('ll')} - ${secondDate.format('ll')}`);
    setStartDate(firstDate);
    setEndDate(secondDate);
    input.onChange(formatDate(firstDate, secondDate));
    setIsCalendarVisible(false);
  };

  const toggleCalendar = () => {
    if (!isCalendarVisible) {
      setStartDate(null);
      setEndDate(null);
    }
    setIsCalendarVisible(!isCalendarVisible);
  };
  const renderDatePresets = () => (
    <div className="DateRangeField__presets">
      <DefaultButton type="button" textId="justToday" onClick={() => handlePresetClick(today, today)} />
      <DefaultButton type="button" textId="justYesterday" onClick={() => handlePresetClick(yesterday, yesterday)} />
      <DefaultButton type="button" textId="justThisWeek" onClick={() => handlePresetClick(startOfWeek, today)} />
      <DefaultButton
        type="button"
        textId="justLastWeek"
        onClick={() => handlePresetClick(startOfLastWeek, endOfLastWeek)}
      />
      <DefaultButton type="button" textId="justThisMonth" onClick={() => handlePresetClick(startOfMonth, today)} />
      <DefaultButton
        type="button"
        textId="justLastMonth"
        onClick={() => handlePresetClick(startOfLastMonth, endOfLastMonth)}
      />
    </div>
  );

  const isOutsideRange = day => {
    if (areFutureDaysAvailable) return false;
    return day.isAfter(moment()) && !moment(day).isSame(moment(), 'day');
  };

  /* eslint-disable */
  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div className="DateRangeField__years-navigation">
      <div className="DateRangeField__month-inner">
        <Select
          value={monthOptions[month.month()]}
          onChange={e => onMonthSelect(month, e.value)}
          isClearable={false}
          isSearchable={false}
          noOptionsMessage={() => null}
          hideSelectedOptions
          menuPlacement="bottom"
          placeholder=""
          classNamePrefix="Select"
          maxMenuHeight={240}
          options={monthOptions}
        />
      </div>
      <div className="DateField__years-inner">
        <Select
          value={{ value: month.year(), label: month.year() }}
          onChange={e => {
            onYearSelect(month, e.value);
          }}
          isClearable={false}
          isSearchable={false}
          noOptionsMessage={() => null}
          hideSelectedOptions
          menuPlacement="bottom"
          placeholder=""
          classNamePrefix="Select"
          maxMenuHeight={240}
          options={yearsOptions}
        />
      </div>
    </div>
  );
  /* eslint-enable */

  const renderHiddenField = field => <input {...field.input} type="hidden" />;

  return (
    <div className="DateRangeField">
      <button
        className={classNames(
          'DateRangeField__picker',
          { 'DateRangeField__picker-error': touched && error },
          { 'DateRangeField__picker-disabled': disabled },
          { active: isCalendarVisible },
          { haveText: input.value }
        )}
        type="button"
        onClick={toggleCalendar}>
        <span className="DateRangeField__picker-text">{dateStr}</span>
        <Field input={input} name={input.name} value={dateStr} component={renderHiddenField} />
        <CalendarIcon />
      </button>
      <div className="DateRangeField__inner">
        {isCalendarVisible && (
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDateChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            minimumNights={0}
            numberOfMonths={1}
            renderCalendarInfo={renderDatePresets}
            renderMonthElement={renderMonthElement}
            isOutsideRange={isOutsideRange}
            disabled={disabled}
            hideKeyboardShortcutsPanel
          />
        )}
      </div>

      <span className="DateRangeField__error-text">{touched && error}</span>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

DateRangeField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  intl: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  areFutureDaysAvailable: PropTypes.bool,
  theme: PropTypes.object,
  yearsOptions: PropTypes.array,
};

DateRangeField.defaultProps = {
  disabled: false,
  areFutureDaysAvailable: false,
  theme: {},
  yearsOptions,
};

export default withTheme()(injectIntl(DateRangeField));
export { DateRangeField };
