import axios from 'axios';

export const getCrmManagers = () => axios.get(`/crm/task/managers/`);
export const getAllCrmTasks = search => axios.get(`/crm/task/list/`, { params: { ...search } });
export const getAllReassignTasks = search => axios.get(`/crm/task/reassign/list/`, { params: { ...search } });
export const getCrmTask = taskId => axios.get(`/crm/task/${taskId}/`);
export const getCrmPlan = () => axios.get('/crm/task/plan/');
export const getCrmPlanOverdue = search => axios.get(`/crm/task/plan/overdue/`, { params: { ...search } });
export const getCrmPlanToday = search => axios.get(`/crm/task/plan/today/`, { params: { ...search } });
export const getCrmPlanTomorrow = search => axios.get(`/crm/task/plan/tomorrow/`, { params: { ...search } });
export const createCrmTask = data => axios.post(`/crm/task/create/`, { ...data });
export const updateCrmTask = (taskId, data) => axios.patch(`/crm/task/${taskId}/`, { ...data });
export const removeCrmTask = taskId => axios.delete(`/crm/task/${taskId}/`);
export const finishCrmTask = (taskId, data) => {
  axios.patch(`/crm/task/${taskId}/`, { ...data });
};
