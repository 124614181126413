import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from 'common/components';
import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const AccountTypeButton = ({ id, title, description, onClick, availabilityProblem, theme }) => {
  const onItemClick = () => onClick(id);
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="AccountTypeButton__inner">
      <Tooltip id="AccountTypeButtonTooltip" textId={availabilityProblem} effect="float">
        <button type="button" className="AccountTypeButton" onClick={onItemClick} disabled={!!availabilityProblem}>
          <div className="AccountTypeButton__logo">{title[0]}</div>
          <div className="AccountTypeButton__text-inner">
            <div className="AccountTypeButton__caption">
              <h3>{title}</h3>
            </div>
            <pre className="AccountTypeButton__description">{description}</pre>
          </div>
          <div className="AccountTypeButton__hover">
            <FormattedMessage id="createPlatformAccount" />
          </div>
          <style jsx>{staticStyles}</style>
          <style jsx>{dynamicStyles}</style>
        </button>
      </Tooltip>
    </div>
  );
};

AccountTypeButton.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  availabilityProblem: PropTypes.string,
  theme: PropTypes.object,
};

AccountTypeButton.defaultProps = {
  availabilityProblem: null,
  description: '',
  theme: {},
};

const ThemedAccountTypeButton = withTheme()(AccountTypeButton);

export default ThemedAccountTypeButton;
export { AccountTypeButton };
