import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { FormattedMessage } from 'react-intl';
import InnerHTML from 'dangerously-set-html-content';
import { staticStyles, getDynamicStyles } from './style';
import angleInfo from './angleInfo.svg';
import paymentSystemMoreIcon from './paymentSystemMoreIcon.svg';

const PaymentSystemButton = ({
  ind,
  systemInfo,
  onClick,
  onConfirm,
  active,
  disabled,
  screenSize,
  isDeposit,
  theme,
}) => {
  const { commission, description, iconUrl, descriptionDeposit, descriptionWithdraw, maxAmount, minAmount, title } =
    systemInfo;

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const [activeInfo, setActiveInfo] = useState(false);

  /* eslint-disable */
  return (
    <div
      className={classNames('PaymentSystemButton', {
        'PaymentSystemButton--active': active,
        'PaymentSystemButton--disabled': disabled,
      })}
      onClick={() => {
        if (disabled) return;
        if (screenSize <= 767) {
          onClick();
          setActiveInfo(!activeInfo);
        } else {
          onClick();
        }
      }}
      onKeyPress={disabled ? null : onClick}
      onMouseOver={() => (screenSize > 767 ? setActiveInfo(true) : null)}
      onMouseOut={() => (screenSize > 767 ? setActiveInfo(false) : null)}>
      {/* eslint-enable */}

      {/* payment-system-inner */}
      <div className="PaymentSystemButton__inner">
        <img className="PaymentSystemButton__icon" src={iconUrl} alt="paymentSystem" />
        <span className="PaymentSystemButton__title">{title}</span>
      </div>

      {/* payment-system-more-icon */}
      {screenSize <= 767 && (
        <div
          className={classNames('PaymentSystemButton__more-icon', {
            'PaymentSystemButton__more-icon--active': activeInfo,
          })}>
          <img src={paymentSystemMoreIcon} alt="payment-system-more-icon" />
        </div>
      )}

      {/* payment-system-info */}
      {/* eslint-disable */}
      <div
        className={classNames('PaymentSystemButton__info', {
          'PaymentSystemButton__info--left': ind === 1 || ind === 3,
          'PaymentSystemButton__info--right': ind === 2 || ind === 4,
          'PaymentSystemButton__info--active': activeInfo,
        })}
        onMouseOver={e => {
          if (screenSize <= 767) return;
          e.stopPropagation();
          setActiveInfo(false);
        }}>
        {/* eslint-enable */}
        <img
          className={classNames('PaymentSystemButton__info-angle', {
            'PaymentSystemButton__info-angle--left': ind === 1 || ind === 3,
            'PaymentSystemButton__info-angle--right': ind === 2 || ind === 4,
          })}
          src={angleInfo}
          alt="angle-info"
        />
        <div className="PaymentSystemButton__info-list">
          <div className="PaymentSystemButton__info-line">
            <FormattedMessage id={isDeposit ? 'justMinDeposit' : 'justMinWithdrawal'}>
              {txt => <div className="PaymentSystemButton__info-line-title">{txt}:</div>}
            </FormattedMessage>
            <div className="PaymentSystemButton__info-line-desc">{minAmount}</div>
          </div>
          <div className="PaymentSystemButton__info-line">
            <FormattedMessage id={isDeposit ? 'justMaxDeposit' : 'justMaxWithdrawal'}>
              {txt => <div className="PaymentSystemButton__info-line-title">{txt}:</div>}
            </FormattedMessage>
            <div className="PaymentSystemButton__info-line-desc">{maxAmount}</div>
          </div>
          <div className="PaymentSystemButton__info-line">
            <FormattedMessage id="justCommission">
              {txt => <div className="PaymentSystemButton__info-line-title">{txt}:</div>}
            </FormattedMessage>
            <div className="PaymentSystemButton__info-line-desc">{commission}</div>
          </div>
        </div>
        <div className="PaymentSystemButton__info-desc">{description}</div>
        <div className="PaymentSystemButton__info-desc-2">
          <InnerHTML html={isDeposit ? descriptionDeposit : descriptionWithdraw} key={1} />
        </div>

        {screenSize <= 767 && <DefaultButton type="submit" textId="justConfirm" filled onClick={onConfirm} />}
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

PaymentSystemButton.propTypes = {
  ind: PropTypes.number,
  systemInfo: PropTypes.object.isRequired,
  isDeposit: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  screenSize: PropTypes.number,
  theme: PropTypes.object,
};

PaymentSystemButton.defaultProps = {
  ind: 0,
  onConfirm: () => {},
  active: false,
  disabled: false,
  screenSize: 1920,
  theme: {},
};

export default withTheme()(PaymentSystemButton);
export { PaymentSystemButton };
