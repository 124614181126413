import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  * {
  }
  .BaseAsyncSelectField--RemoveArrow {
    .select__indicator {
      display: none;
    }
  }

  .BaseAsyncSelectField {
    width: 100%;

    &__label {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      color: #0f0f0f;
      opacity: 0.5;
      margin: 0 0 4px 0;

      &--error {
        color: #ff4085;
      }
    }

    .select__control {
      border: 1px solid #e9e9e9;
    }

    .select__menu {
      z-index: 999;
      margin-top: 2px;
      border-radius: 0;
      > .select__menu-list {
        padding: 0;
      }
    }
    .select__indicator-separator {
      display: none;
    }

    .select__placeholder {
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .BaseAsyncSelectField__error {
      .BaseAsyncSelectField__label {
        color: ${colors.error.hex} !important;
      }
      .select__control--is-focused {
        border: 1px solid ${colors.error.hex} !important;
        &:after {
          border-color: ${colors.error.hex} !important;
        }
      }
      .select__control {
        border-color: ${colors.error.hex} !important;
        &:after {
          border-color: ${colors.error.hex} !important;
        }
      }
    }

    .BaseAsyncSelectField__error-text {
      color: ${colors.error.hex};
      font-weight: 300;
      font-size: 14px;
    }
  `;
};

export { staticStyles, getDynamicStyles };
