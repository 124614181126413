import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars-2';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const DashboardHistory = ({ theme, usersHistory }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const cards =
    usersHistory && usersHistory.results
      ? usersHistory.results.map(event => {
          const data = moment(event.at).format('DD.MM.YYYY');
          const time = moment(event.at).format('LT');
          return {
            email: event.user.email,
            name: event.user.fullName,
            description: event.description,
            data,
            time,
          };
        })
      : [];

  return (
    <div className="DashboardHistory">
      <FormattedMessage id="boHistory">{txt => <h3 className="DashboardHistory__caption">{txt}</h3>}</FormattedMessage>
      <div className="DashboardHistory__cards-inner">
        <Scrollbars className="DashboardHistory__scroll" autoHeight autoHeightMin="100%" autoHeightMax="280px">
          {cards.map((card, index) => (
            <div className="DashboardHistory__card" key={index}>
              <div className="Card__avatar-inner">
                <Gravatar md5={md5(card.email)} size={40} />
              </div>
              <div className="Card__description-inner">
                <div className="Card__caption">
                  <h4>{card.name}</h4>
                </div>
                <div className="Card__description">
                  <p>{card.description}</p>
                </div>
                <div className="Card__date">
                  <span>{card.data}</span>
                  <span>{card.time}</span>
                </div>
              </div>
            </div>
          ))}
        </Scrollbars>
      </div>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

DashboardHistory.propTypes = {
  theme: PropTypes.object,
  usersHistory: PropTypes.object,
};

DashboardHistory.defaultProps = {
  theme: {},
  usersHistory: {},
};

export default compose(withTheme())(DashboardHistory);
export { DashboardHistory };
