import { fork, put, call, take } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import { SubmissionError } from 'redux-form';
import * as actionTypes from './actions';
import * as api from '../api';

export function* getManagersSampleListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getManagersSampleList, search);
    if (status < 300) {
      yield put(actionTypes.getManagersSampleList.success(data));
    } else {
      yield put(actionTypes.getManagersSampleList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getManagersSampleList.failure());
  }
}

export function* getManagersSampleListWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.GET_MANAGERS_SAMPLE_LIST[REQUEST]);
    yield call(getManagersSampleListSaga, data);
  }
}

export function* getManagersInfoSaga({ managerId }) {
  try {
    const { status, data } = yield call(api.getManagerInfo, managerId);
    if (status < 300) {
      yield put(actionTypes.getManagerInfo.success(data));
    } else {
      yield put(actionTypes.getManagerInfo.failure(data));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getManagerInfo.failure());
  }
}

export function* getManagerInfoWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.GET_MANAGER_INFO[REQUEST]);
    yield call(getManagersInfoSaga, data);
  }
}

export function* createManagerSampleSaga(payload) {
  try {
    const { status, data } = yield call(api.createManagerSample, payload);
    if (status < 300) {
      yield put(actionTypes.createManagerSample.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(actionTypes.createManagerSample.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.createManagerSample.failure());
  }
}

export function* createManagerSampleWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.createManagerSample.REQUEST);
    yield call(createManagerSampleSaga, payload);
  }
}

export function* getManagerSampleSaga({ search }) {
  try {
    const { status, data } = yield call(api.getManagerSample, search);
    if (status < 300) {
      yield put(actionTypes.getManagerSample.success(data));
    } else {
      yield put(actionTypes.getManagerSample.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getManagerSample.failure());
  }
}

export function* getManagerSampleWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.GET_MANAGER_SAMPLE[REQUEST]);
    yield call(getManagerSampleSaga, data);
  }
}

export function* editManagerSampleSaga({ sampleId, ...payload }) {
  try {
    const { status, data } = yield call(api.editManagerSample, sampleId, payload);
    if (status < 300) {
      yield put(actionTypes.editManagerSample.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(actionTypes.editManagerSample.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.editManagerSample.failure());
  }
}

export function* editManagerSampleWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.editManagerSample.REQUEST);
    yield call(editManagerSampleSaga, payload);
  }
}

export function* getManagersGroupListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getManagersGroupList, search);
    if (status < 300) {
      yield put(actionTypes.getManagersGroupList.success(data));
    } else {
      yield put(actionTypes.getManagersGroupList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getManagersGroupList.failure());
  }
}

export function* getManagersGroupListWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.GET_MANAGERS_GROUP_LIST[REQUEST]);
    yield call(getManagersGroupListSaga, data);
  }
}

export function* createManagerGroupSaga({ groups, ...formValues }) {
  const managers = Object.values(groups).map(item => ({ id: item.manager }));
  const chiefs = Object.values(groups)
    .filter(item => item.chief === 'chief')
    .map(item => ({ id: item.manager }));
  const payload = { ...formValues, managers, chiefs };
  try {
    const { status, data } = yield call(api.createManagerGroup, payload);
    if (status < 300) {
      yield put(actionTypes.createManagerGroup.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(actionTypes.createManagerGroup.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.createManagerGroup.failure());
  }
}

export function* createManagerGroupWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.createManagerGroup.REQUEST);
    yield call(createManagerGroupSaga, payload);
  }
}

export function* getManagerGroupDetailsSaga({ managerGroupId }) {
  try {
    const { status, data } = yield call(api.getManagerGroupDetails, managerGroupId);
    if (status < 300) {
      yield put(actionTypes.getManagerGroupDetails.success(data));
    } else {
      yield put(actionTypes.getManagerGroupDetails.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getManagerGroupDetails.failure());
  }
}

export function* getManagerGroupDetailsWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.GET_MANAGER_GROUP_DETAILS[REQUEST]);
    yield call(getManagerGroupDetailsSaga, data);
  }
}

export function* editManagerGroupSaga({ managerGroupId, groups, ...formValues }) {
  const managers = Object.values(groups)
    .filter(item => item)
    .map(item => ({ id: item.manager }));
  const chiefs = Object.values(groups)
    .filter(item => item && item.chief === 'chief')
    .map(item => ({ id: item.manager }));
  const payload = { ...formValues, managers, chiefs };
  try {
    const { status, data } = yield call(api.editManagerGroup, managerGroupId, payload);
    if (status < 300) {
      yield put(actionTypes.editManagerGroup.success(data));
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(actionTypes.editManagerGroup.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(actionTypes.editManagerGroup.failure());
  }
}

export function* editManagerGroupWatcherSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.editManagerGroup.REQUEST);
    yield call(editManagerGroupSaga, payload);
  }
}

export function* deleteManagerGroupSaga({ managerGroupId, search }) {
  try {
    const { status, data } = yield call(api.deleteManagerGroup, managerGroupId);
    if (status < 300) {
      yield put(actionTypes.deleteManagerGroup.success(data));
      yield call(getManagersGroupListSaga, { search });
    } else {
      yield put(actionTypes.deleteManagerGroup.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.deleteManagerGroup.failure());
  }
}

export function* deleteManagerGroupWatcherSaga() {
  while (true) {
    const data = yield take(actionTypes.DELETE_MANAGER_GROUP[REQUEST]);
    yield call(deleteManagerGroupSaga, data);
  }
}

export function* getFiltersListSaga() {
  try {
    const { status, data } = yield call(api.getFiltersList);
    if (status < 300) {
      yield put(actionTypes.getFiltersList.success(data));
    } else {
      yield put(actionTypes.getFiltersList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(actionTypes.getFiltersList.failure());
  }
}

export function* getFiltersListWatcherSaga() {
  while (true) {
    yield take(actionTypes.GET_FILTERS_LIST[REQUEST]);
    yield call(getFiltersListSaga);
  }
}

export default [
  fork(getManagersSampleListWatcherSaga),
  fork(getManagerInfoWatcherSaga),
  fork(createManagerSampleWatcherSaga),
  fork(getManagerSampleWatcherSaga),
  fork(editManagerSampleWatcherSaga),
  fork(getManagersGroupListWatcherSaga),
  fork(createManagerGroupWatcherSaga),
  fork(getManagerGroupDetailsWatcherSaga),
  fork(editManagerGroupWatcherSaga),
  fork(deleteManagerGroupWatcherSaga),
  fork(getFiltersListWatcherSaga),
];
