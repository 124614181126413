import React from 'react';
import { BaseTable } from 'crm/_components';
import classNames from 'classnames';
import { staticStyles } from './style';
import testData from './testData';
import iconBadClient from './images/iconBadClient.svg';
import iconNotCompleted from './images/iconNotCompleted.svg';
import iconNotSuccess from './images/iconNotSuccess.svg';
import iconSuccess from './images/iconSuccess.svg';

const AllTasksTableMonitoring = ({ externalClass }) => {
  /* eslint-disable */
  const columns = [
    {
      accessor: 'contact',
      Header: 'crmContact',
      Cell: ({
        row: {
          original: { contact },
        },
      }) => (
        <div className="contact">{contact}</div>
      ),
    },
    {
      accessor: 'lastMail',
      Header: 'crmLastMail',
      Cell: ({
        row: {
          original: { lastMail },
        },
      }) => (
        <div className="last-mail">{lastMail}</div>
      ),
    },
    {
      accessor: 'monitoringStatus',
      Header: 'crmMonitoringStatus',
      Cell: ({
        row: {
          original: { monitoringStatus },
        },
      }) => (
        <div className={classNames('monitoring-status', {
          'monitoring-status--not-success': monitoringStatus === 'Not success',
          'monitoring-status--success': monitoringStatus === 'Success',
          'monitoring-status--not-completed': monitoringStatus === 'Not completed',
          'monitoring-status--bad-client': monitoringStatus === 'Bad client',
        })}>
          <div className="monitoring-status__icon">
            {monitoringStatus === 'Not success' && (
              <img src={iconNotSuccess} alt={monitoringStatus} />
            )}
            {monitoringStatus === 'Success' && (
              <img src={iconSuccess} alt={monitoringStatus} />
            )}
            {monitoringStatus === 'Not completed' && (
              <img src={iconNotCompleted} alt={monitoringStatus} />
            )}
            {monitoringStatus === 'Bad client' && (
              <img src={iconBadClient} alt={monitoringStatus} />
            )}
          </div>
          <div className="monitoring-status__text">{monitoringStatus}</div>
        </div>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className={classNames('AllTasksTableMonitoring', externalClass)}>
      {testData.length && <BaseTable columns={columns} data={testData} onRowClick={() => {}} />}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default AllTasksTableMonitoring;
