/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import { PageContentPreloader, DateCell, StatusCell } from 'common/components';
import { BaseButton } from 'crm/_components';
import { NewCRMPagination, NewCRMTable } from '../../../_newComponents';
import { downloadContactsExport, getContactsExports } from '../../_redux';

import { staticStyles } from './style';

const STATUSES = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
};

const ReportsPage = ({
  contactsExportData,
  contactsExportDataIsLoaded,
  getContactsExports,
  downloadContactsExport,
  location,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getContactsExports({ search: query });
  }, [query]);

  const downloadExport = (id, name) => {
    downloadContactsExport({ id, name });
  };

  const columns = [
    {
      accessor: 'id',
      Header: 'justId',
      Cell: ({ rowData: { id } }) => <span>{id}</span>,
    },
    {
      accessor: 'name',
      Header: 'justName',
      Cell: ({ rowData: { name } }) => <span>{name}</span>,
    },
    {
      accessor: 'createdBy',
      Header: 'justCreatedBy',
      Cell: ({ rowData: { createdBy } }) => <span>{createdBy}</span>,
    },
    {
      accessor: 'justDate',
      Header: 'justDate',
      blueBg: true,
      Cell: ({ rowData: { created } }) => <DateCell value={created} />,
    },
    {
      accessor: 'justStatus',
      Header: 'justStatus',
      Cell: ({ rowData: { id, name, status } }) =>
        status === STATUSES.SUCCESS ? (
          <BaseButton textId="justDownload" type="button" onClick={() => downloadExport(id, name)} />
        ) : (
          <StatusCell statusCode={status} />
        ),
    },
  ];

  return (
    <div className="ReportsTable">
      <PageContentPreloader ready={contactsExportDataIsLoaded} firstLaunchOnly type="accounts">
        {/* temporaly full data due backend not sending count, and otehrs */}
        <NewCRMTable data={contactsExportData.results} columns={columns} />
        {!!contactsExportData.count && <NewCRMPagination count={contactsExportData.count} />}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ReportsPage.propTypes = {
  contactsExportData: PropTypes.object.isRequired,
  contactsExportDataIsLoaded: PropTypes.bool.isRequired,
  getContactsExports: PropTypes.func.isRequired,
  downloadContactsExport: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      contactsExportData: state.crm.contacts.contactsExportData,
      contactsExportDataIsLoaded: state.crm.contacts.contactsExportDataIsLoaded,
    }),
    {
      getContactsExports: search => getContactsExports.request(search),
      downloadContactsExport: data => downloadContactsExport.request(data),
    }
  )
)(ReportsPage);
