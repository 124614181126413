import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { required, email, length } from 'redux-form-validators';
import { isValidNumber } from 'libphonenumber-js';

import { Agreements, ReduxTextField, PhoneField, DefaultButton } from 'common/components';
import { withTheme } from 'common/styling/theme';
import TelegramAuthWidget from '../../../_components/TelegramAuthWidget';

import { loginWithTelegramFromReg, registration, togglePartnerCodeAction } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

const validatePhoneNumber = phoneNumber => {
  if (!isValidNumber(phoneNumber)) {
    return <FormattedMessage id="validationErrorWrongPhone" />;
  }
};

const validate = values => {
  const errors = {};

  if (values.passwordRepeat !== values.password) {
    errors.passwordRepeat = <FormattedMessage id="form.errors.passwordRepeat" />; // eslint-disable-line
  }

  if (!/^\d+$/.test(values.partnerCode)) {
    errors.partnerCode = <FormattedMessage id="form.errors.partnerCode" />; // eslint-disable-line
  }

  return errors;
};

function RegistrationForm({
  partnership,
  handleSubmit,
  submitting,
  error,
  partnerCodeIsOpen,
  disclamer,
  requirePartnerCode,
  togglePartnerCode,
  theme,
  registrationSettings,
  initialValues,
  dispatch,
  isTelegramAuth,
  telegramData,
  loginWithTelegramFromReg,
}) {
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const showPassword = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };

  useEffect(() => {
    if (!partnerCodeIsOpen && (initialValues.partnerCode || requirePartnerCode)) {
      togglePartnerCode();
    }
  }, []);

  const submit = handleSubmit(values => {
    const element = document.getElementById('registration-page-partner-code');
    let isPartnerCodeCustomlyHidden = false;
    if (element) {
      const style = window.getComputedStyle(element);
      isPartnerCodeCustomlyHidden = style.getPropertyValue('display') === 'none';
    }
    const { partnerCode, ...data } = values;
    let sendData = partnerCodeIsOpen ? { ...values, isPartnerCodeCustomlyHidden } : data;

    if (isTelegramAuth && telegramData) sendData = { ...sendData, ...telegramData };

    return registration(sendData, dispatch);
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleTelegramAuth = data => loginWithTelegramFromReg(data);

  return (
    <form id="main-registration-form" className="RegistrationForm" onSubmit={submit}>
      {/* temp for tixee */}
      {window.location.hostname !== 'my.tixee.com' && disclamer?.enabled && disclamer?.subject && (
        <div className="disclamer__text">{disclamer.subject}</div>
      )}
      <div className="RegistrationForm__inputs-inner">
        <Field
          component={ReduxTextField}
          type="text"
          name="firstName"
          textId="justFirstName"
          validate={[required()]}
          disabled={submitting}
        />
        <Field
          component={ReduxTextField}
          type="text"
          name="lastName"
          textId="justLastName"
          validate={[required()]}
          disabled={submitting}
        />
      </div>
      <Field
        component={ReduxTextField}
        type="email"
        name="email"
        textId="authEmail"
        validate={[required(), email()]}
        disabled={submitting}
      />
      <Field
        component={PhoneField}
        name="phone"
        textId="justPhone"
        validate={[required(), validatePhoneNumber]}
        disabled={submitting}
      />
      {!isTelegramAuth && (
        <div className="RegistrationForm__inputs-inner">
          <Field
            component={ReduxTextField}
            type={passwordFieldType}
            name="password"
            textId="authPassword"
            validate={[required(), length({ min: 6 })]}
            disabled={submitting}
            action={showPassword}
            actionTooltipId={passwordFieldType === 'password' ? 'showPassword' : 'hidePassword'}
            actionClassName={passwordFieldType === 'password' ? 'show' : 'hide'}
          />
          <Field
            component={ReduxTextField}
            type="password"
            name="passwordRepeat"
            textId="authPasswordRepeat"
            validate={[required()]}
            disabled={submitting}
          />
        </div>
      )}
      {partnership.enabled && partnerCodeIsOpen && (
        <Field
          id="registration-page-partner-code"
          component={ReduxTextField}
          type="text"
          name="partnerCode"
          textId="authPartnerCodeField"
          validate={[required()]}
          disabled={submitting}
        />
      )}
      {partnership.enabled && !requirePartnerCode && (
        <div id="registration-page-partner-code-inner" className="PartnerCode__inner">
          <FormattedMessage id={partnerCodeIsOpen ? 'authNoPartnerCode' : 'authPartnerCode'}>
            {txt => (
              <button type="button" onClick={togglePartnerCode}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>
      )}
      <Agreements agreements={registrationSettings.agreements} submitting={submitting} />
      {/* temp for tixie */}
      {window.location.hostname === 'my.tixee.com' && disclamer?.enabled && disclamer?.subject && (
        <div className="disclamer__text disclamer__text--noMG">{disclamer.subject}</div>
      )}
      <DefaultButton
        id="registration-page-registration-button"
        textId="authDoRegister"
        type="submit"
        disabled={submitting}
        loading={submitting}
        filled
      />
      <TelegramAuthWidget handleTelegramAuth={handleTelegramAuth} />
      {error && <strong className="formError">{error}</strong>}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
}

RegistrationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
  partnership: PropTypes.object.isRequired,
  partnerCodeIsOpen: PropTypes.bool,
  disclamer: PropTypes.object,
  requirePartnerCode: PropTypes.bool.isRequired,
  togglePartnerCode: PropTypes.func.isRequired,
  registrationSettings: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    partnerCode: PropTypes.string,
  }),
  isTelegramAuth: PropTypes.bool.isRequired,
  telegramData: PropTypes.object.isRequired,
  loginWithTelegramFromReg: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  error: '',
  theme: {},
  disclamer: {},
  partnerCodeIsOpen: false,
  initialValues: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      registrationSettings: state.interfaceConfig.registration,
      partnership: state.interfaceConfig.partnership,
      partnerCodeIsOpen: state.auth.registration.partnerCodeIsOpen,
      requirePartnerCode: state.interfaceConfig.requirePartnerCode,
      disclamer: state.interfaceConfig.disclamer,
    }),
    dispatch => ({
      togglePartnerCode: () => dispatch(togglePartnerCodeAction()),
      loginWithTelegramFromReg: data => dispatch(loginWithTelegramFromReg.request(data)),
    })
  ),
  reduxForm({
    form: 'registration',
    validate,
    onSubmitSuccess: (result, dispatch, { history, isEmailConfirmationEnabled, displayThankBlock }) => {
      if (!isEmailConfirmationEnabled) {
        history.push('/');
      } else {
        displayThankBlock();
      }
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })
)(RegistrationForm);
export { RegistrationForm };
