import React from 'react';
import { compose } from 'redux';
import { staticStyles, getDynamicStyles } from './style';
import TagItem from '../TagItem/TagItem';

const Tags = ({ tags, tableWidth, onMouseLeave, onTagSelect, id }) => {
  const dynamicStyles = getDynamicStyles(tableWidth);
  return (
    <div className="Tags" onMouseLeave={onMouseLeave}>
      {tags.map(item => (
        <TagItem onTagSelect={onTagSelect} tag={item.name} key={`TagItem__${item.id}`} tagId={item.id} id={id} />
      ))}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

Tags.propTypes = {};

Tags.defaultProps = {};

export default compose()(Tags);
