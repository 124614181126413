import css from 'styled-jsx/css';

const staticStyles = css.global`
  .Tags {
    background: #fff;
    position: absolute;
    display: flex;
    gap: 10px;
    top: -2px;
    z-index: 10;
    flex-wrap: wrap;
    max-width: 80vw;
  }
`;

const getDynamicStyles = ({ tableWidth, cellWidth }) => {
  const tagsWidth = tableWidth - cellWidth;
  return css.global`
    .Tags {
      left: ${cellWidth + 'px'};
      width: ${tagsWidth + 'px'};
    }
  `;
};

export { staticStyles, getDynamicStyles };
