import React, { useMemo, useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, withRouter, matchPath } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import ReactTooltip from 'react-tooltip';

import { closeMenu } from 'interface/user/_redux/actions';
import { connect } from 'react-redux';
import MENU_ICONS from '../LeftNavigationMenuIcons';
import LeftNavigationOpenLinks from '../LeftNavigationOpenLinks';

import { staticStyles, getDynamicStyles } from './style';
// import infoIconWallet from './infoIconWallet.svg';
import angleIconWallet from './angleIconWallet.svg';

const LeftNavigationLink = ({
  prefix,
  title,
  slug,
  imageIcon,
  isPartner,
  url,
  exact,
  items,
  onClick,
  absolute,
  hintContent,
  unreadCounter,
  counterLink,
  currentInterface,
  theme,
  history,
  dispatch,
  location,
  walletBalance,
  intl,
}) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const path = url === '' ? 'path' : `${prefix}${url}`;
  const isExact = url === '' ? true : exact;
  const [isOpen, setOpen] = useState(matchPath(location.pathname, { path }));

  const handleClick = e => {
    if (items) {
      setOpen(!isOpen);
      e.preventDefault();
    } else {
      onClick();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };
  const Icon = MENU_ICONS[title] || MENU_ICONS[slug] || MENU_ICONS.default;
  const isCurrentPage = counterLink.indexOf(`${location.pathname}`) >= 0;

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useMemo(() => {
    window.addEventListener('resize', () => setScreenSize(window.innerWidth));
  }, []);

  const [activeWalletIcon, setActiveWalletIcon] = useState(false);

  const showWalletBalance = screenSize <= 991 && title === 'menuWallet';

  const LeftNavigationLinkIconClass = classNames('LeftNavigationLink__icon', {
    'LeftNavigationLink__icon--crm': currentInterface.slug === 'crm',
  });

  const isUserPartnerAddedSlug = useMemo(() => {
    if (title === 'menuPartnership' && isPartner) {
      return '-isPartner';
    }
    return '';
  }, []);

  const linkContent = (
    <>
      <div className={LeftNavigationLinkIconClass}>
        {!!unreadCounter && (
          <button
            type="button"
            onClick={e => {
              if (counterLink) {
                e.preventDefault();

                if (isCurrentPage) {
                  window.location.href = counterLink;
                } else {
                  history.push(counterLink);
                }
              }
            }}
            className="LeftNavigationLink__counter">
            {unreadCounter > 99 ? '99+' : unreadCounter}
          </button>
        )}

        {!imageIcon ? (
          <>
            <span className="icon">
              <Icon.default />
            </span>
            <span className="activeIcon">
              <Icon.active />
            </span>
          </>
        ) : (
          <img style={{ width: '21px', objectFit: 'cover' }} src={imageIcon} alt={title} />
        )}
      </div>
      <div
        className={classNames('LeftNavigationLink__p', {
          'LeftNavigationLink__menuWallet-p': title === 'menuWallet',
          'LeftNavigationLink__p--crm': currentInterface.slug === 'crm',
        })}>
        {!imageIcon ? <FormattedMessage id={title} /> : <span>{title}</span>}
        <i className="LeftNavigationLink__arrow-icon" />
        {showWalletBalance && <div className="LeftNavigationLink__menuWallet-balance">${walletBalance ?? '0.00'}</div>}
      </div>
    </>
  );

  return (
    <>
      <div
        id={`LeftNavigationLink__${title}-${currentInterface.slug}${isUserPartnerAddedSlug}`}
        className={classNames('LeftNavigationLink__link-wrapper', {
          'LeftNavigationLink__link-wrapper--menuWallet': title === 'menuWallet',
        })}
        key={title}>
        {absolute ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames('LeftNavigationLink', {
              LeftNavigationLinkCategory: !!items,
              LeftNavigationLinkCategory__open: isOpen,
              [`LeftNavigation--${currentInterface.slug}`]: true,
            })}
            onClick={handleClick}>
            {linkContent}
          </a>
        ) : (
          <>
            <NavLink
              exact={isExact}
              to={`${prefix}${url}`}
              className={classNames('LeftNavigationLink', {
                LeftNavigationLinkCategory: !!items,
                LeftNavigationLinkCategory__open: isOpen,
                [`LeftNavigationLink--${currentInterface.slug}`]: true,
              })}
              onClick={handleClick}>
              {currentInterface.slug === 'crm' && (
                <div className="LeftNavigationLink__Tooltip">{intl.formatMessage({ id: title })}</div>
              )}
              {linkContent}
            </NavLink>
            {title === 'menuWallet' /* && screenSize > 991 */ && (
              <>
                {/* eslint-disable */}
                <div
                  className="LeftNavigationLink__menuWallet-icon"
                  onMouseOver={() => {
                    if (screenSize <= 991) return;
                    setActiveWalletIcon(true);
                  }}
                  onMouseOut={() => {
                    if (screenSize <= 991) return;
                    setActiveWalletIcon(false);
                  }}
                  onClick={() => {
                    if (screenSize > 991) return;
                    history.push('/wallet-info/');
                    dispatch(closeMenu());
                  }}>
                  <div
                    className={classNames('LeftNavigationLink__menuWallet-icon-img', {
                      'LeftNavigationLink__menuWallet-icon-img--active': activeWalletIcon,
                    })}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="10" cy="10" r="9.5" stroke="#0AA1E2" />
                      <path
                        d="M10.786 15H9.092V7.678H10.786V15ZM10.968 5.536C10.968 5.85333 10.8653 6.10533 10.66 6.292C10.4547 6.47867 10.212 6.572 9.932 6.572C9.64267 6.572 9.39533 6.47867 9.19 6.292C8.98467 6.10533 8.882 5.85333 8.882 5.536C8.882 5.20933 8.98467 4.95267 9.19 4.766C9.39533 4.57933 9.64267 4.486 9.932 4.486C10.212 4.486 10.4547 4.57933 10.66 4.766C10.8653 4.95267 10.968 5.20933 10.968 5.536Z"
                        fill="#0AA1E2"
                      />
                    </svg>
                    <img
                      className={classNames('LeftNavigationLink__menuWallet-icon-angle', {
                        'LeftNavigationLink__menuWallet-icon-angle--active': activeWalletIcon,
                      })}
                      src={angleIconWallet}
                      alt="angle-icon-wallet"
                    />
                  </div>
                  <div
                    className={classNames('LeftNavigationLink__menuWallet-icon-info', {
                      'LeftNavigationLink__menuWallet-icon-info--active': activeWalletIcon,
                    })}>
                    <div className="LeftNavigationLink__menuWallet-icon-info-title">
                      <FormattedMessage id="boAWallet">{txt => txt}</FormattedMessage>
                    </div>
                    <div className="LeftNavigationLink__menuWallet-icon-info-text">
                      <FormattedMessage id="boAWalletText">{txt => txt}</FormattedMessage>
                    </div>
                    <div className="LeftNavigationLink__menuWallet-icon-info-link">
                      <NavLink
                        to="/wallet-info/"
                        onClick={e => {
                          e.stopPropagation();
                          setActiveWalletIcon(false);
                        }}>
                        <FormattedMessage id="boAWalletLink">{txt => txt}</FormattedMessage>
                      </NavLink>
                    </div>
                  </div>
                </div>
                {/* eslint-enable */}
              </>
            )}
          </>
        )}
        {!!hintContent && (
          <button type="button" className="LeftNavigationLink__tooltip" onClick={e => e.stopPropagation()}>
            <span data-tip data-for="hint" className="LeftNavigationLink__info-icon Tooltip__text">
              !
            </span>
            <ReactTooltip id="hint" className="Tooltip" place="bottom" delayHide={500} effect="solid" clickable>
              {hintContent}
            </ReactTooltip>
          </button>
        )}
      </div>
      {items && (
        <div
          id={`LeftNavigationLinkItems-${title}-${currentInterface.slug}${isUserPartnerAddedSlug}`}
          className={classNames('LeftNavigationLinkItems', {
            LeftNavigationLinkItems__open: isOpen,
          })}>
          {items
            .filter(item => typeof item === 'object')
            .map(item => (
              <React.Fragment key={`${item.slug} ${item.title}`}>
                {(currentInterface.slug === 'admin' || item.isAvailable) && (
                  <LeftNavigationOpenLinks prefix={`${prefix}${url}`} item={item} onClick={onClick} />
                )}
              </React.Fragment>
            ))}
        </div>
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </>
  );
};

LeftNavigationLink.propTypes = {
  prefix: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isPartner: PropTypes.bool,
  slug: PropTypes.string,
  imageIcon: PropTypes.string,
  url: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  currentInterface: PropTypes.object,
  hintContent: PropTypes.node,
  exact: PropTypes.bool,
  items: PropTypes.array,
  onClick: PropTypes.func,
  absolute: PropTypes.bool,
  unreadCounter: PropTypes.number,
  counterLink: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  walletBalance: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

LeftNavigationLink.defaultProps = {
  slug: '',
  imageIcon: null,
  exact: false,
  isPartner: false,
  items: null,
  onClick: null,
  absolute: false,
  hintContent: null,
  unreadCounter: null,
  counterLink: '',
  currentInterface: {},
  theme: {},
};

export default compose(
  withTheme(),
  injectIntl,
  withRouter,
  connect(state => ({
    walletBalance: state.user.profile.walletBalance,
  }))
)(LeftNavigationLink);
export { LeftNavigationLink };
