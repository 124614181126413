import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Switch, Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Raven from 'raven-js';
import { Auth } from 'auth';
import { User } from 'interface/user';
import { Field, reduxForm } from 'redux-form';
import { CheckAuthHOC } from 'common/HOCs';

import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from 'common/styling/globalStyle';
import datepickerStyle from 'common/styling/datepickerStyle';
import { required } from 'redux-form-validators';
import {
  staticStyles as monthPickerStaticStyles,
  getDynamicStyles as getMonthPickerDynamicStyles,
} from 'common/styling/monthPickerStyle';

import { isRTL, setCookie } from 'common/utils';
import { ReduxTextField } from 'common/components';
import Widgets from './widgets';
import WelcomeSetup from './welcome-setup';

Raven.config(process.env.REACT_APP_SENTRY_DSN).install();

const TestComponent1 = () => (
  <div className="TestComponent">
    form
    <form>
      <Field
        component={ReduxTextField}
        autoComplete="off"
        type="text"
        name="title"
        textId="TestMe"
        validate={[required()]}
      />
      <Field component={ReduxTextField} autoComplete="off" type="text" name="titleNotRequired" textId="TestMeToo" />
    </form>
    standard
    <input />
    <br />
    standard react
    <div>{React.createElement('input', { className: 'react-input' })}</div>
    <br />
    kana
    <input inputMode="kana" />
    <br />
    katakana
    <input inputMode="katakana" />
    <br />
    fifth
    <input className="input-font" />
    <style jsx>{`
      .TestComponent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > input {
          width: 200px;
          height: 40px;
          background: white;
        }
        .input-font {
          font-family: sans-serif;
        }
        > div {
          width: 200px;
          height: 40px;
          background: white;
        }
      }
    `}</style>
  </div>
);

const TestComponent = reduxForm({
  form: 'TestLangForm',
  onSubmitSuccess: (result, dispatch, props) => {
    props.toggleModal();
  },
})(TestComponent1);

const App = ({ theme, title, favicon, history, customCss }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const monthPickerDynamicStyles = Object.keys(theme).length ? getMonthPickerDynamicStyles(theme) : ` `;
  const dir = isRTL ? 'rtl' : 'ltr';

  const query = queryString.parse(history.location.search);

  Object.entries(query).forEach(([key, value]) => {
    if (key.startsWith('utm_')) {
      setCookie(key, value, { expires: 0 });
    }
  });

  return (
    <div className="App" dir={dir}>
      <Helmet
        title={title}
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            href: favicon,
            sizes: '194x194',
          },
        ]}
        meta={[
          { property: 'og:image', content: favicon },
          { property: 'og:title', content: title },
        ]}
      />
      <Switch>
        <Route path="/widgets" component={Widgets} />
        <Route path="/lang-test" component={TestComponent} />
        <Route path="/auth" component={Auth} />
        <Route path="/welcome-setup" component={WelcomeSetup} />
        <Route component={User} />
      </Switch>
      <style className="custom-styles">{customCss}</style>
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
      <style jsx global>
        {datepickerStyle}
      </style>
      <style jsx global>
        {monthPickerStaticStyles}
      </style>
      <style jsx global>
        {monthPickerDynamicStyles}
      </style>
    </div>
  );
};

App.propTypes = {
  theme: PropTypes.object,
  title: PropTypes.string,
  customCss: PropTypes.string,
  favicon: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }),
};

App.defaultProps = {
  theme: {},
  title: 'Trading cabinet',
  customCss: '',
  favicon: '',
  history: {},
};

export default compose(
  withRouter,
  CheckAuthHOC,
  withTheme(),
  connect(
    state => ({
      title: state.interfaceConfig.clientThemeConfig.client.title,
      favicon: state.interfaceConfig.clientThemeConfig.client.images?.favicon || null,
      customCss: state.interfaceConfig.cssConfig?.css ?? '',
    }),
    null
  )
)(App);
