import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ClientPage {
    width: 100%;
    display: flex;

    @include respond-to(sm) {
      flex-direction: column;
    }

    /* left */

    &__left {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      padding: 25px 20px 25px 30px;
      width: 100%;
      max-width: 430px;

      @include respond-to(lg) {
        max-width: 350px;
      }

      @include respond-to(sm) {
        max-width: none;
        width: 100%;
      }
    }

    /* left => name */

    &__left-name-wrap {
      margin: 0 0 16px 0;
      display: grid;
      grid-template-columns: 20px auto 20px;
    }

    &__left-name-icon {
      margin: 0 6px 0 0;
      transform: translateY(-1px);
    }

    &__left-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1px;
      padding: 0 6px 0 6px;
    }

    /* left => status stage */

    &__left-status-plug {
      height: 40px;
    }

    &__left-status-stage-wrap {
      margin: 0 0 40px 0;
      display: flex;
      align-items: center;
      button {
        margin-left: auto;
      }
    }

    &__left-status-stage-title {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #696969;
      margin: 0 5px 0 0;
    }

    &__left-status-stage {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #2ed790;
    }

    &__left-name-edit {
      width: 16px;
    }

    /* left => status*/

    &__left-status-wrap {
      margin-bottom: 40px;
      .StatusPlate {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: fit-content;
      }
    }

    &__left-button-create-tag {
      &.BaseButton {
        margin-bottom: 10px;
        padding: rem(4px 10px);
        display: block;
        margin-left: auto;
      }
    }
    &__left-tags-wrap {
      margin-bottom: 35px;
      .Tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        &--collapsed {
          overflow: hidden;
          height: 50px;
        }
      }
      .Tags__button {
        margin-left: auto;
        display: block;
        margin-top: 15px;
        color: #696969;
      }
    }

    /* left => managers */

    &__left-managers-wrap {
      margin: 0 0 60px 0;
    }

    &__left-managers-item {
      display: flex;
      align-items: center;
      padding: 0 0 7px 0;
      margin: 0 0 9px 0;
      border-bottom: 1px solid #cdcfd2;
    }

    &__left-managers-title {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__left-managers-name {
      margin: 0 10px 0 auto;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }

    &__left-managers-edit {
    }

    &__left-managers-bo-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__left-managers-bo-title {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      margin: 0 10px 0 0;
    }

    &__left-managers-bo-icon {
    }

    /* left => contacts */

    &__left-contacts-wrap {
      margin: 0 0 60px 0;
    }

    &__left-contacts-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;
      cursor: pointer;
      user-select: none;
    }

    &__left-contacts-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    &__left-contacts-title-arrow {
      &--show {
        transform: rotate(180deg);
      }
    }

    &__left-contacts-list {
    }

    &__left-contacts-item {
      display: flex;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__left-contacts-item-type {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__left-contacts-item-contact {
      margin: 0 0 0 auto;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }

    &__left-contacts-item-link {
      display: flex;
      align-items: center;
    }

    &__left-contacts-item-data {
      margin: 0 10px 0 0;
    }

    &__left-contacts-item-img {
      line-height: 0;
    }

    /* left => accounts sum */

    &__left-accounts-sum-wrap {
      margin: 0 0 60px 0;
    }

    &__left-accounts-sum-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;
      cursor: pointer;
      user-select: none;
    }

    &__left-accounts-sum-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      display: flex;

      :global(.MoneyCell__inner) {
        display: flex;
        height: auto;
        :global(span) {
          font-size: rem(14px) !important;
        }
      }
    }

    &__left-accounts-sum-title-arrow {
      &--show {
        transform: rotate(180deg);
      }
    }

    &__left-accounts-sum-list {
    }

    &__left-accounts-sum-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__left-accounts-sum-item-type {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__left-accounts-sum-item-sum {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;

      :global(.MoneyCell__inner) {
        height: auto;
        :global(span) {
          font-size: rem(14px) !important;
        }
      }
    }

    /* left => client info */

    &__left-client-info-wrap {
      margin: 0 0 60px 0;
    }

    &__left-client-info-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;
      cursor: pointer;
      user-select: none;
    }

    &__left-client-info-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    &__left-client-info-title-arrow {
      &--show {
        transform: rotate(180deg);
      }
    }

    &__left-client-info-list {
    }

    &__left-client-info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__left-client-info-item-type {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__left-client-info-item-date {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
    }

    /* right */

    &__right {
      padding: 25px 30px 25px 20px;
      width: calc(100% - 430px);

      @include respond-to(lg) {
        width: calc(100% - 350px);
      }

      @include respond-to(sm) {
        width: 100%;
        padding: 25px 20px 25px 20px;
      }
    }

    &__right-top-menu {
      margin: 0 0 30px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__right-button-doc {
      width: 31px;
      height: 31px;
      border-radius: 3px;
      margin: 0 10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__right-button-create-task {
    }

    &__right-button-and-select-wrap {
      position: relative;
      width: 161px;
    }

    &__right-tabs {
      .Tabs {
        &__list {
          flex-wrap: wrap;
          margin: 0 0 calc(45px - 10px) 0;

          @include respond-to(sm) {
            margin: 0 0 calc(25px - 10px) 0;
          }
        }

        &__tab {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.1px;
          padding: 0 0 3px 0;
          margin: 0 92px 10px 0;

          @include max-width(1450) {
            margin: 0 55px 10px 0;
          }

          @include respond-to(md) {
            margin: 0 35px 10px 0;
          }

          @include respond-to(sm) {
            margin: 0 25px 10px 0;
          }
        }
      }
    }

    &__right-tabs-feed {
    }

    &__right-tabs-accounts {
    }

    &__right-tabs-in-out {
    }

    &__right-tabs-logs {
    }

    &__right-tabs-calls {
    }

    &__right-tabs-support {
    }
  }
`;
const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ClientPage {
      &__left {
        background: ${colors.columHead.hex};
      }
      &__left-title {
        color: ${colors.white.hex};
      }
      &__left-contacts-item-data {
        color: ${colors.black.hex};
      }
      &__left-managers-bo-title {
        color: ${colors.primary.hex};
      }
      &__right-button-doc {
        background: ${colors.primary.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
