import css from 'styled-jsx/css';
import loupe from './loupe.svg';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CRMSearchBar {
    width: 100%;
    height: max-content;
    @include respond-to(xs) {
    }

    &__input {
      height: 30px;
      padding: 5px 10px;
      padding-right: 30px;
      border-color: #cccccc;
      font-size: 14px;
      background-image: url(${loupe});
      background-repeat: no-repeat;
      background-position: 97% 60%;
      background-size: auto;

      &::placeholder {
        font-weight: 300;
        color: rgb(233, 233, 233);
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
