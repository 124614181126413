import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseSelect {
    width: 100%;

    &__label {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #0f0f0f;
      opacity: 0.5;
      margin: 0 0 4px 0;

      &--error {
        color: #ff4085;
      }
    }

    .select__control {
      border: 1px solid #e9e9e9;
    }

    .select__menu {
      z-index: 999;
      margin-top: 2px;
      border-radius: 0;
      > .select__menu-list {
        padding: 0;
      }
    }
    .select__indicator-separator {
      display: none;
    }

    .select__placeholder {
    }
  }
`;

export { staticStyles };
