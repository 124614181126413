import React from 'react';

const CrossIcon = () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.823208 9.19999L9.4669 0.799986" stroke="#0F0F0F" strokeLinecap="square" />
    <path d="M9.46693 9.19999L0.823242 0.799988" stroke="#0F0F0F" strokeLinecap="square" />
  </svg>
);

export default CrossIcon;
