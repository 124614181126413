import axios from 'axios';

export const getIssues = search => axios.get(`/backoffice/support/`, { params: { ...search } });

export const getIssue = id => axios.get(`/backoffice/support/${id}/`);

export const changeIssueLanguage = (issueId, language) => axios.put(`/backoffice/support/${issueId}/`, { language });

export const createMessage = (issue, text, isNote) =>
  axios.post(`/backoffice/support/${issue}/messages/`, { text, isNote });

export const editMessage = (issueId, messageId, payload) =>
  axios.put(`/backoffice/support/${issueId}/messages/${messageId}/edit/`, { ...payload });

export const createIssueTicket = payload => axios.post(`/backoffice/support/issue/create/`, { ...payload });

export const deleteAttachment = attachmentId => axios.delete(`/backoffice/support/attachments/${attachmentId}/delete/`);

export const verifyPartnerFromTicket = id => axios.post(`/backoffice/partnership/${id}/verify/`);

export const rejectPartnerFromTicket = (id, params) =>
  axios.post(`/backoffice/partnership/${id}/reject/`, { ...params });
