import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseStaticSelect {
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;

    &__OpenOptionsButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      border: 1px solid #0aa1e2;
      border-radius: 3px;
      height: 100%;
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: #0aa1e2;
      &:disabled {
        color: #e9e9e9;
        border-color: #e9e9e9;
        > svg > path {
          fill: #e9e9e9;
        }
      }
    }

    &__OptionMulti--Selected {
      background: rgba(10, 161, 226, 0.4);
    }

    &__OpenOptionsButton--FastFilterClosed {
      border: 1px solid #e7e7e7;
      color: gray;
      border-color: #e7e7e7;
      > svg > path {
        fill: #e7e7e7;
      }
    }
    &__Options {
      height: 273px;
      width: 100%;
      position: relative;
      z-index: 4;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      overflow-x: hidden;
      overflow-y: auto;
      height: max-content;
      max-height: 200px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #0aa1e2;
      }

      &::-webkit-scrollbar-track {
        background-color: #ffffff;
      }
    }
    &__Option {
      padding-left: 12px;
      display: flex;
      align-items: center;
      height: 30px;
      position: relative;
      &:hover {
        background: #ceecf9;
      }
    }

    &__Option--FastFilter {
      height: 34px;
      min-height: 34px !important;
    }

    &__NewOptions {
      position: relative;
      width: max-content;
      background: white;
      z-index: 4;
      width: 190%;
      padding: 8px 8px 16px 8px;
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      border: 1px solid #87838340;
      box-shadow: 0 1px 6px 1px lightgrey;
    }

    &__Input {
      border: 0;
      background: transparent;
      width: 100%;
      height: 100%;
      padding-top: 9px;
      padding-left: 0;
    }

    &__NewOption {
      display: flex;
      padding: 2px 8px;
      align-items: center;
      border-radius: 20px;
      background: #91a782;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
      background: gray;
      color: white;

      &:hover {
        background: #ceecf9;
        color: black;
      }
    }

    &__Labels {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
    }

    &__LabelsSelected {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
    }

    &__LabelsSelectedCentered {
      flex: 1; /* Expand to fill remaining space */
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__LabelsAmount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 4px 0 4px;
    }

    &__NewOption--isSelected {
      background: #0aa1e2;
    }
  }
`;

export { staticStyles };
