import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReactDataTable, StatusCell, DateCell } from 'common/components';

import staticStyles from './style';

const CellWEmpty = ({ value }) => (value || value === 0 ? <span>{value}</span> : <span>一</span>);

CellWEmpty.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CellWEmpty.defaultProps = {
  value: '',
};

const DateWEmpty = ({ value }) => (value ? <DateCell value={value} /> : <span>一</span>);

DateWEmpty.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DateWEmpty.defaultProps = {
  value: '',
};

const TradeOperationsTable = ({ data, summary, operationStatus }) => {
  // const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));

  /* eslint-disable */
  const order = {
    Header: 'justOrder',
    Cell: ({
      row: {
        original: { id },
      },
    }) => <CellWEmpty value={id} />,
    Footer: (
      <span className="summary__order">
        <FormattedMessage id="justTotal">{txt => txt}</FormattedMessage>
      </span>
    ),
    isPreviewCell: true,
  };
  const symbol = {
    Header: 'justSymbol',
    Cell: ({
      row: {
        original: { symbol },
      },
    }) => <CellWEmpty value={symbol} />,
    isPreviewCell: true,
  };
  const status = {
    Header: 'justType',
    Cell: ({
      row: {
        original: { command },
      },
    }) => <StatusCell statusCode={command.toLowerCase().replace('/', '')} noDot />,
    isPreviewCell: true,
  };
  const openTime = {
    Header: 'openTime',
    Cell: ({
      row: {
        original: { open_time },
      },
    }) => <DateWEmpty value={open_time} />,
  };
  const closeTime = {
    Header: operationStatus === 'delayed' ? 'justExpiration' : 'justCloseTime',
    Cell: ({
      row: {
        original: { close_time, expiration },
      },
    }) => <DateWEmpty value={close_time || expiration} />,
  };
  const openPrice = {
    Header: 'openPrice',
    Cell: ({
      row: {
        original: { open_price },
      },
    }) => <CellWEmpty value={open_price} />,
  };
  const closePrice = {
    Header: document.location.hostname === 'portal.rock-west.com' ? 'justPrice' : 'closePrice',
    Cell: ({
      row: {
        original: { close_price },
      },
    }) => <CellWEmpty value={close_price} />,
  };
  const lots = {
    Header: 'justLots',
    Cell: ({
      row: {
        original: { lots, command },
      },
    }) => <CellWEmpty value={lots === 0.01 && command === 'In/Out' ? 0 : lots} />,
    Footer: (
      <div className="summary__lots">
        <span className="subtext">
          <FormattedMessage id="justLots">{txt => txt}</FormattedMessage>
        </span>
        <div>{summary.lots}</div>
      </div>
    ),
  };
  const boAmount = {
    Header: 'justAmount',
    Cell: ({
      row: {
        original: { lots },
      },
    }) => <CellWEmpty value={lots} />,
  };
  const stopLoss = {
    Header: 'stopLoss',
    Cell: ({
      row: {
        original: { stoploss },
      },
    }) => <CellWEmpty value={stoploss} />,
  };
  const takeProfit = {
    Header: 'takeProfit',
    Cell: ({
      row: {
        original: { takeprofit },
      },
    }) => <CellWEmpty value={takeprofit} />,
  };
  const commission = {
    Header: 'justCommission',
    Cell: ({
      row: {
        original: { commission },
      },
    }) => <CellWEmpty value={commission} />,
    Footer: (
      <div className="summary__commission">
        <span className="subtext">
          <FormattedMessage id="justCommission">{txt => txt}</FormattedMessage>
        </span>
        <div>{summary.commission}</div>
      </div>
    ),
  };
  const swap = {
    Header: 'justSwap',
    Cell: ({
      row: {
        original: { swap },
      },
    }) => <CellWEmpty value={swap} />,
    Footer: (
      <div className="summary__swap">
        <span className="subtext">
          <FormattedMessage id="justSwap">{txt => txt}</FormattedMessage>
        </span>
        <div>{summary.swap}</div>
      </div>
    ),
  };
  const profit = {
    Header: 'justProfit',
    Cell: ({
      row: {
        original: { profit },
      },
    }) => <CellWEmpty value={profit} />,
    Footer: (
      <div className="summary__profit">
        <span className="subtext">
          <FormattedMessage id="justProfit">{txt => txt}</FormattedMessage>
        </span>
        <div>{summary.profit}</div>
      </div>
    ),
  };
  /* eslint-enable */

  const isBinaryOptions = data.some(item => ['CALL', 'PUT'].includes(item.command));

  const columns = [
    order,
    symbol,
    status,
    openTime,
    closeTime,
    openPrice,
    closePrice,
    lots,
    stopLoss,
    takeProfit,
    commission,
    swap,
    profit,
  ];

  const boColumns = [order, symbol, status, openTime, closeTime, openPrice, closePrice, boAmount, profit];

  return (
    <div className={classNames('TradeOperationsTable', { TradeOperationsTable__binary: isBinaryOptions })}>
      <ReactDataTable columns={isBinaryOptions ? boColumns : columns} data={data} onRowClick={() => {}} isResponsive />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

TradeOperationsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number,
      symbol: PropTypes.string,
      action: PropTypes.string,
      openTime: PropTypes.string,
      closeTime: PropTypes.string,
      price: PropTypes.number,
      lots: PropTypes.number,
      stoploss: PropTypes.number,
      takeprofit: PropTypes.number,
      commission: PropTypes.number,
      swap: PropTypes.number,
      profit: PropTypes.number,
    })
  ).isRequired,
  summary: PropTypes.object,
  operationStatus: PropTypes.string,
};

TradeOperationsTable.defaultProps = {
  summary: {},
  operationStatus: '',
};

export default TradeOperationsTable;
