import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withTheme } from 'common/styling/theme';
import { compose } from 'redux';
import Chart from 'react-apexcharts';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NoItemsPanel } from 'common/components';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getInvestSymbolsChart } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';

const SymbolsChart = ({ getInvestSymbolsChart, strategyId, investSymbolsChart, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  useEffect(() => {
    getInvestSymbolsChart({ strategyId });
  }, [getInvestSymbolsChart, strategyId]);

  const series = investSymbolsChart?.map(item => item.Share) ?? [];
  const labels = investSymbolsChart?.map(item => item.Symbol) ?? [];

  const options = {
    chart: {
      fontFamily: 'ubuntu-font, sans-serif',
      width: '90%',
      zoom: {
        enabled: false,
      },
      foreColor: '#C5C5C5',
      animations: {
        enabled: false,
      },
    },
    colors: ['#43B6E8', '#F27186', '#005479', '#A6D750', '#C5C5C5', '#E33046'],
    fill: {
      type: 'gradient',
      colors: ['#43B6E8', '#F27186', '#005479', '#A6D750', '#C5C5C5', '#E33046'],
    },
    labels,
  };

  return (
    <div className="SymbolsChart">
      <div className="SymbolsChart__chart-wrapper">
        {Object.values(investSymbolsChart).length ? (
          <Chart className="SymbolsChart__inner" options={options} series={series} type="donut" height="300" />
        ) : (
          <NoItemsPanel icon={noPaymentsImage} captionSlug="noDataToDisplay" />
        )}
      </div>
      <FormattedMessage id="rammSymbolsChartDescription">
        {txt => <div className="SymbolsChart__description">{txt}</div>}
      </FormattedMessage>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

SymbolsChart.propTypes = {
  getInvestSymbolsChart: PropTypes.func.isRequired,
  strategyId: PropTypes.number.isRequired,
  investSymbolsChart: PropTypes.array.isRequired,
  theme: PropTypes.object,
};

SymbolsChart.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      investSymbolsChart: state.client.invest.investSymbolsChart,
      rammSymbolsChartIsLoaded: state.client.invest.investSymbolsChartIsLoaded,
    }),
    {
      getInvestSymbolsChart: data => getInvestSymbolsChart.request(data),
    }
  )
)(SymbolsChart);
export { SymbolsChart };
