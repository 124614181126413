/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import EditTaskForm from 'crm/tasks/_components/EditTaskForm';
import CloseTaskForm from 'crm/tasks/_components/CloseTaskForm';
import { compose } from 'redux';
import { DateCell, PageContentPreloader } from 'common/components';
import { NewCRMPagination, NewCRMTable } from 'crm/_newComponents';

import { getAllReassignTasks } from 'crm/tasks/_redux';
import BasicCRMModal from 'crm/_components/BasicCRMModal';
import { staticStyles } from './style';

import { CommentCell } from './_components';

const ReassignTable = ({ allReassignTasks, allReassignTasksAreLoaded, getAllReassignTasks, location }) => {
  const history = useHistory();
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);

  const [isOpen, setIsOpen] = useState(false);
  const [isCLoseOpen, setIsCloseOpen] = useState(false);
  const [selectedReassignTask, setSelectedReassignTask] = useState(null);

  useEffect(() => {
    getAllReassignTasks({ search: query });
  }, [query]);
  const data = useMemo(() => {
    if (allReassignTasks.results) {
      return allReassignTasks.results.map(result => {
        if (result.status === 'accepted') {
          return { ...result, color: 'Green', beforeColor: 'Green' };
        }
        if (result.status === 'declined') {
          return { ...result, color: 'Red', beforeColor: 'Red' };
        }
        return { ...result, color: 'Gray', beforeColor: 'Gray' };
      });
    }
    return [];
  }, [allReassignTasks]);

  const columns = [
    {
      accessor: 'fullName',
      Header: 'crmClientName',
      isSortable: true,
      blueBg: true,
      Cell: ({ rowData: { client } }) => (
        <div className="ReassignTable__ContactCell">
          <button
            className="ReassignTablew__ContactCellClickableTitle"
            onClick={() => {
              if (client) {
                history.push(`crm/contacts/${client.id}/`);
              }
            }}>
            {client ? client.fullName : '—'}
          </button>
        </div>
      ),
    },
    {
      accessor: 'created',
      Header: 'justCreated',
      isSortable: true,
      blueBg: true,
      Cell: ({ rowData: { created } }) => <DateCell value={created} inLine />,
    },
    {
      accessor: 'actor',
      Header: 'crmAuthor',
      isSortable: true,
      blueBg: true,
      Cell: ({
        rowData: {
          actor: { fullName },
        },
      }) => fullName,
    },

    {
      accessor: 'oldManager',
      Header: 'justFrom',
      isSortable: true,
      blueBg: true,
      Cell: ({ rowData: { oldManager } }) =>
        oldManager ? oldManager.fullName : <FM id="crmUserDidNotHaveAManager">{txt => txt}</FM>,
    },
    {
      accessor: 'newManager',
      Header: 'justTo',
      isSortable: true,
      Cell: ({
        rowData: {
          newManager: { fullName },
        },
      }) => fullName,
    },
    {
      accessor: 'reason',
      Header: 'justReason',
      isSortable: true,
      Cell: ({ rowData: { reason } }) => reason ?? '一',
    },
    {
      accessor: 'comment',
      Header: 'justComment',
      isSortable: false,
      Cell: ({ rowData: { id, status, comment, changedBy, color } }) => (
        <CommentCell id={id} status={status} comment={comment} commentedBy={changedBy?.fullName} color={color} />
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="ReassignTable">
      <PageContentPreloader ready={allReassignTasksAreLoaded} firstLaunchOnly type="accounts">
        <NewCRMTable data={data} columns={columns} />
        {!!allReassignTasks.count && <NewCRMPagination count={allReassignTasks.count} />}
        <BasicCRMModal
          captionId="crmEditTask"
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
            setSelectedReassignTask(null);
          }}>
          <EditTaskForm
            onRequestClose={() => {
              setIsOpen(false);
              setSelectedReassignTask(null);
            }}
            {...selectedReassignTask}
          />
        </BasicCRMModal>
        <BasicCRMModal
          captionId="crmCloseTask"
          isOpen={isCLoseOpen}
          onRequestClose={() => {
            setIsCloseOpen(false);
            setSelectedReassignTask(null);
          }}>
          <CloseTaskForm
            onRequestClose={() => {
              setIsCloseOpen(false);
              setSelectedReassignTask(null);
            }}
            {...selectedReassignTask}
          />
        </BasicCRMModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ReassignTable.propTypes = {
  allReassignTasks: PropTypes.object.isRequired,
  allReassignTasksAreLoaded: PropTypes.bool.isRequired,
  getAllReassignTasks: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      allReassignTasks: state.crm.tasks.allReassignTasks,
      allReassignTasksAreLoaded: state.crm.tasks.allReassignTasksAreLoaded,
    }),
    {
      getAllReassignTasks: search => getAllReassignTasks.request(search),
    }
  )
)(ReassignTable);
