export default [
  {
    contact: 'Oscar Wilde',
    to: '******** 3210 (mtt-lines)',
    description: 'Answered',
    duration: '02:36 min',
    record: '',
    callStatus: 'Not success',
  },
  {
    contact: 'William Shakespear',
    to: '******** 7850 (mtt-lines)',
    description: '',
    duration: 'Answered',
    record: '03:04 min',
    callStatus: 'Success',
  },
  {
    contact: 'Edgar Po',
    to: '******** 4745 (mtt-lines)',
    description: 'Answered',
    duration: '12:48 min',
    record: '',
    callStatus: 'Not completed',
  },
  {
    contact: 'Jhone Lenon',
    to: '******** 7850 (mtt-lines)',
    description: 'No answere',
    duration: '-',
    record: '',
    callStatus: 'Not completed',
  },
  {
    contact: 'Mike Tayson',
    to: '******** 7850 (mtt-lines)',
    description: 'No answere',
    duration: '-',
    record: '',
    callStatus: 'Not completed',
  },
  {
    contact: 'Baloneza',
    to: '******** 7850 (mtt-lines)',
    description: 'Answered',
    duration: '50 sec',
    record: '',
    callStatus: 'Bad client',
  },
  {
    contact: 'Pasta I.',
    to: '******** 7850 (mtt-lines)',
    description: 'Answered',
    duration: '14:45 min',
    record: '',
    callStatus: 'Success',
  },
  {
    contact: 'Basalini',
    to: '******** 7850 (mtt-lines)',
    description: 'Answered',
    duration: '07:45 min',
    record: '',
    callStatus: 'Success',
  },
];
