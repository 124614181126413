import { combineReducers } from 'redux';

import { LOGOUT_SUCCESS } from '../../auth';

import { reducer as common } from './commonReducer';
import { reducer as messages } from '../messages';
import { reducer as support } from '../support';
import { reducer as payments } from '../payments';
import { reducer as kyc } from '../KYC';
import { reducer as partnership } from '../partnership';
import { reducer as articles } from '../articles';
import { reducer as files } from '../files';
import { reducer as accounts } from '../accounts';
import { reducer as transfers } from '../transfers';
import { reducer as bonuses } from '../bonuses';
import { reducer as faq } from '../faq';
import { reducer as information } from '../information';
import { reducer as statuses } from '../statuses';
import { reducer as invest } from '../invest';

const reducers = combineReducers({
  common,
  messages,
  support,
  payments,
  kyc,
  partnership,
  articles,
  files,
  accounts,
  transfers,
  bonuses,
  faq,
  information,
  statuses,
  invest,
});

const reducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }

  return reducers(state, action);
};

export default reducer;
