import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { FormMessageHOC } from 'common/HOCs';
import { withRouter } from 'react-router-dom';

import { reduxForm, Field } from 'redux-form';
import { withTheme } from 'common/styling/theme';

import { updateCrmTask } from 'crm/tasks/_redux/';

import { BaseButton } from 'crm/_components';
import { TextFormField } from 'crm/_components/forms/';

import { staticStyles } from './style';

const CloseTaskForm = ({ id, handleSubmit, submitting, invalid, dispatch, error }) => {
  const submit = handleSubmit(values => {
    const data = {
      id,
      ...values,
      page: 'AllTasks',
      status: 'CLOSED',
    };
    updateCrmTask(data, dispatch);
  });

  return (
    <form className="CloseTaskForm" onSubmit={submit}>
      <div className="CloseTaskForm__line">
        <Field labelTextId="justComment" component={TextFormField} name="text" placeholder="Task Comment" />
      </div>
      {error && <strong className="CloseTaskForm__formError">{error}</strong>}
      <div className="CloseTaskForm__submit">
        <BaseButton type="submit" textId="justClose" disabled={invalid || submitting} />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

CloseTaskForm.propTypes = {
  id: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

CloseTaskForm.defaultProps = {
  error: '',
};

export default compose(
  withTheme(),
  FormMessageHOC,
  withRouter,
  reduxForm({
    form: 'crm-edit-task-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmTaskWasSuccessfullyClosed');
      props.onRequestClose();
    },
  })
)(CloseTaskForm);
