import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { DefaultButton, BasicModal } from 'common/components';

import { getWithdrawal, actionWithdrawal } from '../../_redux';
import PaymentPage from '../PaymentPage';
import { FailWithdrawForm, WithdrawError } from '../../_components';

const WithdrawalPage = ({
  profile,
  withdrawal,
  withdrawalIsLoaded,
  withdrawalError,
  getWithdrawal,
  withdrawalActionsLoading,
  redirect,
  history,
  match,
  actionWithdrawal,
}) => {
  useEffect(() => {
    getWithdrawal(match.params.id);
  }, [getWithdrawal, match.params.id]);

  const [loading, setLoading] = useState({
    confirm: false,
    reject: false,
    fail: false,
    payout: false,
    execute: false,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [errorMesseage, setErrorMesseage] = useState(withdrawalError);
  const [activeModal, setActiveModal] = useState('fail');

  const handleWithdrawalButtonClick = type => {
    actionWithdrawal(match.params.id, type);
    setLoading({ ...loading, [type]: true });
    setActiveModal(type);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleRejectWithdrawal = () => {
    toggleModal();
  };

  /* eslint-disable */
  useEffect(() => {
    if (withdrawalError) {
      setErrorMesseage(withdrawalError);
      setLoading({ ...loading, execute: false });
      toggleModal();
    }
  }, [withdrawalError]);
  /* eslint-enable */

  const failableStatuses = ['processing', 'payout'];

  const Buttons = () => (
    <>
      {profile.perms.confirmWithdraw && (
        <>
          {withdrawal.status === 'waiting_confirmation' && (
            <DefaultButton
              onClick={() => handleWithdrawalButtonClick('confirm')}
              textId="boConfirm"
              butColor="warning"
              disabled={withdrawalActionsLoading}
              loading={loading.confirm}
            />
          )}
          {withdrawal.status === 'waiting_confirmation' && (
            <DefaultButton
              onClick={() => handleRejectWithdrawal()}
              textId="boReject"
              butColor="error"
              disabled={withdrawalActionsLoading}
              loading={loading.reject}
            />
          )}
        </>
      )}
      {profile.perms.executeWithdraw && (
        <>
          {withdrawal.status === 'processing' && withdrawal.paymentSystemSlug !== 'AnxPay' && (
            <DefaultButton
              onClick={() => handleWithdrawalButtonClick('payout')}
              textId="boPayout"
              butColor="warning"
              disabled={withdrawalActionsLoading}
              loading={loading.payout}
            />
          )}
          {withdrawal.status === 'payout' && (
            <DefaultButton
              onClick={() => handleWithdrawalButtonClick('execute')}
              textId="boExecute"
              butColor="success"
              disabled={withdrawalActionsLoading}
              loading={loading.execute}
            />
          )}
          {failableStatuses.includes(withdrawal.status) && withdrawal.paymentSystemSlug !== 'AnxPay' && (
            <DefaultButton
              onClick={() => handleWithdrawalButtonClick('fail')}
              textId="boMarkFail"
              butColor="error"
              disabled={withdrawalActionsLoading}
              loading={loading.fail}
            />
          )}
        </>
      )}
    </>
  );

  if (redirect) {
    return <Redirect to="/backoffice/withdrawals/list" />;
  }

  return (
    <>
      <PaymentPage
        type="withdrawals"
        captionSlug="boWithdrawal"
        itemId={match.params.id}
        item={withdrawal}
        getItem={getWithdrawal}
        itemIsLoaded={withdrawalIsLoaded}
        Buttons={Buttons}
        history={history}
        hasEditPerm={profile.perms.executeWithdraw}
        idBackTo="boBackToWithdrawals"
      />

      <BasicModal
        isOpen={isOpen}
        captionId={activeModal === 'fail' ? 'createRejectNotification' : 'boWithdrawFundsError'}
        onRequestClose={toggleModal}>
        {activeModal === 'fail' && <FailWithdrawForm toggleModal={toggleModal} id={withdrawal.id} />}
        {activeModal === 'execute' && <WithdrawError text={errorMesseage} toggleModal={toggleModal} />}
      </BasicModal>
    </>
  );
};

WithdrawalPage.propTypes = {
  profile: PropTypes.object.isRequired,
  withdrawal: PropTypes.object.isRequired,
  withdrawalIsLoaded: PropTypes.bool.isRequired,
  getWithdrawal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  actionWithdrawal: PropTypes.func.isRequired,
  withdrawalActionsLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  withdrawalError: PropTypes.string.isRequired,
  redirect: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      profile: state.user.profile,
      withdrawal: state.backoffice.payments.withdrawal,
      withdrawalIsLoaded: state.backoffice.payments.withdrawalIsLoaded,
      withdrawalActionsLoading: state.backoffice.payments.withdrawalActionsLoading,
      withdrawalError: state.backoffice.payments.withdrawalError,
      redirect: state.backoffice.common.redirect,
    }),
    {
      getWithdrawal: id => getWithdrawal.request(id),
      actionWithdrawal: (id, actionType) => actionWithdrawal.request(id, actionType),
    }
  )
)(WithdrawalPage);
export { WithdrawalPage };
