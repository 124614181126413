import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import { AppEntryPreloader, PageLayout } from 'common/components';

import { ModalProvider, FormMessageProvider } from 'common';
import { ReduxThemeProvider } from 'common/styling/theme';

import { loadInterface as loadCrmInterface } from 'crm';
import { loadInterface as loadAdminInterface } from 'admin';
import { loadInterface as loadBackofficeInterface } from 'backoffice';
import { loadInterface as loadClientInterface } from 'client';

import UserWrapper from './UserWrapper';
import { getProfile, startWS, stopWS, getClientCustomPages, getBackofficeCustomPages } from './_redux/actions';

const UserWrapperHOC = ({ Interface, getMenu }) => {
  const UserInner = ({ interfaces, currentInterface, customPages, ...rest }) => (
    <UserWrapper interfaces={interfaces} currentInterface={currentInterface} getMenu={getMenu(customPages)}>
      <Interface {...rest} customPages={customPages} />
    </UserWrapper>
  );
  UserInner.propTypes = {
    interfaces: PropTypes.array.isRequired,
    currentInterface: PropTypes.object.isRequired,
    customPages: PropTypes.array.isRequired,
  };

  return UserInner;
};

const asyncUserWrappedInterface = loadInterface =>
  Loadable({
    loader: () =>
      new Promise(resolve => {
        loadInterface().then(module => {
          resolve(UserWrapperHOC(module));
        });
      }),
    loading: AppEntryPreloader,
  });

const CRM_INTERFACE = {
  slug: 'crm',
  label: 'salesInterface',
  mainUrl: '/crm',
  Component: asyncUserWrappedInterface(loadCrmInterface),
};

const ADMIN_INTERFACE = {
  slug: 'admin',
  label: 'adminInterface',
  mainUrl: '/admin',
  Component: asyncUserWrappedInterface(loadAdminInterface),
};
const BACKOFFICE_INTERFACE = {
  slug: 'backoffice',
  label: 'backofficeInterface',
  mainUrl: '/backoffice',
  Component: asyncUserWrappedInterface(loadBackofficeInterface),
};
const CLIENT_INTERFACE = {
  slug: 'client',
  label: 'clientInterface',
  mainUrl: '',
  Component: asyncUserWrappedInterface(loadClientInterface),
};

const getUserInterfaces = (profile, addons) => {
  const interfaces = [];
  if (addons.includes('crmInterface') && profile.hasCRMAccess) {
    interfaces.push(CRM_INTERFACE);
  }
  if (profile.hasAdminAccess || profile.perms?.superUserPerm) {
    interfaces.push(ADMIN_INTERFACE);
  }
  if (profile.hasBackofficeAccess) {
    interfaces.push(BACKOFFICE_INTERFACE);
  }
  if (profile.hasClientAccess) {
    interfaces.push(CLIENT_INTERFACE);
  }

  return interfaces;
};

const convertCustomPages = customPagesRaw => {
  const basicAdditionalMenuPart = {
    title: 'menuAdditional',
    caption: 'menuAdditional',
    url: '/',
    isAvailable: true,
    isBlock: true,
    routes: [],
  };
  const customPages = customPagesRaw.map(({ html, title, url, imageIcon }) => ({
    title,
    url: url[0] === '/' ? url : `/${url}`,
    isAvailable: true,
    isBlock: true,
    imageIcon,
    routes: [
      {
        path: '',
        component: () => (
          <PageLayout caption={title}>
            <div style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: html }} />
          </PageLayout>
        ),
      },
    ],
  }));
  if (customPages.length) {
    return [basicAdditionalMenuPart, ...customPages];
  }
  return [];
};

const User = ({
  currentInterfaceSlug,
  profile,
  profileIsLoaded,
  addons,
  getProfile,
  startWS,
  stopWS,
  getClientCustomPages,
  getBackofficeCustomPages,
  clientCustomPages,
  backofficeCustomPages,
  areClientCustomPagesLoaded,
  areBackofficeCustomPagesLoaded,
  location,
}) => {
  // FIXME: ВРЕМЕННЫЙ КОСТЫЛЬ, ПРОВЕРИТЬ Адекватность работы С currentInterfaceSlug
  const currentInterfaceUrl = useMemo(() => {
    if (!location || !location.pathname) {
      return null;
    }
    const pathParts = location.pathname.split('/');
    if (pathParts.length > 1) {
      if (pathParts[1] === 'backoffice') {
        return 'backoffice';
      }
      if (location.pathname === '/') {
        return 'client';
      }
      // FIX(ADD) BY TERISS START
      if (location.pathname === '/documents') {
        return 'client';
      }
      if (location.pathname === '/personal_profile') {
        return 'client';
      }
      // FIX(ADD) BY TERISS END
      return null;
    }
  }, [location.pathname]);
  const interfaces = profile ? getUserInterfaces(profile, addons) : [];
  const [customPages, setCustomPages] = useState(null);
  const [loadingNeededOrDone, setLoadingNeededOrDone] = useState(null);
  useEffect(() => {
    if (profile?.hasClientAccess) {
      getClientCustomPages();
    }
    if (profile?.hasBackofficeAccess) {
      getBackofficeCustomPages();
    }
    if (profileIsLoaded && !profile?.hasClientAccess && !profile?.hasBackofficeAccess) {
      setCustomPages([]);
      setLoadingNeededOrDone(true);
    }
  }, [profile, profileIsLoaded]);

  useEffect(() => {
    if (currentInterfaceUrl === 'client' && areClientCustomPagesLoaded) {
      const convertedCleintCustomPages = convertCustomPages(clientCustomPages);
      setCustomPages(convertedCleintCustomPages);
      setLoadingNeededOrDone(true);
      return;
    }
    if (currentInterfaceUrl === 'backoffice' && areBackofficeCustomPagesLoaded) {
      const convertedBackofficeCustomPages = convertCustomPages(backofficeCustomPages);
      setCustomPages(convertedBackofficeCustomPages);
      setLoadingNeededOrDone(true);
      return;
    }

    if (
      profileIsLoaded &&
      loadingNeededOrDone !== null &&
      !areClientCustomPagesLoaded &&
      !areBackofficeCustomPagesLoaded
    ) {
      return setCustomPages([]);
    }
  }, [
    areClientCustomPagesLoaded,
    areBackofficeCustomPagesLoaded,
    currentInterfaceSlug,
    profileIsLoaded,
    currentInterfaceUrl,
  ]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  useEffect(() => {
    startWS();
    return () => {
      stopWS();
    };
  }, [startWS, stopWS]);

  return profileIsLoaded && loadingNeededOrDone !== null && customPages !== null ? (
    <Switch>
      {interfaces.map(({ slug, mainUrl, label, Component }) => (
        <Route
          key={slug}
          path={mainUrl}
          render={props => (
            <ReduxThemeProvider forInterface={slug}>
              <ModalProvider>
                <FormMessageProvider>
                  <Component
                    interfaces={interfaces}
                    currentInterface={{ slug, label, mainUrl }}
                    customPages={customPages}
                    {...props}
                  />
                </FormMessageProvider>
              </ModalProvider>
            </ReduxThemeProvider>
          )}
        />
      ))}
    </Switch>
  ) : null;
};

User.defaultProps = {
  profile: {},
  addons: [],
};

export default connect(
  ({
    user: {
      profile,
      profileIsLoaded,
      currentInterface,
      clientCustomPages,
      backofficeCustomPages,
      areClientCustomPagesLoaded,
      areBackofficeCustomPagesLoaded,
    },
    interfaceConfig,
  }) => ({
    profile,
    profileIsLoaded,
    currentInterfaceSlug: currentInterface,
    addons: interfaceConfig.addons,
    clientCustomPages,
    backofficeCustomPages,
    areClientCustomPagesLoaded,
    areBackofficeCustomPagesLoaded,
  }),
  {
    getProfile: () => getProfile.request(),
    getClientCustomPages: () => getClientCustomPages.request(),
    getBackofficeCustomPages: () => getBackofficeCustomPages.request(),
    startWS,
    stopWS,
  }
)(User);
