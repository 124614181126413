import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const PageTabs = ({ children, forAuth, forVerification, forPage, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className={classNames('PageTabs', { forAuth }, { forVerification }, { forPage })}>
      {children}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PageTabs.propTypes = {
  children: PropTypes.node.isRequired,
  forAuth: PropTypes.bool,
  forVerification: PropTypes.bool,
  forPage: PropTypes.bool,
  theme: PropTypes.object,
};

PageTabs.defaultProps = {
  forAuth: false,
  forVerification: false,
  forPage: false,
  theme: {},
};

const ThemedPageTabs = withTheme()(PageTabs);

export default ThemedPageTabs;
export { PageTabs };
