import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ContactNoteDocuments {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond-to(sm) {
      flex-direction: column;
    }
  }

  .ContactNoteDocument {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    flex: 50%;

    &:hover {
      :global(.ActionsButton) {
        display: block;
      }
    }

    &__icon {
      max-width: 20px;
      width: 100%;
      margin-right: 15px;
    }

    &__name {
      border-bottom: 1px solid transparent;
      cursor: pointer;
      &:hover {
        border-bottom: 1px solid;
      }
    }

    :global(.ActionsButton) {
      display: none;

      @include respond-to(sm) {
        display: block;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .ContactNoteDocument {
      &__name {
        color: ${colors.secondary.hex} !important;

        &:hover {
          border-bottom-color: ${colors.secondary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
