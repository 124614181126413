import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { DateCell, PageContentPreloader } from 'common/components';
import { withTheme } from 'common/styling/theme';
import noInfoImage from 'common/images/noInfo.svg';
import UserHistoryPagination from 'backoffice/users/_components/UserHistoryPagination';
import MonthPicker from 'common/components/MonthPicker';
import { compose } from 'redux';
import { currentDate } from 'common/dashboards/helpers';
import moment from 'moment';
import UserNoInfoPanel from '../UserNoInfoPanel';

import { staticStyles, getDynamicStyles } from './style';

const UserOperationsHistory = ({ id, userOperationHistory, userOperationHistoryIsLoaded, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const date = currentDate;
  const currentYear = Number(moment(date).add(1, 'months').format('YYYY'));
  const currentMonth = Number(moment(date).add(1, 'months').format('M'));
  const lastMonthYear = Number(moment(date).format('YYYY'));
  const lastMonth = Number(moment(date).format('M'));

  const [firstMonthValue, setFirstMonthValue] = useState({
    year: lastMonthYear,
    month: lastMonth,
  });

  const [secondMonthValue, setSecondMonthValue] = useState({
    year: currentYear,
    month: currentMonth,
  });

  const handleFirstMonthChange = monthValue => {
    setFirstMonthValue(monthValue);
  };

  const handleSecondMonthChange = monthValue => {
    setSecondMonthValue(monthValue);
  };

  return (
    <UserHistoryPagination
      type="operations"
      firstMonthValue={firstMonthValue}
      secondMonthValue={secondMonthValue}
      id={id}
      itemsCount={userOperationHistory?.count}>
      <div className="UserOperationsHistory">
        <FormattedMessage id="boPaymentHistory">{txt => <h2>{txt}</h2>}</FormattedMessage>
        <div className="UserOperationsHistory__month-choose">
          <FormattedMessage id="justFrom">{txt => <span>{txt}</span>}</FormattedMessage>
          <MonthPicker
            monthValue={firstMonthValue}
            years={{ min: { year: 2010, month: 1 }, max: { year: currentYear, month: currentMonth } }}
            handleChange={handleFirstMonthChange}
          />
          <FormattedMessage id="justTo">{txt => <span>{txt}</span>}</FormattedMessage>
          <MonthPicker
            monthValue={secondMonthValue}
            years={{ min: { year: 2010, month: 1 }, max: { year: currentYear, month: currentMonth } }}
            handleChange={handleSecondMonthChange}
          />
        </div>
        {/* FIXME: PRELOADER */}
        <PageContentPreloader ready={userOperationHistoryIsLoaded} type="boUserHistory">
          {userOperationHistoryIsLoaded && userOperationHistory.count ? (
            <Scrollbars autoHeight autoHeightMax={500}>
              {userOperationHistory.results.map((item, index) => (
                <div className="UserOperationsHistory__info" key={index}>
                  <DateCell value={item.at} />
                  <p>{item.description}</p>
                  {item.ip && <span>{`IP: ${item.ip}`}</span>}
                </div>
              ))}
            </Scrollbars>
          ) : (
            <UserNoInfoPanel image={noInfoImage} textId="boNoHistory" />
          )}
        </PageContentPreloader>
        <style jsx global>
          {staticStyles}
        </style>
        <style jsx global>
          {dynamicStyles}
        </style>
      </div>
    </UserHistoryPagination>
  );
};

UserOperationsHistory.propTypes = {
  id: PropTypes.string.isRequired,
  userOperationHistoryIsLoaded: PropTypes.bool.isRequired,
  userOperationHistory: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

UserOperationsHistory.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(
    state => ({
      userOperationHistoryIsLoaded: state.backoffice?.users.userOperationHistoryIsLoaded,
      userOperationHistory: state.backoffice?.users.userOperationHistory,
    }),
    {}
  )
)(UserOperationsHistory);
export { UserOperationsHistory };
