/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';
import CloseTaskForm from 'crm/tasks/_components/CloseTaskForm';
import ReactionForm from 'crm/tasks/_components/ReactionForm';
import { ActionsButton, DateCell } from 'common/components';

import classNames from 'classnames';
import moment from 'moment';
import { BaseButton, BasicCRMModal } from 'crm/_components';

import { TaskIcon } from 'crm/_newComponents/TaskIcon';
import { deleteContactNote } from 'crm/users/_redux/actions';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import accounts from './newImages/accounts.svg';
import money from './newImages/money.svg';
import note from './newImages/note.svg';
import SuccessIcon from './newImages/success.svg';
import NotSuccessIcon from './newImages/notsuccess.svg';
import BadClientIcon from './newImages/badclient.svg';
import SuccessActiveIcon from './newImages/success-active.svg';
import NotSuccessActiveIcon from './newImages/notsuccess-active.svg';
import BadClientActiveIcon from './newImages/badclient-active.svg';
import reassignIcon from './newImages/reassign.svg';
import arrowToRight from './newImages/arrowToRight.svg';
import { staticStyles } from './style';

const doesTaskExpireSoon = expirationDate => {
  const receivedDate = moment(expirationDate);

  const currentDate = moment();

  const hoursDifference = currentDate.diff(receivedDate, 'hours');

  return Math.abs(hoursDifference) <= 24;
};

const ICONS = {
  deposits: money,
  internalTransfers: money,
  withdrawals: money,
  notes: note,
  reassign: reassignIcon,
  accounts,
};

const TITLES = {
  deposits: 'crmDeposit',
  internalTransfers: 'crmInternalTransfer',
  withdrawals: 'crmWithdraws',
  accounts: 'crmAccounts',
  tasks: 'crmTaskFor',
  expirationTime: 'crmExpirationTime',
  notes: 'crmNote',
};

const TabFeedItem = ({
  changedBy,
  reason,
  newManager,
  oldManager,
  id,
  objectType,
  login,
  whichEditTaskOpen,
  setWhichEditTaskOpen,
  accountType,
  handleEditNote,
  created,
  expirationDate,
  icon,
  paymentSystemSlug,
  paymentTo,
  amount,
  assignedTo,
  balance,
  sender,
  recipient,
  sendAmount,
  createdBy,
  text,
  title,
  description,
  comment,
  managerReaction,
  status,
}) => {
  console.log({ oldManager });
  const [isCLoseOpen, setIsCloseOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { id: userId } = useParams();
  const handleSelectReaction = reaction => {
    setWhichEditTaskOpen(id);
    setSelectedReaction(reaction);
  };

  useEffect(() => {
    setSelectedReaction(managerReaction);
    // setWhichEditTaskOpen(id);
  }, [managerReaction]);

  // const handleEditNote = () => {
  //   setEditNote(true);
  // };

  const openDeleteModal = () => {
    setIsDeleteNoteModalOpen(true);
  };

  const handleNoteDelete = () => {
    dispatch(deleteContactNote.request({ userId, noteId: id, reloadFeed: true }));
    setIsDeleteNoteModalOpen(false);
  };
  const SelectedReactionImg = ({ selectedReaction }) => {
    switch (selectedReaction) {
      case 'Bad Client':
        return <img src={BadClientActiveIcon} alt="bad client" />;
      case 'Success':
        return <img src={SuccessActiveIcon} alt="success" />;
      case 'Not Success':
        return <img src={NotSuccessActiveIcon} alt="success" />;
      default:
        return null;
    }
  };

  const PAYMENT_STATUS = {
    deposited: 'success',
    done: 'success',
    COMPLETED: 'success',
    CANCELED: 'failed',
    failed: 'failed',
    rejected: 'failed',
    canceled: 'failed',
    processing: 'waiting',
    payout: 'waiting',
    waiting_confirmation: 'waiting',
    money_waiting: 'waiting',
    WAITING_FOR_APPROVAL: 'waiting',
  };

  return (
    <div className="TabFeedItem" key={id}>
      <div className="TabFeedItem__Wrap">
        <div className={classNames('TabFeedItem__Icon', `TabFeedItem__Icon--${objectType}`)}>
          {icon ? <TaskIcon type={icon} /> : <img src={ICONS[objectType]} alt={objectType} />}
        </div>
        <div className="TabFeedItem__Subwrap">
          <div className="TabFeedItem__Info-wrap">
            <div className="TabFeedItem__Date">
              <DateCell value={created} />
            </div>
            <div className="TabFeedItem__Info">
              {objectType === 'reassign' && (
                <div className="ReassignItem">
                  <div className="ReassignItem__Action">
                    {oldManager && <div className="ReassignItem__From">{oldManager ? oldManager.full_name : '-'}</div>}
                    <img src={arrowToRight} alt="arrowToRight" />
                    <div
                      className={classNames('ReassignItem__To', {
                        'ReassignItem__To--success': status === 'accepted',
                        'ReassignItem__To--failed': status === 'declined',
                      })}>
                      {newManager ? newManager?.full_name : '-'}
                    </div>
                  </div>
                  <div className="ReassignItem__Desc">
                    <div className="ReassignItem__By">{changedBy.full_name}:</div>
                    <div className="ReassignItem__Reason">{reason}</div>
                  </div>
                  <div className="ReassignItem__Comment">{comment}</div>
                </div>
              )}
              {(objectType === 'deposits' || objectType === 'withdrawals') && (
                <>
                  <div className={`TabFeedItem__Title TabFeedItem__Title--${PAYMENT_STATUS[status]}`}>
                    <FM id={TITLES[objectType]}>{txt => `${txt}`}</FM>
                  </div>
                  <div className="TabFeedItem__Description">
                    <span>{`ID ${id}`}</span>
                    <span>{paymentTo?.type === 'wallet' ? ` ${paymentTo?.type}` : null}</span>
                    <span>{paymentTo?.account_type ? `${paymentTo?.account_type} ${paymentTo?.login}` : null}</span>
                    <span>{`${amount?.amount} ${amount?.currency}`}</span>
                    <span className="TabFeedItem__bold">{paymentSystemSlug}</span>
                  </div>
                </>
              )}
              {objectType === 'tasks' && (
                <>
                  <div className="TabFeedItem__Title">
                    <FM id={TITLES[objectType]}>{txt => `${txt} ${assignedTo}`}</FM>
                  </div>
                  <div className="TabFeedItem__Description">{description !== null ? description : ''}</div>
                  <div className="TabFeedItem__ExpirationDate">
                    <FM id="crmDueDate">{txt => txt}</FM> :
                    <DateCell red={doesTaskExpireSoon(expirationDate)} value={expirationDate} />
                  </div>
                  <div className="TabFeedItem__Reaction">
                    {selectedReaction && managerReaction ? (
                      <div className="TabFeedItem__Reaction__title">
                        <span>
                          <FM id="crmTaskResult">{txt => `${txt}:`}</FM>
                        </span>
                        <SelectedReactionImg selectedReaction={selectedReaction} /> {selectedReaction}
                      </div>
                    ) : (
                      <>
                        {selectedReaction && whichEditTaskOpen === id ? (
                          <ReactionForm
                            taskId={id}
                            setSelectedReaction={setSelectedReaction}
                            selectedReaction={selectedReaction}
                            setWhichEditTaskOpen={setWhichEditTaskOpen}
                          />
                        ) : null}

                        <div
                          className={classNames('TabFeedItem__Footer', {
                            'TabFeedItem__Footer--absolute': !!selectedReaction,
                          })}>
                          <div className="TabFeedItem__Buttons">
                            <ActionsButton
                              className={classNames('TabFeedItem__Reaction-button', {
                                active: selectedReaction === 'Success',
                              })}
                              onClickFunc={() => handleSelectReaction('Success')}>
                              <img className="TabFeedItem__Reaction-img" src={SuccessIcon} alt="success" /> Success
                            </ActionsButton>
                            <ActionsButton
                              className={classNames('TabFeedItem__Reaction-button', {
                                active: selectedReaction === 'Not Success',
                              })}
                              onClickFunc={() => handleSelectReaction('Not Success')}>
                              <img className="TabFeedItem__Reaction-img" src={NotSuccessIcon} alt="not success" /> Not
                              Success
                            </ActionsButton>
                            <ActionsButton
                              className={classNames('TabFeedItem__Reaction-button', {
                                active: selectedReaction === 'Bad Client',
                              })}
                              onClickFunc={() => handleSelectReaction('Bad Client')}>
                              <img className="TabFeedItem__Reaction-img" src={BadClientIcon} alt="bad client" /> Bad
                              client
                            </ActionsButton>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {comment && (
                    <>
                      <div className="TabFeedItem__Comment">
                        <FM id="justComment">{txt => txt}</FM>: {comment}
                      </div>
                      {managerReaction && whichEditTaskOpen !== id && (
                        <div className="TabFeedItem__Buttons">
                          <BaseButton color="white" textId="justEdit" onClick={() => setWhichEditTaskOpen(id)} />
                        </div>
                      )}

                      {!!managerReaction && whichEditTaskOpen === id && (
                        <ReactionForm
                          taskId={id}
                          setSelectedReaction={setSelectedReaction}
                          selectedReaction={selectedReaction}
                          setWhichEditTaskOpen={setWhichEditTaskOpen}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {objectType === 'accounts' && (
                <>
                  <div className="TabFeedItem__Title">
                    <FM id="justAccount">{txt => `${txt} ${login} ${accountType?.replace('/', ' ')}`}</FM>
                  </div>
                  <div className="TabFeedItem__Description">{`${balance?.amount} ${balance?.currency}`}</div>
                </>
              )}
              {objectType === 'notes' && (
                <>
                  <div className="TabFeedItem__Title">
                    <FM id={TITLES[objectType]}>{txt => `${txt} ${createdBy}`}</FM>
                  </div>
                  <div className="TabFeedItem__Title">{title}</div>
                  <div className="TabFeedItem__Description">{text !== null ? text : ''}</div>

                  <div className="TabFeedItem__Buttons">
                    <BaseButton color="white" textId="justEdit" onClick={() => handleEditNote(id)} />
                    <BaseButton color="white" textId="doRemove" onClick={() => openDeleteModal()} />
                  </div>

                  <></>
                </>
              )}
              {objectType === 'internalTransfers' && (
                <>
                  <div className="TabFeedItem__Title-container">
                    <div className={`TabFeedItem__Title TabFeedItem__Title--${PAYMENT_STATUS[status]}`}>
                      <FM id={TITLES[objectType]} />
                    </div>
                    <FM id="crmInternalTransferFrom">
                      {txt => <span className="TabFeedItem__Title-span">{txt}</span>}
                    </FM>
                    <FM id="crmInternalTransferTo">{txt => `${sender} ${txt} ${recipient}`}</FM>
                  </div>
                  <div className="TabFeedItem__Description">{`${sendAmount?.amount} ${sendAmount?.currency}`}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <BasicCRMModal
        captionId="crmCloseTask"
        isOpen={isCLoseOpen}
        onRequestClose={() => {
          setIsCloseOpen(false);
          setSelectedTask(null);
        }}>
        <CloseTaskForm
          onRequestClose={() => {
            setIsCloseOpen(false);
            setSelectedTask(null);
          }}
          {...selectedTask}
        />
      </BasicCRMModal>
      <BasicCRMModal
        captionId="confirmText"
        isOpen={isDeleteNoteModalOpen}
        onRequestClose={() => {
          setIsDeleteNoteModalOpen(false);
        }}>
        <FM id="confirmDeleteNote" />
        <div className="TabFeed__ModalButtons">
          <BaseButton
            color="white"
            textId="justCancel"
            onClick={() => {
              setIsDeleteNoteModalOpen(false);
            }}
          />
          <BaseButton color="red" textId="justDelete" onClick={handleNoteDelete} />
        </div>
      </BasicCRMModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

TabFeedItem.propTypes = {
  id: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  paymentSystemSlug: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  expiration_date: PropTypes.string.isRequired,
  assignedTo: PropTypes.string.isRequired,
  balance: PropTypes.number,
  sender: PropTypes.string,
  recipient: PropTypes.string,
  sendAmount: PropTypes.number,
  createdBy: PropTypes.string,
  managerReaction: PropTypes.string,
  comment: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

TabFeedItem.defaultProps = {
  managerReaction: '',
  comment: '',
  title: '',
  text: '',
  sendAmount: null,
  recipient: null,
  createdBy: null,
  sender: null,
  balance: null,
};

export default TabFeedItem;
