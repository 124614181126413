import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.ReassignTable__ContactCell) {
    display: flex;
  }

  :global(.ReassignTablew__ContactCellClickableTitle) {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .PageContentPreloader {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  :global(.ReassignTable__ContactCell) {
    height: 45px;
  }

  :global(.StatusCell--Open) {
    color: green;
  }
  :global(.StatusCell--Closed) {
    color: red;
  }
  :global(.NewCRMTable__wrapper) {
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #0aa1e2;
    }
  }
  :global(.NewCRMTable) {
    min-width: 1850px;
    display: grid;
    grid-template-rows: 28px 1fr !important;
  }
  :global(.NewCRMTable__Cell--Tbody) {
    height: unset;
    max-height: 110px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
  :global(.NewCRMTable__Head-Row),
  :global(.NewCRMTable__Body-Row) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto !important;
  }

  :global(.NewCRMTable__Body),
  :global(.NewCRMTable__Head) {
    padding-left: 6px;
  }

  :global(.NewCRMTable__Cell) {
    height: auto !important;
    overflow: visible;
  }

  :global(.ReassignTable__ResultCell) {
    display: flex;
    flex-direction: column;
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
