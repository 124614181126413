import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { connect } from 'react-redux';
import { getCrmClient } from 'crm/contacts/_redux';
import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const GetClientButton = ({ isGetClientLoading, getCrmClient, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="GetClientButton">
      <button
        type="button"
        className="GetClientButton__button"
        disabled={isGetClientLoading}
        onClick={() => getCrmClient()}>
        Get client
      </button>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

GetClientButton.propTypes = {
  isGetClientLoading: PropTypes.bool.isRequired,
  getCrmClient: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

GetClientButton.defaultProps = {
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({ isGetClientLoading: state.crm.contacts.isGetClientLoading }), {
    getCrmClient: () => getCrmClient.request(),
  })
)(GetClientButton);
