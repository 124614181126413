import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CommissionsTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(tr) {
          grid-template-columns: minmax(20px, 1fr) repeat(1, minmax(50px, 1.5fr)) repeat(8, minmax(100px, 3fr)) minmax(
              50px,
              1fr
            );
        }
        :global(.ReactDataTable__tbody-row) {
          box-shadow: none;
          cursor: default;
        }
      }
    }

    .ResponsiveTable__thead {
      span {
        flex: 50;
        padding: 5px 0;

        &:first-child {
          padding-left: rem(20px);
          flex: 1;
        }

        &:nth-child(2) {
          padding-left: rem(20px);
          flex: 10;
        }

        &:nth-child(3) {
          flex: 30;
        }

        &:nth-child(5) {
          flex: 1;
        }

        &:last-child {
          padding-right: rem(30px);
          flex: 1;
        }
      }
    }

    .ResponsiveTable__tbody {
      min-width: 340px !important;
      .ResponsiveTable__preview-main {
        height: 40px;
      }

      .ResponsiveTable__row-item {
        flex: 50;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          padding-left: rem(5px);
          flex: 1;
        }

        &:nth-child(2) {
          padding-left: rem(10px);
          flex: 10;
        }

        &:nth-child(3) {
          flex: 30;
        }

        &:nth-child(5) {
          flex: 1;
          padding-right: rem(20px);
        }
      }

      .ResponsiveTable__preview-description {
        .ResponsiveTable__row-item {
          justify-content: flex-start;
          padding-left: 10%;
        }
        :global(.StatusCell) {
          font-size: rem(12px) !important;
        }
      }
    }

    .ResponsiveTable__details-item {
      padding-left: 18%;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .CommissionsTable {
      :global(.ReactDataTable__wrapper) {
        :global(.ReactDataTable) {
          :global(.CommissionsTable__disabled-row) {
            background: ${colors.white.hex} ${rgba([colors.lightGray.rgb, 0.15])};

            :global(td) {
              color: ${colors.gray.hex};
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
