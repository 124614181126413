import css from 'styled-jsx/css';

import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BasicCRMModal__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.1px;
    text-align: left;
    color: #000000;
    margin-bottom: 15px;
  }

  .ReactModal__Body--open {
    overflow-y: hidden;
    padding-right: 15px;

    .ReactModalPortal {
      .Modal_error-overlay {
        z-index: 35;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
    .ReactModal__Body--open {
      padding: 0 !important;
    }
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    z-index: 34;
    background-color: rgba(0, 0, 0, 0.4) !important;
    opacity: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0px !important;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content--after-open {
    border: none !important;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0 !important;
    /* padding: 35px 15px 30px !important; */
    /* top: 48.8% !important;
    bottom: auto !important;
    left: 48.8% !important;
    transform: translate(-51.2%, -51.2%);
    right: auto !important; */

    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;

    inset: 0px !important;
    position: relative !important;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    margin: 0 20px 0 20px;

    @include respond-to(md) {
      /* padding: 25px 10px 20px !important; */
    }
    @include respond-to(sm) {
      /* padding: 20px 5px 10px !important; */
      /* left: 15px !important;
      right: 15px !important;
      transform: translate(0, -51.2%); */
    }
    @media only screen and (max-height: 420px) {
      /* top: 5% !important;
      transform: translate(-51.2%, 0); */
    }
    @media only screen and (max-height: 420px) and (max-width: 767px) {
      /* transform: translate(0); */
    }
  }

  .BasicModal__inner {
    min-width: 260px;
    padding: 30px;
  }

  .BasicModal__inner-error {
    text-align: center;
    h2 {
      font-size: rem(36px);
    }
    p {
      font-size: rem(18px);
      margin: 0;
    }
  }

  .BasicModal__scroll {
    @media (max-height: 420px) {
      > div {
        &:last-child {
          background: none;
        }
      }
    }
    > div {
      &:last-child {
        width: 4px !important;
      }
    }
    @include respond-to(xl) {
      > div {
        max-height: 620px !important;
      }
      max-height: 600px !important;
    }
  }

  .BasicModal__scroll {
    & > div {
      @include respond-to(sm) {
        max-height: 500px !important;
      }

      @include respond-to(xs) {
        max-height: 450px !important;
      }
    }
  }

  // .BasicModal__close {
  //   position: absolute;
  //   z-index: 2;
  //   transition: all 0.4s ease;
  //   top: 12px;
  //   right: 12px;

  //   @include respond-to(md) {
  //     top: 8px;
  //     right: ${isRTL ? 'auto' : '8px'};
  //     left: ${isRTL ? '8px' : 'auto'};
  //   }
  // }

  .ReactModal {
    &__Content {
      background: #fff;
      padding: 0 !important;
      overflow: visible !important;

      @include respond-to(md) {
        padding: 0 0 20px 0 !important;
      }

      @include respond-to(sm) {
        padding: 0 0 10px 0 !important;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background-color: #ffffff;
      }

      &.overflowInitial {
        overflow: initial;
      }
    }
  }

  .BasicModalForm {
    max-width: 330px;

    &__inputs-inner,
    &__buttons {
      display: flex;
      justify-content: space-between;

      .BaseTextField,
      .DefaultButton {
        width: 48%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .ReactModalPortal {
      direction: ${isRTL ? 'rtl' : 'ltr'};
    }

    .BasicModal__close {
      position: absolute;
      top: 10px;
      right: ${isRTL ? 'auto' : '10px'};
      left: ${isRTL ? '10px' : 'auto'};
    }

    .BasicModal__scroll {
      > div {
        &:last-child {
          div {
            background: ${rgba([colors.primary.rgb, 0.8])} !important;
          }
        }
      }
    }

    .BasicModal__inner-error {
      h2 {
        color: ${colors.error.hex};
      }
    }

    .ReactModal {
      &__Content {
        &::-webkit-scrollbar-thumb {
          background-color: ${colors.primary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
