import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StatusCell, PaginationTable, DateCell, DefaultButton } from 'common/components';

import { withTheme } from 'common/styling/theme';

import { createBackOfficePaymentExport, actionAnxpayWithdrawals, downloadAnxpayWithdrawalsCsv } from '../../_redux';

import { staticStyles, getDynamicStyles } from './style';

import Checkbox from '../../../partnership/_components/Checkbox';

const PaymentsTable = ({
  data,
  allowManagePaymentsExports,
  exportButtonCaptionSlug,
  isExportPaymentBeingCreated,
  createBackOfficePaymentExport,
  onRowClick,
  getList,
  loading,
  history,
  type,
  location,
  params,
  theme,
  intl,
  hasPaymentOperationManagers,
  query,
}) => {
  const pushToExportsFunc = useCallback(() => history.push(`/backoffice/${type}/exports`), [type]);
  const exportsType = useMemo(() => {
    if (type === 'deposits') {
      return 'deposit_export';
    }
    return 'withdraw_export';
  }, []);

  /* eslint-disable */

  const [anxpayChkboxStatus, setAnxpayChkboxStatus] = useState(false);

  const dispatch = useDispatch();

  const anxpayDialogProcessing = document.getElementById('anxpay-dialog-processing');
  const anxpayDialogPayout = document.getElementById('anxpay-dialog-payout');

  const anxpayStatusChange = () => {
    if (anxpayChkboxStatus === false) {
      alert('出金ステータスを一括変更するには全件チェックをしてください。');
      return;
    }

    if (justStatusFilter === 'processing') {
      anxpayDialogProcessing.style.display = 'block'; // 表示
    } else if (justStatusFilter === 'payout') {
      anxpayDialogPayout.style.display = 'block'; // 表示
    }
  };

  const anxpayStatusChangeCancel = () => {
    anxpayDialogProcessing.style.display = 'none'; // 非表示
    anxpayDialogPayout.style.display = 'none'; // 非表示
  };

  const anxpayStatusChangeFailed = () => {
    var search = {};
    query['toStatus'] = 'failed'; // 失敗
    search['search'] = query;
    console.log(search);
    dispatch(actionAnxpayWithdrawals.request(search));

    anxpayDialogProcessing.style.display = 'none'; // 非表示
    anxpayDialogPayout.style.display = 'none'; // 非表示
  };

  const anxpayStatusChangeDone = () => {
    var search = {};
    query['toStatus'] = 'done'; // 完了
    search['search'] = query;
    console.log(search);
    dispatch(actionAnxpayWithdrawals.request(search));

    anxpayDialogPayout.style.display = 'none'; // 非表示
  };

  const anxpayStatusChangePayout = () => {
    // (参考)data.results は、画面に表示する分しか保持していない（デフォルト10件）
    console.log(query);
    var search = {};
    query['toStatus'] = 'payout'; // 支出
    search['search'] = query;
    console.log(search);
    dispatch(downloadAnxpayWithdrawalsCsv.request(search));

    anxpayDialogProcessing.style.display = 'none'; // 非表示
  };

  const [searchFilter, setSearchFilter] = useState('');
  const [justStatusFilter, setJustStatusFilter] = useState('');
  const [paymentSystemFilter, setPaymentSystemFilter] = useState('');
  useEffect(() => {
    if (type === 'withdrawals') {
      const queries = Object.create(query);
      var isSearchFilterChanged;
      if (searchFilter !== queries.search) isSearchFilterChanged = true;
      else isSearchFilterChanged = false;
      setSearchFilter(queries.search);
      setJustStatusFilter(queries._status);
      setPaymentSystemFilter(queries.payment_system_slug);
      if (
        isSearchFilterChanged ||                                              // 検索フィルターが変化した場合
        paymentSystemFilter !== 'AnxPay' ||                                   // 「支払い方法」がAnxPayではない場合
        (justStatusFilter !== 'processing' && justStatusFilter !== 'payout')  // 処理中(processing)／支出(payout) ではない場合
      )
        setAnxpayChkboxStatus(false);                                         // チェックボックスフィルターをオフにする
    }
  }, [query]);

  const columns = [
    {
      key: 'boId',
      accessor: 'id',
      Cell: ({ original: { id } }) => <span className="id">{id}</span>,
    },
    {
      key: 'justEmail',
      accessor: 'user__email',
      Cell: ({ original: { user } }) => <span>{user.email}</span>,
      isPreviewCell: true,
    },
    {
      key: 'boAccount',
      accessor: 'account',
      Cell: ({ original: { account } }) => (
        <span className="account">{account?.login ?? intl.formatMessage({ id: 'boWallet' })}</span>
      ),
    },
    {
      key: 'boAmount',
      Cell: ({ original: { amount } }) => (
        <span className="amount">
          {amount.amount} {amount.currency}
        </span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justCommission',
      Cell: ({ original: { commission } }) => (
        <span className="commission">
          {commission?.amount} {commission?.currency}
        </span>
      ),
    },
    ...(hasPaymentOperationManagers
      ? [
          {
            key: 'justManager',
            Cell: ({ original: { manager } }) => (
              <div>{manager ? `${manager.firstName} ${manager.lastName}` : '-'}</div>
            ),
          },
        ]
      : []),
    {
      key: 'justConvertationCourse',
      Cell: ({ original: { exchangeString } }) => <span className="convertation-course">{exchangeString}</span>,
    },
    {
      key: 'boPaymentSystem',
      Cell: ({ original: { paymentSystemSlug } }) => <span className="payment-system-slug">{paymentSystemSlug}</span>,
    },
    {
      key: 'boPaymentMethod',
      Cell: ({ original: { paymentMethodTitle } }) => <span className="payment-method-slug">{paymentMethodTitle}</span>,
    },
    {
      key: 'boCreated',
      accessor: 'created',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      key: 'boStatus',
      accessor: '_status',
      Cell: ({ original: { status } }) => (
        <>
          <div className="status">
            <StatusCell statusCode={status} />
          </div>
        </>
      ),
      isPreviewCell: true,
    },
  ];

  const anxPayColumns = [
    {
      key: 'anxpayChkbox',
      Cell: ({ original: { anxpayChkbox } }) => (
        <div className="anxpay-chkbox">
          <Checkbox onClick={e => (e.checked = !e.checked)} value={anxpayChkboxStatus} checked={anxpayChkboxStatus} />
        </div>
      ),
    },
    {
      key: 'boId',
      accessor: 'id',
      Cell: ({ original: { id } }) => <span className="id">{id}</span>,
    },
    {
      key: 'justEmail',
      accessor: 'user__email',
      Cell: ({ original: { user } }) => <span>{user.email}</span>,
      isPreviewCell: true,
    },
    {
      key: 'boAccount',
      accessor: 'account',
      Cell: ({ original: { account } }) => (
        <span className="account">{account?.login ?? intl.formatMessage({ id: 'boWallet' })}</span>
      ),
    },
    {
      key: 'boAmount',
      Cell: ({ original: { amount } }) => (
        <span className="amount">
          {amount.amount} {amount.currency}
        </span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justCommission',
      Cell: ({ original: { commission } }) => (
        <span className="commission">
          {commission?.amount} {commission?.currency}
        </span>
      ),
    },
    ...(hasPaymentOperationManagers
      ? [
          {
            key: 'justManager',
            Cell: ({ original: { manager } }) => (
              <div>{manager ? `${manager.firstName} ${manager.lastName}` : '-'}</div>
            ),
          },
        ]
      : []),
    {
      key: 'justConvertationCourse',
      Cell: ({ original: { exchangeString } }) => <span className="convertation-course">{exchangeString}</span>,
    },
    {
      key: 'boPaymentSystem',
      Cell: ({ original: { paymentSystemSlug } }) => <span className="payment-system-slug">{paymentSystemSlug}</span>,
    },
    {
      key: 'boPaymentMethod',
      Cell: ({ original: { paymentMethodTitle } }) => <span className="payment-method-slug">{paymentMethodTitle}</span>,
    },
    {
      key: 'boCreated',
      accessor: 'created',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      key: 'boStatus',
      accessor: '_status',
      Cell: ({ original: { status } }) => (
        <>
          <div className="status">
            <StatusCell statusCode={status} />
          </div>
        </>
      ),
      isPreviewCell: true,
    },
  ];
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="PaymentsTable">
      <div className="Anxpay-dialog-processing" id="anxpay-dialog-processing">
        <p>指定した対象【処理中】のステータスを一括で変更しますか？</p>
        <span className="Anxpay-dialog-processing-buttons">
          <DefaultButton
            type="button"
            textId="anxpayStatusChangePayoutButton"
            onClick={() => anxpayStatusChangePayout()}
          />
          <DefaultButton
            type="button"
            textId="anxpayStatusChangeFailedButton"
            onClick={() => anxpayStatusChangeFailed()}
          />
          <DefaultButton
            type="button"
            textId="anxpayStatusChangeCancelButton"
            onClick={() => anxpayStatusChangeCancel()}
          />
        </span>
      </div>
      <div className="Anxpay-dialog-payout" id="anxpay-dialog-payout">
        <p>指定した対象【支出】のステータスを一括で変更しますか？</p>
        <span className="Anxpay-dialog-payout-buttons">
          <DefaultButton type="button" textId="anxpayStatusChangeDoneButton" onClick={() => anxpayStatusChangeDone()} />
          <DefaultButton
            type="button"
            textId="anxpayStatusChangeFailedButton"
            onClick={() => anxpayStatusChangeFailed()}
          />
          <DefaultButton
            type="button"
            textId="anxpayStatusChangeCancelButton"
            onClick={() => anxpayStatusChangeCancel()}
          />
        </span>
      </div>
      <div className="buttons-area">
        {type === 'withdrawals' &&
          paymentSystemFilter === 'AnxPay' &&
          (justStatusFilter === 'processing' || justStatusFilter === 'payout') && (
            <span className="PaymentsTable__anxpay-buttons">
              <DefaultButton
                type="button"
                textId="anxpayChkboxOnButtonText"
                onClick={() => setAnxpayChkboxStatus(true)}
              />
              <DefaultButton
                type="button"
                textId="anxpayChkboxOffButtonText"
                onClick={() => setAnxpayChkboxStatus(false)}
              />
              <DefaultButton type="button" textId="anxpayStatusChangeButtonText" onClick={() => anxpayStatusChange()} />
            </span>
          )}
        {allowManagePaymentsExports && exportButtonCaptionSlug && (
          <span className="PaymentsTable__export-buttons">
            <DefaultButton
              type="button"
              textId={exportButtonCaptionSlug}
              onClick={() => history.push(`/backoffice/${type}/exports`)}
            />
            <DefaultButton
              type="button"
              textId="justExportXls"
              onClick={() => createBackOfficePaymentExport({ exportsType, pushToExportsFunc })}
              loading={isExportPaymentBeingCreated}
              disabled={isExportPaymentBeingCreated}
            />
          </span>
        )}
      </div>
      <PaginationTable
        data={data}
        loading={loading}
        columns={
          type === 'withdrawals' &&
          paymentSystemFilter === 'AnxPay' &&
          (justStatusFilter === 'processing' || justStatusFilter === 'payout')
            ? anxPayColumns
            : columns
        }
        getTrProps={rowProps}
        onRowClick={onRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PaymentsTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  allowManagePaymentsExports: PropTypes.bool.isRequired,
  isExportPaymentBeingCreated: PropTypes.bool.isRequired,
  createBackOfficePaymentExport: PropTypes.func.isRequired,
  exportButtonCaptionSlug: PropTypes.string,
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  hasPaymentOperationManagers: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  query: PropTypes.object.isRequired,
};

PaymentsTable.defaultProps = {
  exportButtonCaptionSlug: '',
  type: '',
  theme: {},
};

export default connect(
  state => ({
    isExportPaymentBeingCreated: state.backoffice.payments.isExportPaymentBeingCreated,
  }),
  {
    createBackOfficePaymentExport: ({ exportsType, pushToExportsFunc }) =>
      createBackOfficePaymentExport.request({ exportsType, pushToExportsFunc }),
  }
)(injectIntl(withTheme()(PaymentsTable)));
export { PaymentsTable };
