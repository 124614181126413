import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { getDeposits, getDepositPaymentSystems } from '../../_redux';
import PaymentsPage from '../PaymentsPage';

const DepositsPage = ({
  deposits,
  getDeposits,
  depositsAreLoaded,
  paymentSystemList,
  paymentSystemListLoading,
  getPaymentSystems,
  location,
  history,
  intl,
  isDepositExport,
  features,
}) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const hasPaymentOperationManagers = features?.includes('paymentOperationManager') || false;

  useEffect(() => {
    getDeposits({ search: query });
  }, [getDeposits, query]);

  useEffect(() => {
    getPaymentSystems();
  }, [getPaymentSystems]);

  const optionsPaymentSystemList = [];
  if (!paymentSystemListLoading) {
    paymentSystemList.map(ps =>
      optionsPaymentSystemList.push({ value: ps.paymentSystemSlug, label: ps.paymentSystemSlug })
    );
  }

  const filters = [
    {
      title: 'justStatus',
      accessor: '_status',
      options: [
        { value: '', label: intl.formatMessage({ id: 'justAll' }) },
        { value: 'money_waiting', label: intl.formatMessage({ id: 'status_money_waiting' }) },
        { value: 'in_process', label: intl.formatMessage({ id: 'status_in_process' }) },
        { value: 'canceled', label: intl.formatMessage({ id: 'status_canceled' }) },
        { value: 'failed', label: intl.formatMessage({ id: 'status_failed' }) },
        { value: 'received', label: intl.formatMessage({ id: 'status_received' }) },
        { value: 'deposited', label: intl.formatMessage({ id: 'status_deposited' }) },
        { value: 'id_verification', label: intl.formatMessage({ id: 'status_id_verification' }) },
        { value: 'pmr_init', label: intl.formatMessage({ id: 'status_pmr_init' }) },
        { value: 'payment_proof', label: intl.formatMessage({ id: 'status_payment_proof' }) },
      ],
    },
    {
      title: 'paymentSystem',
      accessor: 'payment_system_slug',
      options: [{ value: '', label: intl.formatMessage({ id: 'justAll' }) }, ...optionsPaymentSystemList],
    },
  ];

  return (
    <PaymentsPage
      type="deposits"
      exportButtonCaptionSlug="showDepositExportList"
      allowManagePaymentsExports={isDepositExport}
      items={deposits}
      noPaymentsCaption="noPaymentsDeposit"
      noMatchingParametersCaption="noDepositsMatchingTheParameters"
      getItems={getDeposits}
      getReloadItems={() => getDeposits({ search: query })}
      captionSlug="boDeposits"
      itemsAreLoaded={depositsAreLoaded}
      filters={filters}
      history={history}
      location={location}
      hasPaymentOperationManagers={hasPaymentOperationManagers}
    />
  );
};

DepositsPage.propTypes = {
  deposits: PropTypes.object.isRequired,
  getDeposits: PropTypes.func.isRequired,
  depositsAreLoaded: PropTypes.bool.isRequired,
  getPaymentSystems: PropTypes.func.isRequired,
  paymentSystemList: PropTypes.array.isRequired,
  paymentSystemListLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  isDepositExport: PropTypes.bool,
  features: PropTypes.array.isRequired,
};

DepositsPage.defaultProps = {
  isDepositExport: false,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    state => ({
      deposits: state.backoffice.payments.deposits,
      depositsAreLoaded: state.backoffice.payments.depositsAreLoaded,
      paymentSystemList: state.backoffice.payments.depositSystems,
      paymentSystemListLoading: state.backoffice.payments.loadingDepositSystems,
      isDepositExport: state.user.profile.perms.manageDepositExports,
      features: state.interfaceConfig.features,
    }),
    {
      getDeposits: search => getDeposits.request(search),
      getPaymentSystems: () => getDepositPaymentSystems.request(),
    }
  )
)(DepositsPage);
export { DepositsPage };
