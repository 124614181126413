import css from 'styled-jsx/css';

const staticStyles = css `
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserNotes__content-inner {
  }

  .UserNotes__cels {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr)) minmax(100px, 0.3fr) minmax(100px, 0.2fr);
    margin: rem(10px 20px);

    @include respond-to(sm) {
      display: none;
    }

    :global(span) {
      font-size: rem(11px);
      line-height: rem(18px);
      text-transform: uppercase;
      padding: rem(0 5px);
    }
  }

  .UserNotes__head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(25px);
  }
`;

const getDynamicStyles = ({ colors }) => {
    return css `
    .UserNotes__cels {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };