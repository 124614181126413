import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .DayPicker_weekHeader {
    top: 65px;
    z-index: 0;
  }

  .DayPicker_weekHeader_li {
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
  }

  .DayPicker_weekHeader_li {
    margin: 0 !important;
  }

  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }

  .SingleDatePickerInput__withBorder {
    border: none;
  }

  .DateInput_input__focused {
    border: none;
  }

  .DateInput,
  .SingleDatePickerInput,
  .SingleDatePicker {
    width: 100% !important;
  }

  .SingleDatePicker_picker {
    padding-bottom: 25px;
    z-index: 999 !important;
    top: 45px !important;
  }

  .DayPickerNavigation_button {
    z-index: 6;
    &:first-child {
      left: 10px;
    }
    &:last-child {
      right: 10px;
    }
  }

  .DateInput_input {
    font-weight: 400;
    font-size: inherit;
    padding: rem(3px 32px 3px 13px);
    box-shadow: none;
    transition: none;
    border: none;
    opacity: 1 !important;
    &::placeholder {
      opacity: 1 !important;
    }
  }
  .DateInput_fang {
    top: 33px !important;
  }

  .DateInput_input__focused {
    border: none;
    box-shadow: none;
  }

  .DateFormField__inner {
    margin-bottom: 10px;
    position: relative;
  }

  .DateFormField__caption {
    font-weight: 300;
    font-size: 14px;
    color: rgba(15, 15, 15, 0.5);
    margin: 0 0 2px 0;
  }

  .DateFormField {
    direction: ltr;
    position: absolute;
    z-index: 10;
  }

  .DateFormField__years-navigation {
    display: flex;
    justify-content: space-between;
    width: 68%;
    margin: 0 auto;
    align-items: center;
    select {
      border: none;
      background: none;
    }
    .Select__value-container {
      padding: 0 !important;
      width: 110px;
    }
    .Select__control {
      border: none;
    }
    .Select__menu {
      z-index: 10 !important;
    }
    .Select__option {
      padding: rem(10px 5px) !important;
    }
    .DateFormField__month-inner {
      > div {
        width: 110px;
      }
    }
    .DateFormField__years-inner {
      > div {
        width: 70px;
      }
    }
  }

  .CalendarMonth_caption {
    font-size: rem(15px);
    width: 280px;
    position: relative;
  }

  .DateInput_input__disabled {
    font-style: normal;
  }

  .CalendarMonth_table {
    position: relative;
    top: 30px;
  }

  .DayPicker_transitionContainer {
    height: 350px !important;
  }

  .CalendarMonth {
    height: 250px !important;
  }

  .CalendarMonthGrid,
  .CalendarMonth,
  .DayPicker {
    background-color: transparent !important;
  }

  .DateFormField {
    position: relative;
    transition: all 0.4s ease;
    background-color: transparent;
    border: 1px solid;
    @include respond-to(md) {
      z-index: 5;
    }
    .SingleDatePickerInput,
    .DateInput,
    .DateInput_input {
      background: transparent !important;
    }
    .CalendarIcon {
      svg {
        position: absolute;
        right: rem(${isRTL ? 'auto' : '10px'});
        left: rem(${isRTL ? '10px' : 'auto'});
        top: rem(7px);
        width: 18px;
        height: 17px;
        g {
          path {
            stroke: transparent !important;
          }
        }
      }
    }
  }

  .DateFormField__label {
    display: inline-block;
    position: absolute;
    top: rem(17px);
    left: rem(13px);
    transition: all 0.25s ease;
    z-index: 9;
    cursor: text;
    &.haveText {
      top: 6px;
      font-size: rem(12px);
    }
    &.active {
      top: 6px;
      font-size: rem(12px);
    }
  }

  .DateFormField__disabled {
    .DateFormField__label {
      cursor: default;
    }
    .CalendarIcon {
      svg {
        z-index: 10;
      }
    }
  }

  .DateFormField__error-text {
    width: 100%;
    display: inline-block;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .DateFormField__label {
      transition: none;
    }
  }

  @supports (-ms-ime-align: auto) {
    .DateFormField__label {
      transition: none;
    }
  }

  .DateFormField__mobile {
    border: none;
    height: 30px;
    padding: 0 10px 0 10px;
  }

  .DateFormField__schema {
    width: 30%;
    margin-right: 3.33%;
    @include respond-to(sm) {
      width: 48%;
      margin: 0;
    }
    .DateFormField__mobile {
      color: $body-background-color;
    }
  }

  .DateFormField__schema {
    .DateFormField__error {
      .DateFormField__mobile {
        color: $body-background-color !important;
        &::placeholder {
          color: $body-background-color !important;
        }
      }
    }
  }

  .DateFormField__presets {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;

    .DateFormField__time {
      width: 40px;
      height: 32px;
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.2px;
      padding: 6px 9px 6px 9px;

      &::placeholder {
        color: #696969;
      }
    }

    .DateFormField__time-caption {
      font-weight: 300;
      font-size: rem(12px);
      color: rgba(15, 15, 15, 0.5);
      margin: 0 0 2px 0;
    }

    .DateFormField__time-separator {
      margin: rem(0 6px);
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .DateInput_input {
      &::placeholder {
        color: ${colors.white.hex};
      }
    }

    .DateFormField__mobile {
      color: ${colors.white.hex};
    }

    .DateInput_input,
    .SingleDatePicker,
    .DateInput,
    .SingleDatePickerInput {
      border-radius: ${border.radius};
      color: ${colors.black.hex};
    }

    .DateInput_input__focused {
      &::placeholder {
        color: ${colors.black.hex};
      }
    }

    .DateInput_input__disabled {
      background: ${colors.disabled.hex};
      color: ${colors.black.hex};
      -webkit-text-fill-color: ${colors.black.hex};
      &::placeholder {
        color: ${colors.disabled.hex} !important;
        -webkit-text-fill-color: ${colors.disabled.hex};
      }
    }

    .DateFormField {
      border-radius: ${border.radius};
      border-color: #e9e9e9;

      &.active {
        border-color: ${colors.primary.hex};
        .DateFormField__mobile {
          color: ${colors.black.hex};
        }
        .CalendarIcon {
          svg {
            g {
              path {
                fill: ${colors.primary.hex};
              }
            }
            path {
              stroke: ${colors.primary.hex};
            }
          }
        }
      }
      &.haveText {
        .DateFormField__mobile {
          color: ${colors.black.hex};
        }
      }
    }

    .DateFormField__disabled {
      background: ${colors.disabled.hex};
      .DateFormField__mobile {
        background: ${colors.disabled.hex};
        color: ${colors.disabled.hex};
      }
      .CalendarIcon {
        svg {
          g {
            path {
              fill: ${colors.gray.hex};
            }
          }
          path {
            stroke: ${colors.gray.hex};
          }
        }
      }
      &.haveText {
        .DateFormField__mobile {
          color: ${colors.black.hex};
        }
      }
    }

    .DayPicker_weekHeader {
      color: ${colors.primary.hex};
    }

    .CalendarDay__selected {
      background: ${colors.primary.hex};
      border-color: ${colors.primary.hex};
      &:hover {
        background: ${colors.secondary.hex};
        border-color: ${colors.secondary.hex};
      }
    }

    .DateFormField__error {
      border-color: ${colors.error.hex} !important;
      .DateInput_input,
      .DateFormField__mobile {
        color: ${colors.white.hex} !important;
        &::placeholder {
          color: ${colors.white.hex} !important;
        }
      }
      .CalendarIcon {
        svg {
          g {
            path {
              fill: ${colors.error.hex};
            }
          }
          path {
            stroke: ${colors.error.hex};
          }
        }
      }
      .DateFormField__label {
        color: ${colors.error.hex} !important;
      }
    }

    .DateFormField__error {
      &.active {
        .DateInput_input__focused {
          color: ${colors.error.hex} !important;
          &::placeholder {
            color: ${colors.error.hex} !important;
          }
        }
        .DateInput_input {
          color: ${colors.error.hex} !important;
          &::placeholder {
            color: ${colors.error.hex} !important;
          }
        }
        .DateFormField__mobile {
          color: ${colors.error.hex} !important;
        }
        .CalendarIcon {
          svg {
            g {
              path {
                fill: ${colors.error.hex};
              }
            }
            path {
              stroke: ${colors.error.hex};
            }
          }
        }
      }
    }

    .DateFormField__label {
      color: ${colors.gray.hex};
      &.haveText {
        color: ${colors.gray.hex};
      }
      &.active {
        color: ${colors.primary.hex};
      }
    }

    .DateFormField__error-text {
      color: ${colors.error.hex};
    }

    .DateFormField__schema {
      .DateFormField.active {
        .DDateFormField_mobile {
          color: ${colors.black.hex} !important;
        }
      }
      .DateFormField.haveText {
        .DateFormField__mobile {
          color: ${colors.black.hex} !important;
        }
      }
    }
    .DateFormField__time:focus {
      border-bottom: 1px solid;
      border-color: ${colors.lightGray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
