import React from 'react';
import PropTypes from 'prop-types';

import { ActionsButton } from 'common/components';
import { CrossIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';

import noFormatImg from './icons/unknown.svg';

import { staticStyles, getDynamicStyles } from './style';

const ContactNoteDocuments = ({ documents, onDownload, onRemove, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const importAll = r => r.keys().map(r);
  const images = importAll(require.context('./icons/', true, /\.(svg)$/));

  return (
    <div className="ContactNoteDocuments">
      {documents.map(({ id, name, ext }) => (
        <div className="ContactNoteDocument" key={id}>
          <div className="ContactNoteDocument__icon">
            <img src={images.find(image => image.includes(ext)) ?? noFormatImg} alt={name} />
          </div>

          <button className="ContactNoteDocument__name" type="button" onClick={() => onDownload(id)}>
            {name}
          </button>

          <ActionsButton
            tooltipId="Delete__tooltip"
            tooltipTextId="justDeleteNote"
            deleteType
            onClickFunc={() => onRemove(id)}>
            <CrossIcon />
          </ActionsButton>
        </div>
      ))}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ContactNoteDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  onDownload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

ContactNoteDocuments.defaultProps = {
  theme: {},
};

export default withTheme()(ContactNoteDocuments);
