import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { withTheme, ReduxThemeProvider } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from 'common/styling/globalStyle';
import ContractSpecs from './contract-specs/ContractSpecs';
import InvestRatingPage from './invest/InvestRatingPage';
import InvestDetailPage from './invest/InvestDetailPage';

const Widgets = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const location = useLocation();
  const { lang } = queryString.parse(location.search);

  if (lang) {
    localStorage.setItem('locale', lang);
  }

  return (
    <ReduxThemeProvider>
      <Switch>
        <Route exact path="/widgets/contractSpecs" component={ContractSpecs} />
        <Route exact path="/widgets/invest" component={InvestRatingPage} />
        <Route exact path="/widgets/invest/:strategyId" component={InvestDetailPage} />
        <Redirect to="/widgets" />
      </Switch>
      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </ReduxThemeProvider>
  );
};

Widgets.propTypes = {
  theme: PropTypes.object,
};

Widgets.defaultProps = {
  theme: {},
};

export default withTheme()(Widgets);
export { Widgets };
