/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage as FM } from 'react-intl';
import queryString from 'query-string';
import { PageContentPreloader, MoneyCell } from 'common/components';
import { getCrmManagersAnalytics } from 'crm/analytics/_redux';

import { NewCRMTable, NewCRMPagination } from 'crm/_newComponents';
import { staticStyles } from './style';

const ManagersTable = ({ managers, managersAreLoaded, getCrmManagersAnalytics, location }) => {
  const query = useMemo(() => ({ page_size: 10, page: 1, ...queryString.parse(location.search) }), [location.search]);

  useEffect(() => {
    getCrmManagersAnalytics({ search: query });
  }, [query]);

  const columns = [
    {
      accessor: 'id',
      Header: 'justId',
      Cell: ({ rowData: { id } }) => id,
    },
    {
      accessor: 'fullName',
      Header: 'justFullName',
      blueBg: true,
      Cell: ({ rowData: { fullName } }) => (
        <button
          onClick={() => {
            // TODO: For now managers are not contacts
            // history.push(`/crm/contacts/${id}`);
          }}>
          {fullName}
        </button>
      ),
      Footer: () => <FM id="justTotal">{txt => <span className="Footer__Total">{txt}</span>}</FM>,
    },
    {
      accessor: 'totalIn',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell
            className="ManagersTable__CustomMoneyHeaderCellTitle"
            heightMaxContent
            value={managers?.total?.sum_total_in}
          />
          <FM id="amountIn">{txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalIn } }) => <MoneyCell crmStyle value={totalIn} />,
      Footer: () => (
        <div className="Managers__FooterMoneyCell">
          <MoneyCell className="MoneyCellBoldFont" crmStyle value={managers?.subtotal?.sum_total_in} />
        </div>
      ),
    },
    {
      accessor: 'totalOut',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell
            className="ManagersTable__CustomMoneyHeaderCellTitle"
            heightMaxContent
            value={managers?.total?.sum_total_out}
          />
          <FM id="amountOut">{txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalOut } }) => <MoneyCell crmStyle value={totalOut} />,
      Footer: () => (
        <div className="Managers__FooterMoneyCell">
          <MoneyCell className="MoneyCellBoldFont" crmStyle value={managers?.subtotal?.sum_total_out} />
        </div>
      ),
    },
    {
      accessor: 'totalDiff',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell
            className="ManagersTable__CustomMoneyHeaderCellTitle"
            heightMaxContent
            value={managers?.total?.sum_total_dif}
          />
          <FM id="amountInOut">{txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalDiff } }) => <MoneyCell crmStyle value={totalDiff} />,
      Footer: () => (
        <div className="Managers__FooterMoneyCell">
          <MoneyCell className="MoneyCellBoldFont" crmStyle value={managers?.subtotal?.sum_total_diff} />
        </div>
      ),
    },
    {
      accessor: 'clientsCount',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <span className="ManagersTable__CustomHeaderTitle">{managers?.total?.sum_clients_count}</span>

          <FM id="crmClientsCount">{txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { managerClientsCount } }) => managerClientsCount,
      Footer: () => <span className="Managers__FooterCell">{managers?.subtotal?.sum_clients_count}</span>,
    },
    {
      accessor: 'tasksOpenCount',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <span className="ManagersTable__CustomHeaderTitle">{managers?.total?.sum_tasks_open_count}</span>

          <FM id="crmTasksOpenCount">
            {txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}
          </FM>
        </div>
      ),
      Cell: ({ rowData: { tasksOpenCount } }) => tasksOpenCount,
      Footer: () => <span className="Managers__FooterCell">{managers?.subtotal?.sum_tasks_open_count}</span>,
    },
    {
      accessor: 'tasksClosedCount',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <span className="ManagersTable__CustomHeaderTitle">{managers?.total?.sum_tasks_closed_count}</span>

          <FM id="crmTasksClosedCount">
            {txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}
          </FM>
        </div>
      ),
      Cell: ({ rowData: { tasksClosedCount } }) => tasksClosedCount,
      Footer: () => <span className="Managers__FooterCell">{managers?.subtotal?.sum_tasks_closed_count}</span>,
    },
    {
      accessor: 'tasksOverdueCount',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <span className="ManagersTable__CustomHeaderTitle">{managers?.total?.sum_tasks_overdue_count}</span>

          <FM id="crmTasksOverdueCount">
            {txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}
          </FM>
        </div>
      ),
      Cell: ({ rowData: { tasksOverdueCount } }) => tasksOverdueCount,
      Footer: () => <span className="Managers__FooterCell">{managers?.subtotal?.sum_tasks_overdue_count}</span>,
    },
    {
      accessor: 'tasksAllCount',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ManagersTable__CustomMoneyHeaderCellWrapper">
          <span className="ManagersTable__CustomHeaderTitle">{managers?.total?.sum_tasks_all_count}</span>
          <FM id="crmTasksAllCount">
            {txt => <span className="ManagersTable__CustomMoneyHeaderCellInfo">{txt}</span>}
          </FM>
        </div>
      ),
      Cell: ({ rowData: { tasksAllCount } }) => tasksAllCount,
      Footer: () => <span className="Managers__FooterCell">{managers?.subtotal?.sum_tasks_all_count}</span>,
    },
  ];

  return (
    <div className="ManagersTable">
      <PageContentPreloader ready={managersAreLoaded} firstLaunchOnly type="accounts">
        <NewCRMTable data={managers.results} columns={columns} hasFooter />
        {!!managers.count && <NewCRMPagination count={managers.count} />}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ManagersTable.propTypes = {
  managers: PropTypes.object.isRequired,
  managersAreLoaded: PropTypes.bool.isRequired,
  getCrmManagersAnalytics: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      managers: state.crm.analytics.managers,
      managersAreLoaded: state.crm.analytics.managersAreLoaded,
    }),
    {
      getCrmManagersAnalytics: search => getCrmManagersAnalytics.request(search),
    }
  )
)(ManagersTable);
