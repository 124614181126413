import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';

import Triangle from './icons/Triangle';
import { staticStyles } from './style';

import DropDown from './_components/DropDown';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const DateSelector = ({ isDisabled, options, onSelect }) => {
  const [customRange, ...selectebleOptions] = options;
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const optionsRightRef = useRef(null);
  const ref = useRef(null);

  const [buttonPosition, setButtonPosition] = useState(null);
  const [isCalendarOutsideOfWindow, setIsCalendarOutsideOfWindow] = useState(false);

  const updateWindowWidth = debounce(() => {
    setIsOpen(false);
    setWindowWidth(window.innerWidth);
    setButtonPosition(ref.current.getBoundingClientRect());
  }, 50);

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    // Check if optionsRightRef has a value before accessing its properties
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      if (rect.right + 200 > windowWidth && !isCalendarOutsideOfWindow) {
        setIsCalendarOutsideOfWindow(true);
      }
      if (rect.right + 200 < windowWidth) {
        setIsCalendarOutsideOfWindow(false);
      }
    }
  }, [isOpen, windowWidth]);

  return (
    <div className="DateSelector">
      <button
        ref={ref}
        className={classNames('DateSelector__OpenOptionsButton DateSelector__OpenOptionsButton--FastFilter', {
          'DateSelector__OpenOptionsButton--FastFilterClosed': !isOpen,
        })}
        disabled={isDisabled}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
          setButtonPosition(ref.current.getBoundingClientRect());
        }}>
        <div className="DateSelector__Labels">
          <span className="DateSelector__LabelsSelected">
            <div className="DateSelector__LabelsSelectedCentered">
              {!customRange.isSelected && (
                <FM id={options.find(({ isSelected }) => isSelected).computedLabel}>{txt => txt}</FM>
              )}
              {customRange.isSelected &&
                `${moment(customRange.value[0]).format('YYYY-MM-DD')} - ${moment(customRange.value[1]).format(
                  'YYYY-MM-DD'
                )}`}
            </div>
          </span>
        </div>
        <Triangle />
      </button>
      <DropDown
        optionsRightRef={optionsRightRef}
        isOpen={isOpen}
        options={options}
        setIsOpen={setIsOpen}
        position={buttonPosition}
        isCalendarOutsideOfWindow={isCalendarOutsideOfWindow}
        windowWidth={windowWidth}
        selectebleOptions={selectebleOptions}
        onSelect={onSelect}
      />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

DateSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

DateSelector.defaultProps = {
  isDisabled: false,
};

export default DateSelector;
