import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { ActionsButton, BasicModal, PaginationTable, StatusCell, DateCell } from 'common/components';
import { ModalHOC } from 'common';
import { withTheme } from 'common/styling/theme';
import DownloadReportForm from '../DownloadReportForm';
import { BasketIcon } from '../../../icons';
import { staticStyles, getDynamicStyles } from './style';

const SavedReportsTable = ({
  data,
  getList,
  loading,
  history,
  location,
  params,
  showModal,
  deleteReport,
  intl,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [modal, setModal] = useState({ isOpen: false, payload: '' });
  const toggleModal = () => setModal({ ...modal, isOpen: !modal.isOpen });

  const handleDeleteReport = (e, reportId) => {
    e.stopPropagation();
    showModal({
      caption: { id: 'confirmText' },
      content: {
        id: 'confirmDeleteReport',
      },
      actionButton: {
        textId: 'justYes',
        handler: () => deleteReport({ reportId, reportType: 'saved', queryParams: params }),
        type: 'error',
      },
      cancelButton: {
        textId: 'justNo',
      },
    });
  };

  /* eslint-disable */
  const columns = [
    {
      key: 'justIbAccount',
      accessor: 'partner',
      Cell: ({ original: { params } }) => (
        <span className="SavedReportsTable__ib-account">{params?.partner || '-'}</span>
      ),
      isPreviewCell: true,
    },
    {
      key: 'justDate',
      accessor: 'updated',
      Cell: ({ original: { updated } }) => <DateCell value={updated} />,
    },
    {
      key: 'boStatus',
      accessor: 'status',
      Cell: ({ original: { status } }) => <StatusCell statusCode={status} />,
      isPreviewCell: true,
    },
    {
      key: 'justReportName',
      accessor: 'name',
      Cell: ({ original: { name } }) => <span className="SavedReportsTable__template">{name}</span>,
      isPreviewCell: true,
    },
    {
      key: 'justPeriod',
      accessor: 'period',
      Cell: ({ original: { params } }) => (
        <span>{`${intl.formatDate(params.start_date)} - ${intl.formatDate(params.end_date)}`}</span>
      ),
    },
    {
      key: 'justActions',
      Cell: ({ original: report }) => (
        <ActionsButton
          tooltipId={`Delete__tooltip-${report.id}`}
          tooltipTextId="justDelete"
          deleteType
          onClickFunc={e => handleDeleteReport(e, report.id)}>
          <BasketIcon />
        </ActionsButton>
      ),
    },
  ];
  /* eslint-enable */
  const handleRowClick = (id, report) => {
    if (report.status === 'SUCCESS') {
      setModal({ isOpen: true, payload: report });
    }
  };

  const rowProps = (state, rowInfo) => ({
    onClick: () => handleRowClick(rowInfo.original.id, rowInfo.original),
  });

  return (
    <div className="SavedReportsTable">
      <PaginationTable
        data={data}
        loading={loading}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={handleRowClick}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isResponsive
      />
      <BasicModal isOpen={modal.isOpen} captionId="justDownloadReport" onRequestClose={toggleModal}>
        <DownloadReportForm toggleModal={toggleModal} report={modal.payload} />
      </BasicModal>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

SavedReportsTable.propTypes = {
  showModal: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

SavedReportsTable.defaultProps = {
  theme: {},
};

export default compose(injectIntl, ModalHOC, withTheme())(SavedReportsTable);
export { SavedReportsTable };
