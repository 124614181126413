import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ActionsButton } from 'common/components';
import { WatchIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';

import { staticStyles, getDynamicStyles } from './style';

const StatisticalCard = ({ theme, rate, withCurrency, withWatch, captionId, amountIsLoaded }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isInfoHidden, setInfoHidden] = useState(false);

  const rateWithCurrency = useMemo(() => (withCurrency ? `$${rate}` : rate), [withCurrency, rate]);
  const amount = useMemo(() => {
    if (amountIsLoaded && isInfoHidden) {
      return '***';
    }
    if (amountIsLoaded) {
      return rateWithCurrency;
    }
    return '-';
  }, [rateWithCurrency, amountIsLoaded, isInfoHidden]);

  const toggleVisibility = () => {
    setInfoHidden(!isInfoHidden);
  };

  return (
    <div className="StatisticalCard">
      <span className="StatisticalCard__number">{amount}</span>
      <FormattedMessage id={captionId}>
        {txt => <span className="StatisticalCard__caption">{txt}</span>}
      </FormattedMessage>
      {withWatch && (
        <ActionsButton
          tooltipId={`StatisticalCard-${captionId}`}
          tooltipTextId="justToggleVisibility"
          watch
          onClickFunc={toggleVisibility}>
          <WatchIcon />
        </ActionsButton>
      )}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

StatisticalCard.propTypes = {
  rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  captionId: PropTypes.string,
  theme: PropTypes.object,
  withCurrency: PropTypes.bool,
  withWatch: PropTypes.bool,
  amountIsLoaded: PropTypes.bool.isRequired,
};

StatisticalCard.defaultProps = {
  rate: 0,
  captionId: '',
  theme: {},
  withCurrency: false,
  withWatch: false,
};

export default withTheme()(StatisticalCard);
export { StatisticalCard };
