import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.UnderChartInfo) {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    margin-left: 10px;
    gap: 7px;
    @include respond-to(xl) {
      bottom: 20px;
    }
  }

  :global(.UnderChartInfo__Title) {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  :global(.UnderChartInfo__Items) {
    position: relative;
    display: flex;
    gap: 4px;
  }

  :global(.UnderChartInfo__Item) {
    position: relative;
    border-radius: 40px;
    border: 4px white solid;
    padding: 3px 11px;
    color: white;
    font-size: 13px;
    display: flex;
    gap: 3px;
  }
  :global(.UnderChartInfo__ItemTitle) {
    position: relative;
    white-space: nowrap;
  }

  :global(.UnderChartInfo__ItemValue) {
    position: relative;
    font-weight: 600;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
