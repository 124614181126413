import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksData {
    padding-right: 30px;
    display: flex;
    height: 100%;
    align-items: center;
    @include respond-to(xs) {
      display: none;
    }
    .Tooltip {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    }

    .Tooltip__text {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__item {
      font-size: 10px;
      font-weight: 500;
      border-radius: 12.5px;
      padding: 5px 8px;
      text-align: center;
      background: #fff;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .TasksData {
      .Tooltip {
        background-color: ${colors.white.hex} !important;
        color: ${colors.black.hex};
      }

      .__react_component_tooltip.type-dark.place-bottom:after {
        border-bottom-color: ${colors.white.hex} !important;
      }

      &__item {
        color: ${colors.primary.hex};

        &.success {
          background: ${colors.success.hex};
          color: ${colors.white.hex};
        }

        &.overdue {
          background: ${colors.error.hex};
          color: ${colors.white.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
