import { FAILURE, REQUEST, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from '../actions';

export const updateItemInArray = (array, itemId, updateItemCallback) =>
  array.map(item => (item.id !== itemId ? item : updateItemCallback(item)));

export const initialState = {
  accountTypes: [],
  loadingAccountTypes: false,

  currentAccount: {},
  currentAccountIsLoaded: false,

  currentAccountOperations: {},
  currentAccountOperationsAreLoaded: false,

  accounts: [],
  loadingAccounts: false,
  accountsIsLoaded: false,

  partnerAccounts: [],
  partnerAccountsIsLoaded: false,

  partnerAccount: {},
  partnerAccountIsLoaded: false,

  partnerAccountCommissions: [],
  partnerAccountCommissionsIsLoaded: false,

  redirect: false,
  error: '',

  mtServers: null,
  mtServersPending: false,
  mtServersError: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TRADING_ACCOUNT_TYPES[REQUEST]:
      return { ...state, loadingAccountTypes: true, error: '' };
    case ActionTypes.GET_TRADING_ACCOUNT_TYPES[SUCCESS]:
      return {
        ...state,
        loadingAccountTypes: false,
        accountTypes: action.accountTypes,
      };
    case ActionTypes.GET_TRADING_ACCOUNT_TYPES[FAILURE]:
      return { ...state, loadingAccountTypes: false };

    case ActionTypes.GET_TRADING_ACCOUNT_LIST[REQUEST]:
      return { ...state, loadingAccounts: true, accountsIsLoaded: false, redirect: false };
    case ActionTypes.GET_TRADING_ACCOUNT_LIST[SUCCESS]: {
      const accounts = action.accounts.map(actionAccount => ({
        ...actionAccount,
        loading: true,
        loadingFailed: false,
      }));
      return {
        ...state,
        loadingAccounts: false,
        accounts,
        accountsIsLoaded: true,
      };
    }
    case ActionTypes.GET_TRADING_ACCOUNT_LIST[FAILURE]:
      return { ...state, loadingAccounts: false, accountsIsLoaded: true };

    case ActionTypes.GET_ACCOUNTS_PREFETCH[SUCCESS]:
      return {
        ...state,
        accounts: action.accounts.map(account => ({ ...account, loading: false })),
        loadingAccounts: false,
        accountsIsLoaded: true,
      };

    case ActionTypes.GET_TRADING_ACCOUNT[REQUEST]:
      return {
        ...state,
        currentAccountIsLoaded: false,
        redirect: false,
      };
    case ActionTypes.GET_TRADING_ACCOUNT[SUCCESS]:
      return {
        ...state,
        currentAccount: action.data,
        currentAccountIsLoaded: true,
      };
    case ActionTypes.GET_TRADING_ACCOUNT[FAILURE]:
      return {
        ...state,
        error: action.error,
        redirect: action.error === 404,
        currentAccountIsLoaded: false,
      };

    case ActionTypes.GET_TRADING_ACCOUNT_OPERATIONS[REQUEST]:
      return {
        ...state,
        currentAccountOperationsAreLoaded: false,
      };
    case ActionTypes.GET_TRADING_ACCOUNT_OPERATIONS[SUCCESS]:
      return {
        ...state,
        currentAccountOperations: action.data,
        currentAccountOperationsAreLoaded: true,
      };
    case ActionTypes.GET_TRADING_ACCOUNT_OPERATIONS[FAILURE]:
      return {
        ...state,
        error: action.error,
        currentAccountOperationsAreLoaded: false,
      };

    case ActionTypes.GET_PARTNER_ACCOUNTS[REQUEST]:
      return { ...state, partnerAccountsIsLoaded: false };
    case ActionTypes.GET_PARTNER_ACCOUNTS[SUCCESS]: {
      const partnerAccounts = action.data.map(actionAccount => ({
        ...actionAccount,
        loading: true,
        loadingFailed: false,
      }));
      return {
        ...state,
        partnerAccounts,
        partnerAccountsIsLoaded: true,
      };
    }
    case ActionTypes.GET_PARTNER_ACCOUNTS[FAILURE]:
      return { ...state, partnerAccountsIsLoaded: true };

    case ActionTypes.GET_PARTNER_ACCOUNT[REQUEST]:
      return {
        ...state,
        partnerAccounts: updateItemInArray(state.partnerAccounts, action.accountId, item => ({
          ...item,
          loading: true,
          loadingFailed: false,
        })),
        partnerAccount: { ...state.partnerAccount, loading: true, loadingFailed: false },
        partnerAccountIsLoaded: false,
      };
    case ActionTypes.GET_PARTNER_ACCOUNT[SUCCESS]:
      return {
        ...state,
        partnerAccounts: updateItemInArray(state.partnerAccounts, action.accountId, item => ({
          ...item,
          ...action.data,
          loading: false,
        })),
        partnerAccount: { ...state.partnerAccount, ...action.data, loading: false },
        partnerAccountIsLoaded: true,
      };
    case ActionTypes.GET_PARTNER_ACCOUNT[FAILURE]:
      return {
        ...state,
        partnerAccounts: updateItemInArray(state.partnerAccounts, action.accountId, item => ({
          ...item,
          ...action.data,
          loading: false,
          loadingFailed: true,
        })),
        partnerAccount: { ...state.partnerAccount, ...action.data, loading: false, loadingFailed: true },
        partnerAccountIsLoaded: true,
      };

    case ActionTypes.GET_PARTNER_ACCOUNT_COMMISSIONS[REQUEST]:
      return { ...state, partnerAccountCommissionsIsLoaded: false };
    case ActionTypes.GET_PARTNER_ACCOUNT_COMMISSIONS[SUCCESS]:
      return {
        ...state,
        partnerAccountCommissionsIsLoaded: true,
        partnerAccountCommissions: action.data,
      };
    case ActionTypes.GET_PARTNER_ACCOUNT_COMMISSIONS[FAILURE]:
      return { ...state, partnerAccountCommissionsIsLoaded: true };

    case ActionTypes.GET_MT_SERVERS_ACTION[REQUEST]:
      return { ...state, mtServersPending: true };
    case ActionTypes.GET_MT_SERVERS_ACTION[SUCCESS]:
      return { ...state, mtServers: action.data, mtServersPending: false };
    case ActionTypes.GET_MT_SERVERS_ACTION[FAILURE]:
      return { ...state, mtServersError: action.error, mtServersPending: false };

    case ActionTypes.SET_REDIRECT:
      return { ...state, redirect: true };

    default:
      return state;
  }
};
