import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';
import iconArrow from './images/iconArrow.svg';
import iconEdit from './images/iconEdit.svg';

import { staticStyles } from './style';

const ContactPageInfoBlock = ({ title, titleValues, items, setCrmModalType }) => {
  const [isContentOpen, setIsContentOpen] = useState(true);

  return (
    <div className="ContactPageInfoBlock">
      <button
        type="button"
        className="ContactPageInfoBlock__TitleWrap"
        onClick={() => setIsContentOpen(!isContentOpen)}>
        <div className="ContactPageInfoBlock__Title">
          <FM id={title} values={titleValues} />
        </div>
        <span
          className={classNames('ContactPageInfoBlock__TitleArrow', {
            'ContactPageInfoBlock__TitleArrow--Show': isContentOpen,
          })}>
          <img src={iconArrow} alt="arrow" />
        </span>
      </button>
      {isContentOpen && (
        <div className="ContactPageInfoBlock__ContentWrap">
          {items.map(({ titleId, cell }) => {
            let modalType;
            switch (titleId) {
              case 'crmLanguage':
                modalType = 'editLanguage';
                break;
              case 'justCountry':
                modalType = 'editCountry';
                break;
              case 'justState':
                modalType = 'editState';
                break;
              case 'justCity':
                modalType = 'editCity';
                break;
              case 'justAddress':
                modalType = 'editAddress';
                break;
              default:
                modalType = '';
            }
            return (
              <div className="ContactPageInfoBlock__Item" key={`contact-page-info-block-${titleId}`}>
                <FM id={titleId}>{txt => <span className="ContactPageInfoBlock__ItemTitle">{txt}</span>}</FM>
                <div className="ContactPageInfoBlock__ItemContent">
                  {cell || '----'}
                  {['crmLanguage', 'justCountry', 'justState', 'justCity', 'justAddress'].includes(titleId) ? (
                    <button type="button" onClick={() => setCrmModalType(modalType)}>
                      <img className="ClientPage__left-contacts-edit" src={iconEdit} alt="edit" />
                    </button>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

ContactPageInfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  titleValues: PropTypes.object,
  setCrmModalType: PropTypes.func.isRequired,
};

ContactPageInfoBlock.defaultProps = {
  titleValues: {},
};

export default ContactPageInfoBlock;
