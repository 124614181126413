import { put, call, fork, take } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';
import { SubmissionError } from 'redux-form';
import { acceptError } from 'common/_redux';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getCRMManagersFiltersListSaga() {
  try {
    const { data } = yield call(api.getCRMManagersFiltersList);
    yield put(ActionTypes.getCRMManagersFiltersList.success(data));
  } catch (error) {
    yield put(ActionTypes.getCRMManagersFiltersList.failure(error));
  }
}

export function* getCRMManagersFiltersListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CRM_MANAGERS_FILTERS_LIST[REQUEST]);
    yield call(getCRMManagersFiltersListSaga);
  }
}

export function* getCRMManagersFiltersOptionsSaga() {
  try {
    const { data } = yield call(api.getCRMManagersFiltersOptions);
    // yield call(api.getCRMManagersFiltersList);
    yield put(ActionTypes.getCRMManagersFiltersOptions.success(data));
  } catch (error) {
    yield put(ActionTypes.getCRMManagersFiltersOptions.failure(error));
  }
}

export function* getCRMManagersFiltersOptionsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CRM_MANAGERS_FILTERS_OPTIONS[REQUEST]);
    yield call(getCRMManagersFiltersOptionsSaga);
  }
}

export function* getCrmClientsAnalyticsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getCrmClientsAnalytics, search);
    if (status < 300) {
      yield put(ActionTypes.getCrmClientsAnalytics.success({ ...data }));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getCrmClientsAnalytics.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmClientsAnalytics.failure());
  }
}
export function* getCrmClientsAnalyticsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_CRM_CLIENTS_ANALYTICS[REQUEST]);
    yield call(getCrmClientsAnalyticsSaga, search);
  }
}

export function* getCrmManagersAnalyticsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getCrmManagersAnalytics, search);
    if (status < 300) {
      yield put(ActionTypes.getCrmManagersAnalytics.success(data));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getCrmManagersAnalytics.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getCrmManagersAnalytics.failure());
  }
}
export function* getCrmManagersAnalyticsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_CRM_MANAGERS_ANALYTICS[REQUEST]);
    yield call(getCrmManagersAnalyticsSaga, search);
  }
}

export function* createCRMManagersCustomFilterSaga(payload) {
  try {
    const { status, data } = yield call(api.createCRMManagersCustomFilter, payload);
    if (status < 300) {
      yield put(ActionTypes.createCRMManagersCustomFilter.success(true));
      yield call(getCRMManagersFiltersOptionsSaga);
    } else {
      const error = data.detail ? { _error: data.detail } : data;
      yield put(ActionTypes.createCRMManagersCustomFilter.failure(new SubmissionError(error)));
    }
  } catch (error) {
    yield put(ActionTypes.createCRMManagersCustomFilter.failure(error));
  }
}

export function* createCRMManagersCustomFilterWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createCRMManagersCustomFilter.REQUEST);
    yield call(createCRMManagersCustomFilterSaga, payload);
  }
}

export function* editCRMManagersCustomFilterSaga({ editData, id }) {
  try {
    const { status, data } = yield call(api.editCRMManagersCustomFilter, id, editData);

    if (status < 300) {
      yield put(ActionTypes.editCRMManagersCustomFilter.success());
      yield call(getCRMManagersFiltersListSaga);
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editCRMManagersCustomFilter.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editCRMManagersCustomFilter.failure());
  }
}

export function* editCRMManagersCustomFilterWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editCRMManagersCustomFilter.REQUEST);
    yield call(editCRMManagersCustomFilterSaga, payload);
  }
}
export function* deleteCRMManagersCustomFilterSaga(id) {
  try {
    const { status, data } = yield call(api.deleteCRMManagersCustomFilter, id);
    if (status < 300) {
      yield put(ActionTypes.deleteCRMManagersCustomFilter.success(data));
      yield call(getCRMManagersFiltersListSaga);
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.deleteCRMManagersCustomFilter.failure());
    throw e;
  }
}
export function* deleteCRMManagersCustomFilterWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.deleteCRMManagersCustomFilter.REQUEST);

    yield call(deleteCRMManagersCustomFilterSaga, payload);
  }
}

export function* getCRMManagersFilterSaga(id) {
  try {
    const { status, data } = yield call(api.getCRMManagersFilter, id);
    if (status < 300) {
      yield put(ActionTypes.getCRMManagersFilter.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCRMManagersFilter.failure());
    throw e;
  }
}
export function* getCRMManagersFilterWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_CRM_MANAGERS_FILTER[REQUEST]);
    yield call(getCRMManagersFilterSaga, id);
  }
}

export default [
  fork(getCRMManagersFiltersListWatcherSaga),
  fork(createCRMManagersCustomFilterWatcherSaga),
  fork(getCRMManagersFiltersOptionsWatcherSaga),
  fork(getCrmClientsAnalyticsWatcherSaga),
  fork(getCrmManagersAnalyticsWatcherSaga),
  fork(getCRMManagersFilterWatcherSaga),
  fork(deleteCRMManagersCustomFilterWatcherSaga),
  fork(createCRMManagersCustomFilterWatcherSaga),
];
