export default [
  {
    contact: 'Oscar Wilde',
    lastMail: 'Текст, что менеджер там написал в последный раз.',
    mailStatus: 'Not success',
  },
  {
    contact: 'William Shakespear',
    lastMail: 'Последнее письмо',
    mailStatus: 'Success',
  },
  {
    contact: 'Edgar Po',
    lastMail: 'Текст, что менеджер там написал в последный раз.',
    mailStatus: 'Not completed',
  },
  {
    contact: 'Jhone Lenon',
    lastMail: 'Последнее письмо',
    mailStatus: 'Not completed',
  },
  {
    contact: 'Mike Tayson',
    lastMail: 'Текст, что менеджер там написал в последный раз.',
    mailStatus: 'Not completed',
  },
  {
    contact: 'Baloneza',
    lastMail: 'Последнее письмо',
    mailStatus: 'Bad client',
  },
  {
    contact: 'Pasta I.',
    lastMail: 'Что менеджер там написал в последный раз.',
    mailStatus: 'Success',
  },
  {
    contact: 'Basalini',
    lastMail: 'Текст, что менеджер там написал в последный раз.',
    mailStatus: 'Success',
  },
];
