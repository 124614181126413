import { REQUEST, FAILURE, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  commissionsList: {},
  commissionsListIsLoaded: false,

  rewardFrequencies: [],
  rewardFrequenciesAreLoaded: false,

  partnershipRequests: {},
  partnershipRequestsAreLoaded: false,

  partnershipRequest: {},
  partnershipRequestAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.getCommissionsList.REQUEST:
      return { ...state, commissionsListIsLoaded: false };
    case ActionTypes.getCommissionsList.SUCCESS:
      return { ...state, commissionsList: action.payload, commissionsListIsLoaded: true };
    case ActionTypes.getCommissionsList.FAILURE:
      return { ...state, error: action.error, commissionsListIsLoaded: false };

    case ActionTypes.GET_REWARD_FREQUENCIES[REQUEST]:
      return { ...state, rewardFrequenciesAreLoaded: false };
    case ActionTypes.GET_REWARD_FREQUENCIES[SUCCESS]:
      return { ...state, rewardFrequencies: action.data, rewardFrequenciesAreLoaded: true };
    case ActionTypes.GET_REWARD_FREQUENCIES[FAILURE]:
      return { ...state, error: action.error, rewardFrequenciesAreLoaded: false };

    case ActionTypes.BO_GET_PARTNERSHIP_REQUESTS[REQUEST]:
      return { ...state, partnershipRequestsAreLoaded: false };
    case ActionTypes.BO_GET_PARTNERSHIP_REQUESTS[SUCCESS]:
      return { ...state, partnershipRequests: action.data, partnershipRequestsAreLoaded: true };
    case ActionTypes.BO_GET_PARTNERSHIP_REQUESTS[FAILURE]:
      return { ...state, error: action.error, partnershipRequestsAreLoaded: false };

    case ActionTypes.BO_GET_PARTNERSHIP_REQUEST[REQUEST]:
      return { ...state, partnershipRequestAreLoaded: false };
    case ActionTypes.BO_GET_PARTNERSHIP_REQUEST[SUCCESS]:
      return { ...state, partnershipRequest: action.data, partnershipRequestsAreLoaded: true };
    case ActionTypes.BO_GET_PARTNERSHIP_REQUEST[FAILURE]:
      return { ...state, error: action.error, partnershipRequestAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
