import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ReactionForm {
    margin-bottom: 5px;
    positin: relative;
    .TextFormField {
      padding: 10px 0;
    }
    &__Buttons {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      @include respond-to(md) {
        justify-content: flex-start;
      }
      .ActionsButton__delete {
        display: flex;
        align-items: center;
        order: -1;
        span {
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }

      .Tooltip {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ReactionForm {
    }
  `;
};

export { staticStyles, getDynamicStyles };
