import css from 'styled-jsx/css';

import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CRMTable {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__Row {
      display: flex;
      flex-wrap: nowrap;
      gap: 14px;
      .text-row {
        height: 100% !important;
        max-height: 100% !important;
      }
    }
    &__CheckBox {
      width: 26px !important;
      height: 26px !important;
      min-width: 26px !important;
      min-height: 26px !important;
      border-radius: 0 !important;
    }
  }

  .text-row {
    width: 100% !important;
  }

  .ImageBlock {
    margin-bottom: rem(25px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-row {
      height: rem(40px) !important;
    }
  }

  .text {
    margin-top: rem(25px);

    .text-row {
      margin-top: rem(30px) !important;
      height: rem(45px) !important;
    }
  }

  .TinyMCE {
    .text-row {
      height: 495px !important;
      margin-bottom: 20px !important;
    }
  }

  .line {
    .text-row {
      height: rem(150px) !important;
    }
  }

  .smallLines {
    .text-row {
      height: rem(45px) !important;
      margin-bottom: 5px;
    }
  }

  .smallLine {
    .text-row {
      height: rem(45px) !important;
      margin-bottom: 20px;
    }
  }

  .bigLine {
    .text-row {
      height: rem(250px) !important;
    }
  }

  .boPaymentPageFirst {
    .text-row {
      height: rem(90px) !important;
      margin-bottom: rem(20px);
    }
  }

  .boPaymentPageSecond {
    .text-row {
      height: rem(150px) !important;
      margin-bottom: rem(20px);
    }
  }

  .boPaymentPageThird {
    .text-row {
      height: rem(250px) !important;
    }
  }

  .boUserInfoTopLine {
    .text-row {
      height: rem(70px) !important;
      margin-bottom: rem(20px);
    }
  }

  .boUserInfo {
    .text-row {
      height: rem(250px) !important;
      margin-bottom: rem(20px);
    }
  }

  .boUserInfoLastLine {
    .text-row {
      height: rem(150px) !important;
    }
  }

  .boUserHistoryInner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .boUserHistory {
    width: 100% !important;
    @include respond-to(md) {
      width: 100% !important;
      margin-bottom: 20px;
    }
    .text-row {
      height: rem(200px) !important;
    }
  }

  .boUserTagsInner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .boUserTag {
      width: auto !important;
    }
    .text-row {
      height: rem(30px) !important;
      margin: rem(0 10px 10px 0);
      width: rem(100px) !important;
    }
  }

  .News {
    .text-row {
      height: rem(385px) !important;
      margin: 0 0 rem(20px) 0 !important;
    }
  }

  .Pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .Pagination__item {
      width: 50% !important;
      @include respond-to(sm) {
        width: 100% !important;
      }
    }
    .text-row {
      height: 41px !important;
      margin: 0 0 rem(15px) 0 !important;
      @include respond-to(sm) {
        height: 78px !important;
      }
    }
  }

  .FileItemBlock {
    .text-row {
      height: rem(150px) !important;
      margin-bottom: rem(15px) !important;
    }
  }

  .Accounts {
    .Accounts__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(20px);

      .text-row {
        width: 30%;
        height: 80px !important;
        border-radius: 3px !important;
      }

      @include respond-to(sm) {
        flex-direction: column;

        .text-row {
          width: 100%;
          height: 60px !important;
        }
      }
    }
    .Accounts__item {
      margin-right: rem(15px) !important;
      &:nth-child(3) {
        margin-right: 0 !important;
      }

      @include respond-to(sm) {
        margin-bottom: rem(15px);
      }
    }
  }

  .Payments {
    .Payments__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(45px);

      .text-row {
        width: 30%;
        height: 100px !important;
        border-radius: 3px !important;
      }

      @include respond-to(sm) {
        flex-direction: column;

        .text-row {
          width: 100%;
          height: 60px !important;
        }
      }
    }
    .Payments__item {
      margin-right: rem(15px) !important;
      &:nth-child(3) {
        margin-right: 0 !important;
      }

      @include respond-to(sm) {
        margin-bottom: rem(15px);
      }
    }
  }

  .Dashboards {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .Dashboards__bigItem {
      width: 66% !important;
      .text-row {
        height: 400px !important;
        margin-bottom: rem(60px);
        border-radius: 3px !important;
      }
    }

    .Dashboards__smallItem {
      width: 32% !important;
      .text-row {
        height: 400px !important;
        margin-bottom: rem(60px);
        border-radius: 3px !important;
      }
    }

    @include respond-to(sm) {
      flex-direction: column;
      .Dashboards__bigItem {
        width: 100% !important;
      }
      .Dashboards__smallItem {
        width: 100% !important;
      }
    }
  }

  .BigBlock {
    width: 100%;

    &__item {
      .text-row {
        height: 80vh !important;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ImageBlock,
    .Block {
      .text-row {
        border-radius: ${border.radius};
      }
    }

    .show-loading-animation.rect-shape,
    .show-loading-animation.round-shape,
    .show-loading-animation.text-row,
    .show-loading-animation .rect-shape,
    .show-loading-animation .round-shape,
    .show-loading-animation .text-row {
      background-image: ${gradient(53.07, [colors.gray.rgb, 0.5], 0, [colors.lightGray.rgb, 0.5], 100)} !important;
    }
  `;
};

export { staticStyles, getDynamicStyles };
