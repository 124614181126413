import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksPage {
    width: 100%;
    padding: 25px 25px 25px 25px;

    @include respond-to(md) {
      padding: 15px 15px 0 15px;
    }
    :global(.TasksPage__tabs) {
      height: 97%;
    }

    :global(.Tabs__content) {
      height: 100%;
    }

    &__tabs {
      height: 97%;
    }
    &__my-tasks {
    }

    &__my-tasks-title-wrap {
      display: flex;
      align-items: flex-end;
      margin: 0 0 10px 0;
    }

    &__my-tasks-title {
      margin: 0 20px 0 0;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1px;
      color: #0f0f0f;
    }

    &__my-tasks-date {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #0f0f0f;
    }
  }
`;

export { staticStyles };
