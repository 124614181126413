import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'common/styling/theme';
import ReassignTable from '../ReassignTable';

import { staticStyles, getDynamicStyles } from './style';

const Reassign = ({ theme }) => {
  const dynamicStyles = Object.keys({}).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="Reassign">
      <ReassignTable externalClass="Reassign__table" />
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

Reassign.propTypes = {
  theme: PropTypes.object,
};

Reassign.defaultProps = {
  theme: {},
};

export default withTheme()(Reassign);
