import { put, call, take, fork } from 'redux-saga/effects';
import { acceptError } from 'common/_redux';
import { REQUEST } from 'redux-config/reduxHelpers';

import * as api from 'common/dashboards/api';
import * as ActionTypes from './actions';

export function* getLastFunnelsSaga({ hasBrokerAccess, currentMonth, lastMonth, partnerId, viewMode }) {
  try {
    let response;
    if (hasBrokerAccess) {
      response = yield call(api.getBackofficeMonthsFunnel, {
        firstMonth: currentMonth,
        secondMonth: lastMonth,
        partnerId,
        viewMode,
      });
    } else {
      response = yield call(api.getMonthsFunnel, {
        firstMonth: currentMonth,
        secondMonth: lastMonth,
      });
    }
    yield put(ActionTypes.getLastFunnels.success(response.data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getLastFunnels.failure(e));
  }
}
export function* getLastFunnelsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_LAST_FUNNELS[REQUEST]);
    yield call(getLastFunnelsSaga, data);
  }
}

export function* getMonthsFunnelsSaga({ hasBrokerAccess, firstMonth, secondMonth, partnerId, viewMode }) {
  try {
    let response;
    if (hasBrokerAccess) {
      response = yield call(api.getBackofficeEquallyMonthsFunnel, {
        firstMonth,
        secondMonth,
        partnerId,
        viewMode,
      });
    } else {
      response = yield call(api.getEquallyMonthsFunnel, {
        firstMonth,
        secondMonth,
      });
    }
    yield put(ActionTypes.getMonthsFunnels.success(response.data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getMonthsFunnels.failure(e));
  }
}

export function* getMonthsFunnelsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_MONTHS_FUNNELS[REQUEST]);
    yield call(getMonthsFunnelsSaga, data);
  }
}

export function* getTargetSaga({ hasBrokerAccess, partnerId, viewMode }) {
  try {
    let target;
    if (hasBrokerAccess) {
      if (partnerId) {
        target = yield call(api.getPartnerTarget, partnerId);
      } else {
        target = yield call(api.getBrokerTarget, viewMode);
      }
    } else {
      target = yield call(api.getPartnerTarget);
    }
    yield put(ActionTypes.getTarget.success(target.data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getTarget.failure(e));
  }
}

export function* getTargetWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_TARGET[REQUEST]);
    yield call(getTargetSaga, data);
  }
}

export function* putTargetSaga({ target, hasBrokerAccess, viewMode }) {
  try {
    let response;
    if (hasBrokerAccess) {
      response = yield call(api.putBrokerTarget, target, viewMode);
    } else {
      response = yield call(api.putPartnerTarget, target);
    }
    yield put(ActionTypes.putTarget.success(response.data));
    yield call(getTargetSaga, { hasBrokerAccess });
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.putTarget.failure(e));
  }
}

export function* putTargetWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.putTarget.REQUEST);
    yield call(putTargetSaga, payload);
  }
}

export function* getDailyFunnelsSaga({ hasBrokerAccess, startOfMonth, endOfMonth, partnerId, viewMode }) {
  try {
    let response;
    if (hasBrokerAccess) {
      response = yield call(api.getBackofficeDailyFunnels, {
        startDate: startOfMonth,
        endDate: endOfMonth,
        partnerId,
        viewMode,
      });
    } else {
      response = yield call(api.getDailyFunnels, { startDate: startOfMonth, endDate: endOfMonth });
    }
    yield put(ActionTypes.getDailyFunnels.success(response.data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getDailyFunnels.failure(e));
  }
}
export function* getDailyFunnelsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_DAILY_FUNNELS[REQUEST]);
    yield call(getDailyFunnelsSaga, data);
  }
}

export function* getUsersHistorySaga({ hasBrokerAccess, partnerId, viewMode }) {
  try {
    let response;
    if (hasBrokerAccess) {
      response = yield call(api.getBackofficeEvents, partnerId, viewMode);
    } else {
      response = yield call(api.getPartnershipEvents);
    }
    yield put(ActionTypes.getUsersHistory.success(response.data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUsersHistory.failure(e));
  }
}
export function* getUsersHistoryWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_USERS_HISTORY[REQUEST]);
    yield call(getUsersHistorySaga, data);
  }
}

export default [
  fork(getLastFunnelsWatcherSaga),
  fork(getMonthsFunnelsWatcherSaga),
  fork(getTargetWatcherSaga),
  fork(putTargetWatcherSaga),
  fork(getDailyFunnelsWatcherSaga),
  fork(getUsersHistoryWatcherSaga),
];
