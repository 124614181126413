import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import { ActionsButton } from 'common/components';
import { EllipsisIcon } from 'common/icons';
import { staticStyles, getDynamicStyles } from './style';

const ResponsiveTable = ({ data, columns, onRowClick, isNewTable, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;
  const [detailedRows, setDetailedRows] = useState([]);
  const toggleRowDetails = (e, id) => {
    e.stopPropagation();
    if (detailedRows.some(item => item.id === id)) {
      setDetailedRows(detailedRows.filter(item => item.id !== id));
    } else {
      setDetailedRows([...detailedRows, { id }]);
    }
  };

  const cellBuilder = rowItem =>
    isNewTable
      ? {
          row: {
            original: {
              ...rowItem,
            },
          },
        }
      : {
          original: {
            ...rowItem,
          },
        };

  return (
    <div className="ResponsiveTable">
      <div className="ResponsiveTable__thead">
        {columns
          .filter(column => column.isPreviewCell)
          .map((column, columnId) =>
            column.key || isNewTable ? (
              <FormattedMessage id={isNewTable ? column.Header : column.key} key={columnId}>
                {txt => <span>{txt}</span>}
              </FormattedMessage>
            ) : (
              <span key={columnId} />
            )
          )}
        <span />
      </div>
      <div className="ResponsiveTable__tbody">
        {data.map(rowItem => (
          <div
            className={classNames('ResponsiveTable__row', {
              'ResponsiveTable__row--opened': detailedRows.some(item => item.id === rowItem.id),
            })}
            key={rowItem.id}>
            <div
              role="button"
              onClick={() => {
                const id = rowItem.id;
                const title = rowItem?.title ?? '';

                onRowClick(rowItem.id, { original: { id, title } });
              }}
              onKeyDown={null}
              className="ResponsiveTable__row-preview"
              tabIndex={rowItem.id}>
              <div className="ResponsiveTable__preview-main">
                {columns
                  .filter(column => column.isPreviewCell)
                  .map((column, columnId) => (
                    <div className="ResponsiveTable__row-item" key={`ResponsiveTable-${rowItem.id}-${columnId}`}>
                      {column.Cell ? column.Cell(cellBuilder(rowItem)) : rowItem[column.accessor]}
                    </div>
                  ))}
                <ActionsButton
                  className="ResponsiveTable__ellipsis"
                  watch
                  onClickFunc={e => toggleRowDetails(e, rowItem.id)}>
                  <EllipsisIcon />
                </ActionsButton>
              </div>
              {!detailedRows.some(item => item.id === rowItem.id) && (
                <div className="ResponsiveTable__preview-description">
                  {columns
                    .filter(column => column.isPreviewDescriptionCell)
                    .map((column, columnId) => (
                      <div className="ResponsiveTable__row-item" key={`ResponsiveTable-${rowItem.id}-${columnId}`}>
                        {column.Cell ? column.Cell(cellBuilder(rowItem)) : rowItem[column.accessor]}
                      </div>
                    ))}
                </div>
              )}
            </div>
            {detailedRows.some(item => item.id === rowItem.id) && (
              <div className="ResponsiveTable__row-details">
                {columns
                  .filter(column => !column.isPreviewCell)
                  .map((column, columnId) => (
                    <div className="ResponsiveTable__details-item" key={`ResponsiveTable-${rowItem.id}-${columnId}`}>
                      {(column.key || typeof column.Header === 'string') && (
                        <FormattedMessage id={column.key || column.Header} key={columnId}>
                          {txt => <span className="ResponsiveTable__detailed-caption">{txt}:</span>}
                        </FormattedMessage>
                      )}
                      {column.Cell ? column.Cell(cellBuilder(rowItem)) : rowItem[column.accessor]}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {columns.some(column => column.Footer) && (
        <div className="ResponsiveTable__tfoot">
          <div className="ResponsiveTable__row-details">
            {columns
              .filter(column => column.Footer)
              .map((column, columnId) => (
                <div className="ResponsiveTable__details-item" key={`ResponsiveTable-footer-${columnId}`}>
                  {column.Footer}
                </div>
              ))}
          </div>
        </div>
      )}

      <style jsx global>
        {staticStyles}
      </style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

ResponsiveTable.propTypes = {
  columns: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  data: PropTypes.array,
  isNewTable: PropTypes.bool,
  theme: PropTypes.object,
};

ResponsiveTable.defaultProps = {
  data: [],
  isNewTable: false,
  theme: {},
};

export default withTheme()(ResponsiveTable);
export { ResponsiveTable };
