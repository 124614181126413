import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import md5 from 'blueimp-md5';
import { EditIcon } from 'common/icons';
import Gravatar from 'react-gravatar';

import {
  StatusCell,
  Collapsible,
  IssueMessages,
  IssueCreateMessage,
  DateCell,
  ActionsButton,
  DefaultButton,
  BasicModal,
} from 'common/components';

import { getLabelAndValueFromLangSlug } from 'common/utils/helpers';

import { withTheme } from 'common/styling/theme';
import IssueChangeLanguageForm from '../IssueChangeLanguageForm';

import { staticStyles, getDynamicStyles } from './style';

import { createMessage, editMessage } from '../../_redux';

import { UserAccounts, UserOperations, UserInteractions, RejectPartnerForm } from '..';

const IssueContent = ({ issue, profile, verifyPartnerFromTicket, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleAvatarClick = id => window.open(`/backoffice/users/${id}`);

  const isMobile = window.innerWidth <= 1180;

  const [isMessageEditing, setMessageEditing] = useState(false);
  const [infoIsHidden, setInfoIsHidden] = useState(isMobile);
  const [isChangeLangModalOpen, setIsChangeLangModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleToggleContent = () => {
    if (isMobile) {
      setInfoIsHidden(!infoIsHidden);
    }
  };

  const ticketAgentLanguage = useMemo(() => getLabelAndValueFromLangSlug(issue.language), [issue.language]);

  return (
    <div className="IssueWrapper">
      <div className="IssueAbout">
        <button type="button" className="Issue__info" onClick={() => handleToggleContent()}>
          <ul>
            <li className="IssueInfo__user-inner">
              <div className="IssueInfo__avatar-inner">
                <Gravatar md5={md5(issue.user.email)} size={62} onClick={() => handleAvatarClick(issue.user.id)} />
              </div>
              <div className="IssueInfo__user-info">
                <FormattedMessage id="boUser" />
                <p>{issue.user.fullName}</p>
              </div>
            </li>
          </ul>
          <i
            className={classNames('Issue__info-arrow', {
              'Issue__info-arrow--right': infoIsHidden,
              'Issue__info-arrow--down': !infoIsHidden,
            })}
          />
        </button>
        <div className="IssueUser">
          {!issue.user.isPartner && issue.isPartnershipRequest && issue.user.isPartnershipRequested && (
            <div className="PartnershipRequestAnswer">
              <FormattedMessage id="boSentPartnership">
                {txt => <span className="PartnershipRequestAnswer__InfoText">{txt}</span>}
              </FormattedMessage>
              <div className="PartnershipRequestAnswer__Buttons">
                <DefaultButton
                  filled
                  wide
                  textId="justAccept"
                  isUpperCase
                  onClick={() => verifyPartnerFromTicket({ id: issue.user.id, issue: issue.id })}
                />
                <DefaultButton
                  wide
                  textId="justReject"
                  onClick={() => setShowModal(true)}
                  butColor="error"
                  isUpperCase
                />
              </div>
            </div>
          )}
          <div className="InfoLine">
            <div className="InfoLine__left">
              <FormattedMessage id="justEmail" className="InfoLine__left" />
            </div>
            <div className="InfoLine__right">{issue.user.email}</div>
          </div>

          <div className="InfoLine">
            <div className="InfoLine__left">
              <FormattedMessage id="justPartner" className="InfoLine__left" />
            </div>
            <div className="InfoLine__right">
              <StatusCell
                statusCode={
                  issue.user.isPartner ? 'yes' : issue.user.isPartnershipRequested ? 'partnership_requested' : 'no'
                }
              />
            </div>
          </div>
          <div className="InfoLine">
            <div className="InfoLine__left">
              <FormattedMessage id="justStatus" className="InfoLine__left" />
            </div>
            {issue.user.status && <StatusCell statusCode={issue.user.status} />}
          </div>
          <div className="InfoLine">
            <div className="InfoLine__left">
              <FormattedMessage id="justActive" className="InfoLine__left" />
            </div>
            <div className="InfoLine__right">
              <StatusCell statusCode={issue.user.isActive ? 'active' : 'blocked'} />
            </div>
          </div>
          <div className="InfoLine">
            <div className="InfoLine__left">
              <FormattedMessage id="justSupportMessages" className="InfoLine__left" />
            </div>
            <div className="InfoLine__right">
              <StatusCell statusCode={issue.user.supportMessages ? 'allow' : 'disallow'} />
            </div>
          </div>
        </div>
        <div hidden={infoIsHidden}>
          <Collapsible captionId="clientAccounts">
            <UserAccounts user={issue.user} />
          </Collapsible>
          <Collapsible captionId="financialOperations">
            <UserOperations user={issue.user} />
          </Collapsible>
          <Collapsible captionId="justInteractions">
            <UserInteractions user={issue.user} currentIssueId={issue.id} />
          </Collapsible>
        </div>
      </div>
      <div className="IssueContent">
        <div className="IssueInfoNew">
          <div className="IssueInfoNew__title">
            <FormattedMessage id="justTitle">{txt => <span className="infoTitle">{txt}</span>}</FormattedMessage>
            <p>{issue.title}</p>
          </div>
          <div className="IssueInfoNew__date">
            <FormattedMessage id="justRegistrationDate">
              {txt => <span className="infoTitle">{txt}</span>}
            </FormattedMessage>
            <DateCell value={issue.created} />
          </div>
          <div className="IssueInfoNew__language">
            <FormattedMessage id="justLanguage">{txt => <span className="infoTitle">{txt}</span>}</FormattedMessage>
            <span>
              <p>{ticketAgentLanguage.label}</p>
              <ActionsButton
                tooltipId="Edit__email__tooltip"
                tooltipTextId="justEdit"
                edit
                onClickFunc={() => setIsChangeLangModalOpen(true)}>
                <EditIcon />
              </ActionsButton>
            </span>
          </div>
          <div className="IssueInfoNew__status">
            <FormattedMessage id="boStatus">{txt => <span className="infoTitle">{txt}</span>}</FormattedMessage>
            <StatusCell statusCode={issue.status} />
          </div>
        </div>
        <IssueCreateMessage
          disabled={issue.status === 'closed'}
          initialValues={{ issue: issue.id }}
          createMessage={createMessage}
          editMessage={editMessage}
          email={profile.email}
          isMessageEditing={isMessageEditing}
          setMessageEditing={setMessageEditing}
          isBroker
          currentInterface="backoffice"
        />
        <IssueMessages issue={issue} isBroker setMessageEditing={setMessageEditing} />
      </div>
      <BasicModal
        isOpen={isChangeLangModalOpen}
        captionId="justEditSupportAgentLanguage"
        onRequestClose={() => setIsChangeLangModalOpen(false)}>
        <IssueChangeLanguageForm issueId={issue.id} onRequestClose={() => setIsChangeLangModalOpen(false)} />
      </BasicModal>

      <BasicModal isOpen={showModal} captionId="createRejectNotification" onRequestClose={() => setShowModal(false)}>
        <RejectPartnerForm id={issue.user.id} issue={issue.id} onSubmitSuccess={() => setShowModal(false)} />
      </BasicModal>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

IssueContent.propTypes = {
  profile: PropTypes.object.isRequired,
  issue: PropTypes.object.isRequired,
  verifyPartnerFromTicket: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

IssueContent.defaultProps = {
  theme: {},
};

export default withTheme()(IssueContent);
export { IssueContent };
