import css from "styled-jsx/css";

const staticStyles = css`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .CommissionsPage__buttons {
    margin: rem(32px 0);
    
    :global(.DefaultButton) {
      &:first-child {
        margin-right: rem(32px);
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .CommissionsPage {
    }
  `;
};

export { staticStyles, getDynamicStyles };
