import React from 'react';
import { BaseTable } from 'crm/_components';
import classNames from 'classnames';
import { staticStyles } from './style';
import testData from './testData';
import iconBadClient from './images/iconBadClient.svg';
import iconNotCompleted from './images/iconNotCompleted.svg';
import iconNotSuccess from './images/iconNotSuccess.svg';
import iconRecord from './images/iconRecord.svg';
import iconSuccess from './images/iconSuccess.svg';

const AllTasksTableCalls = () => {
  /* eslint-disable */
  const columns = [
    {
      accessor: 'contact',
      Header: 'crmContact',
      Cell: ({
        row: {
          original: { contact },
        },
      }) => <div className="contact">{contact}</div>,
    },
    {
      accessor: 'to',
      Header: 'justTo',
      Cell: ({
        row: {
          original: { to },
        },
      }) => <div className="to">{to}</div>,
    },
    {
      accessor: 'description',
      Header: 'justDescription',
      Cell: ({
        row: {
          original: { description },
        },
      }) => <div className="description">{description}</div>,
    },
    {
      accessor: 'duration',
      Header: 'crmDuration',
      Cell: ({
        row: {
          original: { duration },
        },
      }) => <div className="duration">{duration}</div>,
    },
    {
      accessor: 'record',
      Header: 'crmRecord',
      Cell: ({
        row: {
          original: { record },
        },
      }) => (
        <div className="record">
          <img src={iconRecord} alt="record" />
        </div>
      ),
    },
    {
      accessor: 'callStatus',
      Header: 'crmCallStatus',
      Cell: ({
        row: {
          original: { callStatus },
        },
      }) => (
        <div
          className={classNames('call-status', {
            'call-status--not-success': callStatus === 'Not success',
            'call-status--success': callStatus === 'Success',
            'call-status--not-completed': callStatus === 'Not completed',
            'call-status--bad-client': callStatus === 'Bad client',
          })}>
          <div className="call-status__icon">
            {callStatus === 'Not success' && <img src={iconNotSuccess} alt={callStatus} />}
            {callStatus === 'Success' && <img src={iconSuccess} alt={callStatus} />}
            {callStatus === 'Not completed' && <img src={iconNotCompleted} alt={callStatus} />}
            {callStatus === 'Bad client' && <img src={iconBadClient} alt={callStatus} />}
          </div>
          <div className="call-status__text">{callStatus}</div>
        </div>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="AllTasksTableCalls">
      {testData.length && <BaseTable columns={columns} data={testData} onRowClick={() => {}} />}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default AllTasksTableCalls;
