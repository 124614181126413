import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const INITIALIZE_APP = createRequestTypes('INITIALIZE_APP');
export const GET_INTERFACE_CONFIG = createRequestTypes('GET_INTERFACE_CONFIG');
export const GET_CUSTOM_CSS = createRequestTypes('GET_CUSTOM_CSS');
export const GET_CUSTOM_JS = createRequestTypes('GET_CUSTOM_JS');
export const GET_TELEGRAM_AUTH_WIDGET = createRequestTypes('GET_TELEGRAM_AUTH_WIDGET');
export const CHANGE_LANGUAGE = createRequestTypes('CHANGE_LANGUAGE');
export const LOAD_LANGUAGE = createRequestTypes('LOAD_LANGUAGE');

export const initializeApp = {
  request: () => action(INITIALIZE_APP[REQUEST]),
  success: () => action(INITIALIZE_APP[SUCCESS]),
  failure: error => action(INITIALIZE_APP[FAILURE], { error }),
};

export const getInterfaceConfig = {
  request: () => action(GET_INTERFACE_CONFIG[REQUEST]),
  success: data => action(GET_INTERFACE_CONFIG[SUCCESS], { data }),
  failure: error => action(GET_INTERFACE_CONFIG[FAILURE], { error }),
};

export const getCustomCss = {
  request: () => action(GET_CUSTOM_CSS[REQUEST]),
  success: data => action(GET_CUSTOM_CSS[SUCCESS], { data }),
  failure: error => action(GET_CUSTOM_CSS[FAILURE], { error }),
};

export const getCustomJs = {
  request: () => action(GET_CUSTOM_JS[REQUEST]),
  success: data => action(GET_CUSTOM_JS[SUCCESS], { data }),
  failure: error => action(GET_CUSTOM_JS[FAILURE], { error }),
};

export const getTelegramAuthWidget = {
  request: () => action(GET_TELEGRAM_AUTH_WIDGET[REQUEST]),
  success: data => action(GET_TELEGRAM_AUTH_WIDGET[SUCCESS], { data }),
  failure: error => action(GET_TELEGRAM_AUTH_WIDGET[FAILURE], { error }),
};

export const loadLanguage = {
  request: lang => action(LOAD_LANGUAGE[REQUEST], { lang }),
  success: (lang, messages) => action(LOAD_LANGUAGE[SUCCESS], { lang, messages }),
  failure: (lang, error) => action(LOAD_LANGUAGE[FAILURE], { lang, error }),
};

export const changeLanguage = {
  request: (lang, changeUser) => action(CHANGE_LANGUAGE[REQUEST], { lang, changeUser }),
  success: lang => action(CHANGE_LANGUAGE[SUCCESS], { lang }),
  failure: lang => action(CHANGE_LANGUAGE[FAILURE], { lang }),
};

export const GET_COMPANY_NEWS = createRequestTypes('GET_COMPANY_NEWS');
export const getCompanyNews = {
  request: () => action(GET_COMPANY_NEWS[REQUEST]),
  success: data => action(GET_COMPANY_NEWS[SUCCESS], { data }),
  failure: error => action(GET_COMPANY_NEWS[FAILURE], { error }),
};
