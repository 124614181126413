import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.DateFormField__caption) {
    font-size: 12px;
  }

  .CloseTaskForm {
    width: 400px;
    max-width: 100%;
    padding: 0 10px;
    &__title {
      text-align: center;
      font-size: rem(18px);
      font-weight: 500;
    }

    &__line {
      margin-bottom: rem(15px);
    }

    &__submit {
      text-align: center;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .CloseTaskForm__formError {
      color: ${colors.error.hex};
      font-size: 14px;
      font-weight: 400;
    }
  `;
};

export { staticStyles, getDynamicStyles };
