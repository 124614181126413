/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { EditIcon, BasketIcon } from 'common/icons';
import { MoneyCell, LoginCell, DefaultButton, Tooltip, ActionsButton, BasicModal } from 'common/components';
import { withTheme } from 'common/styling/theme';

import EditTradeAccountPartnerCode from '../EditTradeAccountPartnerCode';
import RemoveTradeAccountPartnerCodeForm from '../RemoveTradeAccountPartnerCodeForm';

import { staticStyles, getDynamicStyles } from './style';

const AccountInfoPanel = ({
  id,
  balance,
  equity,
  totalWithdrawals,
  totalDeposits,
  profit,
  profitPercent,
  marginFree,
  leverage,
  partnerCode,
  loading,
  theme,
  accountTypeTitle,
  partnershipAccount,
  userId,
  isBlocked,
  isArchived,
  loadingFailed,
  blockFunc,
  getUserAccount,
  unBlockFunc,
  deleteFunc,
  restoreFunc,
  partnershipPermission,
  actionIsLoading,
  platformTitle,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const selectedTradeAccountId = useMemo(() => id, [id]);

  const [isChangePartnerCodeModalOpen, setIsChangePartnerCodeModalOpen] = useState(false);

  const [isRemovePartnerCodeModalOpen, setIsRemovePartnerCodeModalOpen] = useState(false);

  const openChangePartnerCodeModal = useCallback(
    () => setIsChangePartnerCodeModalOpen(true),
    [isChangePartnerCodeModalOpen]
  );
  const closeChangePartnerCodeModal = useCallback(
    () => setIsChangePartnerCodeModalOpen(false),
    [isChangePartnerCodeModalOpen]
  );

  const openRemovePartnerCodeModalOpen = useCallback(
    () => setIsRemovePartnerCodeModalOpen(true),
    [isRemovePartnerCodeModalOpen]
  );
  const closeRemovePartnerCodeModalOpen = useCallback(
    () => setIsRemovePartnerCodeModalOpen(false),
    [isRemovePartnerCodeModalOpen]
  );

  const [loadingButtons, setLoadingButtons] = useState({
    block: false,
    archive: false,
  });

  const handleBlock = (isBlocked, id) => {
    setLoadingButtons({ archive: false, block: true });
    if (isBlocked) {
      unBlockFunc(userId, id);
    } else {
      blockFunc(userId, id);
    }
  };

  const handleArchive = (isArchived, id) => {
    setLoadingButtons({ archive: true, block: false });
    if (isArchived) {
      restoreFunc(userId, id);
    } else {
      deleteFunc(userId, id);
    }
  };

  return (
    <div className="AccountInfoPanel__wrapper">
      <div className="AccountInfoPanel AccountInfoPanel__top">
        <ul>
          <li>
            <FormattedMessage id="justType">
              {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
            </FormattedMessage>
            <LoginCell title={accountTypeTitle} />
          </li>
          <li>
            <FormattedMessage id="justBalance">
              {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
            </FormattedMessage>
            {isNaN(balance.amount) ? (
              <span className="AccountInfoPanel__second-line">*****</span>
            ) : (
              <MoneyCell value={balance} loading={loading} loadingFailed={loadingFailed} />
            )}
          </li>
          <li>
            <FormattedMessage id="justEquity">
              {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
            </FormattedMessage>
            {isNaN(equity.amount) ? (
              <span className="AccountInfoPanel__second-line">*****</span>
            ) : (
              <MoneyCell value={equity} loading={loading} loadingFailed={loadingFailed} />
            )}
          </li>
          <li className="Leverage__wrapper">
            <FormattedMessage id="justLeverage">
              {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
            </FormattedMessage>
            <span className="AccountInfoPanel__second-line">
              {!loading ? <p>{leverage ? `1:${leverage}` : '—'}</p> : '...'}
            </span>
          </li>
          {partnershipPermission && (
            <li className="Leverage__wrapper">
              <FormattedMessage id="boPartnerCode">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <span className="AccountInfoPanel__second-line">
                {!loading ? <p>{partnerCode || '—'}</p> : '...'}
                <div className="UserAccounts__ActionsButtonsCell">
                  <ActionsButton edit buttonId="changeUserEmail" condition onClickFunc={openChangePartnerCodeModal}>
                    <EditIcon />
                  </ActionsButton>
                  {partnerCode && (
                    <ActionsButton
                      deleteType
                      buttonId="removePartnerCode"
                      onClickFunc={openRemovePartnerCodeModalOpen}
                      condition>
                      <BasketIcon />
                    </ActionsButton>
                  )}
                </div>
              </span>
            </li>
          )}
          {['MetaTrader 4', 'MetaTrader 5'].includes(platformTitle) && !isNaN(balance) && !isNaN(balance) && (
            <li>
              <FormattedMessage id="justFreeMargin">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell value={marginFree} loading={loading} loadingFailed={loadingFailed} />
            </li>
          )}

          {!partnershipAccount && partnershipPermission && (
            <li>
              <FormattedMessage id="justActions">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <Tooltip id={`boBlockedAccountAction_${id}`} textId={balance ? '' : 'accountBroken'}>
                <div className="AccountInfoPanel__payments-buttons">
                  <DefaultButton
                    textId={isBlocked ? 'justUnblock' : 'justBlock'}
                    disabled={actionIsLoading || loading || loadingFailed}
                    loading={actionIsLoading && loadingButtons.block}
                    onClick={() => handleBlock(isBlocked, id)}
                  />
                  <DefaultButton
                    textId={isArchived ? 'justRestore' : 'justArchive'}
                    butColor="error"
                    disabled={actionIsLoading || loading || (isArchived ? !isArchived : loadingFailed)}
                    loading={actionIsLoading && loadingButtons.archive}
                    onClick={() => handleArchive(isArchived, id)}
                  />
                </div>
              </Tooltip>
            </li>
          )}
        </ul>
      </div>
      {profit && totalDeposits && totalWithdrawals && (
        <div className="AccountInfoPanel AccountInfoPanel__bottom">
          <ul>
            <li>
              <FormattedMessage id="justTotalProfit">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell tooltipId={`AccountInfoPanel-justTotalProfit-${id}`} value={profit} />
            </li>
            <li>
              <FormattedMessage id="justProfitInPercent">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell
                tooltipId={`AccountInfoPanel-justTotalProfitInPercent-${id}`}
                value={{ amount: profitPercent, currency: '%' }}
                maxDigitsAfterDecimal={4}
              />
            </li>

            <li className="Account__partner-code">
              <FormattedMessage id="justTotalDeposits">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell tooltipId={`AccountInfoPanel-justTotalDeposits-${id}`} value={totalDeposits} />
            </li>

            <li>
              <FormattedMessage id="justTotalWithdrawals">
                {txt => <span className="AccountInfoPanel__caption">{txt}</span>}
              </FormattedMessage>
              <MoneyCell tooltipId={`AccountInfoPanel-justTotalWithdrawals-${id}`} value={totalWithdrawals} />
            </li>
          </ul>
        </div>
      )}
      <BasicModal
        captionId="editAccountPartnerCode"
        isOpen={isChangePartnerCodeModalOpen}
        onRequestClose={closeChangePartnerCodeModal}>
        {isChangePartnerCodeModalOpen && (
          <EditTradeAccountPartnerCode
            onSubmitSuccess={() => {
              closeChangePartnerCodeModal();
              getUserAccount(userId, id);
            }}
            user={{ id: userId }}
            tradeAccountId={selectedTradeAccountId}
          />
        )}
      </BasicModal>

      <BasicModal
        captionId="confirmText"
        isOpen={isRemovePartnerCodeModalOpen}
        onRequestClose={closeRemovePartnerCodeModalOpen}>
        {isRemovePartnerCodeModalOpen && (
          <RemoveTradeAccountPartnerCodeForm
            onSubmitSuccess={() => {
              closeRemovePartnerCodeModalOpen();
              getUserAccount(userId, id);
            }}
            closeModal={closeRemovePartnerCodeModalOpen}
            user={{ id: userId }}
            tradeAccountId={selectedTradeAccountId}
          />
        )}
      </BasicModal>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};
/* eslint-enable */

AccountInfoPanel.propTypes = {
  totalWithdrawals: MoneyCell.propTypes.value,
  totalDeposits: MoneyCell.propTypes.value,
  profit: MoneyCell.propTypes.value,
  profitPercent: PropTypes.string,
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  balance: MoneyCell.propTypes.value,
  equity: MoneyCell.propTypes.value,
  marginFree: MoneyCell.propTypes.value,
  leverage: PropTypes.number,
  partnerCode: PropTypes.number.isRequired,
  accountTypeTitle: PropTypes.string.isRequired,
  loadingFailed: PropTypes.bool,
  blockFunc: PropTypes.func,
  unBlockFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  restoreFunc: PropTypes.func,
  getUserAccount: PropTypes.func.isRequired,
  isArchived: PropTypes.bool.isRequired,
  platformTitle: PropTypes.string.isRequired,
  partnershipPermission: PropTypes.bool.isRequired,
  actionIsLoading: PropTypes.bool,
  partnershipAccount: PropTypes.bool,
  loading: PropTypes.bool,
  isBlocked: PropTypes.bool,
  theme: PropTypes.object,
};

AccountInfoPanel.defaultProps = {
  totalWithdrawals: null,
  profitPercent: null,
  totalDeposits: null,
  profit: null,
  leverage: 0,
  balance: {},
  equity: {},
  marginFree: {},
  partnershipAccount: false,
  loading: false,
  isBlocked: false,
  actionIsLoading: false,
  loadingFailed: false,
  blockFunc: null,
  unBlockFunc: null,
  deleteFunc: null,
  restoreFunc: null,
  theme: {},
};

export default withTheme()(AccountInfoPanel);
export { AccountInfoPanel };
