/* eslint-disable react/jsx-indent */
import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { reduxForm, Field, change } from 'redux-form';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { DefaultButton, ReduxTextField, ReduxSelectField, MoneyCell } from 'common/components';
import { required, numericality } from 'redux-form-validators';
import { FormMessageHOC } from 'common/HOCs';

import { addStrategyInvestments } from '../../_redux';

import { staticStyles } from './style';

const InvestStrategyForm = ({
  strategyId,
  strategyAccountId,
  strategyCurrency,
  minInvestmentAmount,
  accounts,
  walletBalance,
  isWallet,
  isRollover,
  tpslInRollovers,
  afterSubmitSuccess,
  handleSubmit,
  submitting,
  pristine,
  invalid,
  error,
  dispatch,
}) => {
  const [selectedAccountAmount, setSelectedAccountAmount] = useState(0);
  const [selectedAccountCurrency, setSelectedAccountCurrency] = useState('');
  const [takeProfitFilled, setTakeProfitFilled] = useState(false);
  const [stopLossFilled, setStopLossFilled] = useState(false);
  const [exchangeCoefficient, setExchangeCoefficient] = useState(1);
  const [amountToInvest, setAmountToInvest] = useState(0);
  const [investInfo, setInvestInfo] = useState({});
  const [receiveAmount, setReceiveAmount] = useState(0);
  const { details, availableAmount } = investInfo;
  const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));
  const { recipientCurrencyDigit = 2 } = useMemo(() => {
    if (details) {
      const { recipientCurrencyDigit } = details;
      return { recipientCurrencyDigit };
    }
    return {};
  }, [details]);

  useEffect(() => {
    if (isWallet) {
      axios
        .get('/internal_transfers/transfer/invest_from_wallet/', {
          params: { recipientAccount: strategyAccountId },
        })
        .then(({ data }) => {
          setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
          setInvestInfo(data);
        });
    }
  }, []);

  const submit = handleSubmit(values => {
    const takeProfitAndStopLoss = {
      takeProfit: { amount: values.takeProfit, currency: strategyCurrency },
      stopLoss: { amount: values.stopLoss, currency: strategyCurrency },
    };
    return addStrategyInvestments(
      {
        ...values,
        money: { amount: values.money, currency: isWallet ? 'USD' : selectedAccountCurrency },
        strategy: strategyId,
        ...(values.takeProfit !== undefined ? takeProfitAndStopLoss : {}),
      },
      dispatch
    );
  });

  const history = useHistory();

  const handleChangeAccount = value => {
    const selectedAccount = accounts.find(account => account.id === value);
    setSelectedAccountAmount(selectedAccount.balance.amount);
    setSelectedAccountCurrency(selectedAccount.balance.currency);

    axios
      .get('/internal_transfers/transfer/invest/', {
        params: { senderAccount: selectedAccount.id, recipientAccount: strategyAccountId },
      })
      .then(({ data }) => {
        setExchangeCoefficient(data?.details?.exchangeCoefficient || 1);
        setInvestInfo(data);
      });
  };

  const handleChangeTakeProfit = value => setTakeProfitFilled(value !== '');
  const handleChangeStopLoss = value => setStopLossFilled(value !== '');

  const withdrawalAccountEnabled = takeProfitFilled && stopLossFilled;

  useEffect(() => {
    if (!withdrawalAccountEnabled) dispatch(change('InvestStrategyForm', 'conditionsAccount', null));
  }, [withdrawalAccountEnabled]);

  const accountOptions = accounts.map(({ id, login, balance }) => ({
    value: id,
    label: `${login} (${balance.amount} ${balance.currency})`,
  }));

  const justAmountOfInvestmentNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justAmountOfInvestmentTixee'
      : 'justAmountOfInvestment';

  const justTpSlWithdrawalAccountDescriptionNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justTpSlWithdrawalAccountDescriptionTixee'
      : 'justTpSlWithdrawalAccountDescription';

  const investAvailableFundsTipNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'investAvailableFundsTipTixee'
      : 'investAvailableFundsTip';
  const justMinimumInvestAmountNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justMinimumInvestAmountTixee'
      : 'justMinimumInvestAmount';

  const calculateReceiveAmount = value => {
    const sendAmount = parseFloat(value);
    if (typeof sendAmount === 'number' && sendAmount <= availableAmount && details) {
      const { exchangeCoefficient = 1, commission } = details;
      let calculatedCommission =
        (sendAmount / 100) * commission.percentage + commission.fix * details.exchangeCoefficientForFix;
      if (calculatedCommission > details.maxCommission.amount) calculatedCommission = details.maxCommission.amount;
      if (calculatedCommission < details.minCommission.amount) calculatedCommission = details.minCommission.amount;
      const receiveAmount = (sendAmount - calculatedCommission) * exchangeCoefficient;
      let roundedReceiveAmount = 0;
      if (receiveAmount > 0) {
        roundedReceiveAmount = getFixedNumber(receiveAmount, recipientCurrencyDigit);
      }
      setReceiveAmount(roundedReceiveAmount.toString());
    } else {
      setReceiveAmount(null);
    }
  };

  return (
    <form className="InvestStrategyForm" onSubmit={submit}>
      {!isWallet && (
        <Field
          component={ReduxSelectField}
          name="account"
          maxMenuHeight={140}
          textId="justAccount"
          options={accountOptions}
          onChange={handleChangeAccount}
          disabled={submitting}
          validate={[required()]}
          searchable
        />
      )}
      <Field
        component={ReduxTextField}
        textId={justAmountOfInvestmentNew}
        type="number"
        name="money"
        disabled={submitting}
        validate={[required(), numericality({ '>=': 0, '<=': isWallet ? walletBalance : selectedAccountAmount })]}
        description={isWallet ? 'USD' : selectedAccountCurrency}
        onChange={newValue => {
          setAmountToInvest(Number(newValue));
          calculateReceiveAmount(Number(newValue));
        }}
        tooltipId={investAvailableFundsTipNew}
        tooltipTextId={investAvailableFundsTipNew}
      />
      {isWallet && (
        <div className="InvestStrategyForm__desc">
          <FormattedMessage id="rammAccountAmount" />
          <MoneyCell value={{ amount: availableAmount, currency: 'USD' }} />
        </div>
      )}
      {typeof amountToInvest === 'number' && amountToInvest !== 0 && (
        <div className="InvestStrategyForm__desc">
          <FormattedMessage id="clinetYouWillInvestAround" />
          <MoneyCell value={{ amount: amountToInvest * exchangeCoefficient, currency: strategyCurrency }} />
        </div>
      )}
      {receiveAmount ? (
        <div className="InvestStrategyForm__desc">
          <FormattedMessage id="justWillReceive" />
          <MoneyCell value={{ amount: receiveAmount, currency: strategyCurrency }} />
        </div>
      ) : null}
      <div className="InvestStrategyForm__desc">
        <FormattedMessage id={justMinimumInvestAmountNew} />
        <MoneyCell value={{ amount: minInvestmentAmount, currency: strategyCurrency }} />
      </div>
      {(!isRollover || tpslInRollovers) && (
        <>
          <div className="InvestStrategyForm__line">
            <Field
              component={ReduxTextField}
              textId="justTakeProfit"
              type="number"
              name="takeProfit"
              onChange={handleChangeTakeProfit}
              description={strategyCurrency}
              disabled={submitting}
              validate={stopLossFilled ? [required()] : []}
            />

            <Field
              component={ReduxTextField}
              textId="justStopLoss"
              type="number"
              name="stopLoss"
              onChange={handleChangeStopLoss}
              description={strategyCurrency}
              disabled={submitting}
              validate={takeProfitFilled ? [required()] : []}
            />
          </div>
          <Field
            component={ReduxSelectField}
            name="conditionsAccount"
            maxMenuHeight={140}
            textId="justTpSlWithdrawalAccount"
            options={accountOptions}
            disabled={!withdrawalAccountEnabled || submitting}
            validate={withdrawalAccountEnabled ? [required()] : []}
            searchable
          />
          <div className="InvestStrategyForm__desc">
            <FormattedMessage id={justTpSlWithdrawalAccountDescriptionNew} />
          </div>
        </>
      )}
      {isRollover && (
        <p className="InvestStrategyForm__desc">
          <FormattedMessage id="investRolloversOrderWarning" />
        </p>
      )}
      {error && <strong className="formError">{error}</strong>}
      <div className="InvestStrategyForm__line">
        {(isWallet &&
          Number(walletBalance) >= Number(minInvestmentAmount) / Number(exchangeCoefficient) &&
          Number(amountToInvest) >= Number(minInvestmentAmount) / Number(exchangeCoefficient)) ||
        (!isWallet &&
          Number(amountToInvest) * exchangeCoefficient >= Number(minInvestmentAmount) &&
          Number(selectedAccountAmount) * exchangeCoefficient > Number(minInvestmentAmount)) ? (
          <DefaultButton
            type="submit"
            textId="justInvest"
            loading={submitting}
            disabled={invalid || submitting || pristine}
            filled
          />
        ) : (
          <DefaultButton textId="justDeposit" onClick={() => history.push(`/deposit/`)} filled />
        )}

        <DefaultButton textId="justCancel" onClick={() => afterSubmitSuccess()} />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

InvestStrategyForm.propTypes = {
  strategyAccountId: PropTypes.number.isRequired,
  minInvestmentAmount: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
  afterSubmitSuccess: PropTypes.func.isRequired,
  isWallet: PropTypes.bool.isRequired,
  strategyCurrency: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  strategyId: PropTypes.number,
  walletBalance: PropTypes.string,
  error: PropTypes.string,
  isRollover: PropTypes.bool.isRequired,
  tpslInRollovers: PropTypes.bool.isRequired,
};

InvestStrategyForm.defaultProps = {
  walletBalance: 0,
  strategyId: null,
  error: '',
};

export default compose(
  FormMessageHOC,
  reduxForm({
    form: 'InvestStrategyForm',
    onSubmitSuccess(result, dispatch, props) {
      props.showFormSuccessMessage('paymentSuccessful');
      props.afterSubmitSuccess();
    },
  })
)(InvestStrategyForm);
export { InvestStrategyForm };
