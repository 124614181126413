import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

const updateItemInArray = (array, itemId, newItem) => {
  if (!array.length && newItem.id) {
    return [newItem];
  }
  return array.map(item => (item.id !== itemId ? item : { ...item, ...newItem }));
};

const emptyDocuments = {
  passport: [],
  identification_proof: [],
  address_proof: [],
  additional_document: [],
  passport_with_face: [],
};

export const initialState = {
  users: {},
  usersAreLoaded: false,

  lpas: {},
  lpasAreLoaded: false,

  user: {},
  userIsLoaded: false,
  userIsFullyLoaded: false,

  userVerifiedIsLoading: false,
  userRejectedIsLoading: false,

  userBlockIsLoading: false,
  userUnblockIsLoading: false,

  userEnableSupportMessagesIsLoading: false,
  userDisableSupportMessagesIsLoading: false,

  documentsUploadLoading: false,
  withdrawalProof: null,
  documentsUploadErrors: {},
  documentVerifyIsLoading: {},
  documentRejectIsLoading: {},

  userDocuments: emptyDocuments,
  userDocumentAreLoaded: false,

  account: {},
  accountIsLoaded: false,

  accounts: [],
  accountsAreLoaded: false,

  payments: {},
  loadingPayments: false,

  bonuses: {},
  bonusesAreLoaded: false,

  notes: [],
  notesDocuments: {},
  notesDocumentsAreLoaded: false,
  notesIsLoaded: false,

  allTags: [],
  allTagsIsLoaded: false,

  userTags: [],
  userTagsIsLoaded: false,

  userPartnerAccounts: [],
  userPartnerAccountsIsLoaded: false,

  partnershipConditionsList: [],

  availablePartnerCodes: [],
  availablePartnerCodesAreLoaded: false,

  userIssue: [],
  userIssueAreLoaded: false,

  userCards: [],
  userCardsAreLoaded: false,

  userAvailableDeposits: [],
  userAvailableDepositsAreLoaded: false,

  cardPhoto: '',
  cardPhotoIsLoaded: false,

  cancelUserBonusError: '',

  userUtm: {},
  userUtmAreLoaded: false,

  internalTransfers: {},
  internalTransfersAreLoaded: false,

  showWithdrawApproveModal: false,

  managersList: [],
  managersListAreLoaded: false,

  managerClient: null,
  managerClientIsLoading: false,

  kycValues: {},
  kycValuesAreLoaded: false,

  freeClientsCount: 0,

  redirect: false,
  error: '',

  serverList: [],
  serverListIsLoaded: false,

  callProvidersList: {},
  callProvidersListIsLoaded: false,

  callIsMade: false,

  countries: [],
  countriesAreLoaded: false,

  chosenClientType: 'new',

  userImportData: [],
  userImportDataIsLoaded: false,
  isImportRedirect: false,

  importExtensions: {},

  userLoginHistoryIsLoaded: false,
  userLoginHistory: [],

  userOperationHistoryIsLoaded: false,
  userOperationHistory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COUNTRIES[REQUEST]:
      return { ...state, countriesAreLoaded: false };
    case ActionTypes.GET_COUNTRIES[SUCCESS]:
      return { ...state, countries: action.data, countriesAreLoaded: true };
    case ActionTypes.GET_COUNTRIES[FAILURE]:
      return { ...state, error: action.error, countriesAreLoaded: false };

    case ActionTypes.GET_GEONAME_BY_GID[REQUEST]:
      return { ...state };
    case ActionTypes.GET_GEONAME_BY_GID[SUCCESS]:
      return { ...state, user: { ...state.user, ...action.data } };
    case ActionTypes.GET_GEONAME_BY_GID[FAILURE]:
      return { ...state, error: action.error };

    case ActionTypes.GET_USERS[REQUEST]:
      return { ...state, usersAreLoaded: false };
    case ActionTypes.GET_USERS[SUCCESS]:
      return { ...state, users: action.data, usersAreLoaded: true };
    case ActionTypes.GET_USERS[FAILURE]:
      return { ...state, error: action.error, usersAreLoaded: false };

    case ActionTypes.GET_USER_DATA[REQUEST]:
      return {
        ...state,
        userIsLoaded: false,
        userDocuments: emptyDocuments,
        redirect: false,
      };
    case ActionTypes.GET_USER_DATA[SUCCESS]:
      return { ...state, user: { ...state.user, ...action.data }, userIsLoaded: true, redirect: false };
    case ActionTypes.GET_USER_DATA[FAILURE]:
      return { ...state, error: action.error, userIsLoaded: false, redirect: action.error === 404 };

    case ActionTypes.GET_USER_INFO[REQUEST]:
      return { ...state, userIsFullyLoaded: false, userDocuments: emptyDocuments };
    case ActionTypes.GET_USER_INFO[SUCCESS]:
      return { ...state, userIsFullyLoaded: true };
    case ActionTypes.GET_USER_INFO[FAILURE]:
      return { ...state, userIsFullyLoaded: false };

    case ActionTypes.GET_USER_LOGIN_HISTORY[REQUEST]:
      return { ...state, userLoginHistoryIsLoaded: false };
    case ActionTypes.GET_USER_LOGIN_HISTORY[SUCCESS]:
      return { ...state, userLoginHistoryIsLoaded: true, userLoginHistory: action.data };
    case ActionTypes.GET_USER_LOGIN_HISTORY[FAILURE]:
      return { ...state, userLoginHistoryIsLoaded: false };

    case ActionTypes.GET_USER_OPERATION_HISTORY[REQUEST]:
      return { ...state, userOperationHistoryIsLoaded: false };
    case ActionTypes.GET_USER_OPERATION_HISTORY[SUCCESS]:
      return { ...state, userOperationHistoryIsLoaded: true, userOperationHistory: action.data };
    case ActionTypes.GET_USER_OPERATION_HISTORY[FAILURE]:
      return { ...state, userOperationHistoryIsLoaded: false };

    case ActionTypes.VERIFY_USER[REQUEST]:
      return { ...state, userVerifiedIsLoading: true };
    case ActionTypes.VERIFY_USER[SUCCESS]:
      return { ...state, userVerifiedIsLoading: false };
    case ActionTypes.VERIFY_USER[FAILURE]:
      return { ...state, userVerifiedIsLoading: false };

    case ActionTypes.REJECT_USER[REQUEST]:
      return { ...state, userRejectedIsLoading: true };
    case ActionTypes.REJECT_USER[SUCCESS]:
      return { ...state, userRejectedIsLoading: false };
    case ActionTypes.REJECT_USER[FAILURE]:
      return { ...state, userRejectedIsLoading: false };

    case ActionTypes.UNBLOCK_USER[REQUEST]:
      return { ...state, userUnblockIsLoading: true };
    case ActionTypes.UNBLOCK_USER[SUCCESS]:
      return { ...state, userUnblockIsLoading: false, user: { ...state.user, isActive: true } };
    case ActionTypes.UNBLOCK_USER[FAILURE]:
      return { ...state, userUnblockIsLoading: false };

    case ActionTypes.GET_USER_DOCUMENT_LIST[REQUEST]:
      return { ...state, userDocumentAreLoaded: false };
    case ActionTypes.GET_USER_DOCUMENT_LIST[SUCCESS]:
      return { ...state, userDocuments: { ...state.userDocuments, ...action.data }, userDocumentAreLoaded: true };
    case ActionTypes.GET_USER_DOCUMENT_LIST[FAILURE]:
      return { ...state, error: action.error, userDocumentAreLoaded: false };

    case ActionTypes.GET_USER_DOCUMENT_FILE[REQUEST]:
      return { ...state, userDocumentAreLoaded: false };
    case ActionTypes.GET_USER_DOCUMENT_FILE[SUCCESS]: {
      const { type, documentId, file } = action.data;

      return {
        ...state,
        userDocuments: {
          ...state.userDocuments,
          [type]: state.userDocuments[type].map(document =>
            document.id === documentId ? { ...document, file } : document
          ),
        },
        userDocumentAreLoaded: true,
      };
    }
    case ActionTypes.GET_USER_DOCUMENT_FILE[FAILURE]:
      return { ...state, error: action.error, userDocumentAreLoaded: false };

    case ActionTypes.VERIFY_USER_DOCUMENT[REQUEST]:
      return { ...state, documentVerifyIsLoading: { ...state.documentVerifyIsLoading, [action.doc]: true } };
    case ActionTypes.VERIFY_USER_DOCUMENT[SUCCESS]:
    case ActionTypes.VERIFY_USER_DOCUMENT[FAILURE]:
      return { ...state, documentVerifyIsLoading: { ...state.documentVerifyIsLoading, [action.doc]: false } };

    case ActionTypes.UPLOAD_USER_DOCUMENT[REQUEST]:
      return { ...state, documentsUploadLoading: true, withdrawalProof: null };
    case ActionTypes.UPLOAD_USER_DOCUMENT[SUCCESS]: {
      return {
        ...state,
        documentsUploadLoading: false,
        withdrawalProof: action.data,
      };
    }
    case ActionTypes.UPLOAD_USER_DOCUMENT[FAILURE]:
      return {
        ...state,
        documentsUploadLoading: true,
      };

    case ActionTypes.GET_WITHDRAWAL_PROOF[REQUEST]: {
      return { ...state, withdrawalProof: null };
    }
    case ActionTypes.GET_WITHDRAWAL_PROOF[SUCCESS]: {
      return {
        ...state,
        withdrawalProof: action.data,
      };
    }

    case ActionTypes.GET_USER_ACCOUNT[REQUEST]:
      return {
        ...state,
        accountIsLoaded: false,
        redirect: false,
      };
    case ActionTypes.GET_USER_ACCOUNT[SUCCESS]:
      return {
        ...state,
        accountIsLoaded: true,
        account: action.data,
        redirect: false,
      };
    case ActionTypes.GET_USER_ACCOUNT[FAILURE]:
      return {
        ...state,
        accountIsLoaded: false,
        redirect: action.error === 404,
        error: action.error,
      };

    case ActionTypes.GET_USER_ACCOUNTS[REQUEST]:
      return { ...state, accountsAreLoaded: false };
    case ActionTypes.GET_USER_ACCOUNTS[SUCCESS]:
      action.data.forEach(acc => {
        acc.loading = true;
      });
      return { ...state, accounts: action.data, accountsAreLoaded: true };
    case ActionTypes.GET_USER_ACCOUNTS[FAILURE]:
      return { ...state, error: action.error, accountsAreLoaded: false };

    case ActionTypes.GET_USER_ACCOUNTS_PREFETCH[SUCCESS]:
      return {
        ...state,
        accounts: action.data.map(account => ({ ...account, loading: false })),
        accountsAreLoaded: true,
      };

    case ActionTypes.BLOCK_USER_ACCOUNT[REQUEST]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: true,
        }),
      };
    case ActionTypes.BLOCK_USER_ACCOUNT[SUCCESS]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          isBlocked: true,
          actionIsLoading: false,
        }),
      };
    case ActionTypes.BLOCK_USER_ACCOUNT[FAILURE]:
      return {
        ...state,
        error: action.error,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: false,
        }),
      };

    case ActionTypes.UNBLOCK_USER_ACCOUNT[REQUEST]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: true,
        }),
      };
    case ActionTypes.UNBLOCK_USER_ACCOUNT[SUCCESS]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          isBlocked: false,
          actionIsLoading: false,
        }),
      };
    case ActionTypes.UNBLOCK_USER_ACCOUNT[FAILURE]:
      return {
        ...state,
        error: action.error,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: false,
        }),
      };

    case ActionTypes.DELETE_USER_ACCOUNT[REQUEST]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: true,
        }),
      };
    case ActionTypes.DELETE_USER_ACCOUNT[SUCCESS]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          isDeleted: true,
          actionIsLoading: false,
        }),
      };
    case ActionTypes.DELETE_USER_ACCOUNT[FAILURE]:
      return {
        ...state,
        error: action.error,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: false,
        }),
      };

    case ActionTypes.RESTORE_USER_ACCOUNT[REQUEST]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: true,
        }),
      };
    case ActionTypes.RESTORE_USER_ACCOUNT[SUCCESS]:
      return {
        ...state,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          isDeleted: false,
          actionIsLoading: false,
        }),
      };
    case ActionTypes.RESTORE_USER_ACCOUNT[FAILURE]:
      return {
        ...state,
        error: action.error,
        accounts: updateItemInArray(state.accounts, action.accountId, {
          actionIsLoading: false,
        }),
      };

    case ActionTypes.GET_USER_PAYMENTS[REQUEST]:
      return { ...state, loadingPayments: false };
    case ActionTypes.GET_USER_PAYMENTS[SUCCESS]:
      return {
        ...state,
        loadingPayments: true,
        payments: action.data,
      };
    case ActionTypes.GET_USER_PAYMENTS[FAILURE]:
      return { ...state, loadingPayments: false };

    case ActionTypes.GET_USER_BONUS[REQUEST]:
      return { ...state, bonusesAreLoaded: false };
    case ActionTypes.GET_USER_BONUS[SUCCESS]:
      return { ...state, bonuses: action.data, bonusesAreLoaded: true };
    case ActionTypes.GET_USER_BONUS[FAILURE]:
      return { ...state, error: action.error, bonusesAreLoaded: false };

    case ActionTypes.GET_USER_NOTES[REQUEST]:
      return { ...state, notesIsLoaded: false };
    case ActionTypes.GET_USER_NOTES[SUCCESS]:
      return { ...state, notes: action.data, notesIsLoaded: true };
    case ActionTypes.GET_USER_NOTES[FAILURE]:
      return { ...state, error: action.error, notesIsLoaded: false };

    case ActionTypes.GET_ALL_TAGS[REQUEST]:
      return { ...state, allTagsIsLoaded: false };
    case ActionTypes.GET_ALL_TAGS[SUCCESS]:
      return { ...state, allTags: action.data, allTagsIsLoaded: true };
    case ActionTypes.GET_ALL_TAGS[FAILURE]:
      return { ...state, error: action.error, allTagsIsLoaded: false };

    case ActionTypes.GET_USER_TAGS[REQUEST]:
      return { ...state, userTagsIsLoaded: false };
    case ActionTypes.GET_USER_TAGS[SUCCESS]:
      return { ...state, userTags: action.data, userTagsIsLoaded: true };
    case ActionTypes.GET_USER_TAGS[FAILURE]:
      return { ...state, error: action.error, userTagsIsLoaded: false };

    case ActionTypes.GET_USER_PARTNER_ACCOUNTS[REQUEST]:
      return { ...state, userPartnerAccountsIsLoaded: false };
    case ActionTypes.GET_USER_PARTNER_ACCOUNTS[SUCCESS]:
      return { ...state, userPartnerAccounts: action.data, userPartnerAccountsIsLoaded: true };
    case ActionTypes.GET_USER_PARTNER_ACCOUNTS[FAILURE]:
      return { ...state, error: action.error, userPartnerAccountsIsLoaded: false };

    case ActionTypes.GET_PARTNERSHIP_CONDITIONS_LIST[REQUEST]:
      return { ...state };
    case ActionTypes.GET_PARTNERSHIP_CONDITIONS_LIST[SUCCESS]:
      return { ...state, partnershipConditionsList: action.data };
    case ActionTypes.GET_PARTNERSHIP_CONDITIONS_LIST[FAILURE]:
      return { ...state, error: action.error };

    case ActionTypes.GET_AVAILABLE_PARTNER_CODES[REQUEST]:
      return { ...state, availablePartnerCodesAreLoaded: false };
    case ActionTypes.GET_AVAILABLE_PARTNER_CODES[SUCCESS]:
      return { ...state, availablePartnerCodes: action.data, availablePartnerCodesAreLoaded: true };
    case ActionTypes.GET_AVAILABLE_PARTNER_CODES[FAILURE]:
      return { ...state, error: action.error, availablePartnerCodesAreLoaded: false };

    case ActionTypes.GET_USER_ISSUES[REQUEST]:
      return { ...state, userIssuesAreLoaded: false };
    case ActionTypes.GET_USER_ISSUES[SUCCESS]:
      return { ...state, userIssues: action.data, userIssuesAreLoaded: true };
    case ActionTypes.GET_USER_ISSUES[FAILURE]:
      return { ...state, error: action.error, userIssuesAreLoaded: false };

    case ActionTypes.GET_USER_CARDS[REQUEST]:
      return { ...state, userCardsAreLoaded: false };
    case ActionTypes.GET_USER_CARDS[SUCCESS]:
      return {
        ...state,
        userCards: action.data.map((card, id) => ({ ...state.userCards[id], ...card })),
        userCardsAreLoaded: true,
      };
    case ActionTypes.GET_USER_CARDS[FAILURE]:
      return { ...state, error: action.error, userCardsAreLoaded: false };

    case ActionTypes.GET_CARD_PHOTO[REQUEST]:
      return { ...state, cardPhotoIsLoaded: false };
    case ActionTypes.GET_CARD_PHOTO[SUCCESS]:
      return {
        ...state,
        userCards: state.userCards.map(card => {
          if (card.id === action.data.cardId)
            return { ...card, backPhoto: action.data.cardPhoto, [`${action.data.side}Photo`]: action.data.cardPhoto };
          return card;
        }),
        cardPhotoIsLoaded: true,
      };
    case ActionTypes.GET_CARD_PHOTO[FAILURE]:
      return { ...state, error: action.error, cardPhotoIsLoaded: false };

    case ActionTypes.GET_USER_AVAILABLE_DEPOSITS[REQUEST]:
      return { ...state, userAvailableDepositsAreLoaded: false };
    case ActionTypes.GET_USER_AVAILABLE_DEPOSITS[SUCCESS]:
      return { ...state, userAvailableDeposits: action.data, userAvailableDepositsAreLoaded: true };
    case ActionTypes.GET_USER_AVAILABLE_DEPOSITS[FAILURE]:
      return { ...state, error: action.error, userAvailableDepositsAreLoaded: false };

    case ActionTypes.CANCEL_USER_BONUS[REQUEST]:
      return { ...state, cancelUserBonusError: '' };
    case ActionTypes.CANCEL_USER_BONUS[SUCCESS]:
      return { ...state, cancelUserBonusError: '' };
    case ActionTypes.CANCEL_USER_BONUS[FAILURE]:
      return { ...state, cancelUserBonusError: action.error };

    case ActionTypes.GET_USER_UTM_HISTORY[REQUEST]:
      return { ...state, userUtmAreLoaded: false };
    case ActionTypes.GET_USER_UTM_HISTORY[SUCCESS]:
      return { ...state, userUtm: action.data, userUtmAreLoaded: true };
    case ActionTypes.GET_USER_UTM_HISTORY[FAILURE]:
      return { ...state, error: action.error, userUtmAreLoaded: false };

    case ActionTypes.GET_USER_INTERNAL_TRANSFERS[REQUEST]:
      return { ...state, internalTransfersAreLoaded: false };
    case ActionTypes.GET_USER_INTERNAL_TRANSFERS[SUCCESS]:
      return { ...state, internalTransfersAreLoaded: true, internalTransfers: action.data };
    case ActionTypes.GET_USER_INTERNAL_TRANSFERS[FAILURE]:
      return { ...state, internalTransfersAreLoaded: false };

    case ActionTypes.SHOW_WITHDRAW_APPOVE_MODAL:
      return { ...state, showWithdrawApproveModal: true };

    case ActionTypes.GET_BACKOFFICE_MANAGER_LIST[REQUEST]:
      return { ...state, managersListAreLoaded: false };
    case ActionTypes.GET_BACKOFFICE_MANAGER_LIST[SUCCESS]:
      return { ...state, managersListAreLoaded: true, managersList: action.data };
    case ActionTypes.GET_BACKOFFICE_MANAGER_LIST[FAILURE]:
      return { ...state, managersListAreLoaded: false };

    case ActionTypes.GET_MANAGER_CLIENT[REQUEST]:
      return { ...state, managerClientIsLoading: true };
    case ActionTypes.GET_MANAGER_CLIENT[SUCCESS]:
      return { ...state, managerClient: action.data, managerClientIsLoading: false };
    case ActionTypes.GET_MANAGER_CLIENT[FAILURE]:
      return { ...state, managerClient: null, managerClientIsLoading: false, error: action.error };

    case ActionTypes.GET_FREE_CLIENTS_COUNT[SUCCESS]:
      return { ...state, freeClientsCount: action.data };
    case ActionTypes.GET_FREE_CLIENTS_COUNT[FAILURE]:
      return { ...state, error: action.error };

    case ActionTypes.createDocument[REQUEST]:
      return {
        ...state,
        notesDocumentsAreLoaded: false,
      };
    case ActionTypes.createDocument[SUCCESS]:
      return { ...state, notesDocuments: action.payload, notesDocumentsAreLoaded: true };
    case ActionTypes.createDocument[FAILURE]:
      return { ...state, error: action.error, notesDocumentsAreLoaded: false };

    case ActionTypes.createUserNote[SUCCESS]:
      return { ...state, notesDocuments: {}, notesDocumentsAreLoaded: false };

    case ActionTypes.editUserNote[REQUEST]:
      return {
        ...state,
        notesDocumentsAreLoaded: false,
      };
    case ActionTypes.EDIT_USER_CAN_ACTIVATE_BONUSES[SUCCESS]: {
      return { ...state, user: { ...state.user, disallowBonuses: action.data.disallowBonuses } };
    }
    case ActionTypes.editUserNote[SUCCESS]:
      return { ...state, notesDocuments: undefined, notesDocumentsAreLoaded: true };
    case ActionTypes.editUserNote[FAILURE]:
      return { ...state, error: action.error, notesDocumentsAreLoaded: false };

    case ActionTypes.GET_USER_KYC[REQUEST]:
      return { ...state, kycValuesAreLoaded: false };
    case ActionTypes.GET_USER_KYC[SUCCESS]: {
      const sections = ['forex', 'derivative', 'securities', 'investmentFund'];
      sections.forEach(section => {
        if (!!action.data.experience[section] && Object.keys(action.data.experience[section]).length > 0) {
          action.data.experience[section].checkbox = true;
        }
      });
      return { ...state, kycValuesAreLoaded: true, kycValues: action.data };
    }
    case ActionTypes.GET_USER_KYC[FAILURE]:
      return { ...state, kycValuesAreLoaded: false };

    case ActionTypes.GET_TREDERO_CUSTOM_USER_KYC[REQUEST]:
      return { ...state, kycValuesAreLoaded: false };
    case ActionTypes.GET_TREDERO_CUSTOM_USER_KYC[SUCCESS]: {
      const sections = ['forex', 'derivative', 'securities', 'investmentFund'];
      sections.forEach(section => {
        if (!!action.data.experience[section] && Object.keys(action.data.experience[section]).length > 0) {
          action.data.experience[section].checkbox = true;
        }
      });
      return { ...state, kycValuesAreLoaded: true, kycValues: action.data };
    }
    case ActionTypes.GET_TREDERO_CUSTOM_USER_KYC[FAILURE]:
      return { ...state, kycValuesAreLoaded: false };

    case ActionTypes.GET_SERVER_LIST[REQUEST]:
      return { ...state, serverListIsLoaded: false };
    case ActionTypes.GET_SERVER_LIST[SUCCESS]:
      return { ...state, serverList: action.data, serverListIsLoaded: true };
    case ActionTypes.GET_SERVER_LIST[FAILURE]:
      return { ...state, serverListIsLoaded: false };

    case ActionTypes.GET_CALL_PROVIDERS_LIST[REQUEST]:
      return { ...state, callProvidersListIsLoaded: false };
    case ActionTypes.GET_CALL_PROVIDERS_LIST[SUCCESS]:
      return { ...state, callProvidersList: action.data, callProvidersListIsLoaded: true };
    case ActionTypes.GET_CALL_PROVIDERS_LIST[FAILURE]:
      return { ...state, callProvidersListIsLoaded: false };

    case ActionTypes.MAKE_A_CALL[REQUEST]:
      return { ...state };
    case ActionTypes.MAKE_A_CALL[SUCCESS]: {
      return { ...state };
    }
    case ActionTypes.MAKE_A_CALL[FAILURE]:
      return { ...state, error: action.error };

    case ActionTypes.GET_CURRENCIES[REQUEST]:
      return { ...state, currenciesAreLoaded: false };
    case ActionTypes.GET_CURRENCIES[SUCCESS]:
      return { ...state, currencies: action.data, currenciesAreLoaded: true };
    case ActionTypes.GET_CURRENCIES[FAILURE]:
      return { ...state, error: action.error, currenciesAreLoaded: false };

    case ActionTypes.GET_LPA_INFO[REQUEST]:
      return { ...state, lpaInfoIsLoaded: false };
    case ActionTypes.GET_LPA_INFO[SUCCESS]:
      return { ...state, lpaInfo: action.data, lpaInfoIsLoaded: true };
    case ActionTypes.GET_LPA_INFO[FAILURE]:
      return { ...state, error: action.error, lpaInfoIsLoaded: false };

    case ActionTypes.GET_LPAS[REQUEST]:
      return { ...state, lpasAreLoaded: false };
    case ActionTypes.GET_LPAS[SUCCESS]:
      return { ...state, lpas: action.data, lpasAreLoaded: true };
    case ActionTypes.GET_LPAS[FAILURE]:
      return { ...state, error: action.error, lpasAreLoaded: false };

    case ActionTypes.SET_CLIENT_TYPE:
      return { ...state, user: { ...state.user, chosenClientType: action.data } };

    case ActionTypes.GET_BO_USER_IMPORTS[REQUEST]:
      return { ...state, userImportDataIsLoaded: false, isImportRedirect: false };
    case ActionTypes.GET_BO_USER_IMPORTS[SUCCESS]:
      return { ...state, userImportData: action.data, userImportDataIsLoaded: true };
    case ActionTypes.GET_BO_USER_IMPORTS[FAILURE]:
      return { ...state, error: action.error, userImportDataIsLoaded: false };

    case ActionTypes.SET_IMPORT_EXTENSION:
      return {
        ...state,
        importExtensions: { ...state.importExtensions, ...{ [action.id]: action.value } },
      };

    default:
      return state;
  }
};

export default reducer;
