import { put, call, fork, take } from 'redux-saga/effects';
import FileDownload from 'js-file-download';
import { SubmissionError } from 'redux-form';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { getCRMFiltersListSaga } from 'crm/_redux/commonSagas/sagas';
import * as api from '../api';
import * as ActionTypes from './actions';

export function* getContactsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getContacts, search);

    if (status < 300) {
      yield put(ActionTypes.getContacts.success({ ...data }));
    } else {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.getContacts.failure());
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getContacts.failure());
  }
}
export function* getContactsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_CONTACTS[REQUEST]);
    yield call(getContactsSaga, search);
  }
}

export function* getContactsFavoritesSaga() {
  try {
    const { status, data } = yield call(api.getContactsFavorites);
    if (status < 300) {
      yield put(ActionTypes.getContactsFavorites.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getContactsFavorites.failure());
    throw e;
  }
}
export function* getContactFavoritesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CONTACTS_FAVORITES[REQUEST]);
    yield call(getContactsFavoritesSaga);
  }
}

export function* getCrmClientSaga() {
  try {
    const { status, data } = yield call(api.getCrmClient);
    if (status < 300) {
      yield put(ActionTypes.getCrmClient.success(data));
      yield call(getContactsSaga, { search: { page: 1, page_size: 10 } });
    } else if (status === 404) {
      yield put(acceptError(data.error, false));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCrmClient.failure());
    throw e;
  }
}
export function* getCrmClientWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_CRM_CLIENT[REQUEST]);

    yield call(getCrmClientSaga);
  }
}

export function* setContactFavoriteSaga(userId) {
  try {
    const { status, data } = yield call(api.setContactFavorite, userId);
    if (status < 300) {
      yield put(ActionTypes.setContactFavorite.success(data));
      yield call(getContactsFavoritesSaga);
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.setContactFavorite.failure());
    throw e;
  }
}
export function* setContactFavoriteWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.SET_CONTACT_FAVORITE[REQUEST]);
    yield call(setContactFavoriteSaga, userId);
  }
}

export function* deleteContactFavoriteSaga(userId) {
  try {
    const { status, data } = yield call(api.deleteContactFavorite, userId);
    if (status < 300) {
      yield put(ActionTypes.deleteContactFavorite.success(data));
      yield call(getContactsFavoritesSaga);
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.deleteContactFavorite.failure());
    throw e;
  }
}
export function* deleteContactFavoriteWatcherSaga() {
  while (true) {
    const { userId } = yield take(ActionTypes.DELETE_CONTACT_FAVORITE[REQUEST]);

    yield call(deleteContactFavoriteSaga, userId);
  }
}

export function* createContactSaga(values) {
  try {
    const { data, status } = yield call(api.createContact, values);

    if (status < 300) {
      yield put(ActionTypes.createContact.success(data));
      yield call(getContactsSaga, { search: { page_size: 10 } });
    } else {
      const formError = new SubmissionError(data);
      yield put(ActionTypes.createContact.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createContact.failure(e));
  }
}
export function* createContactWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createContact.REQUEST);
    yield call(createContactSaga, payload);
  }
}

export function* uploadContactsSaga(file) {
  try {
    const { data, status } = yield call(api.uploadContacts, file);
    if (status < 300) {
      yield put(ActionTypes.uploadContacts.success(data));
      yield call(getContactsSaga, { search: { page_size: 10 } });
    }
    yield put(ActionTypes.uploadContacts.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.uploadContacts.failure(e));
  }
}

function* uploadContactsWatcherSaga() {
  while (true) {
    const { file } = yield take(ActionTypes.UPLOAD_CONTACTS[REQUEST]);
    yield call(uploadContactsSaga, file);
  }
}

export function* editContactsManagerSaga({ search, contactsId, manager, taskComment, partnerAccount }) {
  try {
    const { status, data } = yield call(api.editContactsManager, contactsId, manager, taskComment, partnerAccount);

    if (status < 300) {
      yield put(ActionTypes.editContactsManager.success());
      yield call(getContactsSaga, { search });
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editContactsManager.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editContactsManager.failure());
  }
}

export function* editContactsManagerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editContactsManager.REQUEST);
    yield call(editContactsManagerSaga, payload);
  }
}

export function* editContactsIBManagerSaga(payload) {
  try {
    const { search, contactsId, ibManager, taskComment, partnerAccount } = payload;
    const { status, data } = yield call(api.editContactsIBManager, ibManager, contactsId, taskComment, partnerAccount);
    if (status < 300) {
      yield put(ActionTypes.editContactsIBManager.success());
      yield call(getContactsSaga, { search });
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editContactsIBManager.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editContactsIBManager.failure());
  }
}

export function* editContactsIBManagerWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editContactsIBManager.REQUEST);
    yield call(editContactsIBManagerSaga, payload);
  }
}

export function* editContactsPartnerCodeSaga(payload) {
  try {
    const { search, contactsId, partnerAccount } = payload;
    const { status, data } = yield call(api.editContactsPartnerCode, contactsId, partnerAccount);
    if (status < 300) {
      yield put(ActionTypes.editContactsPartnerCode.success());
      yield call(getContactsSaga, { search });
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editContactsPartnerCode.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editContactsPartnerCode.failure());
  }
}

export function* editContactsPartnerCodeWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editContactsPartnerCode.REQUEST);
    yield call(editContactsPartnerCodeSaga, payload);
  }
}

export function* createContactsExportsSaga(payload) {
  try {
    const { contactsId } = payload;
    const { status, data } = yield call(api.createContactsExports, contactsId);
    if (status < 300) {
      yield put(ActionTypes.createContactsExports.success(data));
    } else if (status === 409) {
      yield put(acceptError(data.detail, true));
      yield put(ActionTypes.createContactsExports.failure());
    } else {
      yield put(ActionTypes.createContactsExports.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createContactsExports.failure(e));
  }
}

export function* createContactsExportsWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createContactsExports.REQUEST);
    yield call(createContactsExportsSaga, payload);
  }
}

export function* getContactsExportsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getContactsExports, search);
    if (status < 300) {
      yield put(ActionTypes.getContactsExports.success(data));
    } else {
      acceptError(data.detail, true);
      yield put(ActionTypes.getContactsExports.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getContactsExports.failure(e));
  }
}
export function* getContactsExportsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CONTACTS_EXPORTS[REQUEST]);
    yield call(getContactsExportsSaga, data);
  }
}

export function* downloadContactsExportSaga({ id, name }) {
  try {
    const { status, data } = yield call(api.downloadContactsExport, id, name);
    if (status < 300) {
      yield put(ActionTypes.downloadContactsExport.success(data));
      FileDownload(data, name);
    } else {
      yield put(ActionTypes.downloadContactsExport.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.downloadContactsExport.failure(e));
  }
}
export function* downloadContactsExportWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DOWNLOAD_CONTACTS_EXPORT[REQUEST]);
    yield call(downloadContactsExportSaga, data);
  }
}

export function* editCrmCustomFilterSaga({ editData, id }) {
  try {
    const { status, data } = yield call(api.editCrmCustomFilter, id, editData);

    if (status < 300) {
      yield put(ActionTypes.editCrmCustomFilter.success());
      yield call(getCRMFiltersListSaga);
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editCrmCustomFilter.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.editCrmCustomFilter.failure());
  }
}

export function* editCrmCustomFilterWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editCrmCustomFilter.REQUEST);
    yield call(editCrmCustomFilterSaga, payload);
  }
}
export function* deleteCrmCustomFilterSaga(id) {
  try {
    const { status, data } = yield call(api.deleteCrmCustomFilter, id);
    if (status < 300) {
      yield put(ActionTypes.deleteCrmCustomFilter.success(data));
      yield call(getCRMFiltersListSaga);
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.deleteCrmCustomFilter.failure());
    throw e;
  }
}
export function* deleteCrmCustomFilterWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.deleteCrmCustomFilter.REQUEST);

    yield call(deleteCrmCustomFilterSaga, payload);
  }
}

export function* getCrmFilterSaga(id) {
  try {
    const { status, data } = yield call(api.getCrmFilter, id);
    if (status < 300) {
      yield put(ActionTypes.getCrmFilter.success(data));
    } else {
      yield put(acceptError(data.detail, true));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getCrmFilter.failure());
    throw e;
  }
}
export function* getCrmFilterWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_CRM_FILTER[REQUEST]);
    yield call(getCrmFilterSaga, id);
  }
}

export default [
  fork(getContactsWatcherSaga),
  fork(getContactFavoritesWatcherSaga),
  fork(getCrmClientWatcherSaga),
  fork(setContactFavoriteWatcherSaga),
  fork(deleteContactFavoriteWatcherSaga),
  fork(createContactWatcherSaga),
  fork(uploadContactsWatcherSaga),
  fork(editContactsManagerWatcherSaga),
  fork(editContactsIBManagerWatcherSaga),
  fork(editContactsPartnerCodeWatcherSaga),
  fork(createContactsExportsWatcherSaga),
  fork(getContactsExportsWatcherSaga),
  fork(downloadContactsExportWatcherSaga),
  fork(editCrmCustomFilterWatcherSaga),
  fork(deleteCrmCustomFilterWatcherSaga),
  fork(getCrmFilterWatcherSaga),
];
