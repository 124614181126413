import React from 'react';
import { colorType, withTheme } from 'common/styling/theme';

export const SupportIconActive = ({ gradientStartColor }) => (
  <div>
    <svg viewBox="0 0 21 18" fill={gradientStartColor.hex} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.479 17.6c.006-.07.01-.141.01-.213v-6.202c0-.367-.04-.724-.116-1.067h1.01c1.338 0 2.471 1.17 2.47 2.663v3.381c0 .818-.617 1.438-1.321 1.438h-2.053zM4.252 10.118c-.076.343-.116.7-.116 1.067v6.202c0 .072.003.143.01.213H2.093c-.705 0-1.323-.62-1.323-1.438v-3.381c0-1.493 1.133-2.663 2.47-2.663h1.012zM8.555 8.522h3.515c1.338 0 2.47 1.17 2.47 2.663v6.202c0 .143-.102.213-.174.213H6.258c-.072 0-.174-.07-.174-.213v-6.202c0-1.493 1.133-2.663 2.47-2.663zM7.26 3.684C7.26 1.849 8.654.4 10.312.4c1.659 0 3.052 1.449 3.052 3.284 0 1.24-.64 2.312-1.57 2.87a2.865 2.865 0 01-2.963 0c-.93-.558-1.57-1.63-1.57-2.87zM6.171 7.304h0c.166-.332.261-.711.261-1.115 0-1.323-1.003-2.355-2.181-2.355-1.179 0-2.182 1.031-2.182 2.355 0 1.323 1.003 2.355 2.182 2.355.301 0 .588-.067.85-.187l1.07-1.053zm0 0a2.27 2.27 0 01-1.07 1.053l1.07-1.053zM14.453 7.304h0a2.484 2.484 0 01-.26-1.115c0-1.324 1.002-2.355 2.18-2.355 1.18 0 2.182 1.031 2.182 2.355 0 1.323-1.003 2.355-2.181 2.355-.302 0-.589-.067-.85-.187a2.27 2.27 0 01-1.07-1.053z"
        fill={gradientStartColor.hex}
        stroke={gradientStartColor.hex}
      />
    </svg>
  </div>
);

SupportIconActive.propTypes = {
  gradientStartColor: colorType,
};

SupportIconActive.defaultProps = {
  gradientStartColor: {},
};

const ThemedSupportIconActive = withTheme(({ colors: { gradientStart } }) => ({
  gradientStartColor: gradientStart,
}))(SupportIconActive);

export default ThemedSupportIconActive;
