import React from 'react';

const DocumentIcon = () => (
  <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6462 0H0.0969238V12H8.14332V2.9172L5.6462 0ZM5.74652 0.6828L7.55885 2.8H5.74652V0.6828ZM0.439324 11.6V0.4H5.40412V3.2H7.80092V11.6H0.439324Z"
      fill="#0AA1E2"
      stroke="#0AA1E2"
      strokeWidth="0.1"
    />
    <path
      d="M6.26001 4.2002H3.52081C3.42631 4.2002 3.34961 4.2896 3.34961 4.4002C3.34961 4.5108 3.42631 4.6002 3.52081 4.6002H6.26001C6.35451 4.6002 6.43121 4.5108 6.43121 4.4002C6.43121 4.2896 6.35451 4.2002 6.26001 4.2002Z"
      fill="#0AA1E2"
      stroke="#0AA1E2"
      strokeWidth="0.1"
    />
    <path
      d="M2.90034 3.64399L2.16401 4.33219L1.92998 4.05879C1.86304 3.98059 1.75484 3.98059 1.6879 4.05879C1.62096 4.13699 1.62096 4.26339 1.6879 4.34159L2.0303 4.74159C2.06351 4.78039 2.10734 4.80019 2.15134 4.80019C2.189 4.80019 2.22684 4.78579 2.25817 4.75639L3.11417 3.95639C3.18812 3.88719 3.19994 3.76139 3.14087 3.67519C3.08198 3.58879 2.97412 3.57499 2.90034 3.64399Z"
      fill="#0AA1E2"
      stroke="#0AA1E2"
      strokeWidth="0.1"
    />
    <path
      d="M6.26001 6.3999H3.52081C3.42631 6.3999 3.34961 6.4893 3.34961 6.5999C3.34961 6.7105 3.42631 6.7999 3.52081 6.7999H6.26001C6.35451 6.7999 6.43121 6.7105 6.43121 6.5999C6.43121 6.4893 6.35451 6.3999 6.26001 6.3999Z"
      fill="#0AA1E2"
      stroke="#0AA1E2"
      strokeWidth="0.1"
    />
    <path
      d="M2.90034 5.8437L2.16401 6.5319L1.92998 6.2585C1.86304 6.1803 1.75484 6.1803 1.6879 6.2585C1.62096 6.3367 1.62096 6.4631 1.6879 6.5413L2.0303 6.9413C2.06351 6.9801 2.10734 6.9999 2.15134 6.9999C2.189 6.9999 2.22684 6.9855 2.25817 6.9561L3.11417 6.1561C3.18812 6.0869 3.19994 5.9611 3.14087 5.8749C3.08198 5.7885 2.97412 5.7747 2.90034 5.8437Z"
      fill="#0AA1E2"
      stroke="#0AA1E2"
      strokeWidth="0.1"
    />
    <path
      d="M6.26001 8.6001H3.52081C3.42631 8.6001 3.34961 8.6895 3.34961 8.8001C3.34961 8.9107 3.42631 9.0001 3.52081 9.0001H6.26001C6.35451 9.0001 6.43121 8.9107 6.43121 8.8001C6.43121 8.6895 6.35451 8.6001 6.26001 8.6001Z"
      fill="#0AA1E2"
      stroke="#0AA1E2"
      strokeWidth="0.1"
    />
    <path
      d="M2.90034 8.04389L2.16401 8.73209L1.92998 8.45869C1.86304 8.38049 1.75484 8.38049 1.6879 8.45869C1.62096 8.53689 1.62096 8.66329 1.6879 8.74149L2.0303 9.14149C2.06351 9.18029 2.10734 9.20009 2.15134 9.20009C2.189 9.20009 2.22684 9.18569 2.25817 9.15629L3.11417 8.35629C3.18812 8.28709 3.19994 8.16129 3.14087 8.07509C3.08198 7.98869 2.97412 7.97489 2.90034 8.04389Z"
      fill="#0AA1E2"
      stroke="#0AA1E2"
      strokeWidth="0.1"
    />
  </svg>
);

export default DocumentIcon;
