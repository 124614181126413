/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedDate as FD } from 'react-intl';

import { InfoBlock } from '../_components';

const ClientInfoBlock = ({ setCrmModalType }) => {
  // TODO: registrationPage in request
  const { language, backofficeName, lastSeen, registrationPage, currentStatus, firstDepositDate, dateJoined, address } =
    useSelector(state => state.crm.users.userData);
  const items = useMemo(
    () => [
      {
        titleId: 'crmLastActivity',
        cell: lastSeen ? <FD month="2-digit" day="2-digit" year="numeric" value={lastSeen} /> : '----',
      },
      ...(backofficeName ? [{ titleId: 'crmBackofficeName', cell: backofficeName }] : []),
      {
        titleId: 'crmDateOfRegistration',
        cell: dateJoined ? <FD month="2-digit" day="2-digit" year="numeric" value={dateJoined} /> : '----',
      },
      {
        titleId: 'crmFirstDepositDay',
        cell: firstDepositDate ? <FD month="2-digit" day="2-digit" year="numeric" value={firstDepositDate} /> : '----',
      },
      // language
      { titleId: 'crmLanguage', cell: language },
      { titleId: 'crmKYC', cell: currentStatus?.title },
      { titleId: 'justCountry', cell: address?.country?.label },
      { titleId: 'justState', cell: address?.state?.label },
      { titleId: 'justCity', cell: address?.city },
      { titleId: 'justAddress', cell: address?.address },
      { titleId: 'crmRegistrationPage', cell: registrationPage },
    ],
    [language, lastSeen, currentStatus, firstDepositDate, dateJoined, address]
  );
  return (
    <>
      <InfoBlock title="justContacts" items={items} setCrmModalType={setCrmModalType} />
    </>
  );
};

export default ClientInfoBlock;
