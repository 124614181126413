import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';

import { FirstStep, SecondStep, ThirdStep } from './_components';

import { staticStyles } from './style';

const FilterCreate = ({
  filtersOptionsSelector,
  filtersOptionsPendingSelector,
  getFiltersOptions,
  onRequestClose,
  createCustomFilterSubmitFunc,
}) => {
  const filtersOptions = useSelector(filtersOptionsSelector);
  const filtersOptionsPending = useSelector(filtersOptionsPendingSelector);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dataFilterValues, setDataFilterValues] = useState({ title: '', query: {} });
  const [step, setStep] = useState(1);
  const selectedFilterTypes = useMemo(() => {
    if (step === 2) {
      const result = {};
      Object.keys(filtersOptions).forEach(filterKey => {
        const selectedFilter = selectedOptions.find(({ value }) => value === filterKey);
        if (selectedFilter) {
          result[filterKey] = filtersOptions[filterKey];
        }
      });
      return result;
    }
  }, [step]);

  const selectFiltersOptions = useMemo(
    () => Object.keys(filtersOptions).map(item => ({ value: item, label: <FM id={item}>{txt => txt}</FM> })),
    [filtersOptions]
  );

  useEffect(() => {
    getFiltersOptions();
  }, []);

  const onNewFilterSelect = newValues => {
    setSelectedOptions(newValues);
  };

  return (
    <div className="FilterCreate__Wrapper">
      {step === 1 && (
        <FirstStep
          options={selectFiltersOptions}
          loading={filtersOptionsPending}
          selectedOptions={selectedOptions}
          onChange={onNewFilterSelect}
          setNextStep={() => {
            setStep(prevValue => prevValue + 1);
          }}
        />
      )}
      {step === 2 && (
        <SecondStep
          selectedFilterTypes={selectedFilterTypes}
          setNextStep={newDataFilterValues => {
            setDataFilterValues(prevState => ({ ...prevState, query: newDataFilterValues }));
            setStep(prevValue => prevValue + 1);
          }}
        />
      )}

      {step === 3 && (
        <ThirdStep
          onRequestClose={onRequestClose}
          dataFilterValues={dataFilterValues}
          createCustomFilterSubmitFunc={createCustomFilterSubmitFunc}
        />
      )}

      <style jsx>{staticStyles}</style>
    </div>
  );
};

FilterCreate.propTypes = {
  filtersOptionsSelector: PropTypes.func.isRequired,
  filtersOptionsPendingSelector: PropTypes.func.isRequired,
  getFiltersOptions: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  createCustomFilterSubmitFunc: PropTypes.func.isRequired,
};

export default FilterCreate;
