import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { PageLayout, NoItemsPanel, PageContentPreloader, SearchPanel } from 'common/components';

import noPartnersImage from 'common/images/noPartners.svg';

import { getPartners } from '../../../_redux';
import { PartnersTable } from '../../_components';

class PartnersPage extends React.Component {
  query = queryString.parse(this.props.location.search);

  constructor(props) {
    super(props);
    this.query.page_size = this.query.page_size ? this.query.page_size : 10;
    props.getPartners({ search: this.query });
  }

  handleGetPartners = () => {
    this.query.page_size = this.query.page_size ? this.query.page_size : 10;
    this.props.getPartners({ search: this.query });
  };

  render() {
    const { partners, getPartners, partnersAreLoaded, history, location } = this.props;

    const query = queryString.parse(location.search);
    const handleRowClick = (_rowId, rowInfo) => {
      history.push(`/backoffice/users/${rowInfo.original.id}`);
    };
    const inputs = [
      {
        title: 'justMasterIb',
        accessor: 'master_ib',
      },
    ];

    return (
      <PageLayout
        captionSlug="boPartners"
        showReload
        onReloadClick={this.handleGetPartners}
        reloading={!partnersAreLoaded}>
        <PageContentPreloader ready={partnersAreLoaded} firstLaunchOnly>
          {!partners.count && !Object.keys(query).length ? (
            ''
          ) : (
            <SearchPanel
              location={location}
              history={history}
              getList={getPartners}
              params={{ search: query }}
              inputs={inputs}
            />
          )}
          {partners.count ? (
            <PartnersTable
              data={partners}
              loading={!partnersAreLoaded}
              location={location}
              history={history}
              getList={getPartners}
              params={{ search: query }}
              onRowClick={handleRowClick}
            />
          ) : (
            <NoItemsPanel
              icon={noPartnersImage}
              captionSlug={Object.keys(query).length ? 'noPartnersMatchingTheParameters' : 'noPartnersYet'}
            />
          )}
        </PageContentPreloader>
      </PageLayout>
    );
  }
}

PartnersPage.propTypes = {
  location: PropTypes.object.isRequired,
  getPartners: PropTypes.func.isRequired,
  partners: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  partnersAreLoaded: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    partners: state.backoffice.common.partners,
    partnersAreLoaded: state.backoffice.common.partnersAreLoaded,
  }),
  { getPartners: search => getPartners.request(search) }
)(PartnersPage);
export { PartnersPage };
