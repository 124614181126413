import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage as FM } from 'react-intl';
import { currentDate } from 'common/dashboards/helpers';
import { getLastFunnels, getMonthsFunnels, getDailyFunnels } from 'common/dashboards/_redux';
import MonthPicker from 'common/components/MonthPicker';
import { getCrmClientsAnalytics } from '../../_redux';
import { ClientsTable, RightChart, UsersChart } from './_components';

import { staticStyles } from './style';

const ClientsPage = ({
  dailyFunnels,
  getCrmClientsAnalytics,
  getLastFunnels,
  getMonthsFunnels,
  getDailyFunnels,
  perms,
}) => {
  const [date, setDate] = useState(currentDate);
  const currentMonth = moment(date).format('YYYY-MM');
  const lastMonth = moment(date).subtract(1, 'months').format('YYYY-MM');
  const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');
  const currentYear = Number(moment().format('YYYY'));
  const currentMonthNum = Number(moment().format('M'));
  const [dayDates, allDayData] = useMemo(() => {
    let dayDates = [];
    let allDayData = [];

    Object.entries(dailyFunnels).forEach(([date, dayData]) => {
      dayDates = [...dayDates, date.replace(/^\d+-/, '')];
      allDayData = [...allDayData, dayData];
    });
    return [dayDates, allDayData];
  }, [dailyFunnels]);

  const hasBrokerAccess = perms && (perms.viewDashboards || perms.managerViewDashboard);

  const [monthValue, setMonthValue] = useState({
    year: currentYear,
    month: currentMonthNum,
  });
  const handleMonthChange = monthValue => {
    setMonthValue(monthValue);
    setDate(moment(`${monthValue.year}-${monthValue.month}`).format('YYYY-MM'));
  };
  useEffect(() => {
    getCrmClientsAnalytics({ page: 1, page_size: 10 });
    getLastFunnels({ hasBrokerAccess, currentMonth, lastMonth });
    getMonthsFunnels({ hasBrokerAccess, firstMonth: currentMonth, secondMonth: lastMonth });
    getDailyFunnels({ hasBrokerAccess, startOfMonth, endOfMonth });
  }, [date]);
  return (
    <div className="ClientsPageAnalytics">
      <FM id="crmDashboard">{txt => <h1 className="ClientsPageAnalytics__title">{txt}</h1>}</FM>
      <div className="ClientsPageAnalytics__Charts">
        <MonthPicker
          monthValue={monthValue}
          years={{ min: { year: 2010, month: 1 }, max: { year: currentYear, month: currentMonthNum } }}
          handleChange={handleMonthChange}
        />
        <UsersChart dayDates={dayDates} allDayData={allDayData} />
        <RightChart dayDates={dayDates} allDayData={allDayData} />
      </div>
      <FM id="crmClients">{txt => <h1 className="ClientsPageAnalytics__title">{txt}</h1>}</FM>
      <ClientsTable date={date} />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

export default connect(
  state => ({
    lastFunnels: state.dashboards.lastFunnels,
    lastFunnelsAreLoaded: state.dashboards.lastFunnelsAreLoaded,
    dailyFunnels: state.dashboards.dailyFunnels,
    dailyFunnelsAreLoaded: state.dashboards.dailyFunnelsAreLoaded,
    clients: state.crm.analytics.clients,
    perms: state.user.profile.perms,
  }),
  {
    getCrmClientsAnalytics: search => getCrmClientsAnalytics.request(search),
    getLastFunnels: data => getLastFunnels.request(data),
    getMonthsFunnels: data => getMonthsFunnels.request(data),
    getDailyFunnels: data => getDailyFunnels.request(data),
  }
)(ClientsPage);
