import axios from 'axios';

const geonamesAPI = axios.create({ baseURL: '' });

export const getGeonameContactByGID = gid => geonamesAPI.get(`/geonames/gid/${gid}/`);

const crmContactsBaseClient = axios.create({ baseURL: '/api/', validateStatus: status => status < 500 });

crmContactsBaseClient.interceptors.request.use(config => {
  const locale = localStorage.getItem('locale');

  if (locale) config.headers.locale = locale;

  return config;
});

// FIXME: НЕ РАБОТАЮТ СТОРОВЫЕ ИНТЕРСЕПТОРЫ

crmContactsBaseClient.interceptors.request.use(request => {
  const token = localStorage.getItem('jwtToken');
  const locale = localStorage.getItem('locale');

  if (locale) {
    request.headers.locale = locale;
  }
  if (token) {
    request.headers.Authorization = `JWT ${token}`;
  }
  return request;
});

export const getCrmUserFeed = (contactId, search) =>
  crmContactsBaseClient.get(`/crm/contacts/${contactId}/feed/`, { params: { ...search } });

export const createCRMUserExports = contactsId =>
  crmContactsBaseClient.post(`/reports/contact_export/create/`, { contactsId });

export const getUserContacts = search => crmContactsBaseClient.get(`/crm/contacts/list/`, { params: { ...search } });

export const createUserContact = payload => crmContactsBaseClient.post(`/crm/contacts/create/`, { ...payload });
export const uploadUserContacts = file => {
  const data = new FormData();
  data.append('file', file);

  return crmContactsBaseClient.post(`/crm/contacts/upload/`, data, {
    headers: { 'content-type': 'multipart/form-data' },
  });
};

export const getCrmUserData = userId => crmContactsBaseClient.get(`/crm/contacts/${userId}/`);
export const getCrmUserAccounts = userId => crmContactsBaseClient.get(`/crm/contacts/${userId}/account/list/`);
export const getCrmUserAccountData = (userId, accountId) =>
  crmContactsBaseClient.get(`/crm/contacts/${userId}/account/${accountId}/`);
export const getCrmAccountTrades = (userId, accountId, operation, search) =>
  crmContactsBaseClient.get(`/crm/contacts/${userId}/account/${accountId}/trade/list/${operation}/`, {
    params: { ...search },
  }); // open, closed, delayed
export const getCrmUserInternalTransfers = (userId, search) =>
  crmContactsBaseClient.get(`/crm/contacts/${userId}/internal_transfer/list/`, { params: { ...search } });
export const getCrmUserPayments = (userId, search) =>
  crmContactsBaseClient.get(`/crm/contacts/${userId}/payment/list/`, { params: { ...search } });
export const getCrmUserLogs = (userId, search) =>
  crmContactsBaseClient.get(`/crm/contacts/${userId}/log/list/`, { params: { ...search } });
export const getContactFavorites = () => crmContactsBaseClient.get(`/crm/contacts/favorite/`);
export const setContactFavorite = id => crmContactsBaseClient.put(`/crm/contacts/favorite/`, { id });
export const deleteContactFavorite = id => crmContactsBaseClient.delete(`/crm/contacts/favorite/`, { data: { id } });

export const getManagerList = search => crmContactsBaseClient.get(`/backoffice/managers/list/?search=${search}`);

export const getManagerListForTask = contactId => crmContactsBaseClient.get(`/backoffice/managers/list/${contactId}/`);

export const getIBManagerList = search => crmContactsBaseClient.get(`/backoffice/managers/list/?search=${search}`);

export const setUserName = (id, firstName, lastName) =>
  crmContactsBaseClient.patch(`/crm/contacts/${id}/`, { firstName, lastName });

export const setUserManager = (userId, manager, reason) =>
  crmContactsBaseClient.post(`/crm/contacts/${userId}/change_manager/`, { manager, reason });

export const setUserIBManager = (userId, ibManager) =>
  crmContactsBaseClient.post(`/crm/contacts/${userId}/change_ib_manager/`, { ibManager });

export const getAvailablePartnerCodes = (userId, search) =>
  crmContactsBaseClient.get(`/backoffice/partnership/systems/${userId}/change_partner_account/?search=${search}`);
export const editUserPartnerCode = (userId, partnerAccount) =>
  crmContactsBaseClient.post(`/backoffice/partnership/systems/${userId}/change_partner_account/`, { partnerAccount });

export const getSalesStatuses = () => crmContactsBaseClient.get('/crm/contacts/sales_statuses_list/');

export const editContact = (userId, data) => crmContactsBaseClient.patch(`/crm/contacts/${userId}/`, data);

export const getContactNotes = id => axios.get(`/crm/contacts/${id}/note/`);
export const createContactNote = (id, data) => axios.post(`/crm/contacts/${id}/note/`, data);
export const deleteContactNote = (id, noteId) => axios.delete(`/crm/contacts/${id}/note/${noteId}/`);
export const editContactNote = (id, noteId, data) => axios.put(`/crm/contacts/${id}/note/${noteId}/`, { ...data });
export const createContactDocument = (id, body) =>
  axios.post(`/crm/contacts/${id}/document/internal/upload/`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
export const removeContactNoteDocument = (userId, documentId) =>
  axios.delete(`/crm/contacts/${userId}/document/internal/${documentId}/`);
export const getContactNoteDocument = (userId, documentId) =>
  axios.get(`/crm/contacts/${userId}/document/internal/${documentId}/`, { responseType: 'blob' });
export const updateCrmTask = (taskId, data) => axios.patch(`/crm/task/${taskId}/`, { ...data });
// TODO: TYT
export const getAvailableTags = search => axios.get(`/crm/contacts/tags/list/?search=${search}`);
export const addContactTag = (id, tag) => axios.post(`/crm/contacts/${id}/tags/add/`, { tag });
export const deleteContactTag = (id, tag) => axios.post(`/crm/contacts/${id}/tags/delete/`, { tag });
