import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ReloadIcon } from 'common/icons';

import { withTheme } from 'common/styling/theme';
import classNames from 'classnames';
import { staticStyles, getDynamicStyles } from './style';
import { DefaultButton } from '../index';

const PageLayout = ({
  externalClass,
  caption,
  description,
  captionSlug,
  captionValues,
  extraCaptionText,
  showReload,
  onReloadClick,
  reloading,
  children,
  withBackLink,
  withBackButton,
  history,
  intl,
  globalMetaTitle,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [metaTitle, setMetaTitle] = useState(globalMetaTitle);

  useEffect(() => {
    if (caption) setMetaTitle(`${caption} ・ ${globalMetaTitle}`);
    if (captionSlug) {
      setMetaTitle(
        `${intl.formatMessage({
          id: captionSlug,
          values: captionValues,
        })} ・ ${globalMetaTitle}`
      );
    }
  }, []);

  // FIXME: intl not working as intended, search suggests update lib

  useEffect(() => {
    if (captionValues?.strategy && captionSlug) {
      setMetaTitle(
        `${intl
          .formatMessage({ id: captionSlug, values: captionValues })
          .replace(/{.*}/, captionValues.strategy)} ・ ${globalMetaTitle}`
      );
    }
  }, [captionValues?.strategy]);

  return (
    <div
      className={classNames('PageLayout', {
        [externalClass]: externalClass,
      })}>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <div className="container">
        {withBackLink ? (
          <FormattedMessage id="backToTopics">
            {txt => (
              <button className="PageLayout__back" type="button" onClick={() => history.goBack()}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        ) : null}
        <div className="PageLayout__header" id={`PageLayout__Header-${caption || captionSlug}`}>
          <h1>
            {withBackButton && (
              <DefaultButton textId="justBack" onClick={() => history.goBack()} previous isUpperCase />
            )}
            {caption || (captionSlug && <FormattedMessage id={captionSlug} values={captionValues} />)}
            {extraCaptionText ? ` ${extraCaptionText}` : ''}
            {showReload && (
              <button className="PageLayout__data-reload" type="button" onClick={onReloadClick} disabled={reloading}>
                <ReloadIcon />
              </button>
            )}
          </h1>
          {description && <div className="PageLayout__description" dangerouslySetInnerHTML={{ __html: description }} />}
        </div>
        <div className="PageLayout__body">{children}</div>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

PageLayout.propTypes = {
  externalClass: PropTypes.string,
  history: PropTypes.object.isRequired,
  caption: PropTypes.string,
  description: PropTypes.string,
  captionSlug: PropTypes.string,
  captionValues: PropTypes.object,
  extraCaptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showReload: PropTypes.bool,
  onReloadClick: PropTypes.func,
  reloading: PropTypes.bool,
  withBackLink: PropTypes.bool,
  withBackButton: PropTypes.bool,
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  globalMetaTitle: PropTypes.string,
  theme: PropTypes.object,
};

PageLayout.defaultProps = {
  externalClass: null,
  caption: '',
  description: '',
  captionSlug: '',
  captionValues: {},
  extraCaptionText: '',
  showReload: false,
  onReloadClick: () => {},
  withBackButton: false,
  reloading: false,
  withBackLink: false,
  globalMetaTitle: 'Trading cabinet',
  theme: {},
};

export default compose(
  injectIntl,
  withRouter,
  withTheme(),
  connect(
    state => ({
      globalMetaTitle: state.interfaceConfig.clientThemeConfig.client.title,
    }),
    null
  )
)(PageLayout);
export { PageLayout };
