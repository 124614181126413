import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/rem';

  .TaskIcon {
    width: rem(18px);
    height: rem(18px);
  }
`;

export { staticStyles };
