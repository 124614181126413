import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import BaseStaticInput from '../BaseStaticInput';

import { staticStyles } from './style';

const InputStr = ({ name, value, onChange }) => (
  <div className="InputStr">
    <FM id={name}>{txt => <span className="InputStr__Title">{txt}</span>}</FM>
    <BaseStaticInput onlyNumbers value={value} onChange={onChange} />
    <style jsx>{staticStyles}</style>
  </div>
);

InputStr.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default InputStr;
