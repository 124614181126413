import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { acceptance } from 'redux-form-validators';

import { CheckboxField } from '../form-fields';

import { staticStyles } from './style';

const Agreements = ({ agreements, submitting }) => (
  <div className="Agreements">
    {!!agreements.length &&
      agreements.map((agreement, index) => (
        <Field
          key={index}
          component={CheckboxField}
          name={`agreement_${index}`}
          type="checkbox"
          link={agreement}
          validate={[acceptance()]}
          disabled={submitting}
        />
      ))}
    <style jsx>{staticStyles}</style>
  </div>
);

Agreements.propTypes = {
  agreements: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default Agreements;
