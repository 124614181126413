import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';
import { EditIcon, CopyIcon } from 'common/icons';

import {
  PageLayout,
  PageContentPreloader,
  ActionsButton,
  StatusCell,
  BasicModal,
  DefaultButton,
} from 'common/components';
import { EditAmountForm, UserDepositProofForm } from 'backoffice/payments/_components';
import {
  UserInfoPanel,
  OperationsHistory,
  UserTransactionsHistory,
  UserTradingHistory,
  UserAmountEditHistory,
  PaymentNotes,
} from 'backoffice/_components';

import { NavLink } from 'react-router-dom';
import EditPaymentManagerForm from 'backoffice/payments/_components/EditPaymentManagerForm';
import EditAdditionalFieldsForm from 'backoffice/payments/_components/EditAdditionalFieldsForm';
import { staticStyles, getDynamicStyles } from './style';

const PaymentPage = ({
  type,
  itemId,
  captionSlug,
  item,
  perms,
  itemIsLoaded,
  getItem,
  Buttons,
  history,
  addons,
  features,
  hasEditPerm,
  idBackTo,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenManagerForm, setIsOpenManagerForm] = useState(false);
  const [isOpenAdditionalFieldsForm, setIsOpenAdditionalFieldsForm] = useState(false);
  const [isMailCopied, setIsMailCopied] = useState(false);
  const [textId, setTextId] = useState('');
  const [fieldName, setFieldName] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const goToUser = () => history.push(`/backoffice/users/${item.user.id}`);
  const isWithdrawal = window.location.pathname.includes('withdrawals');
  const handleBackToClick = () => {
    // TODO: тк у withdrawals все выводы находятся по пути с list в отдельной вкладке пока
    if (type === 'withdrawals') {
      history.push(`/backoffice/${type}/list`);
    } else {
      history.push(`/backoffice/${type}/`);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleManagerModal = () => {
    setIsOpenManagerForm(!isOpenManagerForm);
  };

  const toggleAdditionalFieldsModal = () => {
    setIsOpenAdditionalFieldsForm(!isOpenAdditionalFieldsForm);
  };

  const onEditDepositAmountModal = () => {
    toggleModal();
  };
  const onEditAdditionFieldsModal = (textId, fieldName, fieldValue) => {
    toggleAdditionalFieldsModal();
    if (textId && fieldName) {
      setTextId(textId);
      setFieldName(fieldName);
      setFieldValue(fieldValue);
    }
  };

  const onEditManagerModal = () => {
    toggleManagerModal();
  };

  const handleMailCopyClick = useCallback(
    email => {
      navigator.clipboard.writeText(email);
      setIsMailCopied(true);

      setTimeout(() => {
        setIsMailCopied(false);
      }, 2000);
    },
    [isMailCopied]
  );

  const walletAddon = addons.includes('walletAccount');
  const additionalFields = item.psAdditionalFields && Object.entries(item.psAdditionalFields);

  return (
    <PageLayout captionSlug={captionSlug} showReload onReloadClick={() => getItem(itemId)} reloading={!itemIsLoaded}>
      <PageContentPreloader ready={itemIsLoaded} type="boPaymentPage">
        {/* eslint-disable */}
        <FormattedMessage id={idBackTo}>
          {txt => (
            <div className="PaymentPage__back-to" onClick={handleBackToClick}>
              {txt}
            </div>
          )}
        </FormattedMessage>
        {/* eslint-enable */}
        <div id={`backoffice-${type}-page-details`} className="PaymentPage__top-info">
          <ul className="PaymentPage__top-info-list">
            {/* Amount */}
            {item?.amountWithCommission?.amount && item?.amountWithCommission?.currency && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-amount`}>
                <FormattedMessage id="boAmount" />
                <div>
                  {item.amountWithCommission.amount} {item.amountWithCommission.currency}
                  {item.canEditValue && hasEditPerm && (
                    <ActionsButton edit buttonId="actionsEditValue" onClickFunc={onEditDepositAmountModal}>
                      <EditIcon />
                    </ActionsButton>
                  )}
                </div>
              </li>
            )}

            {/* operation Id */}
            {itemId && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-id`}>
                <FormattedMessage id="boId" />
                <div>{itemId}</div>
              </li>
            )}

            {/* FullName */}
            {item?.user?.fullName && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-fullName`}>
                <FormattedMessage id="justFullName" />
                <div>{item.user.fullName}</div>
              </li>
            )}

            {/* Email */}
            {item?.user?.email && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-email`}>
                <FormattedMessage id="justEmail" />
                <div className="email">
                  {item.user.email}
                  <ActionsButton
                    tooltipId="email-copy-tooltip"
                    tooltipTextId={isMailCopied ? 'justCopiedWord' : 'justCopy'}
                    edit
                    buttonId="actionsCopyValue"
                    onClickFunc={() => handleMailCopyClick(item.user.email)}>
                    <CopyIcon />
                  </ActionsButton>
                </div>
              </li>
            )}

            {/* Account */}
            {item?.account?.login && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-account`}>
                <FormattedMessage id="boAccount" />
                <div>{item.account.login}</div>
              </li>
            )}

            {/* Commission */}
            {item?.commission && (
              <li id={`backoffice-${type}-page-detail-comission`} className="PaymentPage__top-info-item">
                <FormattedMessage id="justCommission" />
                <div>
                  {item.commission.amount} {item.commission.currency}
                </div>
              </li>
            )}

            {/* Convertation course */}
            {item?.exchangeString && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-convertation-course`}>
                <FormattedMessage id="justConvertationCourse" />
                <div>{item.exchangeString}</div>
              </li>
            )}

            {/* Payment system */}
            {item?.paymentSystemSlug && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-payment-system`}>
                <FormattedMessage id="boPaymentSystem" />
                <div>{item.paymentSystemSlug}</div>
              </li>
            )}

            {/* Balance */}
            {item?.account?.balance?.amount && item?.account?.balance?.currency && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-balance`}>
                <FormattedMessage id="boBalance" />
                <div>
                  {item.account.balance.amount} {item.account.balance.currency}
                </div>
              </li>
            )}

            {/* Equity */}
            {item?.account?.equity?.amount && item?.account?.equity?.currency && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-equity`}>
                <FormattedMessage id="boEquity" />
                <div>
                  {item.account.equity.amount} {item.account.equity.currency}
                </div>
              </li>
            )}

            {/* wallet-balance */}
            {walletAddon && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-wallet-balance`}>
                <FormattedMessage id="justWalletBalance" />
                <div>{item?.user?.walletBalance} USD</div>
              </li>
            )}

            {/* Status */}
            <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-detail-status`}>
              <FormattedMessage id="justStatus" />
              <div>
                <StatusCell statusCode={item.status} />
              </div>
            </li>

            {/* Payment operation manager */}
            {features.includes('paymentOperationManager') && (
              <li className="PaymentPage__top-info-item" id={`backoffice-${type}-page-operation-manager`}>
                <FormattedMessage id="boInvestManager" />
                <div>
                  {item.manager ? `${item.manager.firstName} ${item.manager.lastName}` : '-'}
                  {hasEditPerm && (
                    <ActionsButton edit buttonId="actionsEditValue" onClickFunc={onEditManagerModal}>
                      <EditIcon />
                    </ActionsButton>
                  )}
                </div>
              </li>
            )}

            {/* additional-fields */}
            {additionalFields &&
              additionalFields.map(item =>
                typeof item[1].value !== 'object' || item[1].value === null ? (
                  <li
                    className="PaymentPage__top-info-item"
                    key={item[1].name}
                    id={`backoffice-${type}-page-additional-field`}>
                    <FormattedMessage id={item[1].name} />
                    <div>
                      {item[1].value ? item[1].value : '-'}
                      {hasEditPerm && (
                        <ActionsButton
                          edit
                          buttonId="actionsEditValue"
                          onClickFunc={() => onEditAdditionFieldsModal(item[1].name, item[0], item[1].value)}>
                          <EditIcon />
                        </ActionsButton>
                      )}
                    </div>
                  </li>
                ) : null
              )}
          </ul>

          {item?.metadata && Object.entries(item.metadata).length !== 0 && (
            <ul className="PaymentPage__additional-info-list">
              {Object.entries(item.metadata).map((metadata, index) =>
                typeof metadata[1] === 'string' ? (
                  <li className="PaymentPage__additional-info-item" key={index}>
                    <span>{metadata[0]}</span>
                    <div>{metadata[1]}</div>
                  </li>
                ) : null
              )}
            </ul>
          )}

          {/* buttons */}
          <div className="PaymentPage__buttons-inner">
            <Buttons />
          </div>
        </div>

        {captionSlug === 'boWithdrawal' && addons.includes('withdrawApproveSystem') && item?.approval && (
          <div className="PaymentPage__approve-wrap">
            <NavLink to={`/backoffice/withdrawals/approve/${item?.approval}`}>
              <DefaultButton textId="justApprove" type="button" filled />
            </NavLink>
          </div>
        )}

        {item.paymentProofEnabled && (
          <UserDepositProofForm status={item.paymentProofStatus ?? ''} user={item.user} isDeposit={!isWithdrawal} />
        )}

        {item.id && <UserAmountEditHistory accountId={item.id} />}
        {item.id && (
          <PaymentNotes
            id={item.id}
            paymentType={type === 'deposits' ? 'deposit' : 'withdraw'}
            fullAccess={type === 'deposits' ? perms.fullAccessDeposit : perms.fullAccessWithdraw}
          />
        )}

        {item.user && isWithdrawal && <UserTransactionsHistory userId={item.user.id} />}

        {!addons.includes('walletAccount') && item.user && isWithdrawal && (
          <UserTradingHistory userId={item.user.id} accountId={item.account.id} accountLabel={item.account.login} />
        )}

        <OperationsHistory items={item.changeSet} />

        <UserInfoPanel user={item.user} onClick={goToUser} />

        {item.canEditValue && (
          <BasicModal isOpen={isOpen} captionId="justEditAmount" onRequestClose={toggleModal}>
            <EditAmountForm
              toggleModal={toggleModal}
              id={item.id}
              amount={item.amount}
              type={isWithdrawal ? 'withdrawal' : 'deposit'}
              initialValues={{
                currentAmount: item.amount ? item.amount.amount : {},
              }}
            />
          </BasicModal>
        )}
        <BasicModal captionId="editManager" isOpen={isOpenManagerForm} onRequestClose={() => toggleManagerModal()}>
          <EditPaymentManagerForm
            onSubmitSuccess={() => toggleManagerModal()}
            id={item.id}
            type={isWithdrawal ? 'withdrawal' : 'deposit'}
          />
        </BasicModal>
        <BasicModal
          captionId={textId}
          isOpen={isOpenAdditionalFieldsForm}
          onRequestClose={() => toggleAdditionalFieldsModal()}>
          <EditAdditionalFieldsForm
            onSubmitSuccess={() => toggleAdditionalFieldsModal()}
            id={item.id}
            textId={textId}
            fieldName={fieldName}
            type={type}
            initialValues={{
              [fieldName]: fieldValue,
            }}
          />
        </BasicModal>
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

PaymentPage.propTypes = {
  type: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  captionSlug: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  perms: PropTypes.object.isRequired,
  itemIsLoaded: PropTypes.bool.isRequired,
  getItem: PropTypes.func,
  idBackTo: PropTypes.string,
  Buttons: PropTypes.func,
  history: PropTypes.object.isRequired,
  addons: PropTypes.array.isRequired,
  hasEditPerm: PropTypes.bool,
  theme: PropTypes.object,
};

PaymentPage.defaultProps = {
  idBackTo: 'justBack',
  getItem: null,
  Buttons: null,
  hasEditPerm: false,
  theme: {},
};

export default compose(
  withTheme(),
  connect(state => ({
    addons: state.interfaceConfig.addons,
    features: state.interfaceConfig.features,
    perms: state.user.profile.perms,
  }))
)(PaymentPage);
export { PaymentPage };
