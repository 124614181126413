import React from 'react';
import PropTypes from 'prop-types';
import ReactPlaceholder from 'react-placeholder';
import { MediaBlock, TextBlock, RoundShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const ImageLine = () => (
  <>
    <RoundShape className="RoundShape" color="transparent" />
    <MediaBlock color="transparent" className="ImageBlock" rows={1} />
  </>
);

const crmTable = (
  <div className="CRMTable">
    {Array(10)
      .fill(0)
      .map((_, ind) => (
        <div className="CRMTable__Row" key={`crmTable-placeholder-row-${ind}`}>
          <RoundShape className="CRMTable__CheckBox" color="transparent" />
          <TextBlock className="CRMTable__MediaBlock" color="transparent" rows={1} />
        </div>
      ))}
  </div>
);

const image = (
  <div>
    <ImageLine />
    <ImageLine />
    <ImageLine />
  </div>
);

const text = (
  <div>
    <TextBlock rows={5} className="text" color="transparent" />
  </div>
);

const tinyMCE = (
  <div>
    <TextBlock rows={1} className="TinyMCE" color="transparent" />
  </div>
);

const line = (
  <div>
    <TextBlock rows={1} className="line" color="transparent" />
  </div>
);

const smallLines = (
  <div>
    <TextBlock rows={1} className="smallLines" color="transparent" />
    <TextBlock rows={1} className="smallLines" color="transparent" />
    <TextBlock rows={1} className="smallLines" color="transparent" />
  </div>
);

const bigLine = (
  <div>
    <TextBlock rows={1} className="bigLine" color="transparent" />
  </div>
);

const boPaymentPage = (
  <div>
    <TextBlock rows={1} className="boPaymentPageFirst" color="transparent" />
    <TextBlock rows={1} className="boPaymentPageSecond" color="transparent" />
    <TextBlock rows={1} className="boPaymentPageThird" color="transparent" />
  </div>
);

const boUserInfo = (
  <div>
    <TextBlock rows={1} className="boUserInfoTopLine" color="transparent" />
    <TextBlock rows={1} className="boUserInfo" color="transparent" />
    <TextBlock rows={1} className="boUserInfoLastLine" color="transparent" />
  </div>
);

const boUserHistory = (
  <div className="boUserHistoryInner">
    <TextBlock rows={1} className="boUserHistory" color="transparent" />
  </div>
);

const boUserTags = (
  <div className="boUserTagsInner">
    <TextBlock rows={1} className="boUserTag" color="transparent" />
    <TextBlock rows={1} className="boUserTag" color="transparent" />
    <TextBlock rows={1} className="boUserTag" color="transparent" />
    <TextBlock rows={1} className="boUserTag" color="transparent" />
  </div>
);

const news = (
  <div className="News">
    <TextBlock rows={1} className="News__item" color="transparent" />
    <TextBlock rows={1} className="News__item" color="transparent" />
    <TextBlock rows={1} className="News__item" color="transparent" />
    <TextBlock rows={1} className="News__item" color="transparent" />
    <TextBlock rows={1} className="News__item" color="transparent" />
  </div>
);

const pagination = (
  <div className="Pagination">
    <TextBlock rows={1} className="Pagination__item" color="transparent" />
  </div>
);

const fileItemBlock = (
  <div className="FileItemBlock">
    <TextBlock rows={1} className="FileItemBlock__item" color="transparent" />
    <TextBlock rows={1} className="FileItemBlock__item" color="transparent" />
  </div>
);

const accounts = (
  <div className="Accounts">
    <div className="Accounts__row">
      <TextBlock rows={1} className="Accounts__item" color="transparent" />
      <TextBlock rows={1} className="Accounts__item" color="transparent" />
      <TextBlock rows={1} className="Accounts__item" color="transparent" />
    </div>
    <ImageLine />
    <ImageLine />
    <ImageLine />
  </div>
);

const dashboards = (
  <div className="Dashboards">
    <TextBlock rows={1} className="Dashboards__bigItem" color="transparent" />
    <TextBlock rows={1} className="Dashboards__smallItem" color="transparent" />
    <TextBlock rows={1} className="Dashboards__bigItem" color="transparent" />
    <TextBlock rows={1} className="Dashboards__smallItem" color="transparent" />
  </div>
);

const payments = (
  <div className="Payments">
    <div className="Payments__row">
      <TextBlock rows={2} className="Payments__item" color="transparent" />
      <TextBlock rows={2} className="Payments__item" color="transparent" />
      <TextBlock rows={2} className="Payments__item" color="transparent" />
    </div>
    <TextBlock rows={1} className="bigLine" color="transparent" />
  </div>
);

const bigBlock = (
  <div className="BigBlock">
    <TextBlock rows={1} className="BigBlock__item" color="transparent" />
  </div>
);

const PageContentPreloader = ({ ready, firstLaunchOnly, children, type, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const blockType = {
    crmTable,
    image,
    text,
    tinyMce: tinyMCE,
    line,
    smallLines,
    bigLine,
    boPaymentPage,
    boUserInfo,
    boUserHistory,
    boUserTags,
    news,
    pagination,
    fileItemBlock,
    accounts,
    payments,
    dashboards,
    bigBlock,
  };

  return (
    <div className="PageContentPreloader">
      <ReactPlaceholder
        color="transparent"
        ready={ready}
        customPlaceholder={blockType[type]}
        firstLaunchOnly={firstLaunchOnly}
        showLoadingAnimation>
        {children}
      </ReactPlaceholder>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

PageContentPreloader.propTypes = {
  ready: PropTypes.bool.isRequired,
  firstLaunchOnly: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  type: PropTypes.string,
  theme: PropTypes.object,
};

PageContentPreloader.defaultProps = {
  firstLaunchOnly: false,
  type: 'image',
  theme: {},
};

export default withTheme()(PageContentPreloader);
export { PageContentPreloader };
