import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .IssueWrapper {
    display: flex;
    width: 100%;
    background: #ffffff;
    min-height: 75vh;

    @media only screen and (max-width: 1180px) {
      flex-direction: column;
      height: auto;
    }
  }

  .IssueAbout {
    width: 100%;
    max-width: 360px;
    min-width: 360px;

    @include respond-to(sm) {
      min-width: auto;
    }

    :global(.Collapsible) {
      margin: 0;

      :global(.Collapsible__header) {
        box-shadow: none;
        border-top: 1px solid #c5c5c5;
      }

      :global(.Collapsible__content) {
        margin-bottom: 0;
      }

      &:last-child {
        :global(.Collapsible__header) {
          border-bottom: 1px solid #c5c5c5;
        }
      }
    }

    @media only screen and (max-width: 1180px) {
      max-width: 100%;
    }
  }

  .IssueUser {
    padding: 0 25px 50px 25px;

    @media only screen and (max-width: 1180px) {
      padding-bottom: 0;
    }

    @include respond-to(sm) {
      padding: 0;
      padding-top: 10px;
    }
  }

  .PartnershipRequestAnswer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 6px;
    margin-bottom: 16px;
    &__InfoText {
      font-weight: 400;
      font-size: 17px;
    }
    &__Buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .InfoLine {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: rem(10px);

    .InfoLine__left {
      display: inline-block;
      font-size: rem(13px);
      width: 45%;
      padding-left: ${isRTL ? '0' : '15px'};
      padding-right: ${isRTL ? '15px' : '0'};
    }

    .InfoLine__right {
      display: inline-block;
      width: 55%;
      padding-right: rem(${isRTL ? '0' : '35px'});
      padding-left: rem(${isRTL ? '35px' : '0'});
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .IssueContent {
    padding-left: 25px;
    padding-right: 25px;
    min-width: 550px;
    position: relative;

    @include respond-to(sm) {
      min-width: auto;
      padding: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: ${isRTL ? 'auto' : '0'};
      right: ${isRTL ? '0' : 'auto'};
      top: 0;
      width: 1px;
      height: 100%;
      background: #c5c5c5;

      @media only screen and (max-width: 1180px) {
        content: none;
      }
    }
  }

  .Issue__info {
    width: 100%;
    padding: 25px 40px 10px 15px;
    position: relative;

    @include respond-to(sm) {
      padding: 15px 10px;
      margin-bottom: 10px;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
        margin-bottom: 15px;
        min-height: 50px;

        @include respond-to(sm) {
          margin-bottom: 0;
        }

        :global(img) {
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.4s ease;
          &:hover {
            transform: scale(1.1);
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 50px;
            height: 50px;
          }

          @include respond-to(sm) {
            min-height: auto;
          }
        }
        p {
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 485px;

          @include respond-to(xl) {
            max-width: 350px;
          }
          @include respond-to(sm) {
            max-width: 250px;
          }
        }
        :global(span) {
          &:first-child {
            font-size: 11px;
            text-transform: uppercase;
            margin-bottom: 5px;
            display: block;
          }
        }
        :global(.StatusCell) {
          margin-top: rem(5px);
        }
      }
    }

    &-arrow {
      display: none;
      padding: 5px;
      border: solid black;
      border-width: 0 2px 2px 0;
      position: absolute;
      right: rem(20px);
      top: 35px;

      @media only screen and (max-width: 1180px) {
        display: inline-block;
      }

      &--right {
        transform: rotate(-45deg);
        transition: all 0.3s ease;
      }

      &--down {
        transform: rotate(45deg);
        transition: all 0.3s ease;
      }
    }
  }

  button.Issue__info {
    cursor: default;
    @include respond-to(sm) {
      cursor: pointer;
      border-bottom: 1px solid #c5c5c5;
    }
  }

  .IssueInfo__user-inner {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    width: 100%;

    .IssueInfo__avatar-inner {
      max-width: 62px;
      max-height: 62px;
      height: auto;
      width: auto;

      @include respond-to(sm) {
        max-width: 50px;
        max-height: 50px;

        :global(img) {
          width: 100%;
          height: 100%;
        }
      }
    }

    .IssueInfo__user-info {
      margin-left: ${isRTL ? 'auto' : '20px'};
      margin-right: ${isRTL ? '20px' : 'auto'};
      width: calc(100% - 50px);
      text-align: left;
    }
  }
  .IssueInfoNew {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 25px 40px 10px 15px;

    .infoTitle {
      font-family: 'Ubuntu';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #5d647d;
    }

    @include respond-to(sm) {
      display: flex;
      flex-direction: column;
      padding: 15px 15px;
      margin-bottom: 10px;
    }

    > div {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 15px;

      font-family: 'Ubuntu';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;

      @include respond-to(sm) {
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }
    }

    &__title {
      grid-column: 1/4;
    }

    &__date {
      align-items: flex-start;
      :global(.DateCell) {
        @include respond-to(sm) {
          display: flex;
          gap: 3px;
        }
      }
    }

    &__language {
      > span {
        display: flex;
      }
      align-items: flex-end;
    }

    &__status {
      align-items: flex-end;
    }
  }

  .IssueInfo__title {
    width: 50%;

    @include respond-to(sm) {
      width: 100%;
    }
  }

  .IssueInfo__date {
    width: 16%;
    min-width: 120px;

    @include respond-to(sm) {
      width: 50%;
    }
  }

  .IssueInfo__status {
    width: 10%;

    @include respond-to(sm) {
      width: 50%;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  // TODO: разобраться с colors
  return css`
    .InfoLine__left {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
