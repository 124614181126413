import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import InnerHTML from 'dangerously-set-html-content';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, reset, FormSection } from 'redux-form';
import { required } from 'redux-form-validators';
import { Agreements, DefaultButton, ReduxTextField, ReduxSelectField, BasicModal } from 'common/components';
import { withTheme } from 'common/styling/theme';
import classNames from 'classnames';
import PaymentSystemButton from '../PaymentSystemButton';
import SecondPaymentsForm from '../SecondPaymentsForm';
import arrowDown from './arrowDown.svg';
import { makeDeposit, makePaymentLpa, makeWithdraw, resetPaymentForm } from '../../_redux';
import { staticStyles, getDynamicStyles } from './style';
import iconRadio from './images/iconRadio.svg';
import iconRadioActive from './images/iconRadioActive.svg';
import ChooseLpaButton from '../ChooseLpaButton';

const PaymentsForm = ({
  accounts,
  // captionId,
  paymentSystemList,
  availableLpasList,
  openStepLPA,
  setOpenStepLPA,
  paymentForm,
  resetPaymentForm,
  handleSubmit,
  dispatch,
  profile,
  invalid,
  pristine,
  submitting,
  action,
  accountValue,
  currencyValue,
  amountValue,
  loadAccount,
  baseValue,
  addons,
  theme,
  history,
}) => {
  /* screen-size */
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useMemo(() => {
    window.addEventListener('resize', () => setScreenSize(window.innerWidth));
  }, []);

  const [openStep1, setOpenStep1] = useState(true);

  const [disabledStep2, setDisabledStep2] = useState(true);
  const [openStep2, setOpenStep2] = useState(false);

  const [disabledStep3, setDisabledStep3] = useState(true);
  const [openStep3, setOpenStep3] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedLpa, setSelectedLpa] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const submitText = openStepLPA ? 'justConfirm' : 'clientContinue';

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const step2FormTitle = !openStepLPA ? 'clientSpecifyTheSum' : 'justChooseLPA';

  const accountsFiltered = useMemo(
    () => accounts.filter(acc => !acc.isDemo && !acc.isBlocked && !acc.loading),
    [accounts]
  );

  /* eslint-disable */
  const typeOperation = ['deposit', 'wallet_deposit'].includes(action) ? 'deposit' : 'withdraw';
  const search = window.location.search.split('=')[1];
  const [typeAccount, setTypeAccount] = useState(
    addons.includes('walletAccount') ? (search ? search : 'wallet') : 'account'
  );
  const [operation, setOperation] = useState(null);
  /* eslint-enable */

  const getOperation = typeAccount =>
    setOperation(typeAccount === 'account' ? typeOperation : `wallet_${typeOperation}`);
  useMemo(() => getOperation(typeAccount), []);

  const [selectedSystem, setSelectedSystem] = useState(paymentSystemList[0].title);
  const isDeposit = ['wallet_deposit', 'deposit'].includes(action);
  // const account = addons.includes('walletAccount') ? 'wallet' : 'not wallet';
  const lpaPercent = isDeposit ? selectedLpa?.depositCommissionPercent : selectedLpa?.withdrawCommissionPercent;
  const [calculatedReceiveAmount, setCalculatedReceiveAmount] = useState(0);

  const selectedSystemInfo = useMemo(
    () => paymentSystemList?.find(system => system.title === selectedSystem),
    [paymentSystemList, selectedSystem]
  );

  const description = isDeposit ? selectedSystemInfo.descriptionDeposit : selectedSystemInfo.descriptionWithdraw;
  const operationNum = isDeposit ? 0 : 1;
  const minDepositTitleNew =
    document.location.hostname === 'my.swaycapitalgroup.com' ? 'justSwayCapitalMinDeposit' : 'justMinDeposit';
  const maxDepositTitleNew =
    document.location.hostname === 'my.swaycapitalgroup.com' ? 'justSwayCapitalMaxDeposit' : 'justMaxDeposit';
  const firstMinDepositTitleNew =
    document.location.hostname === 'my.swaycapitalgroup.com' ? 'jusSwayFirstMinDeposit' : 'justFirstMinDeposit';

  const handlePaymentSystemClick = title => {
    setSelectedSystem(title);

    dispatch(reset('payments-form'));
    resetPaymentForm();
  };

  const handleResetPaymentForm = () => {
    dispatch(reset('payments-form'));
    resetPaymentForm();
  };

  if (!paymentSystemList.length) {
    return false;
  }

  const calculateReceiveAmount = (_, value) => {
    const sendAmount = parseFloat(value);
    const commission = Math.floor(lpaPercent);
    const calculatedCommission = (sendAmount / 100) * commission;
    const receiveAmount = sendAmount + calculatedCommission;
    setCalculatedReceiveAmount(receiveAmount);
  };

  const submit = handleSubmit(values =>
    openStepLPA
      ? makePaymentLpa(
          {
            amount: {
              amount: values.amount,
              currency: values.currency,
            },
            operation: operationNum,
            agent: selectedLpa.user.id,
          },
          dispatch
        )
      : isDeposit
      ? makeDeposit({ ...values, paymentMethod: selectedSystemInfo.id, operation }, dispatch)
      : makeWithdraw({ ...values, paymentMethod: selectedSystemInfo.id, operation }, dispatch)
  );

  const properties = paymentForm?.requisitesForm ?? {};

  const isNullForm = Object.keys(properties).length ? properties.widget === 'nullform' : true;

  useEffect(() => {
    if (paymentForm?.request.status === 'waiting_verification' && isNullForm) {
      setModalOpen(true);
    }
  }, [paymentForm]);

  const accountsOptions = accountsFiltered.map(item => {
    const balance = baseValue === 'balance' ? item.balance : item.marginFree;
    return {
      label: `${item.accountTypeTitle} : ${item.login} ${balance ? `( ${balance.amount}${balance.currency} )` : ''}`,
      value: item.id,
    };
  });

  return (
    <div className="PaymentsForm">
      {/* step-1 */}
      <div
        className={classNames('PaymentsForm__step-1', {
          'PaymentsForm__step-1--open': openStep1,
        })}>
        {/* eslint-disable */}
        <div
          className="PaymentsForm__step-1-wrap-title"
          onClick={() => {
            setOpenStep1(!openStep1);
          }}>
          {/* eslint-enable */}
          <div className="PaymentsForm__step-1-number">
            <span>1</span>
          </div>
          <div className="PaymentsForm__step-1-title">
            <FormattedMessage id="clientSelectMethod">{txt => txt}</FormattedMessage>
          </div>
          <div
            className={classNames('PaymentsForm__step-1-arrow', {
              'PaymentsForm__step-1-arrow--hide': !openStep1,
            })}>
            <img src={arrowDown} alt="arrow-down" />
          </div>
        </div>

        {openStep1 && (
          <>
            <div className="PaymentsForm__step-1-systems">
              {/* eslint-disable */}
              {paymentSystemList.map((system, i) => (
                <PaymentSystemButton
                  key={system.id}
                  systemInfo={system}
                  active={system.title === selectedSystem && !openStepLPA}
                  onClick={() => {
                    if (screenSize > 767) {
                      handlePaymentSystemClick(system.title);
                      setOpenStep2(false);
                      setDisabledStep2(true);
                      setOpenStep3(false);
                      setDisabledStep3(true);
                      setOpenStepLPA(false);
                    }
                  }}
                  onConfirm={e => {
                    e.stopPropagation();
                    handlePaymentSystemClick(system.title);
                    setOpenStep2(true);
                    setDisabledStep2(false);
                    setOpenStep1(false);
                    setOpenStep3(false);
                    setDisabledStep3(true);
                  }}
                  ind={++i <= 4 ? i : i % 4}
                  screenSize={screenSize}
                  isDeposit={isDeposit}
                />
              ))}
              {profile.lpa ? (
                <ChooseLpaButton
                  active={openStepLPA}
                  onClick={() => {
                    if (screenSize > 767) {
                      setOpenStepLPA(true);
                    }
                  }}
                  screenSize={screenSize}
                  isDeposit={isDeposit}
                />
              ) : null}
            </div>
            {selectedSystemInfo && screenSize > 767 && (
              <>
                {!openStepLPA ? (
                  <div className="PaymentsForm__step-1-payment-info-wrap">
                    <div className="PaymentsForm__step-1-payment-info">
                      <div className="PaymentsForm__step-1-payment-info-left">
                        <img src={selectedSystemInfo.iconUrl} alt="icon-payment-method" />
                      </div>
                      <div className="PaymentsForm__step-1-payment-info-right">
                        <div className="PaymentsForm__step-1-payment-info-deposits">
                          <div className="PaymentsForm__step-1-payment-info-deposits-item">
                            {selectedSystemInfo.firstDeposit && isDeposit && (
                              <div className="PaymentsForm__step-1-payment-info-deposits-item-title">
                                <FormattedMessage id={firstMinDepositTitleNew}>{txt => txt}</FormattedMessage>
                              </div>
                            )}
                            <div className="PaymentsForm__step-1-payment-info-deposits-item-title">
                              <FormattedMessage id={isDeposit ? minDepositTitleNew : 'justMinWithdrawal'}>
                                {txt => txt}
                              </FormattedMessage>
                            </div>
                            <div className="PaymentsForm__step-1-payment-info-deposits-item-desc">
                              {selectedSystemInfo.minAmount}
                            </div>
                          </div>
                          <div className="PaymentsForm__step-1-payment-info-deposits-item">
                            <div className="PaymentsForm__step-1-payment-info-deposits-item-title">
                              <FormattedMessage id={isDeposit ? maxDepositTitleNew : 'justMaxWithdrawal'}>
                                {txt => txt}
                              </FormattedMessage>
                            </div>
                            <div className="PaymentsForm__step-1-payment-info-deposits-item-desc">
                              {selectedSystemInfo.maxAmount}
                            </div>
                          </div>
                          <div className="PaymentsForm__step-1-payment-info-deposits-item">
                            <div className="PaymentsForm__step-1-payment-info-deposits-item-title">
                              <FormattedMessage id="justCommission">{txt => txt}</FormattedMessage>
                            </div>
                            <div className="PaymentsForm__step-1-payment-info-deposits-item-desc">
                              {selectedSystemInfo.commission}
                            </div>
                          </div>
                        </div>
                        <div className="PaymentsForm__step-1-payment-info-description">
                          <InnerHTML html={description} key={1} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="PaymentsForm__step-1-payment-info-confirm">
                  <DefaultButton
                    type="submit"
                    textId="justConfirm"
                    onClick={() => {
                      setOpenStep1(false);
                      setDisabledStep2(false);
                      setOpenStep2(true);
                    }}
                    filled
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* step-2 */}
      <div
        className={classNames('PaymentsForm__step-2', {
          'PaymentsForm__step-2--open': openStep2,
          'PaymentsForm__step-2--disabled': disabledStep2,
        })}>
        {/* eslint-disable */}
        <div
          className="PaymentsForm__step-2-wrap-title"
          onClick={() => {
            if (disabledStep2) return;
            setOpenStep2(!openStep2);
          }}>
          {/* eslint-enable */}
          <div className="PaymentsForm__step-2-number">
            <span>2</span>
          </div>
          <div className="PaymentsForm__step-2-title">
            <FormattedMessage id={step2FormTitle}>{txt => txt}</FormattedMessage>
          </div>
          <div
            className={classNames('PaymentsForm__step-2-arrow', {
              'PaymentsForm__step-2-arrow--hide': !openStep2,
            })}>
            <img src={arrowDown} alt="arrow-down" />
          </div>
        </div>

        {/* open-step-2 */}
        <div
          className={classNames('PaymentsForm__step-2-wrap', {
            'PaymentsForm__step-2-wrap--hide': !openStep2,
          })}>
          {openStepLPA ? (
            <form onSubmit={submit}>
              <div className="PaymentsForm__step-2-form-subwrap">
                <div
                  className={classNames('PaymentsForm__step-2-form-left', {
                    'PaymentsForm__step-2-form-left--right-empty': !paymentForm,
                  })}>
                  <div className="PaymentsForm__step-2-form-account">
                    <Field
                      name="account"
                      textId="justChooseLPA"
                      validate={required()}
                      options={availableLpasList?.map(item => ({
                        value: item,
                        label: `${item.user.firstName}
                          ${item.user.middleName ? item.user.middleName : ''}
                          ${item.user.lastName}`,
                      }))}
                      component={ReduxSelectField}
                      onChange={(event, value) => setSelectedLpa(value)}
                      disabled={submitting || !!paymentForm}
                      searchable
                    />
                  </div>
                  {selectedLpa ? (
                    <>
                      {/* amount */}
                      <div className="PaymentsForm__step-2-form-amount">
                        <Field
                          name="amount"
                          type="number"
                          textId="justAmount"
                          validate={[required()]}
                          component={ReduxTextField}
                          disabled={submitting || !!paymentForm}
                          onChange={calculateReceiveAmount}
                        />
                      </div>

                      {/* currency */}
                      <div className="PaymentsForm__step-2-form-currency">
                        <Field
                          name="currency"
                          textId="justCurrency"
                          validate={required()}
                          options={selectedLpa.currencies.map(item => ({
                            value: item,
                            label: item,
                          }))}
                          component={ReduxSelectField}
                          onChange={(event, value) => setSelectedCurrency(value)}
                          disabled={submitting || !!paymentForm}
                        />
                      </div>
                      <div className="PaymentsForm__step-2-payment-info">
                        <div className="PaymentsForm__step-2-payment-info-item">
                          {!isDeposit ? (
                            <>
                              <div className="PaymentsForm__step-2-payment-info-item-title">
                                <FormattedMessage id="justCommission">{txt => txt}</FormattedMessage>
                              </div>
                              <div className="PaymentsForm__step-2-payment-info-item-commission">{lpaPercent}</div>
                            </>
                          ) : (
                            <>
                              <div className="PaymentsForm__step-2-payment-info-item-title">
                                <FormattedMessage id="justLPA">{txt => txt}</FormattedMessage>
                              </div>
                              <div className="PaymentsForm__step-2-payment-info-item-commission">
                                <span>{`${selectedLpa.user.firstName}
                                ${selectedLpa.user.middleName ? selectedLpa.user.middleName : ''}
                                ${selectedLpa.user.lastName}`}</span>
                                <span>{selectedLpa.user.email}</span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="PaymentsForm__step-2-payment-info-item">
                          <div className="PaymentsForm__step-2-payment-info-item-description">
                            <FormattedMessage id="justDescription">{txt => txt}</FormattedMessage>
                          </div>
                          <div className="PaymentsForm__step-2-payment-info-item-desc">
                            {isDeposit
                              ? selectedLpa.depositDescription
                                ? selectedLpa.depositDescription
                                : '-'
                              : selectedLpa.withdrawDescription
                              ? selectedLpa.withdrawDescription
                              : '-'}
                          </div>
                        </div>
                        <div class="PaymentsForm__step-2-form-info-line-break" />
                        <div className="PaymentsForm__step-2-payment-info-item-receive">
                          {calculatedReceiveAmount && selectedCurrency && !isDeposit ? (
                            <div class="PaymentsForm__step-2-form-info-line-1">
                              <div class="PaymentsForm__step-2-form-info-title-1">
                                <FormattedMessage id="clientSendAmount">{txt => txt}</FormattedMessage>
                              </div>
                              <div class="PaymentsForm__step-2-form-info-text-1">
                                {`${calculatedReceiveAmount} ${selectedCurrency}`}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {paymentForm && (
                  <div className="PaymentsForm__step-2-form-right">
                    <div className="PaymentsForm__step-2-form-info-line-1">
                      <div className="PaymentsForm__step-2-form-info-title-1">
                        <FormattedMessage id="clientYouWillGet">{txt => txt}</FormattedMessage>
                      </div>
                      <div className="PaymentsForm__step-2-form-info-text-1">
                        {paymentForm.request.amountWithCommission.amount}
                        {` `}
                        {paymentForm.request.amountWithCommission.currency}
                      </div>
                    </div>
                    <div className="PaymentsForm__step-2-form-info-line-2">
                      <div className="PaymentsForm__step-2-form-info-title-2">
                        <FormattedMessage id="clientCurrentConversionCourse">{txt => txt}</FormattedMessage>:
                      </div>
                      <div className="PaymentsForm__step-2-form-info-text-2">{selectedSystemInfo.commission}</div>
                    </div>
                    <div className="PaymentsForm__step-2-form-info-line-3">
                      <div className="PaymentsForm__step-2-form-info-line-3-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="10" cy="10" r="9.5" stroke="#0AA1E2" />
                          <path
                            d="M11.052 7.902C11.052 8.67667 11.024 9.386 10.968 10.03C10.912 10.674 10.842 11.3227 10.758 11.976H9.554C9.46067 11.3227 9.386 10.674 9.33 10.03C9.274 9.37667 9.246 8.66733 9.246 7.902V5.298H11.052V7.902ZM11.262 14.076C11.262 14.4027 11.1547 14.6687 10.94 14.874C10.7253 15.0793 10.4593 15.182 10.142 15.182C9.834 15.182 9.568 15.0793 9.344 14.874C9.12933 14.6687 9.022 14.4027 9.022 14.076C9.022 13.7493 9.12933 13.4833 9.344 13.278C9.568 13.0633 9.834 12.956 10.142 12.956C10.4593 12.956 10.7253 13.0633 10.94 13.278C11.1547 13.4833 11.262 13.7493 11.262 14.076Z"
                            fill="#0AA1E2"
                          />
                        </svg>
                      </div>
                      <div className="PaymentsForm__step-2-form-info-line-3-text">
                        <FormattedMessage id="clientWarningAmountTranslation">{txt => txt}</FormattedMessage>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* step-2-submit-button */}
              {!paymentForm && (
                <div className="PaymentsForm__step-2-form-submit">
                  <DefaultButton
                    type="submit"
                    textId={submitText}
                    disabled={invalid || pristine || submitting}
                    loading={submitting}
                    filled
                  />
                </div>
              )}
            </form>
          ) : (
            <>
              {/* radio-wallet-account */}
              {addons.includes('walletAccount') && (
                <div className="PaymentsForm__step-2-radio-wrap">
                  {/* eslint-disable */}
                  {/* radio-wallet */}
                  {/* eslint-disable */}
                  <div
                    className="PaymentsForm__step-2-radio"
                    onClick={() => {
                      setTypeAccount('wallet');
                      getOperation('wallet');
                      handleResetPaymentForm();
                      setOpenStep3(false);
                      setDisabledStep3(true);
                    }}>
                    {/* eslint-enable */}
                    <FormattedMessage id="boWallet">
                      {txt => (
                        <>
                          <img src={typeAccount === 'wallet' ? iconRadioActive : iconRadio} alt="icon-radio" />
                          {txt}
                        </>
                      )}
                    </FormattedMessage>
                  </div>

                  {/* radio-account */}
                  {/* eslint-disable */}
                  <div
                    className="PaymentsForm__step-2-radio"
                    onClick={() => {
                      setTypeAccount('account');
                      getOperation('account');
                      handleResetPaymentForm();
                      setOpenStep3(false);
                      setDisabledStep3(true);
                    }}>
                    {/* eslint-enable */}
                    <FormattedMessage id="justAccount">
                      {txt => (
                        <>
                          <img src={typeAccount === 'account' ? iconRadioActive : iconRadio} alt="icon-radio" />
                          {txt}
                        </>
                      )}
                    </FormattedMessage>
                  </div>
                  {/* eslint-enable */}
                </div>
              )}

              <form onSubmit={submit}>
                <div className="PaymentsForm__step-2-form-subwrap">
                  <div
                    className={classNames('PaymentsForm__step-2-form-left', {
                      'PaymentsForm__step-2-form-left--right-empty': !paymentForm,
                    })}>
                    {/* wallet */}
                    {typeAccount === 'wallet' && addons.includes('walletAccount') && (
                      <div className="PaymentsForm__step-2-form-wallet wallet-balance PaymentsForm__wallet-balance">
                        <FormattedMessage id="justWalletBalance">
                          {txt => <span className="PaymentsForm__wallet-caption">{txt}:</span>}
                        </FormattedMessage>
                        <span className="PaymentsForm__wallet-amount">{profile.walletBalance} USD</span>
                      </div>
                    )}

                    {/* account */}
                    {typeAccount === 'account' && (
                      <div className="PaymentsForm__step-2-form-account">
                        <Field
                          name="account"
                          textId="chooseAccount"
                          validate={required()}
                          options={accountsOptions}
                          component={ReduxSelectField}
                          onChange={(event, value) => loadAccount(value)}
                          disabled={submitting || !!paymentForm}
                          searchable
                        />
                      </div>
                    )}

                    {/* amount */}
                    <div className="PaymentsForm__step-2-form-amount">
                      <Field
                        name="amount"
                        type="number"
                        textId="justAmount"
                        validate={[required()]}
                        component={ReduxTextField}
                        disabled={submitting || !!paymentForm}
                      />
                    </div>

                    {/* currency */}
                    <div className="PaymentsForm__step-2-form-currency">
                      <Field
                        name="currency"
                        textId="justCurrency"
                        validate={required()}
                        options={selectedSystemInfo.currencies.map(item => ({
                          value: item,
                          label: item,
                        }))}
                        component={ReduxSelectField}
                        disabled={submitting || !!paymentForm}
                      />
                    </div>
                  </div>

                  {paymentForm && (
                    <div className="PaymentsForm__step-2-form-right">
                      <div className="PaymentsForm__step-2-form-info-line-1">
                        <div className="PaymentsForm__step-2-form-info-title-1">
                          <FormattedMessage id="clientYouWillGet">{txt => txt}</FormattedMessage>
                        </div>
                        <div className="PaymentsForm__step-2-form-info-text-1">
                          {paymentForm.request.amountWithCommission.amount}
                          {` `}
                          {paymentForm.request.amountWithCommission.currency}
                        </div>
                      </div>
                      <div className="PaymentsForm__step-2-form-info-line-2">
                        <div className="PaymentsForm__step-2-form-info-title-2">
                          <FormattedMessage id="clientCurrentConversionCourse">{txt => txt}</FormattedMessage>:
                        </div>
                        <div className="PaymentsForm__step-2-form-info-text-2">{selectedSystemInfo.commission}</div>
                      </div>
                      <div className="PaymentsForm__step-2-form-info-line-3">
                        <div className="PaymentsForm__step-2-form-info-line-3-icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="9.5" stroke="#0AA1E2" />
                            <path
                              d="M11.052 7.902C11.052 8.67667 11.024 9.386 10.968 10.03C10.912 10.674 10.842 11.3227 10.758 11.976H9.554C9.46067 11.3227 9.386 10.674 9.33 10.03C9.274 9.37667 9.246 8.66733 9.246 7.902V5.298H11.052V7.902ZM11.262 14.076C11.262 14.4027 11.1547 14.6687 10.94 14.874C10.7253 15.0793 10.4593 15.182 10.142 15.182C9.834 15.182 9.568 15.0793 9.344 14.874C9.12933 14.6687 9.022 14.4027 9.022 14.076C9.022 13.7493 9.12933 13.4833 9.344 13.278C9.568 13.0633 9.834 12.956 10.142 12.956C10.4593 12.956 10.7253 13.0633 10.94 13.278C11.1547 13.4833 11.262 13.7493 11.262 14.076Z"
                              fill="#0AA1E2"
                            />
                          </svg>
                        </div>
                        <div className="PaymentsForm__step-2-form-info-line-3-text">
                          <FormattedMessage id="clientWarningAmountTranslation">{txt => txt}</FormattedMessage>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* step-2-submit-button */}
                {!paymentForm && (
                  <div className="PaymentsForm__step-2-form-submit">
                    <DefaultButton
                      type="submit"
                      textId="clientContinue"
                      disabled={invalid || pristine || submitting}
                      loading={submitting}
                      filled
                    />
                  </div>
                )}

                {/* step-2-continue-step-3 */}
                {paymentForm && !isNullForm && (
                  <div className="PaymentsForm__step-2-continue">
                    <DefaultButton
                      type="button"
                      textId="clientContinue"
                      disabled={invalid || pristine || submitting}
                      loading={submitting}
                      filled
                      onClick={() => {
                        setOpenStep2(false);
                        setOpenStep3(true);
                        setDisabledStep3(false);
                      }}
                    />
                  </div>
                )}

                {/* finist-step-3 */}
                {paymentForm && isNullForm && (
                  <div className="PaymentsForm__step-2-step-3">
                    <SecondPaymentsForm
                      paymentForm={paymentForm}
                      onResetForm={() => {
                        handleResetPaymentForm();
                        setOpenStep3(false);
                        setDisabledStep3(true);
                        setOpenStep2(true);
                      }}
                      addons={addons}
                      history={history}
                      isNullForm={isNullForm}
                    />
                  </div>
                )}
              </form>
            </>
          )}
        </div>
      </div>

      {/* step-3 */}
      {!isNullForm && (
        <div
          className={classNames('PaymentsForm__step-3', {
            'PaymentsForm__step-3--open': openStep3,
            'PaymentsForm__step-3--disabled': disabledStep3,
          })}>
          {/* eslint-disable */}
          <div
            className="PaymentsForm__step-3-wrap-title"
            onClick={() => {
              if (disabledStep3) return;
              setOpenStep3(!openStep3);
            }}>
            {/* eslint-enable */}
            <div className="PaymentsForm__step-3-number">
              <span>3</span>
            </div>
            <div className="PaymentsForm__step-3-title">
              <FormattedMessage id="clientEnterAdditionalInformation">{txt => txt}</FormattedMessage>
            </div>
            <div
              className={classNames('PaymentsForm__step-3-arrow', {
                'PaymentsForm__step-3-arrow--hide': !openStep3,
              })}>
              <img src={arrowDown} alt="arrow-down" />
            </div>
          </div>

          {openStep3 && (
            <div className="PaymentsForm__step-3-wrap">
              {/* payment-will-get */}

              {accountValue && currencyValue && amountValue && (
                <FormSection name="agreements">
                  <Agreements agreements={selectedSystemInfo.agreements} submitting={submitting} />
                </FormSection>
              )}

              {/* finish-step-3 */}
              {paymentForm && (
                <div className="PaymentsForm__step-3-second-form">
                  <SecondPaymentsForm
                    paymentForm={paymentForm}
                    onResetForm={() => {
                      handleResetPaymentForm();
                      setOpenStep3(false);
                      setDisabledStep3(true);
                      setOpenStep2(true);
                    }}
                    addons={addons}
                    history={history}
                    isNullForm={isNullForm}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <BasicModal
        captionId="justConfirmationMessageSent"
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      />

      <style jsx global>
        {dynamicStyles}
      </style>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

PaymentsForm.propTypes = {
  loadAccount: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  // captionId: PropTypes.string.isRequired,
  accounts: PropTypes.array,
  addons: PropTypes.array.isRequired,
  paymentSystemList: PropTypes.array.isRequired,
  availableLpasList: PropTypes.array.isRequired,
  openStepLPA: PropTypes.bool.isRequired,
  setOpenStepLPA: PropTypes.func.isRequired,
  resetPaymentForm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  baseValue: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  accountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  history: PropTypes.object.isRequired,
  currencyValue: PropTypes.string,
  paymentForm: PropTypes.object,
  amountValue: PropTypes.number,
  theme: PropTypes.object,
};

PaymentsForm.defaultProps = {
  accounts: [],
  accountValue: null,
  currencyValue: null,
  amountValue: null,
  paymentForm: null,
  theme: {},
};

const selector = formValueSelector('payments-form');

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      accounts: state.client.common.accounts,
      accountValue: selector(state, 'account') || null,
      currencyValue: selector(state, 'currency') || null,
      amountValue: selector(state, 'amount') || null,
      addons: state.interfaceConfig.addons,
      baseValue: state.interfaceConfig.baseValue,
      profile: state.user.profile,
      paymentForm: state.client.payments.paymentForm,
    }),
    {
      resetPaymentForm: () => resetPaymentForm.request(),
      makePaymentLpa: () => makePaymentLpa.request(),
    }
  ),
  reduxForm({
    form: 'payments-form',
    onSubmitSuccess: (result, dispatch, { history, addons }) => {
      const properties = result?.requisitesForm ?? {};
      const isNullForm = Object.keys(properties).length ? properties.widget === 'nullform' : true;
      if (result.agent) {
        history.push(`/localpayments/${result.id}`);
      }
      if (result.request.status === 'waiting_verification' && isNullForm) {
        setTimeout(() => {
          history.push(
            addons.includes('walletAccount') ? `/wallet/dw/${result.request.id}` : `/payments/dw/${result.request.id}`
          );
        }, 3000);
      }
    },
  })
)(PaymentsForm);
export { PaymentsForm };
