/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';

import { ReduxSelectField, DefaultButton } from 'common/components';
import FXCAUnlimitedLeverage from 'client/_components/FXCAUnlimitedLeverage';

import { changeTradingAccountLeverage } from '../../_redux';

import { staticStyles } from './style';

const AccountLeverageChangeForm = ({
  accountId,
  options,
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  dispatch,
}) => {
  const submit = handleSubmit(values => changeTradingAccountLeverage({ ...values, accountId }, dispatch));

  return (
    <form onSubmit={submit} className="AccountLeverageChangeForm">
      <Field
        component={ReduxSelectField}
        name="leverage"
        maxMenuHeight={155}
        textId="justLeverage"
        // FXCA
        options={
          options &&
          options.map(option => ({
            value: option,
            label: FXCAUnlimitedLeverage({ leverage: option }),
          }))
        }
        validate={[required()]}
        disabled={submitting}
        searchable
      />
      <div className="AccountLeverageChangeForm__button-inner">
        <DefaultButton
          textId="changeLeverage"
          type="submit"
          disabled={invalid || pristine || submitting}
          loading={submitting}
          filled
        />
      </div>
      {error && <strong className="formError">{error}</strong>}
      <style jsx>{staticStyles}</style>
    </form>
  );
};

AccountLeverageChangeForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  options: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

AccountLeverageChangeForm.defaultProps = {
  options: [],
  error: '',
};

export default reduxForm({ form: 'change-account-leverage-form' })(AccountLeverageChangeForm);
export { AccountLeverageChangeForm };
