/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';

import { EditIcon } from 'common/icons';
import { ActionsButton, Tooltip } from 'common/components';
import FXCAUnlimitedLeverage from 'client/_components/FXCAUnlimitedLeverage';

import { staticStyles } from './style';

const LeverageCell = ({ loading, leverage, onLeverageChangeClick, isBlocked }) => (
  <div className="LeverageCell">
    {!loading ? (
      <div>
        {/* FXCA */}
        {leverage ? (
          <div className="LeverageCell__inner">
            <p>{leverage == 2000000000 ? FXCAUnlimitedLeverage({ leverage }) : leverage}</p>
            <Tooltip id="clientChangeLeverageTooltip" textId={isBlocked ? 'clientChangeLeverageBlockedAccount' : ''}>
              <ActionsButton edit onClickFunc={onLeverageChangeClick} disabled={isBlocked}>
                <EditIcon />
              </ActionsButton>
            </Tooltip>
          </div>
        ) : (
          <div className="LeverageCell__inner">—</div>
        )}
      </div>
    ) : (
      '...'
    )}
    <style jsx>{staticStyles}</style>
  </div>
);

LeverageCell.propTypes = {
  loading: PropTypes.bool.isRequired,
  leverage: PropTypes.number,
  onLeverageChangeClick: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool,
};

LeverageCell.defaultProps = {
  leverage: null,
  isBlocked: false,
};

export default LeverageCell;
