import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.NewCRMTable__Cell--Tbody) {
    border-top: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9;
  }

  :global(.LastCommentCell) {
    width: 100%;
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  :global(.NewCRMTable__Cell--Tbody) {
    &:hover {
      transition: none;
      background: #ebf7fd;
      border: 1px solid #78b8d6;
      border: none;
    }
  }

  :global(.NewCRMTable__wrapper) {
    @include respond-to(xl) {
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #0aa1e2;
      }
    }
  }

  :global(.NewCRMTable) {
    @include respond-to(xl) {
      width: 1539px;
    }
  }

  :global(.PageContentPreloader) {
    padding: 20px 30px 0 11px;
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  :global(th) {
  }

  :global(.checkboxWrapper) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  :global(.CheckBox) {
    appearance: auto;
    opacity: 1;
    width: auto;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    /* max-width: 30px !important;
    min-width: 30px !important; */
  }
  :global(.ClientsTable) {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    :global(.PageContentPreloader) {
      padding: 0;
    }
  }
  :global(.ClientsTable__LastSeenHeaderWrapper) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  :global(.ClientsTable__LastSeenTitle) {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #0f0f0f;
  }
  :global(.ClientsTable__LastSeenInfo) {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #696969;
  }
  :global(.ClientsTable__CustomMoneyHeaderCellTitle) {
    :global(span) {
      font-weight: 700 !important;
      font-size: 12px !important;
      line-height: 14px !important;
      color: #0f0f0f;
    }
  }
  :global(.ClientsTable__CustomMoneyHeaderCellInfo) {
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #696969;
  }
  :global(.ClientsTable__CustomMoneyHeaderCellWrapper) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  :global(.NewCRMTable__Head-Row),
  :global(.NewCRMTable__Body-Row) {
    display: grid;
    grid-template-columns: 40px 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 2.5fr;
  }

  :global(.NewCRMPagination__left) {
    margin-left: 9px;
  }
`;

const getDynamicStyles = state => {
  return css``;
};

export { staticStyles, getDynamicStyles };
