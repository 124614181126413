import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseStaticSelect {
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;

    &__OpenOptionsButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      border: 1px solid #0aa1e2;
      border-radius: 3px;
      height: 100%;
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: #0aa1e2;
      &:disabled {
        color: #e9e9e9;
        border-color: #e9e9e9;
        > svg > path {
          fill: #e9e9e9;
        }
      }
    }

    &__OptionMulti--Selected {
      background: rgba(10, 161, 226, 0.4);
    }

    &__OpenOptionsButton--FastFilterClosed {
      border: 1px solid #e7e7e7;
      color: #e7e7e7;
      border-color: #e7e7e7;
      > svg > path {
        fill: #e7e7e7;
      }
    }
    &__OpenOptionsButton--FastFilter {
      justify-content: flex-end;
    }
    &__Options {
      height: 273px;
      width: 100%;
      top: 100%;
      position: absolute;
      z-index: 4;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      overflow-x: hidden;
      overflow-y: scroll;
    }
    &__Option {
      padding-left: 12px;
      display: flex;
      align-items: center;
      height: 30px;
      position: relative;
      &:hover {
        background: #ceecf9;
      }
    }

    &__Option--FastFilter {
      height: 34px;
      min-height: 34px !important;
    }
  }
`;

export { staticStyles };
