import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global(.CommentCell) {
    width: 100%;
  }

  :global(.CommentCell__Retry) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
  }

  :global(.CommentCell__Comment) {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    white-space: normal;
  }

  :global(.CommentCell__CommentedBy) {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
