import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TasksMonitoringPage {
    width: 100%;
    padding: 25px 25px 25px 25px;

    @include respond-to(md) {
      padding: 15px 15px 0 15px;
    }

    &__back {
      display: flex;
      cursor: pointer;
      align-items: center;
      margin: 0 0 20px 0;
    }

    &__back-icon {
      margin: 0 10px 0 0;
    }

    &__back-text {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #0aa1e2;
    }

    &__manager-name {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin: 0 0 20px 0;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
