import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormMessageHOC } from 'common/HOCs';
import { withTheme } from 'common/styling/theme';

import { reduxForm, Field } from 'redux-form';

import { updateCrmTask } from 'crm/tasks/_redux/';

import { BaseButton } from 'crm/_components';
import { TextFormField, DateFormField } from 'crm/_components/forms/';
import { getManagerList } from 'crm/users/api';
import BaseAsyncSelectField from 'crm/_components/forms/fields/BaseAsyncSelectField';
import { ActionsButton } from 'common/components';
import classNames from 'classnames';
import SuccessIcon from './newImages/success.svg';
import NotSuccessIcon from './newImages/notsuccess.svg';
import BadClientIcon from './newImages/badclient.svg';
import { staticStyles } from './style';

const EditTaskForm = ({ id, managerReaction, subject, type, handleSubmit, submitting, invalid, dispatch, error }) => {
  const [selectedReaction, setSelectedReaction] = useState(managerReaction);
  const [depositAmount, setDepositAmount] = useState('');
  const [comment, setComment] = useState('');
  const [description, setDescription] = useState('');

  const handleSelectReaction = reaction => {
    setSelectedReaction(reaction);
  };
  const submit = handleSubmit(values => {
    const plannedDeposit = values.plannedDepositAmount
      ? { plannedDepositAmount: { amount: Number(values.plannedDepositAmount), currency: 'USD' } }
      : {};
    const data = {
      ...values,
      icon: type,
      id,
      page: 'tasks',
      subject,
      dateRange: undefined,
      assignedTo: values.assignedTo.value,
      managerReaction: selectedReaction,
      ...plannedDeposit,
    };
    updateCrmTask(data, dispatch);
  });

  const yearsOptions = [...Array(10).keys()].map(i => ({
    value: moment().year() + i,
    label: moment().year() + i,
  }));

  // const platformOptions = [
  //   { value: PLATFORMS.TELEGRAM, label: 'Telegram' },
  //   { value: PLATFORMS.VIBER, label: 'Viber' },
  //   { value: PLATFORMS.MESSENGER, label: 'Messenger' },
  //   { value: PLATFORMS.WHATSAPP, label: 'Whatsapp' },
  //   { value: PLATFORMS.WECHAT, label: 'WeChat' },
  //   { value: PLATFORMS.EMAIL, label: 'Email' },
  // ];

  const loadOptions = search =>
    getManagerList(search).then(({ data }) =>
      data.map(manager => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
    );

  return (
    <form className="EditTaskForm" onSubmit={submit}>
      <div className="EditTaskForm__line">
        <Field
          labelTextId="justDescription"
          component={TextFormField}
          name="description"
          placeholder="Task Description"
          onChange={value => setDescription(value)}
          value={description}
        />
      </div>
      <div className="CreateTaskForm__line">
        <Field
          labelTextId="crmPlannedDepositAmount"
          type="number"
          component={TextFormField}
          name="plannedDepositAmount"
          placeholder="USD"
          onChange={value => setDepositAmount(value)}
          value={depositAmount}
        />
      </div>
      <div className="EditTaskForm__line">
        <Field
          searchPromptTextId="Start typing..."
          labelTextId="crmJustAssignToManager"
          component={BaseAsyncSelectField}
          name="assignedTo"
          loadOptions={loadOptions}
          disabled={submitting}
          maxMenuHeight={150}
        />
      </div>
      <div className="EditTaskForm__line">
        <Field
          component={DateFormField}
          type="date"
          name="expirationDate"
          label="Expiration date"
          placeholder="dd.mm.yyyy"
          disabled={submitting}
          yearsOptions={yearsOptions}
          areFutureDaysAvailable
          withTime
        />
      </div>
      <div className="EditTaskForm__line">
        <Field
          labelTextId="justManagerComment"
          component={TextFormField}
          name="comment"
          placeholder="Manager Comment"
          onChange={value => setComment(value)}
          value={comment}
        />
      </div>
      <div className="EditTaskForm__Reaction">
        <ActionsButton
          className={classNames('EditTaskForm__Reaction-button', {
            active: selectedReaction === 'Success',
          })}
          onClickFunc={() => handleSelectReaction('Success')}>
          <img src={SuccessIcon} alt="success" /> Success
        </ActionsButton>
        <ActionsButton
          className={classNames('EditTaskForm__Reaction-button', {
            active: selectedReaction === 'Not Success',
          })}
          onClickFunc={() => handleSelectReaction('Not Success')}>
          <img src={NotSuccessIcon} alt="not success" /> Not Success
        </ActionsButton>
        <ActionsButton
          className={classNames('EditTaskForm__Reaction-button', {
            active: selectedReaction === 'Bad Client',
          })}
          onClickFunc={() => handleSelectReaction('Bad Client')}>
          <img src={BadClientIcon} alt="bad client" /> Bad client
        </ActionsButton>
      </div>
      {error && <strong className="EditTaskForm__formError">{error}</strong>}
      <div className="EditTaskForm__submit">
        <BaseButton type="submit" textId="justSave" disabled={invalid || submitting} />
      </div>
      <style jsx>{staticStyles}</style>
    </form>
  );
};

EditTaskForm.propTypes = {
  type: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

EditTaskForm.defaultProps = {
  error: '',
};

export default compose(
  withTheme(),
  FormMessageHOC,
  withRouter,
  reduxForm({
    form: 'crm-edit-task-form',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage('crmTaskWasSuccessfullyEdited');
      props.onRequestClose();
    },
  })
)(EditTaskForm);
