import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from '../actions';

const initialState = {
  /* filters */
  isTaskStatusChanging: false,

  fastFilters: {},
  fastFiltersPending: false,
  fastFiltersError: null,

  filtersList: [],
  filtersListPending: false,
  filtersListError: null,

  filtersOptions: [],
  filtersOptionsPending: false,
  filtersOptionsError: null,

  createCustomFilterPending: false,
  createCustomFilterError: null,
  filterCreated: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_REASSIGN_TASK_STATUS[REQUEST]:
      return {
        ...state,
        isTaskStatusChanging: true,
      };

    case ActionTypes.CHANGE_REASSIGN_TASK_STATUS[SUCCESS]:
      return {
        ...state,
        isTaskStatusChanging: false,
      };

    case ActionTypes.CHANGE_REASSIGN_TASK_STATUS[FAILURE]:
      return {
        ...state,
        isTaskStatusChanging: false,
      };

    case ActionTypes.GET_CRM_FILTERS_LIST[REQUEST]:
      return {
        ...state,
        filtersListPending: true,
      };

    case ActionTypes.GET_CRM_FILTERS_LIST[SUCCESS]:
      return {
        ...state,
        filtersList: action.data,
        filtersListPending: false,
      };

    case ActionTypes.GET_CRM_FILTERS_LIST[FAILURE]:
      return {
        ...state,
        filtersListError: action.error,
      };

    case ActionTypes.GET_CRM_FAST_FILTERS[REQUEST]:
      return {
        ...state,
        fastFiltersPending: true,
      };

    case ActionTypes.GET_CRM_FAST_FILTERS[SUCCESS]:
      return {
        ...state,
        fastFilters: action.data,
        fastFiltersPending: false,
      };

    case ActionTypes.GET_CRM_FAST_FILTERS[FAILURE]:
      return {
        ...state,
        fastFiltersError: action.error,
      };

    case ActionTypes.GET_CRM_FILTERS_OPTIONS[REQUEST]:
      return {
        ...state,
        filtersOptionsPending: true,
      };

    case ActionTypes.GET_CRM_FILTERS_OPTIONS[SUCCESS]:
      return {
        ...state,
        filtersOptions: action.data,
        filtersOptionsPending: false,
      };

    case ActionTypes.GET_CRM_FILTERS_OPTIONS[FAILURE]:
      return {
        ...state,
        filtersOptionsError: action.error,
      };

    case ActionTypes.createCRMCustomFilter[REQUEST]:
      return {
        ...state,
        createCustomFilterPending: true,
      };

    case ActionTypes.createCRMCustomFilter[SUCCESS]:
      return {
        ...state,
        filterCreated: action.bool,
        createCustomFilterPending: false,
      };

    case ActionTypes.createCRMCustomFilter[FAILURE]:
      return {
        ...state,
        createCustomFilterError: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
