import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  dealStats: {},
  dealStatsAreLoaded: false,

  convertationOptions: [],
  convertationOptionsAreLoaded: false,

  lpaAgreements: [],
  lpaAgreementsAreLoaded: false,

  createdAccount: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DEAL_STATS[REQUEST]:
      return { ...state, dealStatsAreLoaded: false };
    case ActionTypes.GET_DEAL_STATS[SUCCESS]:
      return { ...state, dealStats: action.data, dealStatsAreLoaded: true };
    case ActionTypes.GET_DEAL_STATS[FAILURE]:
      return { ...state, error: action.error, dealStatsAreLoaded: false };

    case ActionTypes.GET_CONVERTATION_OPTIONS[REQUEST]:
      return { ...state, convertationOptionsAreLoaded: false };
    case ActionTypes.GET_CONVERTATION_OPTIONS[SUCCESS]:
      return { ...state, convertationOptions: action.data, convertationOptionsAreLoaded: true };
    case ActionTypes.GET_CONVERTATION_OPTIONS[FAILURE]:
      return { ...state, error: action.error, convertationOptionsAreLoaded: false };

    case ActionTypes.GET_LPA_AGREEMENTS[REQUEST]:
      return { ...state, lpaAgreementsAreLoaded: false };
    case ActionTypes.GET_LPA_AGREEMENTS[SUCCESS]:
      return { ...state, lpaAgreements: action.data.agreements, lpaAgreementsAreLoaded: true };
    case ActionTypes.GET_LPA_AGREEMENTS[FAILURE]:
      return { ...state, error: action.error, lpaAgreementsAreLoaded: false };

    case ActionTypes.GET_SUMSUB_EXTERNAL_LINK[REQUEST]:
      return { ...state, sumsubExternalLinkIsLoaded: false };
    case ActionTypes.GET_SUMSUB_EXTERNAL_LINK[SUCCESS]:
      return { ...state, sumsubExternalLink: action.data, sumsubExternalLinkIsLoaded: true };
    case ActionTypes.GET_SUMSUB_EXTERNAL_LINK[FAILURE]:
      return { ...state, error: action.error, sumsubExternalLinkIsLoaded: false };

    case ActionTypes.TRADING_ACCOUNT_IS_CREATED[SUCCESS]:
      return { ...state, createdAccount: action.data };

    default:
      return state;
  }
};

export default reducer;
