import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
    
    .PartnerAccountWithdrawalForm {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 180px;
      
      :global(.DefaultButton) {
        width: 140px;
      }
    }
  
`
export { staticStyles }
