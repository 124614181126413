import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';

import CrossIcon from './CrossIcon';
import { staticStyles, getDynamicStyles } from './style';

const BasicCRMModal = ({
  isOpen,
  caption,
  captionId,
  captionValues,
  description,
  descriptionId,
  alignTitleCenter,
  onRequestClose,
  children,
  error,
  overlayClassName,
  className,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const hasVScroll = document.body.scrollHeight > document.body.clientHeight;
  const isDeviceTouch = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  if (!hasVScroll) {
    document.body.classList.add('Body__no-scroll');
  } else {
    document.body.classList.remove('Body__no-scroll');
  }

  if (isDeviceTouch()) {
    document.body.classList.add('Body__touch-screen');
  } else {
    document.body.classList.remove('Body__touch-screen');
  }

  const basicCRMModalTitleClass = classNames('BasicCRMModal__title', {
    'BasicCRMModal__title--align-center': alignTitleCenter,
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={className} overlayClassName={overlayClassName}>
      <button className="ReactModal__close BasicModal__close" type="button" onClick={onRequestClose}>
        <CrossIcon />
      </button>
      <div
        className={classNames('ReactModal__inner BasicModal__inner', {
          'BasicModal__inner-error': error,
        })}>
        <h2 className={basicCRMModalTitleClass}>
          {caption || <FormattedMessage id={captionId} values={captionValues} />}
        </h2>
        {Array.isArray(description) ? (
          description.map((desc, ind) => <p key={`basic-modal-line-error-${ind}`}>{desc}</p>)
        ) : (
          <p>{description || (descriptionId && <FormattedMessage id={descriptionId} />)}</p>
        )}
        {children}
      </div>
      {/* </Scrollbars> */}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </Modal>
  );
};

BasicCRMModal.propTypes = {
  isOpen: PropTypes.bool,
  caption: PropTypes.string,
  captionId: PropTypes.string,
  captionValues: PropTypes.object,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  descriptionId: PropTypes.string,
  alignTitleCenter: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  error: PropTypes.bool,
  overlayClassName: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.object,
};

BasicCRMModal.defaultProps = {
  isOpen: false,
  caption: '',
  captionId: '',
  captionValues: {},
  description: '',
  descriptionId: '',
  alignTitleCenter: false,
  children: false,
  error: false,
  overlayClassName: '',
  className: '',
  theme: {},
};

export default withTheme()(BasicCRMModal);
export { BasicCRMModal };
