/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage as FM } from 'react-intl';
import queryString from 'query-string';
import { PageContentPreloader, MoneyCell, DateCell } from 'common/components';
import { NewCRMPagination, NewCRMTable } from 'crm/_newComponents';
import { getCrmClientsAnalytics } from 'crm/analytics/_redux';

import { staticStyles } from './style';

const ClientsTable = ({ clients, date, clientsAreLoaded, getCrmClientsAnalytics, location }) => {
  const query = useMemo(() => ({ page_size: 10, page: 1, ...queryString.parse(location.search) }), [location.search]);
  const history = useHistory();

  useEffect(() => {
    getCrmClientsAnalytics({ search: query });
  }, [query, date]);

  const columns = [
    {
      accessor: 'id',
      Header: 'justId',
      isSortable: true,
      blueBg: true,
      Cell: ({ rowData: { id } }) => id,
    },
    {
      accessor: 'fullName',
      Header: 'justFullName',
      isSortable: true,
      blueBg: true,
      Cell: ({ rowData: { id, fullName } }) => (
        <button onClick={() => history.push(`/crm/contacts/${id}`)}>{fullName}</button>
      ),
    },
    {
      accessor: 'lastSeen',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ClientsTable__LastSeenHeaderWrapper">
          <FM id="justLastSeen">{txt => <span className="ClientsTable__LastSeenTitle">{txt}</span>}</FM>
          <FM id="justSortBy">{txt => <span className="ClientsTable__LastSeenInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { lastSeen } }) => (lastSeen ? <DateCell value={lastSeen} hideTime /> : '---'),
    },
    {
      accessor: 'totalIn',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ClientsTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell className="ClientsTable__CustomMoneyHeaderCellTitle" heightMaxContent value={clients.totalIn} />
          <FM id="amountIn">{txt => <span className="ClientsTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalIn } }) => <MoneyCell crmStyle value={totalIn} />,
    },
    {
      accessor: 'totalOut',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ClientsTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell className="ClientsTable__CustomMoneyHeaderCellTitle" heightMaxContent value={clients.totalOut} />
          <FM id="amountOut">{txt => <span className="ClientsTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalOut } }) => <MoneyCell crmStyle value={totalOut} />,
    },
    {
      accessor: 'totalDiff',
      isSortable: true,
      blueBg: true,
      Header: () => (
        <div className="ClientsTable__CustomMoneyHeaderCellWrapper">
          <MoneyCell className="ClientsTable__CustomMoneyHeaderCellTitle" heightMaxContent value={clients.totalDiff} />
          <FM id="amountInOut">{txt => <span className="ClientsTable__CustomMoneyHeaderCellInfo">{txt}</span>}</FM>
        </div>
      ),
      Cell: ({ rowData: { totalDiff } }) => <MoneyCell crmStyle value={totalDiff} />,
    },
    {
      Header: 'justBalance',
      isSortable: true,
      Cell: ({ rowData: { balance } }) => <MoneyCell crmStyle value={balance} />,
    },
    {
      Header: 'justIbPartner',
      isSortable: true,
      Cell: ({ rowData: { ibPartner } }) => ibPartner ?? '一',
    },
    {
      Header: 'justIbPartnerStatus',
      isSortable: true,
      Cell: ({ rowData: { ibPartnerStatus } }) => ibPartnerStatus ?? '一',
    },
    {
      Header: 'justLastComment',
      Cell: ({ rowData: { lastComment } }) => <span className="LastCommentCell">{lastComment ?? '—'}</span>,
    },
  ];

  return (
    <div className="ClientsTable">
      <PageContentPreloader ready={clientsAreLoaded} firstLaunchOnly type="accounts">
        {/* temporaly full data due backend not sending count, and otehrs */}
        <NewCRMTable data={clients.results ? clients.results : clients} columns={columns} />
        {!!clients.count && <NewCRMPagination count={clients.count} />}
      </PageContentPreloader>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

ClientsTable.propTypes = {
  clients: PropTypes.object.isRequired,
  clientsAreLoaded: PropTypes.bool.isRequired,
  getCrmClientsAnalytics: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      clients: state.crm.analytics.clients,
      clientsAreLoaded: state.crm.analytics.clientsAreLoaded,
    }),
    {
      getCrmClientsAnalytics: search => getCrmClientsAnalytics.request(search),
    }
  )
)(ClientsTable);
