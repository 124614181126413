/* eslint-disable eqeqeq */
import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { BaseButton, Tabs, CRMModals, BasicCRMModal } from 'crm/_components';
import { TabAccounts, TabInOut, TabLogs, TabFeed, TabNotes, ContactNoteCreateForm } from 'crm/users/_components/';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';
import { getCrmManagers } from 'crm/tasks/_redux';

import {
  getUserContacts,
  getContactFavorites,
  getCrmUserData,
  getCrmUserAccounts,
  getSalesStatuses,
} from 'crm/users/_redux';

import { withTheme } from 'common/styling/theme';
// import { StatusPlate } from 'client/statuses/_components';
// import { StatusCell } from 'common/components';
import CRMContactsModals from 'crm/_components/CRMContactsModals';
import Tag from 'crm/users/_components/Tag';

import { CRMSelect, AccountsInfoBlock, ContactsInfoBlock, ClientInfoBlock } from '../../_components';

import { setContactFavorite, deleteContactFavorite } from '../../_redux';

import iconDoc from './images/iconDoc.svg';
import iconEdit from './images/iconEdit.svg';
// import iconTitle from './images/iconTitle.svg';
import IconFavorite from './images/IconFavorite.svg';
import IconFavoriteFilled from './images/IconFavoriteFilled.svg';

import { staticStyles, getDynamicStyles } from './style';

const ClientPage = ({ theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showSelect, setShowSelect] = useState(false);
  const [crmModalType, setCrmModalType] = useState(null);
  const [userEditModalType, setUserEditModalType] = useState('');

  const { userData, userDataIsLoaded } = useSelector(state => state.crm.users);

  const profile = useSelector(state => state.user.profile);

  const { favorites } = useSelector(state => state.crm.contacts);
  const noGeonamesFormat = true;

  useEffect(() => {
    dispatch(getCrmUserData.request(id, noGeonamesFormat));
    dispatch(getCrmUserAccounts.request(id));
    dispatch(getCrmManagers.request());
    dispatch(getUserContacts.request({ search: {} }));
    dispatch(getContactFavorites.request());
    dispatch(getSalesStatuses.request());
  }, [dispatch, id]);

  const isFavorite = useMemo(() => favorites.find(favorite => favorite.id == id), [favorites]);
  const [showAllTags, setShowAllTags] = useState(false);
  const toggleShowAllTags = () => {
    setShowAllTags(!showAllTags);
  };

  const openCreateNoteModal = () => {
    setIsCreateNoteModalOpen(true);
  };

  const closeCreateNoteModal = () => {
    setIsCreateNoteModalOpen(false);
  };

  return (
    <div className="ClientPage">
      {/* left */}
      <div className="ClientPage__left">
        {/* left => name */}
        <div className="ClientPage__left-name-wrap">
          <button
            type="button"
            onClick={() => {
              if (isFavorite) {
                dispatch(deleteContactFavorite.request(id));
              } else {
                dispatch(setContactFavorite.request(id));
              }
            }}>
            <img
              className="ClientPage__left-name-icon"
              src={isFavorite ? IconFavoriteFilled : IconFavorite}
              alt="Favorite"
            />
          </button>

          <div className="ClientPage__left-name">{userDataIsLoaded && userData.fullName}</div>
          <button type="button" onClick={() => setCrmModalType('editContactName')}>
            <img className="ClientPage__left-name-edit" src={iconEdit} alt="edit" />
          </button>
        </div>

        {/* left => status stage */}
        {userDataIsLoaded ? (
          <>
            <div className="ClientPage__left-status-stage-wrap">
              <div className="ClientPage__left-status-stage-title">
                <FM id="crmSalesStatus" />
              </div>

              <div className="ClientPage__left-status-stage">{userData?.status?.title ?? '----'}</div>
              <button type="button" onClick={() => setUserEditModalType('editSalesStatus')}>
                <img className="ClientPage__left-managers-edit" src={iconEdit} alt="edit" />
              </button>
            </div>

            {/* left => title */}
            {/* <div className="ClientPage__left-status-wrap">
              {userData.currentStatus ? (
                <StatusPlate
                  reverse
                  title={userData.currentStatus.title}
                  level={userData.currentStatus.level}
                  statusColor={userData.currentStatus.statusColor}
                />
              ) : (
                <StatusCell statusCode="none" />
              )}
            </div> */}
          </>
        ) : (
          <div className="ClientPage__left-status-plug" />
        )}

        {/* left => tags */}
        <div className="ClientPage__left-tags-wrap">
          <BaseButton
            className="ClientPage__left-button-create-tag"
            textId="crmAddTag"
            onClick={() => setCrmModalType('addTag')}
          />
          <div
            className={classNames('Tags', {
              'Tags--collapsed': !showAllTags && userData?.tags?.length > 10,
            })}>
            {userDataIsLoaded &&
              userData.tags.map(item => <Tag key={item.id} name={item.name} id={item.id} contactId={id} />)}
          </div>
          {userData.tags?.length > 10 ? (
            <button className="Tags__button" onClick={toggleShowAllTags}>
              {showAllTags ? 'hide' : 'show more'}
            </button>
          ) : null}
        </div>

        {/* left => managers */}

        <div className="ClientPage__left-managers-wrap">
          <div className="ClientPage__left-managers-item">
            <div className="ClientPage__left-managers-title">Manager</div>
            <div className="ClientPage__left-managers-name">{userDataIsLoaded && (userData.manager || '----')}</div>
            {(profile.perms?.editManager || profile.isAdmin) && (
              <button type="button" onClick={() => setCrmModalType('editManagerCode')}>
                <img className="ClientPage__left-managers-edit" src={iconEdit} alt="edit" />
              </button>
            )}
          </div>

          {userData.userId && (
            <>
              <div className="ClientPage__left-managers-item">
                <div className="ClientPage__left-managers-title">
                  <FM id="justIBmanager" />
                </div>

                <div className="ClientPage__left-managers-name">{userData.ibManager ?? '----'}</div>
                {(profile.perms?.editManager || profile.isAdmin) && (
                  <button type="button" onClick={() => setCrmModalType('editIBManagerCode')}>
                    <img className="ClientPage__left-managers-edit" src={iconEdit} alt="edit" />
                  </button>
                )}
              </div>

              <div className="ClientPage__left-managers-item">
                <div className="ClientPage__left-managers-title">
                  <FM id="justPartnerCode" />
                </div>

                <div className="ClientPage__left-managers-name">
                  {userData.partnerAccount ? userData.partnerAccount : <FM id="crmNoPartnerCode" />}
                </div>
                <button type="button" onClick={() => setCrmModalType('editPartnerCode')}>
                  <img className="ClientPage__left-managers-edit" src={iconEdit} alt="edit" />
                </button>
              </div>

              <div className="ClientPage__left-managers-item">
                <div className="ClientPage__left-managers-title">
                  <FM id="justPartnerId" />
                </div>

                <div className="ClientPage__left-managers-name">
                  {userData.partnerId ? userData.partnerId : <FM id="crmNoPartnerId" />}
                </div>
              </div>
            </>
          )}
        </div>

        {/* left => contacts */}
        <ContactsInfoBlock setCrmModalType={setUserEditModalType} />

        {/* left => accounts sum */}
        <AccountsInfoBlock />

        {/* left => client info */}
        <ClientInfoBlock setCrmModalType={setUserEditModalType} />
      </div>

      {/* right */}
      <div className="ClientPage__right">
        <div className="ClientPage__right-top-menu">
          <button type="button" className="ClientPage__right-button-doc" onClick={openCreateNoteModal}>
            <img src={iconDoc} alt="doc" />
          </button>
          <div id="ClientPage__right-button-and-select-wrap" className="ClientPage__right-button-and-select-wrap">
            <BaseButton
              className="ClientPage__right-button-create-task"
              textId="crmCreateTask"
              onClick={() => setShowSelect(!showSelect)}
              plus
              fill
            />
            {showSelect && <CRMSelect closeShowSelect={() => setShowSelect(false)} />}
          </div>
        </div>
        <Tabs externalClass="ClientPage__right-tabs" tabSlugActive="feed">
          <TabFeed tabSlug="feed" tabTextId="crmFeed" />
          <TabNotes tabSlug="notes" tabTextId="crmNotes" />
          <TabAccounts tabSlug="accounts" tabTextId="crmAccounts" />
          {userData.userId && <TabInOut tabSlug="inOut" tabTextId="crmInOut" />}
          <TabLogs tabSlug="logs" tabTextId="crmLogs" />
          {/* <TabCalls tabSlug="calls" tabTextId="crmCalls" /> */}
          {/* <TabSupport tabSlug="support" tabTextId="crmSupport" /> */}
        </Tabs>
      </div>

      <CRMModals modalType={crmModalType} isOpen={crmModalType} onRequestClose={() => setCrmModalType(null)} />
      <CRMContactsModals modalType={userEditModalType} onRequestClose={() => setUserEditModalType(null)} />

      <BasicCRMModal captionId="crmAddingNewNote" isOpen={isCreateNoteModalOpen} onRequestClose={closeCreateNoteModal}>
        <ContactNoteCreateForm
          reloadFeed
          onRequestClose={closeCreateNoteModal}
          onSubmitSuccess={closeCreateNoteModal}
        />
      </BasicCRMModal>

      <style jsx>{staticStyles}</style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

ClientPage.propTypes = {
  theme: PropTypes.object,
};

ClientPage.defaultProps = {
  theme: {},
};

export default withTheme()(ClientPage);
export { ClientPage };
