import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from 'redux-config/reduxHelpers';

export const GET_TRADING_ACCOUNT_TYPES = createRequestTypes('GET_TRADING_ACCOUNT_TYPES');
export const getTradingAccountTypes = {
  request: () => action(GET_TRADING_ACCOUNT_TYPES[REQUEST]),
  success: accountTypes => action(GET_TRADING_ACCOUNT_TYPES[SUCCESS], { accountTypes }),
  failure: error => action(GET_TRADING_ACCOUNT_TYPES[FAILURE], { error }),
};

export const GET_TRADING_ACCOUNT_LIST = createRequestTypes('GET_TRADING_ACCOUNT_LIST');
export const getTradingAccountList = {
  request: data => action(GET_TRADING_ACCOUNT_LIST[REQUEST], data),
  success: accounts => action(GET_TRADING_ACCOUNT_LIST[SUCCESS], { accounts }),
  failure: error => action(GET_TRADING_ACCOUNT_LIST[FAILURE], { error }),
};

export const GET_ACCOUNTS_PREFETCH = createRequestTypes('GET_ACCOUNTS_PREFETCH');
export const getAccountsPrefetch = {
  request: () => action(GET_ACCOUNTS_PREFETCH[REQUEST]),
  success: accounts => action(GET_ACCOUNTS_PREFETCH[SUCCESS], { accounts }),
  failure: error => action(GET_ACCOUNTS_PREFETCH[FAILURE], { error }),
};

export const GET_TRADING_ACCOUNT = createRequestTypes('GET_TRADING_ACCOUNT');
export const getTradingAccount = {
  request: id => action(GET_TRADING_ACCOUNT[REQUEST], { id }),
  success: data => action(GET_TRADING_ACCOUNT[SUCCESS], { data }),
  failure: error => action(GET_TRADING_ACCOUNT[FAILURE], { error }),
};

export const GET_TRADING_ACCOUNT_OPERATIONS = createRequestTypes('GET_TRADING_ACCOUNT_OPERATIONS');
export const getTradingAccountOperations = {
  request: (id, status, startDate, endDate) =>
    action(GET_TRADING_ACCOUNT_OPERATIONS[REQUEST], {
      id,
      status,
      startDate,
      endDate,
    }),
  success: data => action(GET_TRADING_ACCOUNT_OPERATIONS[SUCCESS], { data }),
  failure: error => action(GET_TRADING_ACCOUNT_OPERATIONS[FAILURE], { error }),
};

export const GET_PARTNER_ACCOUNTS = createRequestTypes('GET_PARTNER_ACCOUNTS');
export const getPartnerAccounts = {
  request: () => action(GET_PARTNER_ACCOUNTS[REQUEST]),
  success: data => action(GET_PARTNER_ACCOUNTS[SUCCESS], { data }),
  failure: error => action(GET_PARTNER_ACCOUNTS[FAILURE], { error }),
};

export const GET_PARTNER_ACCOUNT = createRequestTypes('GET_PARTNER_ACCOUNT');
export const getPartnerAccount = {
  request: accountId => action(GET_PARTNER_ACCOUNT[REQUEST], { accountId }),
  success: (accountId, data) => action(GET_PARTNER_ACCOUNT[SUCCESS], { accountId, data }),
  failure: (accountId, error) => action(GET_PARTNER_ACCOUNT[FAILURE], { accountId, error }),
};

export const GET_PARTNER_ACCOUNT_COMMISSIONS = createRequestTypes('GET_PARTNER_ACCOUNT_COMMISSIONS');
export const getPartnerAccountCommissions = {
  request: data => action(GET_PARTNER_ACCOUNT_COMMISSIONS[REQUEST], data),
  success: data => action(GET_PARTNER_ACCOUNT_COMMISSIONS[SUCCESS], { data }),
  failure: error => action(GET_PARTNER_ACCOUNT_COMMISSIONS[FAILURE], { error }),
};

export const GET_MT_SERVERS_ACTION = createRequestTypes('GET_MT_SERVERS_ACTION');
export const getMTServersAction = {
  request: () => action(GET_MT_SERVERS_ACTION[REQUEST]),
  success: data => action(GET_MT_SERVERS_ACTION[SUCCESS], { data }),
  failure: error => action(GET_MT_SERVERS_ACTION[FAILURE], { error }),
};

export const SET_REDIRECT = 'SET_REDIRECT';
export const setRedirect = () => action(SET_REDIRECT);
