import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .IntroPageLayout {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .IntroPage {
    position: relative;
    background: #f0f4f6;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 32px;
    &__BeforeYouStartTitle {
      grid-column: 1/3;
      display: flex;
      align-items: flex-end;
      font-family: 'Ubuntu';
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 42px;
      color: #0c0c3d;

      @include respond-to(md) {
        grid-column: initial;
        justify-content: center;
      }
    }
    &__BlocksContainer {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-template-rows: max-content 1fr;
      gap: 32px;
      padding-right: 5px;
      padding-left: 5px;
      @include respond-to(md) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    }
    .IntroPageBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 32px;
      gap: 32px;

      background: white;
      width: 100%;
      height: 100%;

      &__MessagesContainer {
        display: flex;
        flex-direction: column;
        max-width: 380px;
        gap: 10px;
        height: max-content;
      }
      &:last-child {
        @include respond-to(md) {
          grid-row: 3/4;
        }
      }
      &__Message--Red {
        position: relative;
        color: red;
      }
    }
  }
`;

export { staticStyles };
