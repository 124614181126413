import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DefaultButton, PaginationTable } from 'common/components';
import { withTheme } from 'common/styling/theme';
import { FormattedMessage } from 'react-intl';
import SmallAreaChart from '../SmallAreaChart';

import { REWARD_TYPES_TR_KEYS } from '../../consts';

import { staticStyles, getDynamicStyles } from './style';

const drawTradersFee = fee => (REWARD_TYPES_TR_KEYS[fee] ? <FormattedMessage id={REWARD_TYPES_TR_KEYS[fee]} /> : '一');

const getFixedNumber = (num, decimal) => Number(Number(num).toFixed(decimal));
const toPercentage = num => (!Number.isNaN(num) ? `${getFixedNumber(num * 100, 2)}%` : '一');

const InvestRatingTable = ({
  data,
  onRowClick,
  toggleInvestModal,
  loading,
  getList,
  location,
  history,
  params,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleInvest = (event, ...values) => {
    event.stopPropagation();

    toggleInvestModal(...values);
  };
  const justInvestNew =
    document.location.hostname === 'my.tixee.com' ||
    document.location.hostname === 'portal.disona.com' ||
    document.location.hostname === 'portal.rock-west.com'
      ? 'justInvestTixeeShort'
      : 'justInvest';

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'justStrategy',
        Cell: ({
          row: {
            original: { title, symbols },
          },
        }) => (
          <div className="InvestRatingTable__strategy">
            <span className="InvestRatingTable__strategy-caption">{title}</span>
            <span className="InvestRatingTable__strategy-symbols">{symbols}</span>
          </div>
        ),
        isPreviewCell: true,
      },
      {
        accessor: 'monthly_yield',
        Header: 'investIncomeMonthlyAndTotal',
        Cell: ({
          row: {
            original: { monthlyYield, totalYield },
          },
        }) => (
          <div className="InvestRatingTable__yield">
            <span className="InvestRatingTable__monthlyYield">{toPercentage(monthlyYield)}/</span>
            <span className="InvestRatingTable__totalYield">{toPercentage(totalYield)}</span>
          </div>
        ),
        isPreviewCell: true,
      },
      {
        accessor: 'strategysettings__rollovers',
        Header: 'investBalanceOperations',
        Cell: ({
          row: {
            original: { balanceOperation },
          },
        }) => <FormattedMessage id={balanceOperation ? 'investRollover' : 'investAuto'} />,
      },
      {
        accessor: 'fee',
        Header: 'investTradersFee',
        Cell: ({
          row: {
            original: { fee },
          },
        }) => drawTradersFee(fee),
      },
      {
        accessor: 'strategysettings__reward_percentage',
        Header: 'justFeeRate',
        Cell: ({
          row: {
            original: { feeRate },
          },
        }) => <span>{feeRate}%</span>,
      },
      {
        Header: 'justDynamics',
        Cell: ({
          row: {
            original: { chart },
          },
        }) => {
          const chartData = chart.length ? chart.map(item => Math.round(item * 100) || item * 100) : Array(10).fill(0);
          return <SmallAreaChart seriesData={chartData} categoriesData={[...Array(chartData.length).keys()]} />;
        },
      },
      {
        accessor: 'investors_number',
        Header:
          document.location.hostname === 'my.tixee.com' ||
          document.location.hostname === 'portal.disona.com' ||
          document.location.hostname === 'portal.rock-west.com'
            ? 'justInvestorsNumberTixee'
            : 'justInvestorsNumber',
        Cell: ({
          row: {
            original: { investorsNumber },
          },
        }) => <span>{investorsNumber ?? '一'}</span>,
      },
      {
        accessor: 'age',
        Header: 'ageAndWeeks',
        Cell: ({
          row: {
            original: { age },
          },
        }) => <span>{age}</span>,
      },
      {
        Header: () => null,
        id: 'actions',
        Cell: ({
          row: {
            original: {
              id,
              rollovers,
              takeprofitStoplossInRollovers,
              currency,
              accountId,
              settings: { minInvestmentAmount },
            },
          },
        }) => (
          <DefaultButton
            textId={justInvestNew}
            onClick={e =>
              handleInvest(e, id, rollovers, takeprofitStoplossInRollovers, currency, accountId, minInvestmentAmount)
            }
            filled
          />
        ),
      },
    ],
    []
  );
  /* eslint-enable */

  const rowProps = (state, rowInfo) => ({
    onClick: () => onRowClick(rowInfo.original.id),
  });

  return (
    <div className="InvestRatingTable">
      <PaginationTable
        {...props}
        data={data}
        columns={columns}
        getTrProps={rowProps}
        onRowClick={onRowClick}
        loading={loading}
        getList={getList}
        location={location}
        history={history}
        params={params}
        isNewTable
      />
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

InvestRatingTable.propTypes = {
  data: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
  toggleInvestModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getList: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

InvestRatingTable.defaultProps = {
  theme: {},
};

export default withTheme()(InvestRatingTable);
