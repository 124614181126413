import axios from 'axios';

export const getCommissionsList = search =>
  axios.get(`/backoffice/partnership/systems/commissions/`, { params: { ...search } });

export const editCommission = (commissionId, amount) =>
  axios.put(`/backoffice/partnership/systems/commissions/${commissionId}/`, { amount }, { validateStatus: false });

export const payCommissions = payload => axios.post(`/backoffice/partnership/systems/commissions/pay/`, { ...payload });

export const rejectRewards = payload =>
  axios.post(`/backoffice/partnership/systems/commissions/reject/`, { ...payload });

export const getRewardFrequencies = () => axios.get(`/backoffice/partnership/systems/account/freq_of_payments/`);

export const boGetPartnershipRequests = search =>
  axios.get('/backoffice/partnership/requests/', { params: { ...search } });

export const boGetPartnershipRequest = requestId => axios.get(`/backoffice/partnership/requests/${requestId}`);
