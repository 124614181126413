import css from 'styled-jsx/css';

import { isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .GetClientButton {
    display: flex;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    border-right: 1px solid rgba(255, 255, 255, 0.4);

    &__button {
      position: relative;
      display: inline-block;
      cursor: pointer;
      font-size: rem(15px);
      line-height: rem(22px);
      font-weight: 500;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      letter-spacing: rem(0.5px);
      padding-left: rem(${isRTL ? '30px' : '40px'});
      padding-right: rem(${isRTL ? '40px' : '30px'});
      border: none !important;
      background: transparent !important;
      min-width: auto;

      &:active {
        box-shadow: none;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        width: rem(10px);
        height: rem(2px);
        top: 51%;
        transform: translateY(-51%);
        left: 20px;
      }

      &:after {
        transform: rotate(-90deg);
        top: 49%;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .GetClientButton {
      &:hover {
        .GetClientButton__button {
          background: rgba(255, 255, 255, 0.3) !important;
        }
      }
      &__button {
        color: ${colors.white.hex};

        &::after,
        &::before {
          background: ${colors.white.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
