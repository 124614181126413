import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import BaseStaticSelect from '../BaseStaticSelect';

import { staticStyles } from './style';

const InputIntegerChoice = ({ name, multi, options, onSelect, isNew, withInput, onInputChange }) => {
  const modifedOptions = useMemo(() => {
    const result = options.map(({ name, value, isSelected }) => ({ label: name, value, isSelected }));
    return result;
  });

  return (
    <div className="InputIntegerChoice">
      <FM id={name}>{txt => <span className="InputIntegerChoice__Title">{txt}</span>}</FM>
      <BaseStaticSelect
        onInputChange={onInputChange}
        withInput={withInput}
        labelTextId={name}
        multi={multi}
        fastFilter
        options={modifedOptions}
        onSelect={onSelect}
        isNew={isNew}
      />
      <style jsx>{staticStyles}</style>
    </div>
  );
};

InputIntegerChoice.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  multi: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};

InputIntegerChoice.defaultProps = {
  multi: false,
  isNew: false,
};

export default InputIntegerChoice;
