/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { FormattedMessage as FM } from 'react-intl';
import useOutsideClick from 'hooks/useOutsideClick';
import { staticStyles } from './style';

const DropDown = ({ isOpen, position, isNew, options, onSelect, setIsOpen, multi }) => {
  if (!isOpen) return null;
  const ref = useRef(null);

  const handleOutSideClick = () => {
    setIsOpen(false);
  };

  useOutsideClick(ref, handleOutSideClick);

  const portalStyle = {
    position: 'absolute',
    top: position.top + position.height,
    left: position.left,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    zIndex: 9999,
    width: '200px',
  };

  const OldComponent = () => (
    <div className="BaseStaticSelect__Options">
      {options.map(({ label, value, isSelected }) => (
        <button
          key={`BaseStaticSelect-options-${label}`}
          className={classNames('BaseStaticSelect__Option BaseStaticSelect__Option--FastFilter', {
            'BaseStaticSelect__OptionMulti--Selected': isSelected,
          })}
          type="button"
          onClick={() => {
            onSelect(value);
            if (!multi || value === 'All') {
              setIsOpen(!isOpen);
            }
          }}>
          <FM id={label}>{txt => txt}</FM>
        </button>
      ))}
    </div>
  );

  const NewComponent = () => (
    <div className="BaseStaticSelect__NewOptions">
      {options.map(({ label, value, isSelected }) => (
        <button
          key={`BaseStaticSelect-options-New${label + value}`}
          className={classNames('BaseStaticSelect__NewOption', {
            'BaseStaticSelect__NewOption--isSelected': isSelected,
          })}
          type="button"
          onClick={() => {
            onSelect(value);
            if (!multi || value === 'All') {
              setIsOpen(!isOpen);
            }
          }}>
          <FM id={label}>{txt => txt}</FM>
        </button>
      ))}
    </div>
  );

  let ComponentItself = null;

  ComponentItself = isNew ? NewComponent : OldComponent;

  return ReactDOM.createPortal(
    <div ref={ref} style={portalStyle} className="congratulations-portal">
      <ComponentItself />
      <style jsx>{staticStyles}</style>
    </div>,
    document.body
  );
};

export default DropDown;
