import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TabAccounts {
    .text-row {
      height: 77px !important;
      margin-bottom: 25px;
    }
    &__list {
    }

    &__item {
      display: flex;
      margin: 0 0 25px 0;
      padding: 0 0 8px 0;
      border-bottom: 1px solid rgba(105, 105, 105, 0.5);

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__item-icon {
      width: 28px;
      min-width: 28px;
      height: 28px;
      min-height: 28px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 10px 0 0;
      font-weight: 500;
      transform: translateY(-5px);

      &--wallet {
        background: rgba(10, 161, 226, 0.65);
        color: #fff;
      }

      &--mt5 {
        background: rgba(233, 233, 233, 0.65);
        color: #696969;
        font-size: 11px;
      }

      &--ramm {
        background: rgba(233, 233, 233, 0.65);
        color: #696969;
      }

      &--cfh {
        background: rgba(233, 233, 233, 0.65);
        color: #696969;
      }

      &--mt4 {
        background: rgba(233, 233, 233, 0.65);
        color: #696969;
        font-size: 11px;
      }
    }

    &__item-info {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include respond-to(xs) {
        flex-direction: column;
      }
    }

    &__item-info-subwrap {
      @include respond-to(xs) {
        order: 2;
      }
    }

    &__item-info-title {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.2px;
      margin: 0 0 5px 0;
    }

    &__item-info-balance {
      display: flex;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      margin: 0 0 5px 0;

      :global(.MoneyCell__inner) {
        height: auto;
        margin-left: 3px;
      }
    }

    &__item-info-bonus {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      margin: 0 0 5px 0;
    }

    &__item-info-history {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #0aa1e2;
      cursor: pointer;
    }

    &__item-info-date {
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);

      @include respond-to(xs) {
        order: 1;
      }
    }

    &__no-data {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin: 0 0 10px 0;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .TabAccounts {
      &__no-data {
        span {
          color: ${colors.gray.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
