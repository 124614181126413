import React from 'react';

const CrmAnalyticsIconActive = () => (
  <div>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0272 3.387L13.935 0.087C13.8814 0.0317413 13.8099 0.000592286 13.7354 0H6.70758C6.48412 0.000712227 6.27 0.0957621 6.11199 0.264391C5.95398 0.433019 5.86491 0.661524 5.86424 0.9V2.715C4.35004 2.79074 2.92135 3.48599 1.87486 4.65635C0.828375 5.82673 0.244507 7.38228 0.244507 9C0.244507 10.6177 0.828375 12.1733 1.87486 13.3436C2.92135 14.514 4.35004 15.2093 5.86424 15.285V17.1C5.86491 17.3385 5.95398 17.567 6.11199 17.7356C6.27 17.9042 6.48412 17.9993 6.70758 18H16.2654C16.4889 17.9993 16.703 17.9042 16.861 17.7356C17.019 17.567 17.1081 17.3385 17.1088 17.1V3.6C17.1082 3.52047 17.079 3.44421 17.0272 3.387ZM14.0165 1.023L16.1502 3.3H14.2976C14.2231 3.3 14.1516 3.26839 14.0988 3.21213C14.0461 3.15587 14.0165 3.07957 14.0165 3V1.023ZM0.804216 9C0.806047 7.54089 1.33151 6.13794 2.27261 5.07948C3.21372 4.02103 4.49897 3.38747 5.86424 3.309V8.877L2.18167 12.807C1.29518 11.7648 0.804128 10.4076 0.804216 9ZM2.57804 13.23L6.26061 9.3H11.4781C11.4269 10.375 11.0909 11.4124 10.5093 12.2917C9.92773 13.1711 9.12429 13.8563 8.19233 14.2679C7.26037 14.6794 6.23813 14.8003 5.24434 14.6166C4.25055 14.4329 3.32599 13.9521 2.57804 13.23ZM16.5465 17.1C16.5465 17.1796 16.5169 17.2559 16.4642 17.3121C16.4115 17.3684 16.34 17.4 16.2654 17.4H6.70758C6.63303 17.4 6.56153 17.3684 6.50881 17.3121C6.45609 17.2559 6.42647 17.1796 6.42647 17.1V15.285C7.94015 15.207 9.36784 14.5111 10.4143 13.3413C11.4607 12.1715 12.0459 10.6173 12.0487 9C12.0487 8.92043 12.0191 8.84413 11.9664 8.78787C11.9137 8.73161 11.8422 8.7 11.7676 8.7H6.42647V0.9C6.42647 0.820435 6.45609 0.744129 6.50881 0.687868C6.56153 0.631607 6.63303 0.6 6.70758 0.6H13.4543V3C13.455 3.23848 13.544 3.46698 13.702 3.63561C13.86 3.80424 14.0742 3.89929 14.2976 3.9H16.5465V17.1Z"
        fill="white"
      />
      <path
        d="M7.26976 1.5C7.1952 1.5 7.1237 1.53161 7.07098 1.58787C7.01826 1.64413 6.98865 1.72044 6.98865 1.8V7.80001C6.98865 7.87957 7.01826 7.95588 7.07098 8.01214C7.1237 8.0684 7.1952 8.10001 7.26976 8.10001H12.892C12.9666 8.10001 13.0381 8.0684 13.0908 8.01214C13.1435 7.95588 13.1731 7.87957 13.1731 7.80001C13.1714 6.1297 12.5489 4.52833 11.4422 3.34725C10.3355 2.16616 8.8349 1.50183 7.26976 1.5ZM7.55087 7.50001V2.109C8.86769 2.18446 10.1119 2.77666 11.0444 3.77178C11.9768 4.7669 12.5318 6.09472 12.6025 7.50001H7.55087Z"
        fill="white"
      />
      <path
        d="M13.4543 11.3999C12.9539 11.3999 12.4648 11.5583 12.0487 11.8549C11.6327 12.1516 11.3084 12.5733 11.1169 13.0667C10.9254 13.56 10.8753 14.1029 10.9729 14.6267C11.0706 15.1504 11.3115 15.6315 11.6653 16.0091C12.0192 16.3867 12.47 16.6439 12.9607 16.748C13.4515 16.8522 13.9602 16.7987 14.4225 16.5944C14.8848 16.39 15.28 16.044 15.558 15.5999C15.836 15.1559 15.9843 14.6339 15.9843 14.0999C15.9833 13.3842 15.7164 12.6981 15.2422 12.1919C14.7679 11.6858 14.125 11.401 13.4543 11.3999ZM13.4543 16.1999C13.0651 16.1999 12.6847 16.0767 12.3611 15.846C12.0375 15.6152 11.7853 15.2873 11.6363 14.9035C11.4874 14.5198 11.4484 14.0976 11.5244 13.6902C11.6003 13.2829 11.7877 12.9087 12.0629 12.615C12.3381 12.3213 12.6887 12.1213 13.0704 12.0403C13.4521 11.9592 13.8478 12.0008 14.2074 12.1598C14.5669 12.3187 14.8743 12.5879 15.0905 12.9332C15.3067 13.2786 15.4221 13.6846 15.4221 14.0999C15.4215 14.6567 15.214 15.1904 14.8451 15.5841C14.4762 15.9778 13.976 16.1993 13.4543 16.1999Z"
        fill="white"
      />
      <path
        d="M12.8921 14.5757L12.2475 13.8878L11.85 14.312L12.6933 15.212C12.746 15.2682 12.8175 15.2998 12.8921 15.2998C12.9666 15.2998 13.0381 15.2682 13.0908 15.212L14.7775 13.412L14.38 12.9878L12.8921 14.5757Z"
        fill="white"
      />
      <path d="M13.7354 5.1001H15.9843V5.70009H13.7354V5.1001Z" fill="white" />
      <path d="M13.7354 6.8999H15.9843V7.49991H13.7354V6.8999Z" fill="white" />
      <path d="M13.7354 8.7002H15.9843V9.3002H13.7354V8.7002Z" fill="white" />
    </svg>
  </div>
);

export default CrmAnalyticsIconActive;
