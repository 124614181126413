import css from 'styled-jsx/css';

const staticStyles = css.global`
  .TagItem {
    padding: 3px 13.5px;
    background: #343846;
    color: #fff;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    .TagItem__delete {
      display: none;
    }
    &:hover,
    &:focus {
      padding: 3px 6px;
      .TagItem__delete {
        display: block;
      }
    }
  }
`;

export { staticStyles };
