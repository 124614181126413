/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { staticStyles } from './style';

const FavoritItem = ({ id, fullName }) => {
  const [firstName, lastName] = fullName.length ? fullName.split(' ') : ['A', 'A'];

  return (
    <div className="FavoritItem">
      <NavLink className="FavoritItem__link" to={`/crm/contacts/${id}`}>
        {`${firstName[0]}${lastName ? lastName[0] : ''}`}
      </NavLink>

      <div className="FavoritItem__Tooltip">{`${firstName} ${lastName !== undefined ? lastName : ''}`}</div>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

FavoritItem.propTypes = {
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default FavoritItem;
