import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { isRTL } from 'common/styling/theme';
import { toggleMenu } from '../_redux/actions';

import TopMenu from '../TopMenu';
import LeftNavigation from '../LeftNavigation';

import { staticStyles } from './style';

const UserWrapper = ({
  profile,
  counters,
  interfaces,
  interfaceConfig,
  currentInterface,
  getMenu,
  isMenuOpen,
  onOverlayClick,
  children,
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const initMenu = async () => {
      const menuItems = await getMenu(profile, interfaceConfig, counters);
      setItems(menuItems);
    };
    initMenu();
  }, [getMenu, profile, interfaceConfig, counters]);

  return (
    <div className="UserWrapper__inner">
      {isMenuOpen && (
        <div
          role="presentation"
          className="UserWrapper__inner-overlay"
          onClick={onOverlayClick}
          onKeyPress={onOverlayClick}
        />
      )}
      <TopMenu
        interfaces={interfaces}
        availableLangs={interfaceConfig.availableLangs}
        currentInterface={currentInterface}
      />
      <div className={classNames('full-page-container', `full-page-container-${currentInterface.slug}`, { ar: isRTL })}>
        <LeftNavigation
          isMenuOpen={isMenuOpen}
          items={items}
          interfaces={interfaces}
          currentInterface={currentInterface}
          prefix={currentInterface.mainUrl}
        />
        {children}
      </div>
      <div className="UserWrapper__inner-hidden-element-with-profile-data" id="hidden-element-with-profile-data">
        {JSON.stringify(profile)}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

UserWrapper.propTypes = {
  profile: PropTypes.object.isRequired,
  counters: PropTypes.object.isRequired,
  interfaces: PropTypes.array.isRequired,
  currentInterface: PropTypes.object.isRequired,
  interfaceConfig: PropTypes.object.isRequired,
  getMenu: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  onOverlayClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(
  state => ({
    profile: state.user.profile,
    isMenuOpen: state.user.isMenuOpen,
    interfaceConfig: state.interfaceConfig,
    counters: state.backoffice.common.counters,
  }),
  { onOverlayClick: () => toggleMenu() }
)(UserWrapper);
export { UserWrapper };
