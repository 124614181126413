import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TextFormField {
    &__label {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      color: #0f0f0f;
      opacity: 0.5;
      margin: 0 0 4px 0;

      &--error {
        color: #ff4085;
      }
    }

    &__input {
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.2px;
      padding: 6px 9px 6px 9px;

      &::placeholder {
        color: #696969;
      }

      &--error {
        border: 1px solid #ff4085;
      }
    }

    &__error {
      font-weight: 300;
      font-size: 14px;
      color: #ff4085;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
