import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { rejectError } from 'common/_redux';

import BasicModal from '../modals/BasicModal';
import DefaultButton from '../DefaultButton';

const ErrorHandler = ({ children, rejectError, hasErrors, errorMessage }) => (
  <>
    {children}
    <BasicModal
      onRequestClose={rejectError}
      isOpen={hasErrors}
      captionId={typeof errorMessage === 'string' ? 'commonErrorModalTitle' : 'somethingWentWrongCaption'}
      description={typeof errorMessage === 'string' ? errorMessage : ''}
      descriptionId="somethingWentWrongDescription"
      overlayClassName="Modal_error-overlay">
      <DefaultButton textId="justClose" onClick={rejectError} />
    </BasicModal>
  </>
);

ErrorHandler.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
  rejectError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
};

ErrorHandler.defaultProps = {
  errorMessage: '',
};

export default connect(({ common: { errorMessage, hasErrors } }) => ({ errorMessage, hasErrors }), { rejectError })(
  ErrorHandler
);
export { ErrorHandler };
