import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { crmSetSelectedContactsIds } from '../../_redux';

const SelectCheckbox = ({ selectedContactsIds, contactId, contactsIds, header }) => {
  const dispatch = useDispatch();

  const areAllSelected = useMemo(
    () => contactsIds.length === selectedContactsIds.length,
    [contactsIds, selectedContactsIds]
  );

  const isContactSelected = useMemo(
    () => !header && selectedContactsIds.includes(contactId),
    [contactId, selectedContactsIds]
  );

  return (
    <div className="checkboxWrapper">
      <input
        onChange={() => {
          if (header) {
            if (selectedContactsIds.length) {
              dispatch(crmSetSelectedContactsIds([]));
            } else {
              dispatch(crmSetSelectedContactsIds([...contactsIds]));
            }
            return;
          }
          if (!isContactSelected) {
            dispatch(crmSetSelectedContactsIds([...selectedContactsIds, contactId]));
          } else {
            const selectedContactsWithoutThisUser = selectedContactsIds.filter(
              selectedContactId => selectedContactId !== contactId
            );
            dispatch(crmSetSelectedContactsIds(selectedContactsWithoutThisUser));
          }
        }}
        checked={isContactSelected || areAllSelected}
        className="CheckBox"
        type="checkbox"
      />
    </div>
  );
};

SelectCheckbox.propTypes = {
  contactId: PropTypes.number,
  // selectAll: PropTypes.bool,
  selectedContactsIds: PropTypes.array.isRequired,
  contactsIds: PropTypes.number,
  header: PropTypes.bool,
};

SelectCheckbox.defaultProps = {
  contactId: null,
  // selectAll: false,
  header: false,
  contactsIds: [],
};

export default connect(state => ({
  selectedContactsIds: state.crm.contacts.selectedContactsIds,
  contactsIds: state.crm.contacts.contacts?.results?.map(({ id }) => id),
}))(SelectCheckbox);
