import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .RegistrationForm {
    width: 100%;
    padding: 30px 0;
    text-align: center;
    display: flex;
    flex-direction: column;

    .DefaultButton {
      align-self: center;
    }

    span {
      display: block;
      text-align: left;
    }
    strong {
      display: block;
      text-align: left;
    }

    #telegramAuthWrapper {
      margin-top: 25px;
    }

    .disclamer {
      &__text {
        text-align: center;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
        &--noMG {
          margin-bottom: 0;
        }
      }
    }
  }

  .RegistrationForm__inputs-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    :global(.BaseTextField) {
      width: 48%;
      @include respond-to(sm) {
        width: 100%;
      }
    }
  }

  .PartnerCode__inner {
    margin-bottom: 30px;
    text-align: left;
    button {
      font-size: 14px;
      position: relative;
      padding-bottom: 2px;
      &:hover {
        &:after {
          transform: scale(0);
        }
      }
      &:active {
        transform: translateY(2px);
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        transition: all 0.4s ease;
        opacity: 0.3;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    .PartnerCode__inner {
      button {
        color: ${colors.secondary.hex};
        &:hover {
          color: ${colors.primary.hex};
          &:after {
            background-color: ${colors.primary.hex};
          }
        }
        &:after {
          background: ${colors.secondary.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
