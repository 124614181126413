import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/screen-size';

  .MoneyCell__inner {
    height: rem(45px);
    display: flex;
    align-items: center;

    :global(.Tooltip__text) {
      display: flex !important;
      align-items: center;
      flex-wrap: wrap;
    }

    :global(span) {
      font-size: rem(16px);
      line-height: 1;
      &:first-child {
        margin: 0 !important;
        color: #000;
      }
    }

    .currency {
      margin-left: 5px;
    }

    &.inTable {
      height: 40px;
      flex-wrap: wrap;
      @include respond-to(sm) {
        height: 30px;
        position: relative;
        bottom: 1px;
      }
      :global(span) {
        &:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.crmStyle {
      height: 100%;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      letter-spacing: 0.2px;
      :global(span) {
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0.2px;
      }
    }

    &.heightMaxContent {
      height: max-content;
    }

    &.success {
      span {
        color: #389700 !important;
      }
    }

    &.danger {
      span {
        color: #e33046 !important;
      }
    }
  }
`;

export { staticStyles };
