import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import EditTaskForm from 'crm/tasks/_components/EditTaskForm';
import CloseTaskForm from 'crm/tasks/_components/CloseTaskForm';
import classNames from 'classnames';
import { compose } from 'redux';
import { DateCell, MoneyCell, PageContentPreloader, ActionsButton } from 'common/components';
import PropTypes from 'prop-types';
import { NewCRMPagination, NewCRMTable } from 'crm/_newComponents';
import { EditIcon, StopIcon } from 'common/icons';
import { getAllCrmTasks } from 'crm/tasks/_redux';
import BasicCRMModal from 'crm/_components/BasicCRMModal';
import { staticStyles } from './style';

const AllTasksTable = ({ allTasks, allTasksAreLoaded, getAllCrmTasks, location }) => {
  const query = useMemo(() => ({ page_size: 10, ...queryString.parse(location.search) }), [location.search]);
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [isCLoseOpen, setIsCloseOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const plannedDepositAmountNumber =
    selectedTask && typeof plannedDepositAmount === 'number'
      ? Number(selectedTask?.plannedDepositAmount.replace(/[^0-9,.]/g, ' '))
      : null;

  useEffect(() => {
    getAllCrmTasks({ search: query });
  }, [query]);

  const columns = [
    {
      accessor: 'fullName',
      Header: 'crmFullName',
      isSortable: true,
      blueBg: true,
      // TODO: fullName
      Cell: ({ rowData: { id, fullName } }) => (
        <button onClick={() => history.push(`/crm/contacts/${id}`)}>{fullName}</button>
      ),
    },
    {
      accessor: 'plannedDepositAmount',
      Header: 'crmPlannedDepositAmount',
      isSortable: true,
      blueBg: true,
      // TODO: plannedDepositAmount
      Cell: ({ rowData: { plannedDepositAmount } }) => plannedDepositAmount ?? '一',
    },
    {
      accessor: 'balance',
      Header: 'crmBalance',
      isSortable: false,
      blueBg: true,
      // TODO: balance
      Cell: ({ rowData: { balance } }) => <MoneyCell value={balance} crmStyle />,
    },
    {
      accessor: 'assignedTo',
      Header: 'crmAssignedTo',
      isSortable: false,
      blueBg: true,
      // TODO: manager
      Cell: ({ rowData: { assignedTo } }) => assignedTo.fullName,
    },
    {
      accessor: 'expirationDate',
      Header: 'crmExpirationDate',
      isSortable: true,
      Cell: ({ rowData: { expirationDate } }) => (expirationDate ? <DateCell value={expirationDate} inLine /> : '---'),
    },
    {
      accessor: 'country',
      Header: 'crmCountry',
      isSortable: true,
      // TODO: country
      Cell: ({ rowData: { country } }) => country ?? '一',
    },
    {
      accessor: 'type',
      Header: 'crmType',
      isSortable: true,
      // TODO: monitoring
      Cell: ({ rowData: { type } }) => type,
    },
    {
      accessor: 'isOverdue',
      Header: 'crmIsOverdue',
      isSortable: false,
      // TODO:isOverdue
      Cell: ({ rowData: { isOverdue } }) => (isOverdue ? 'overdue' : '一'),
    },
    {
      accessor: 'status',
      Header: 'justStatus',
      isSortable: true,
      // TODO:isOverdue
      Cell: ({ rowData: { status } }) => (
        <span
          className={classNames('StatusCell', {
            'StatusCell--Open': status === 'OPEN',
            'StatusCell--Closed': status === 'CLOSED',
          })}>
          {status}
        </span>
      ),
    },

    {
      accessor: 'description',
      Header: 'crmComment',
      overflowText: true,
      isSortable: false,
      Cell: ({ rowData: { description } }) => description ?? '一',
    },
    {
      accessor: 'managerReaction',
      Header: 'crmManagerReaction',
      overflowText: true,
      isSortable: false,
      Cell: ({ rowData: { managerReaction } }) => managerReaction ?? '一',
    },
    {
      accessor: 'comment',
      Header: 'crmManagerComment',
      overflowText: true,
      isSortable: false,
      Cell: ({ rowData: { comment } }) => comment ?? '一',
    },
    {
      accessor: 'actions',
      Header: 'justActions',
      isSortable: false,
      // TODO: text
      Cell: ({ rowData }) => (
        <div className="actions">
          <ActionsButton
            deleteType
            disabled={rowData.status === 'CLOSED'}
            onClickFunc={() => {
              setSelectedTask(rowData);
              setIsCloseOpen(true);
            }}>
            <StopIcon />
          </ActionsButton>

          <ActionsButton
            edit
            disabled={rowData.status === 'CLOSED'}
            type={selectedTask?.type}
            onClickFunc={() => {
              setSelectedTask(rowData);
              setIsOpen(true);
            }}>
            <EditIcon />
          </ActionsButton>
        </div>
      ),
    },
  ];
  /* eslint-enable */

  return (
    <div className="AllTasksTable">
      <PageContentPreloader ready={allTasksAreLoaded} firstLaunchOnly type="accounts">
        <NewCRMTable data={allTasks.results} columns={columns} />
        {!!allTasks.count && <NewCRMPagination count={allTasks.count} />}
        <BasicCRMModal
          captionId="crmEditTask"
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
            setSelectedTask(null);
          }}>
          <EditTaskForm
            onRequestClose={() => {
              setIsOpen(false);
              setSelectedTask(null);
            }}
            initialValues={{
              ...selectedTask,
              plannedDepositAmount: plannedDepositAmountNumber,
              assignedTo: { value: selectedTask?.assignedTo?.id, label: selectedTask?.assignedTo?.fullName },
            }}
            {...selectedTask}
          />
        </BasicCRMModal>
        <BasicCRMModal
          captionId="crmCloseTask"
          isOpen={isCLoseOpen}
          onRequestClose={() => {
            setIsCloseOpen(false);
            setSelectedTask(null);
          }}>
          <CloseTaskForm
            onRequestClose={() => {
              setIsCloseOpen(false);
              setSelectedTask(null);
            }}
            {...selectedTask}
          />
        </BasicCRMModal>
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

AllTasksTable.propTypes = {
  allTasks: PropTypes.object.isRequired,
  allTasksAreLoaded: PropTypes.bool.isRequired,
  getAllCrmTasks: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  connect(
    state => ({
      allTasks: state.crm.tasks.allTasks,
      allTasksAreLoaded: state.crm.tasks.allTasksAreLoaded,
    }),
    {
      getAllCrmTasks: search => getAllCrmTasks.request(search),
    }
  )
)(AllTasksTable);
