import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserOperationsHistory {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 30px 25px);
    margin-bottom: rem(20px);
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
  }

  .UserOperationsHistory__info {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(10px);
    p {
      font-size: rem(14px);
      letter-spacing: 0.6px;
      display: inline;
      margin-left: rem(10px);
    }
  }
  .UserOperationsHistory__month-choose {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: rem(20px);
    .month-picker:first-of-type {
      margin-right: 15px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .UserOperationsHistory {
      background: ${colors.white.hex};
    }

    .UserOperationsHistory__info {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
