/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { FormattedMessage as FM } from 'react-intl';
import useOutsideClick from 'hooks/useOutsideClick';
import { Calendar } from 'common/components';
import { staticStyles } from './style';

const DropDown = ({
  optionsRightRef,
  isOpen,
  options,
  setIsOpen,
  position,
  isCalendarOutsideOfWindow,
  windowWidth,
  selectebleOptions,
  onSelect,
}) => {
  if (!isOpen || !Array.isArray(options)) return null;

  const handleOutSideClick = () => {
    setIsOpen(false);
  };

  useOutsideClick(optionsRightRef, handleOutSideClick);

  const portalStyle = {
    position: 'absolute',
    top: position.top + position.height,
    left: position.left,
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
    zIndex: 9999,
    width: '200px',
  };

  return ReactDOM.createPortal(
    <div style={portalStyle} className="congratulations-portal">
      <div
        ref={optionsRightRef}
        className={classNames('DateSelector__Options', {
          'DateSelector__Options--OutOfRange': isCalendarOutsideOfWindow && windowWidth >= 600,
          'DateSelector__Options--Mobile': windowWidth < 600,
        })}>
        <div className="DateSelector__OptionsLeft">
          {selectebleOptions.map(({ value, label, isSelected }) => (
            <button
              key={`DateSelector-options-${label}`}
              className={classNames('DateSelector__Option DateSelector__Option--FastFilter', {
                'DateSelector__Option--FastFilterIsSelected': isSelected,
              })}
              type="button"
              onClick={() => {
                onSelect({ value, label });
                setIsOpen(!isOpen);
              }}>
              <FM id={label}>{txt => txt}</FM>
            </button>
          ))}
        </div>
        <div className="DateSelector__OptionsRight">
          <FM id="justChooseCustomRange">{txt => <span className="DateSelector__OptionsRightTitle">{txt}</span>}</FM>
          <Calendar
            daysLimit={1000}
            isUnix={false}
            onChangeFunc={(...value) => {
              onSelect({ value: value.map(val => val.valueOf()), label: 'justCustomRange' });
              setIsOpen(!isOpen);
            }}
          />
        </div>
      </div>

      <style jsx>{staticStyles}</style>
    </div>,
    document.body
  );
};

export default DropDown;
