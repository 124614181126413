import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { PageContentPreloader, PageLayout } from 'common/components';
import { StrategyInfoPanel } from 'client/invest/_components';
import { retrieveInvestStrategy } from 'client/invest/_redux';
import YieldChart from '../YieldChart';

import { staticStyles, getDynamicStyles } from './style';

const InvestDetailPage = ({ retrieveInvestStrategy, investStrategyDetail, investStrategyDetailIsLoaded, theme }) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();
  const match = useRouteMatch();
  const {
    params: { strategyId },
  } = match;

  const [chartTab, setChartTab] = useState('yield');

  useEffect(() => {
    retrieveInvestStrategy({ strategyId });
  }, [retrieveInvestStrategy, strategyId]);

  const handleBackToClick = () => {
    history.push(`/widgets/invest/`);
  };

  return (
    <PageLayout caption={investStrategyDetail.title} showReload={false}>
      <PageContentPreloader ready={investStrategyDetailIsLoaded} type="bigLine">
        {/* eslint-disable */}
        <FormattedMessage id="backToRating">
          {txt => (
            <div className="InvestDetailPage__back-to" onClick={handleBackToClick}>
              {txt}
            </div>
          )}
        </FormattedMessage>
        {/* eslint-enable */}
        <div className="InvestDetailPage__chart-tabs">
          <FormattedMessage id="justYield">
            {txt => (
              <button
                type="button"
                className={classNames('InvestDetailPage__tab', {
                  active: chartTab === 'yield',
                })}
                onClick={() => setChartTab('yield')}>
                {txt}
              </button>
            )}
          </FormattedMessage>
        </div>

        <StrategyInfoPanel strategyDetail={investStrategyDetail} />

        {chartTab === 'yield' && <YieldChart strategyId={investStrategyDetail.id} />}
      </PageContentPreloader>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </PageLayout>
  );
};

InvestDetailPage.propTypes = {
  retrieveInvestStrategy: PropTypes.func.isRequired,
  investStrategyDetail: PropTypes.object.isRequired,
  investStrategyDetailIsLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

InvestDetailPage.defaultProps = {
  theme: {},
};

export default connect(
  state => ({
    investStrategyDetail: state.client.invest.investStrategyDetail,
    investStrategyDetailIsLoaded: state.client.invest.investStrategyDetailIsLoaded,
  }),
  {
    retrieveInvestStrategy: data => retrieveInvestStrategy.request(data),
  }
)(withTheme()(InvestDetailPage));

export { InvestDetailPage };
