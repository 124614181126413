import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { withTheme } from 'common/styling/theme';
import { connect } from 'react-redux';
import staticStyles from './style';
import LogoImage from './image/LogoImage';

const MainLogo = ({ url, className, logo, logoLink, currentInterface }) => (
  <div className={classNames('MainLogo', className)}>
    {logoLink && (currentInterface === 'client' || currentInterface === 'auth') ? (
      <a href={logoLink} rel="noopener noreferrer">
        {logo ? <img src={logo} alt="logo" /> : <LogoImage />}
      </a>
    ) : (
      <Link to={url}>{logo ? <img src={logo} alt="logo" /> : <LogoImage />}</Link>
    )}
    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

MainLogo.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  logo: PropTypes.string,
  logoLink: PropTypes.string,
  currentInterface: PropTypes.string,
};

MainLogo.defaultProps = {
  url: '/',
  className: '',
  logo: '',
  logoLink: '',
  currentInterface: '',
};

export default connect(state => ({
  logoLink: state.interfaceConfig?.clientThemeConfig.client.logo_link,
}))(withTheme(({ images: { logo } }) => ({ logo }))(MainLogo));
export { MainLogo };
