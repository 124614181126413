import css from 'styled-jsx/css';
import { isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .OperationsHistory {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    padding: rem(25px 30px 25px);
    margin-bottom: rem(20px);
    @include respond-to(sm) {
      padding: rem(20px 15px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
    .BasicModal__scroll {
      > div {
        margin-left: ${isRTL ? '-15px' : '0'};
        margin-right: ${isRTL ? '0' : '-15px'};
      }
    }
  }

  .OperationsHistory__infoWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(10px);
  }

  .OperationsHistory__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;

    span {
      font-size: rem(13px);
      letter-spacing: 0.6px;
      display: inline-block;
      margin-right: rem(10px);
      span {
        margin-right: 1px;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .OperationsHistory {
      background: ${colors.white.hex};
    }

    .OperationsHistory__info {
      :global(span) {
        color: ${colors.gray.hex};
      }
    }
    .OperationsHistory__infoIp {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
