import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  lastFunnels: {},
  lastFunnelsAreLoaded: false,
  monthsFunnels: {},
  monthsFunnelsAreLoaded: false,
  dailyFunnels: {},
  dailyFunnelsAreLoaded: false,
  target: {},
  targetIsLoaded: false,
  usersHistory: {},
  usersHistoryIsLoaded: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LAST_FUNNELS[REQUEST]:
      return { ...state, lastFunnelsAreLoaded: false };
    case ActionTypes.GET_LAST_FUNNELS[SUCCESS]:
      return { ...state, lastFunnels: { ...action.data }, lastFunnelsAreLoaded: true };
    case ActionTypes.GET_LAST_FUNNELS[FAILURE]:
      return { ...state, error: action.error, lastFunnelsAreLoaded: false };

    case ActionTypes.GET_MONTHS_FUNNELS[REQUEST]:
      return { ...state, monthsFunnelsAreLoaded: false };
    case ActionTypes.GET_MONTHS_FUNNELS[SUCCESS]:
      return { ...state, monthsFunnels: { ...action.data }, monthsFunnelsAreLoaded: true };
    case ActionTypes.GET_MONTHS_FUNNELS[FAILURE]:
      return { ...state, error: action.error, monthsFunnelsAreLoaded: false };

    case ActionTypes.GET_TARGET[REQUEST]:
      return { ...state, targetIsLoaded: false };
    case ActionTypes.GET_TARGET[SUCCESS]:
      return { ...state, target: { ...state.target, ...action.data }, targetIsLoaded: true };
    case ActionTypes.GET_TARGET[FAILURE]:
      return { ...state, error: action.error, targetIsLoaded: false };

    case ActionTypes.GET_DAILY_FUNNELS[REQUEST]:
      return { ...state, dailyFunnelsAreLoaded: false };
    case ActionTypes.GET_DAILY_FUNNELS[SUCCESS]:
      return { ...state, dailyFunnels: { ...action.data }, dailyFunnelsAreLoaded: true };
    case ActionTypes.GET_DAILY_FUNNELS[FAILURE]:
      return { ...state, error: action.error, dailyFunnelsAreLoaded: false };

    case ActionTypes.GET_USERS_HISTORY[REQUEST]:
      return { ...state, usersHistoryIsLoaded: false };
    case ActionTypes.GET_USERS_HISTORY[SUCCESS]:
      return { ...state, usersHistory: { ...action.data }, usersHistoryIsLoaded: true };
    case ActionTypes.GET_USERS_HISTORY[FAILURE]:
      return { ...state, error: action.error, usersHistoryIsLoaded: false };

    case ActionTypes.RESET_DASHBOARD_LOADED_FLAGS:
      return {
        ...state,
        lastFunnelsAreLoaded: false,
        targetIsLoaded: false,
        dailyFunnelsAreLoaded: false,
      };

    default:
      return state;
  }
};

export default reducer;
