import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ReassignItem {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .ReassignItem__Action {
    display: flex;
    gap: 10px;
  }

  .ReassignItem__From {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    color: #696969;
    text-align: center;
    background: #e9e9e9;
    padding: 4px 4px;
    border-radius: 5%;
  }

  .ReassignItem__To {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    color: #696969;
    text-align: center;
    background: #e9e9e9;
    padding: 4px 4px;
    border-radius: 5%;
    &--success {
      background: #2ed790;
      color: white !important;
    }
    &--failed {
      background: #ff4085;
      color: white !important;
    }
  }

  .ReassignItem__Desc {
    display: flex;
    gap: 6px;
  }
  .ReassignItem__By {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 120% */
    letter-spacing: 0.2px;
  }
  .ReassignItem__Reason {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }
  .ReassignItem__Comment {
  }

  .TabFeedItem {
    border-bottom: 0.5px solid #696969;
    margin: 0 0 10px 0;
    padding: 10px 20px 10px 10px;
    &__Reaction-img {
      width: 20px;
      height: 20px;
    }
    &__bold {
      font-weight: 500;
      margin-left: rem(5px);
    }

    @include respond-to(lg) {
      padding: 15px 10px 15px 10px;
    }

    &:last-child {
      margin: 0 0 0 0;
    }

    &__Reaction__title {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        font-weight: 500;
        // margin-right: 8px;
      }
    }

    &__Reaction-button {
      display: flex;
      align-items: center;
      gap: 4.5px;
      font-size: 12px;
      font-weight: 300;
      margin: 0;
      &.active {
        font-weight: 500;
      }
    }

    &__Wrap {
      display: flex;
      align-items: flex-start;
    }

    &__Icon {
      background: #e9e9e9;
      width: 28px;
      min-width: 28px;
      height: 28px;
      min-height: 28px;
      border-radius: 50%;
      margin: 0 10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &--impersonate {
        img {
          transform: translateY(1px);
        }
      }

      &--accountcreate {
        img {
          transform: translateY(1px);
        }
      }

      &--userstatuschanged {
        img {
          transform: translateY(1px);
        }
      }

      &--registration {
        img {
          transform: translateY(1px);
        }
      }

      &--internaltransferchange {
        img {
          transform: translateY(1px);
        }
      }

      &--requestchange {
        img {
          transform: translateY(1px);
        }
      }

      &--login {
        img {
          transform: translateY(1px);
        }
      }

      &--bonusactivate {
        img {
          transform: translateY(1px);
        }
      }
    }

    &__Subwrap {
      display: flex;
      align-items: center;
      width: 100%;

      @include respond-to(lg) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__Info-wrap {
      width: 100%;
      position: relative;
    }

    &__Description {
      width: 100%;
      display: flex;
      gap: 7px;
    }

    &__Title-container {
      display: flex;
      flex-wrap: wrap;
    }

    &__Title {
      padding: 2px 4px;
      padding-left: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #0f0f0f;
      margin: 0 10px 5px 0;
      width: fit-content;
      border-radius: 5px;
      &-span {
        margin-right: 5px;
      }

      &--success {
        background: #2ed790;
        color: #fff;
        padding-left: 4px;
      }
      &--failed {
        background: #ff4085;
        color: #fff;
        padding-left: 4px;
      }
      &--waiting {
        background: #fdff89;
        padding-left: 4px;
      }
    }

    &__Description {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.2px;

      color: #000000;
      margin-bottom: 5px;
    }

    &__Comment {
      margin-top: 5px;
    }

    &__Action {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;

      @include respond-to(lg) {
        order: 3;
      }
    }

    &__Date {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0 0 0 auto;
      min-width: 120px;
      text-align: right;
      .DateCell {
        position: absolute;
        right: 0;
      }

      @include respond-to(lg) {
        min-width: 0;
        text-align: left;
        order: 1;
        margin: 0 0 5px 0;
        .DateCell {
          position: static;
        }
      }
    }

    &__Footer {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: flex-start;
      flex-direction: row;

      @include respond-to(md) {
        flex-direction: column;
      }
      /* &--absolute {
        position: absolute;
        bottom: 10px;
        @include respond-to(md) {
          position: static;
        }
      } */
    }
    &__Buttons {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
      .ActionsButton__delete {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }
    }

    &__Log-data {
      padding: 0 0 10px 38px;
      margin: 20px 0 0 0;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #343846;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #d6dbe0;
      }

      .TabFeedItem[data-visible='hide'] & {
        display: none;
      }
    }

    &__Log-data-td-key {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }

    &__Log-data-td-value {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }
    .DateCell {
      display: flex;
      gap: 5px;
    }
    &__ExpirationDate {
      display: flex;
      font-size: rem(12px);
      justify-content: flex-end;
      .DateCell {
        margin-left: 5px;

        font-weight: 500;
        &__time {
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }
`;

export { staticStyles };
