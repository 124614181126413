import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import CustomFilterButton from '../CustomFilterButton';
import iconEdit from './images/iconEdit.svg';
import iconBasket from './images/iconBasket.svg';
import iconCross from './images/iconCross.svg';

import { staticStyles, getDynamicStyles } from './style';

const CustomFilterSelect = ({
  getFilters,
  filtersSelector,
  onChange,
  onResetFilter,
  filter,
  filterId,
  toggleEditModal,
  toggleDeleteModal,
  theme,
  intl,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFilters.request());
  }, [dispatch]);
  const filters = useSelector(filtersSelector);
  const options = [
    ...filters.map(item => ({ value: item.id, label: item.title })),
    { value: 'create', label: intl.formatMessage({ id: 'crmCreateAndEdit' }) },
  ];
  const initialFilter = filterId ? options.find(option => option.value === +filterId) : filter;

  const handleToggleSelect = () => {
    if (!isFocused) {
      selectRef.current.focus();
    }

    setIsFocused(prev => !prev);
  };

  const Option = props => {
    if (props.data.value !== 'create') {
      return (
        <div
          className={classNames(
            { 'CustomFilterSelect__option--selected': props.isSelected },
            { 'CustomFilterSelect__option--focused': props.isFocused },
            'CustomFilterSelect__option'
          )}>
          <div className="CustomFilterSelect__option-text">
            <components.Option {...props} />
          </div>
          <div className="CustomFilterSelect__option-buttons">
            <button
              className="CustomFilterSelect__option-edit"
              onClick={e => {
                e.stopPropagation();
                toggleEditModal(props.data.value);
              }}>
              <img className="CustomFilterSelect__edit-icon" src={iconEdit} alt="edit" />
            </button>
            <button
              className="CustomFilterSelect__option-edit"
              onClick={e => {
                e.stopPropagation();
                toggleDeleteModal(props.data.value);
              }}>
              <img className="CustomFilterSelect__delete-icon" src={iconBasket} alt="edit" />
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="CustomFilterSelect__option-text CustomFilterSelect__option-text--whole">
        <components.Option {...props} />
      </div>
    );
  };

  return (
    <div className="CustomFilterSelect">
      <div className="CustomFilterSelect__button">
        <CustomFilterButton onClick={() => handleToggleSelect()} />
      </div>
      <div className="CustomFilterSelect__inner">
        <Select
          classNamePrefix="Select"
          placeholder={<FM id="crmCustomFilter" />}
          onChange={onChange}
          options={options}
          ref={selectRef}
          noOptionsMessage={() => null}
          isClearable={false}
          isSearchable={false}
          openMenuOnFocus
          components={{ Option }}
          value={filter.value ? { value: filter.value, label: filter.label } : initialFilter}
        />
        {filterId && (
          <button onClick={onResetFilter}>
            <img className="CustomFilterSelect__delete-icon" src={iconCross} alt="reset" />
          </button>
        )}
      </div>
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

CustomFilterSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  toggleEditModal: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,

  intl: PropTypes.object.isRequired,
  theme: PropTypes.object,
};

CustomFilterSelect.defaultProps = {
  theme: {},
};

export default withTheme()(injectIntl(CustomFilterSelect));
