import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .TabLogs {
    .text-row {
      height: 100px !important;
      margin-bottom: 10px;
    }
    &__no-data {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin: 0 0 10px 0;
      }

      span {
        color: #777777;
      }
    }
    &__list {
    }

    &__item {
      border-bottom: 0.5px solid #696969;
      margin: 0 0 10px 0;
      padding: 10px 20px 10px 10px;

      @include respond-to(lg) {
        padding: 15px 10px 15px 10px;
      }

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__item-wrap {
      display: flex;
      align-items: center;
    }

    &__item-icon {
      background: #e9e9e9;
      width: 28px;
      min-width: 28px;
      height: 28px;
      min-height: 28px;
      border-radius: 50%;
      margin: 0 10px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &--impersonate {
        img {
          transform: translateY(1px);
        }
      }

      &--accountcreate {
        img {
          transform: translateY(1px);
        }
      }

      &--userstatuschanged {
        img {
          transform: translateY(1px);
        }
      }

      &--registration {
        img {
          transform: translateY(1px);
        }
      }

      &--internaltransferchange {
        img {
          transform: translateY(1px);
        }
      }

      &--requestchange {
        img {
          transform: translateY(1px);
        }
      }

      &--login {
        img {
          transform: translateY(1px);
        }
      }

      &--bonusactivate {
        img {
          transform: translateY(1px);
        }
      }
    }

    &__item-subwrap {
      display: flex;
      align-items: center;
      width: 100%;

      @include respond-to(lg) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__item-info-wrap {
    }

    &__item-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #0f0f0f;
      margin: 0 10px 5px 0;
    }

    &__item-description {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.2px;

      color: #000000;
    }

    &__item-action {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;

      @include respond-to(lg) {
        order: 3;
      }
    }

    &__item-date {
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0 0 0 auto;
      min-width: 120px;
      text-align: right;

      @include respond-to(lg) {
        min-width: 0;
        text-align: left;
        order: 1;
        margin: 0 0 0 0;
      }
    }

    &__item-log-data {
      padding: 0 0 10px 38px;
      margin: 20px 0 0 0;
      overflow: auto;

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #343846;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: #d6dbe0;
      }

      .TabLogs__item[data-visible='hide'] & {
        display: none;
      }
    }

    &__item-log-data-td-key {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }

    &__item-log-data-td-value {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      min-width: 220px;
    }

    &__pagination {
      margin: 20px 0 0 0;
    }
  }
`;

export { staticStyles };
