import { FAILURE, REQUEST, SUCCESS } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  investStrategies: {},
  investStrategiesAreLoaded: false,

  myInvestStrategies: {},
  myInvestStrategiesAreLoaded: false,

  investments: {},
  investmentsAreLoaded: false,

  investmentsRequests: {},
  investmentsRequestsAreLoaded: false,

  strategyInvestments: {},
  strategyInvestmentsAreLoaded: false,

  investStrategyDetail: {},
  investStrategyDetailIsLoaded: false,

  investYieldChart: [],
  investYieldChartIsLoaded: false,

  investSymbolsChart: [],
  investSymbolsChartIsLoaded: false,

  investStatement: {},
  investStatementIsLoaded: false,

  investmentTrades: {},
  investmentTradesAreLoaded: false,

  investAwaitinigApprove: {},
  investAwaitinigApproveAreLoaded: false,

  investStrategy: null,

  pendingInvestApproveStrategy: false,
  pendingInvestRejectStrategy: false,
  investStrategyId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.createInvestStrategy[REQUEST]:
      return { ...state };
    case ActionTypes.createInvestStrategy[SUCCESS]:
      return { ...state, investStrategy: action.payload };
    case ActionTypes.createInvestStrategy[FAILURE]:
      return { ...state, error: action.error };

    case ActionTypes.GET_INVEST_STRATEGIES[REQUEST]:
      return { ...state, investStrategiesAreLoaded: false };
    case ActionTypes.GET_INVEST_STRATEGIES[SUCCESS]:
      return { ...state, investStrategies: action.data, investStrategiesAreLoaded: true };
    case ActionTypes.GET_INVEST_STRATEGIES[FAILURE]:
      return { ...state, error: action.error, investStrategiesAreLoaded: false };

    case ActionTypes.GET_MY_INVEST_STRATEGIES[REQUEST]:
      return { ...state, myInvestStrategiesAreLoaded: false };
    case ActionTypes.GET_MY_INVEST_STRATEGIES[SUCCESS]:
      return { ...state, myInvestStrategies: action.data, myInvestStrategiesAreLoaded: true };
    case ActionTypes.GET_MY_INVEST_STRATEGIES[FAILURE]:
      return { ...state, error: action.error, myInvestStrategiesAreLoaded: false };

    case ActionTypes.GET_INVESTMENTS[REQUEST]:
      return { ...state, investments: {}, investmentsAreLoaded: false };
    case ActionTypes.GET_INVESTMENTS[SUCCESS]:
      return { ...state, investments: action.data, investmentsAreLoaded: true };
    case ActionTypes.GET_INVESTMENTS[FAILURE]:
      return { ...state, error: action.error, investmentsAreLoaded: false };

    case ActionTypes.GET_STRATEGY_INVESTMENTS[REQUEST]:
      return { ...state, strategyInvestmentsAreLoaded: false };
    case ActionTypes.GET_STRATEGY_INVESTMENTS[SUCCESS]:
      return { ...state, strategyInvestments: action.data, strategyInvestmentsAreLoaded: true };
    case ActionTypes.GET_STRATEGY_INVESTMENTS[FAILURE]:
      return { ...state, error: action.error, strategyInvestmentsAreLoaded: false };

    case ActionTypes.GET_INVESTMENTS_REQUESTS[REQUEST]:
      return { ...state, investmentsRequestsAreLoaded: false };
    case ActionTypes.GET_INVESTMENTS_REQUESTS[SUCCESS]:
      return { ...state, investmentsRequests: action.data, investmentsRequestsAreLoaded: true };
    case ActionTypes.GET_INVESTMENTS_REQUESTS[FAILURE]:
      return { ...state, error: action.error, investmentsRequestsAreLoaded: false };

    case ActionTypes.RETRIEVE_INVEST_STRATEGY[REQUEST]:
      return { ...state, investStrategyDetailIsLoaded: false };
    case ActionTypes.RETRIEVE_INVEST_STRATEGY[SUCCESS]:
      return { ...state, investStrategyDetail: action.data, investStrategyDetailIsLoaded: true };
    case ActionTypes.RETRIEVE_INVEST_STRATEGY[FAILURE]:
      return { ...state, error: action.error, investStrategyDetailIsLoaded: false };

    case ActionTypes.GET_INVEST_YIELD_CHART[REQUEST]:
      return { ...state, investYieldChartIsLoaded: false };
    case ActionTypes.GET_INVEST_YIELD_CHART[SUCCESS]:
      return { ...state, investYieldChart: action.data, investYieldChartIsLoaded: true };
    case ActionTypes.GET_INVEST_YIELD_CHART[FAILURE]:
      return { ...state, error: action.error, investYieldChartIsLoaded: false };

    case ActionTypes.GET_INVEST_SYMBOLS_CHART[REQUEST]:
      return { ...state, investSymbolsChartIsLoaded: false };
    case ActionTypes.GET_INVEST_SYMBOLS_CHART[SUCCESS]:
      return { ...state, investSymbolsChart: action.data, investSymbolsChartIsLoaded: true };
    case ActionTypes.GET_INVEST_SYMBOLS_CHART[FAILURE]:
      return { ...state, error: action.error, investSymbolsChartIsLoaded: false };

    case ActionTypes.GET_INVEST_STATEMENT[REQUEST]:
      return { ...state, investStatementIsLoaded: false };
    case ActionTypes.GET_INVEST_STATEMENT[SUCCESS]:
      return { ...state, investStatement: action.data, investStatementIsLoaded: true };
    case ActionTypes.GET_INVEST_STATEMENT[FAILURE]:
      return { ...state, error: action.error, investStatementIsLoaded: false };

    case ActionTypes.GET_INVESTMENT_TRADES[REQUEST]:
      return { ...state, investmentTradesAreLoaded: false };
    case ActionTypes.GET_INVESTMENT_TRADES[SUCCESS]:
      return { ...state, investmentTrades: action.data, investmentTradesAreLoaded: true };
    case ActionTypes.GET_INVESTMENT_TRADES[FAILURE]:
      return { ...state, error: action.error, investmentTradesAreLoaded: false };

    case ActionTypes.GET_INVEST_STRATEGIES_APPROVE[REQUEST]:
      return { ...state, investAwaitinigApproveAreLoaded: false };
    case ActionTypes.GET_INVEST_STRATEGIES_APPROVE[SUCCESS]:
      return { ...state, investAwaitinigApprove: action.data, investAwaitinigApproveAreLoaded: true };
    case ActionTypes.GET_INVEST_STRATEGIES_APPROVE[FAILURE]:
      return { ...state, error: action.error, investAwaitinigApproveAreLoaded: false };

    case ActionTypes.SET_INVESTMENTS_ACTION[REQUEST]:
      return action.action === 'approve'
        ? {
            ...state,
            pendingInvestApproveStrategy: true,
            investStrategyId: action.investmentId,
          }
        : {
            ...state,
            pendingInvestRejectStrategy: true,
            investStrategyId: action.investmentId,
          };
    case ActionTypes.SET_INVESTMENTS_ACTION[SUCCESS]:
    case ActionTypes.SET_INVESTMENTS_ACTION[FAILURE]:
      return {
        ...state,
        pendingInvestApproveStrategy: false,
        pendingInvestRejectStrategy: false,
        investStrategyId: null,
      };

    default:
      return state;
  }
};

export default reducer;
