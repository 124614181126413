import { watchers as smWatchers } from '../staff-management/_redux';
import { watchers as cmWatchers } from '../customization/_redux';
import { watchers as filesWatchers } from '../files/_redux';
import { sagas as fuWatchers } from '../_components/file-uploader/_redux';
import { watchers as faqWatchers } from '../faq/_redux';
import { watchers as managersWatchers } from '../managers-system/_redux';
import { watchers as dynamicsWatchers } from '../dynamics/_redux';
import { watchers as withdrawWatchers } from '../withdraw-requests/_redux';
import { watchers as bannersWatchers } from '../banners/_redux';
import { watchers as paymentsWatchers } from '../payment-system/_redux';

export default [
  ...smWatchers,
  ...cmWatchers,
  ...filesWatchers,
  ...fuWatchers,
  ...faqWatchers,
  ...managersWatchers,
  ...withdrawWatchers,
  ...bannersWatchers,
  ...dynamicsWatchers,
  ...paymentsWatchers,
];
