import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tabs } from 'crm/_components/';
import { MyTasks, AllTasks, Reassign } from '../../_components';
import { staticStyles } from './style';

const TasksPage = ({ perms }) => (
  <div className="TasksPage">
    <Tabs externalClass="TasksPage__tabs">
      <MyTasks tabTextId="crmMyTasks" tabSlug="myTasks" />
      <AllTasks tabTextId="crmAllTasks" tabSlug="allTasks" />
      {perms.setManagerPerm && <Reassign tabTextId="crmReassign" tabSlug="reassign" />}
    </Tabs>
    <style jsx>{staticStyles}</style>
  </div>
);

TasksPage.propTypes = {
  perms: PropTypes.object.isRequired,
};

export default connect(state => ({
  perms: state.user.profile.perms,
}))(TasksPage);
