/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, change, formValueSelector } from 'redux-form';
import { required } from 'redux-form-validators';

import { Agreements, ReduxSelectField, DefaultButton } from 'common/components';

import { createTradingAccount } from '../../_redux';

import { staticStyles } from './style';

class CreateAccountForm extends Component {
  shouldComponentUpdate({ accountSchema: { accountCreationSpec }, valuesDict, clearField }) {
    let shouldUpdate = true;

    if (accountCreationSpec) {
      let optionsLevel = accountCreationSpec.options;
      let previousLevelValue = -1;
      const tree = accountCreationSpec.zipper;

      tree.forEach(({ slug }) => {
        if (valuesDict[slug] && !(optionsLevel && valuesDict[slug] in optionsLevel)) {
          clearField(slug);
          shouldUpdate = false;
        }
        optionsLevel = (optionsLevel && valuesDict[slug] && optionsLevel[valuesDict[slug]]) || null;
        previousLevelValue = previousLevelValue && optionsLevel ? valuesDict[slug] : undefined;
      });
    }

    return shouldUpdate;
  }

  render() {
    const { accountSchema, accountTypeId, valuesDict, submitting, invalid, handleSubmit, dispatch } = this.props;

    let fields = [];
    const label = label => (label === 'ZMW' ? 'ZMW (Zambia Kwacha)' : label);

    if (accountSchema.accountCreationSpec) {
      let optionsLevel = accountSchema.accountCreationSpec.options;
      let previousLevelValue = -1;

      fields = accountSchema.accountCreationSpec.zipper.map(({ slug, name, displays }) => {
        let selectOptions = [];

        if (optionsLevel && typeof previousLevelValue !== 'undefined') {
          selectOptions = Object.keys(optionsLevel).map(key => ({
            value: key,
            label: label(displays[key]) || label(key),
          }));
        }
        optionsLevel = (optionsLevel && valuesDict[slug] && optionsLevel[valuesDict[slug]]) || null;
        previousLevelValue = previousLevelValue && optionsLevel ? valuesDict[slug] : undefined;

        // FXCA
        if (slug === 'lev') {
          selectOptions.reverse();
          const optionWithUnlimietedLeverage = selectOptions.find(option => option.value == 2000000000);
          if (optionWithUnlimietedLeverage) {
            optionWithUnlimietedLeverage.label = 'unlimited';
          }
        }

        return (
          <Field
            component={ReduxSelectField}
            name={slug}
            textId={name}
            options={selectOptions}
            validate={[required()]}
            searchable={false}
            maxMenuHeight={accountSchema.agreements.length ? 120 : 80}
            disabled={!selectOptions.length || submitting}
            key={slug}
          />
        );
      });
    }

    const submit = handleSubmit(values => createTradingAccount({ accountTypeId, values }, dispatch));

    return (
      <form className="CreateAccountForm" onSubmit={submit}>
        {fields}
        <Agreements agreements={accountSchema.agreements} submitting={submitting} />
        {this.props.error && <p className="formError">{this.props.error}</p>}
        <DefaultButton
          textId="createPlatformAccount"
          type="submit"
          disabled={invalid || submitting}
          loading={submitting}
          filled
        />
        <style jsx>{staticStyles}</style>
      </form>
    );
  }
}

CreateAccountForm.propTypes = {
  accountSchema: PropTypes.shape({
    accountCreationSpec: PropTypes.object,
    agreements: PropTypes.array,
  }),
  accountTypeId: PropTypes.number.isRequired,
  valuesDict: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  clearField: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.string,
};

CreateAccountForm.defaultProps = {
  accountSchema: {
    accountCreationSpec: {},
    agreements: [],
  },
  error: '',
};

const CreateAccountFormWrapped = reduxForm({
  form: 'createAccount',
  onSubmitSuccess: (result, dispatch, { onSuccessfulCreateAccount }) => onSuccessfulCreateAccount(),
})(CreateAccountForm);
const selector = formValueSelector('createAccount');

const ConnectedCreateAccountForm = connect(
  (state, { accountSchema, accountTypeId }) => {
    const valuesDict = {};
    const initialValues = {};
    if (accountSchema && accountSchema.accountCreationSpec) {
      accountSchema.accountCreationSpec.zipper.forEach(item => {
        valuesDict[item.slug] = selector(state, item.slug);
        initialValues[item.slug] = `${item.default}`;
      });
    }
    return { valuesDict, accountSchema, accountTypeId, initialValues };
  },
  { clearField: field => change('createAccount', field, null) }
)(CreateAccountFormWrapped);

ConnectedCreateAccountForm.propTypes = {
  accountSchema: PropTypes.shape({
    accountCreationSpec: PropTypes.object,
  }),
  accountTypeId: PropTypes.number.isRequired,
};

ConnectedCreateAccountForm.defaultProps = {
  accountSchema: {
    accountCreationSpec: {},
  },
};

export default ConnectedCreateAccountForm;
export { CreateAccountForm };
