import axios from 'axios';
import { locale } from 'common/utils/helpers';

export const getInterfaceConfig = () => axios.get('/utils/broker_metadata/');

export const getCustomCss = () => axios.get('/common/custom_css/');

export const getCustomMenu = () => axios.get('/common/custom_menu/');

export const getCustomJs = () => axios.get('/common/custom_js/');

export const getTelegramAuthWidget = () => axios.get('/utils/broker_telegram_widget');

export const loadLanguage = (lang, changeUser) =>
  axios.get(`/utils/locale/${lang}/${changeUser ? '?manual=True' : ''}`, {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  });

export const getCompanyNews = () =>
  axios.get(`/meta/news/list/`, {
    headers: {
      locale,
    },
  });
