import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { BaseButton } from 'crm/_components';
import { InputChoice, InputRange, InputDate, InputBoolean } from './_components';

const SecondStep = ({ selectedFilterTypes, setNextStep }) => {
  const [filtersValues, setFilterValues] = useState({});
  const MIN_DATE = '2019-01-01T00:00:00';
  const isSecondStepNotDone = useMemo(
    () =>
      Object.keys(selectedFilterTypes).find(filterKey => {
        if (selectedFilterTypes[filterKey].inputType === 'inputBoolean') {
          return false;
        }
        return !filtersValues[filterKey] || filtersValues[filterKey].length === 0;
      }),
    [filtersValues]
  );

  const onFilterChange = (filterKey, newValue) =>
    setFilterValues(prevState => ({ ...prevState, [filterKey]: newValue }));

  return (
    <>
      {Object.keys(selectedFilterTypes).map(filterKey => {
        const currentFilter = selectedFilterTypes[filterKey];
        if (currentFilter.inputType === 'inputStringChoice' || currentFilter.inputType === 'inputIntegerChoice') {
          return (
            <InputChoice
              key={`FilterCreate-Step-2-${filterKey}`}
              label={filterKey}
              // FIXME: С БЕКА ДОЛЖЕН ПРИЛЕТАТЬ {LABEL,VALUE} А НЕ {NAME,VALUE}
              options={currentFilter.choiceValues.map(({ name, value }) => ({ label: name, value }))}
              selectedOptions={filtersValues[filterKey] || []}
              onChange={newValue => onFilterChange(filterKey, newValue)}
            />
          );
        }
        if (currentFilter.inputType === 'inputRange') {
          return (
            <InputRange
              key={`FilterCreate-Step-2-${filterKey}`}
              label={filterKey}
              values={filtersValues[filterKey]}
              min={currentFilter.choiceValues[0]}
              max={currentFilter.choiceValues[1]}
              currency={currentFilter.currency}
              onChange={newValue => {
                onFilterChange(filterKey, newValue);
              }}
            />
          );
        }
        if (currentFilter.inputType === 'inputDate') {
          return (
            <InputDate
              key={`FilterCreate-Step-2-${filterKey}`}
              label={filterKey}
              values={filtersValues[filterKey]}
              min={MIN_DATE}
              max={currentFilter.choiceValues[1]}
              onChange={newValue => {
                onFilterChange(filterKey, newValue);
              }}
            />
          );
        }
        if (currentFilter.inputType === 'inputBoolean') {
          return (
            <InputBoolean
              key={`FilterCreate-Step-2-${filterKey}`}
              inputDesc={currentFilter.description}
              label={filterKey}
              values={filtersValues[filterKey]}
              onChange={newValue => {
                onFilterChange(filterKey, newValue);
              }}
            />
          );
        }
        return null;
      })}
      <BaseButton
        type="button"
        textId="justNextStep"
        onClick={() => setNextStep(filtersValues)}
        maxContent
        disabled={isSecondStepNotDone}
      />
    </>
  );
};

SecondStep.propTypes = {
  selectedFilterTypes: PropTypes.object.isRequired,
  setNextStep: PropTypes.func.isRequired,
};

export default connect(null)(SecondStep);
