import { createFormAction } from 'redux-form-saga';
import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from 'redux-config/reduxHelpers';

export const createInvestStrategy = createFormAction('CREATE_INVEST_STRATEGY');
export const editInvestStrategy = createFormAction('EDIT_INVEST_STRATEGY');

export const GET_INVEST_STRATEGIES = createRequestTypes('GET_INVEST_STRATEGIES');
export const getInvestStrategies = {
  request: data => action(GET_INVEST_STRATEGIES[REQUEST], data),
  success: data => action(GET_INVEST_STRATEGIES[SUCCESS], { data }),
  failure: error => action(GET_INVEST_STRATEGIES[FAILURE], { error }),
};

export const GET_MY_INVEST_STRATEGIES = createRequestTypes('GET_MY_INVEST_STRATEGIES');
export const getMyInvestStrategies = {
  request: data => action(GET_MY_INVEST_STRATEGIES[REQUEST], data),
  success: data => action(GET_MY_INVEST_STRATEGIES[SUCCESS], { data }),
  failure: error => action(GET_MY_INVEST_STRATEGIES[FAILURE], { error }),
};

export const addStrategyInvestments = createFormAction('ADD_STRATEGY_INVESTMENTS');

export const SET_INVEST_STRATEGY_ACTION = createRequestTypes('SET_INVEST_STRATEGY_ACTION');
export const setInvestStrategyAction = {
  request: data => action(SET_INVEST_STRATEGY_ACTION[REQUEST], data),
  success: data => action(SET_INVEST_STRATEGY_ACTION[SUCCESS], { data }),
  failure: error => action(SET_INVEST_STRATEGY_ACTION[FAILURE], { error }),
};

export const RETRIEVE_INVEST_STRATEGY = createRequestTypes('RETRIEVE_INVEST_STRATEGY');
export const retrieveInvestStrategy = {
  request: data => action(RETRIEVE_INVEST_STRATEGY[REQUEST], data),
  success: data => action(RETRIEVE_INVEST_STRATEGY[SUCCESS], { data }),
  failure: error => action(RETRIEVE_INVEST_STRATEGY[FAILURE], { error }),
};

export const getRammStrategyScript = createFormAction('GET_RAMM_STRATEGY_SCRIPT');

export const GET_INVESTMENTS = createRequestTypes('GET_INVESTMENTS');
export const getInvestments = {
  request: data => action(GET_INVESTMENTS[REQUEST], data),
  success: data => action(GET_INVESTMENTS[SUCCESS], { data }),
  failure: error => action(GET_INVESTMENTS[FAILURE], { error }),
};

export const GET_STRATEGY_INVESTMENTS = createRequestTypes('GET_STRATEGY_INVESTMENTS');
export const getStrategyInvestments = {
  request: data => action(GET_STRATEGY_INVESTMENTS[REQUEST], data),
  success: data => action(GET_STRATEGY_INVESTMENTS[SUCCESS], { data }),
  failure: error => action(GET_STRATEGY_INVESTMENTS[FAILURE], { error }),
};

export const SET_INVESTMENTS_ACTION = createRequestTypes('SET_INVESTMENTS_ACTION');
export const setInvestmentsAction = {
  request: data => action(SET_INVESTMENTS_ACTION[REQUEST], data),
  success: data => action(SET_INVESTMENTS_ACTION[SUCCESS], { data }),
  failure: error => action(SET_INVESTMENTS_ACTION[FAILURE], { error }),
};

export const closeInvestment = createFormAction('CLOSE_INVESTMENT');

export const GET_INVEST_YIELD_CHART = createRequestTypes('GET_INVEST_YIELD_CHART');
export const getInvestYieldChart = {
  request: data => action(GET_INVEST_YIELD_CHART[REQUEST], data),
  success: data => action(GET_INVEST_YIELD_CHART[SUCCESS], { data }),
  failure: error => action(GET_INVEST_YIELD_CHART[FAILURE], { error }),
};

export const GET_INVEST_SYMBOLS_CHART = createRequestTypes('GET_INVEST_SYMBOLS_CHART');
export const getInvestSymbolsChart = {
  request: data => action(GET_INVEST_SYMBOLS_CHART[REQUEST], data),
  success: data => action(GET_INVEST_SYMBOLS_CHART[SUCCESS], { data }),
  failure: error => action(GET_INVEST_SYMBOLS_CHART[FAILURE], { error }),
};

export const setInvestmentsDeposit = createFormAction('SET_INVESTMENTS_DEPOSIT');

export const editRammInvestmentsValue = createFormAction('EDIT_RAMM_INVESTMENTS_VALUE');

export const withdrawInvestments = createFormAction('WITHDRAW_INVESTMENTS');

export const editInvestment = createFormAction('EDIT_INVESTMENT');

export const GET_INVEST_STATEMENT = createRequestTypes('GET_INVEST_STATEMENT');
export const getInvestStatement = {
  request: data => action(GET_INVEST_STATEMENT[REQUEST], data),
  success: data => action(GET_INVEST_STATEMENT[SUCCESS], { data }),
  failure: error => action(GET_INVEST_STATEMENT[FAILURE], { error }),
};

export const setPauseRammInvestments = createFormAction('SET_PAUSE_RAMM_INVESTMENTS');

export const setResumeRammInvestments = createFormAction('SET_RESUME_RAMM_INVESTMENTS');

export const GET_INVESTMENT_TRADES = createRequestTypes('GET_INVESTMENT_TRADES');
export const getInvestmentTrades = {
  request: data => action(GET_INVESTMENT_TRADES[REQUEST], data),
  success: data => action(GET_INVESTMENT_TRADES[SUCCESS], { data }),
  failure: error => action(GET_INVESTMENT_TRADES[FAILURE], { error }),
};

export const setResumeRammStrategy = createFormAction('SET_RESUME_RAMM_STRATEGY');

export const closeInvestStrategy = createFormAction('CLOSE_INVEST_STRATEGY');

export const changeInvestmentProfile = createFormAction('CHANGE_INVESTMENT_PROFILE');

export const GET_INVEST_STRATEGIES_APPROVE = createRequestTypes('GET_INVEST_STRATEGIES_APPROVE');
export const getInvestStrategyApprove = {
  request: data => action(GET_INVEST_STRATEGIES_APPROVE[REQUEST], data),
  success: data => action(GET_INVEST_STRATEGIES_APPROVE[SUCCESS], { data }),
  failure: error => action(GET_INVEST_STRATEGIES_APPROVE[FAILURE], { error }),
};

export const GET_INVESTMENTS_REQUESTS = createRequestTypes('GET_INVESTMENTS_REQUESTS');
export const getInvestmentsRequests = {
  request: data => action(GET_INVESTMENTS_REQUESTS[REQUEST], data),
  success: data => action(GET_INVESTMENTS_REQUESTS[SUCCESS], { data }),
  failure: error => action(GET_INVESTMENTS_REQUESTS[FAILURE], { error }),
};

export const closeInvestmentRequest = createFormAction('CLOSE_INVESTMENT_REQUEST');
