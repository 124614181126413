import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { FormattedMessage } from 'react-intl';

import { DateCell, ActionsButton } from 'common/components';
import { EditIcon, BasketIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import UserNoteDocuments from '../UserNoteDocuments';
import { staticStyles, getDynamicStyles } from './style';

const UserNote = ({
  noteId,
  created,
  createdBy,
  title,
  text,
  documents,
  handleDownloadNote,
  handleEditNote,
  handleDeleteNote,
  handleRemoveNoteDocument,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="UserNote">
      <div className="UserNote__content">
        <div className="UserNote__top-line">
          <div className="UserNote__createdBy">
            <div className="UserNote__avatar">
              <Gravatar md5={md5(createdBy.email)} size={60} />
            </div>
            <div className="UserNote__line">
              <FormattedMessage id="justAuthor" />
              <p>{createdBy.email}</p>
            </div>
          </div>

          <div className="UserNote__line UserNote__title">
            <p>{title}</p>
          </div>

          <div className="UserNote__line UserNote__data-created">
            <DateCell value={created} />
          </div>
          <div className="UserNote__line UserNote__data-action">
            <ActionsButton
              tooltipId="Edit__tooltip"
              tooltipTextId="justEditNote"
              edit
              onClickFunc={() => handleEditNote(noteId)}>
              <EditIcon />
            </ActionsButton>
            <ActionsButton
              tooltipId="Delete__tooltip"
              tooltipTextId="justDeleteNote"
              deleteType
              onClickFunc={() => handleDeleteNote(noteId)}>
              <BasketIcon />
            </ActionsButton>
          </div>
        </div>
        <div className="UserNote__bottom-line">
          <FormattedMessage id="justText" />
          <pre>{text}</pre>
        </div>
        {!!documents.length && (
          <div className="UserNote__files">
            <FormattedMessage id="menuTitleFiles" />
            <UserNoteDocuments
              documents={documents}
              onDownload={handleDownloadNote}
              onRemove={handleRemoveNoteDocument}
            />
          </div>
        )}
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserNote.propTypes = {
  noteId: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  handleDownloadNote: PropTypes.func.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired,
  handleRemoveNoteDocument: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

UserNote.defaultProps = {
  theme: {},
};

export default withTheme()(UserNote);
export { UserNote };
