import React, { useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import PropTypes from 'prop-types';

import { staticStyles } from './style';

const InputBoolean = ({ label, values, onChange }) => {
  useEffect(() => {
    onChange(false);
  }, []);
  return (
    <div className="InputBoolean">
      <span className="InputBoolean__Label">
        <FM id={label}>{txt => txt}</FM>
      </span>
      <div className="InputBoolean__InputWrapper">
        <input
          className="InputBoolean__Input"
          type="checkbox"
          checked={values}
          onChange={e => {
            onChange(e.target.checked);
          }}
        />
        <FM id="justEnabled" />
      </div>

      <style jsx>{staticStyles}</style>
    </div>
  );
};
InputBoolean.propTypes = {
  values: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

InputBoolean.defaultProps = {
  values: false,
};

export default InputBoolean;
