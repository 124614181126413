import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import queryString from 'query-string';
import moment from 'moment';
import classNames from 'classnames';
import {
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNewValueIsAll,
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAll,
  getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAllAndTypeBoolean,
  defaultDates,
} from 'crm/_newComponents/FastFilters/helpers';
import InputIntegerChoice from './_components/InputIntegerChoice';
import InputDate from './_components/InputDate';
import InputStr from './_components/InputStr';
import { staticStyles } from './style';

function processChoiceValues(obj, allParams) {
  const resultObj = {};

  Object.keys(obj).forEach(key => {
    const choiceValues = obj[key].choiceValues;
    const processedValues = [];
    const currentFilterSelectedValues = allParams[obj[key].query_param];

    if (Array.isArray(choiceValues) && choiceValues.length > 0) {
      if (typeof choiceValues[0] === 'object') {
        processedValues.push({
          name: 'justAll',
          value: 'All',
          isSelected: !currentFilterSelectedValues,
        });

        if (obj[key].inputType === 'inputBoolean') {
          processedValues.push({ name: 'justYes', value: 'True', isSelected: false });
          processedValues.push({ name: 'justNo', value: 'False', isSelected: false });
        } else {
          choiceValues.forEach(item => {
            if (!currentFilterSelectedValues) {
              processedValues.push({
                ...item,
                isSelected: false,
              });
            } else {
              processedValues.push({
                ...item,
                isSelected: currentFilterSelectedValues.includes(item.value.toString()),
              });
            }
          });
        }
      } else if (obj[key].inputType === 'inputDate') {
        resultObj[key] = { ...obj[key], choiceValues: defaultDates };
        return;
      } else if (obj[key].inputType === 'inputStr') {
        resultObj[key] = { ...obj[key], value: '' };
        return;
      } else {
        resultObj[key] = { ...obj[key], selectedValues: [] };
        return;
      }
    }

    resultObj[key] = { ...obj[key], choiceValues: processedValues, choiceValuesAfterSearch: [...processedValues] };
  });

  return resultObj;
}

const FastFilters = ({
  fastFiltersModified,
  setFastFiltersModified,
  onChange,
  getFastFiltersFunc,
  fastFiltersSelector,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [allFilterListOpen, setAllFilterListOpen] = useState(false);
  const [isFirstLaunch, setIsFirstLaunch] = useState(true);
  const fastFilters = useSelector(fastFiltersSelector);
  const [urlParams, setUrlParams] = useState('');

  const currentUrl = useMemo(() => location.search.replace('?', ''), [location.search]);

  useEffect(() => {
    if (Object.keys(fastFilters).length === 1) {
      setAllFilterListOpen(true);
    }
  }, [Object.keys(fastFilters).length]);

  useEffect(() => {
    if (Object.keys(fastFilters).length !== 0) {
      setFastFiltersModified(
        processChoiceValues(
          fastFilters,

          queryString.parse(window.location.search.replace('?', ''))
        )
      );
    }
  }, [fastFilters]);

  useEffect(() => {
    dispatch(getFastFiltersFunc.request());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(fastFiltersModified).length === 0) return;
    const queryObjects = {};
    const justArrayOfQueryParams = [];
    Object.keys(fastFiltersModified).forEach(key => {
      const currentFilter = fastFiltersModified[key];
      justArrayOfQueryParams.push(currentFilter.query_param);

      if (currentFilter.inputType === 'inputStr') {
        queryObjects[currentFilter.query_param] = currentFilter.value;
      }

      if (currentFilter.inputType === 'inputDate') {
        let selectedValues = null;
        currentFilter.choiceValues.forEach(({ value, label, isSelected }) => {
          if (isSelected && label !== 'justAll') {
            selectedValues = value.map(val => moment(val).format('YYYY-MM-DD'));
          }
        });
        if (selectedValues) {
          queryObjects[currentFilter.query_param] = selectedValues;
        }
      }

      if (
        currentFilter.inputType === 'inputIntegerChoice' ||
        currentFilter.inputType === 'inputStringChoice' ||
        currentFilter.inputType === 'inputBoolean'
      ) {
        let selectedValues = null;

        currentFilter.choiceValues.forEach(({ value, isSelected }) => {
          if (isSelected && value !== 'All') {
            if (!selectedValues) {
              selectedValues = [];
            }
            selectedValues.push(value);
          }
        });

        if (selectedValues) {
          queryObjects[currentFilter.query_param] = selectedValues;
        }
      }
    });

    const allParams = queryString.parse(location.search);
    justArrayOfQueryParams.forEach(param => delete allParams[param]);
    Object.keys(queryObjects).forEach(key => {
      if (queryObjects[key]) {
        allParams[key] = queryObjects[key];
      }
    });

    const stringified = queryString.stringify({ ...allParams });
    setUrlParams(stringified);
  }, [fastFiltersModified, currentUrl, location]);

  useEffect(() => {
    if (!isFirstLaunch) {
      history.replace({ ...location, search: urlParams });
    } else {
      setIsFirstLaunch(false);
    }
  }, [urlParams]);

  const onInputChange = filterName => inputValue => {
    setFastFiltersModified(prevState => ({
      ...prevState,
      [filterName]: {
        ...prevState[filterName],
        choiceValuesAfterSearch: prevState[filterName].choiceValues.filter(({ name }) =>
          name.toLowerCase().includes(inputValue.toLowerCase())
        ),
      },
    }));
  };

  return (
    <div className={classNames('FastFilters', { 'FastFilters--Closed': !allFilterListOpen })}>
      {Object.keys(fastFiltersModified).map(filterName => {
        const currentFastFilter = fastFiltersModified[filterName];
        const withInput =
          currentFastFilter.inputType === 'inputIntegerChoice' || currentFastFilter.inputType === 'inputStringChoice';
        if (
          currentFastFilter.inputType === 'inputIntegerChoice' ||
          currentFastFilter.inputType === 'inputStringChoice' ||
          currentFastFilter.inputType === 'inputBoolean'
        ) {
          return (
            <InputIntegerChoice
              key={`fastFilter-${filterName}`}
              onInputChange={withInput ? onInputChange(filterName) : () => {}}
              options={
                withInput
                  ? fastFiltersModified[filterName].choiceValuesAfterSearch
                  : fastFiltersModified[filterName].choiceValues
              }
              name={filterName}
              multi={currentFastFilter.inputType !== 'inputBoolean'}
              isNew={
                filterName === 'accountTypeContactFilter' ||
                filterName === 'tagContactFilter' ||
                filterName === 'clientTypeContactFilter'
              }
              withInput={withInput}
              onSelect={value => {
                onChange(filterName, currentFastFilter.inputType, value);
              }}
            />
          );
        }

        if (currentFastFilter.inputType === 'inputDate') {
          return (
            <InputDate
              key={`fastFilter-${filterName}`}
              options={fastFiltersModified[filterName].choiceValues}
              name={filterName}
              onSelect={value => {
                onChange(filterName, currentFastFilter.inputType, value);
              }}
            />
          );
        }

        if (currentFastFilter.inputType === 'inputStr') {
          return (
            <InputStr
              key={`fastFilter-${filterName}`}
              name={filterName}
              value={currentFastFilter.value}
              onChange={value => {
                onChange(filterName, currentFastFilter.inputType, value);
              }}
            />
          );
        }

        return '';
      })}
      {Object.keys(fastFilters).length !== 1 && (
        <button
          className={classNames('FastFilters__OpenMoreFiltersButton', {
            FastFilters__OpenMoreFiltersButtonOpen: allFilterListOpen,
          })}
          type="button"
          onClick={() => {
            setAllFilterListOpen(!allFilterListOpen);
          }}>
          <FM id={allFilterListOpen ? 'justLessFilters' : 'justMoreFilters'}>{txt => txt}</FM>
        </button>
      )}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

// TODO: Перенести в отдельный файл
const FastFiltersWrapper = ({ getFastFiltersFunc, fastFiltersSelector, customValueOfOneFilter }) => {
  const [fastFiltersModified, setFastFiltersModified] = useState({});
  const onChange = (filterName, inputType, newValue) => {
    if (inputType === 'inputStr') {
      setFastFiltersModified(prevState => {
        const currentFilter = prevState[filterName];
        return {
          ...prevState,
          [filterName]: {
            ...currentFilter,
            value: newValue,
          },
        };
      });
      return;
    }
    if (inputType === 'inputDate') {
      setFastFiltersModified(prevState => {
        const currentFilter = prevState[filterName];

        const newChoiceValues = prevState[filterName].choiceValues.map(choiceValue => {
          if (choiceValue.label === newValue.label) {
            return { ...choiceValue, value: newValue.value, isSelected: true };
          }
          return { ...choiceValue, isSelected: false };
        });
        return {
          ...prevState,
          [filterName]: {
            ...currentFilter,
            choiceValues: newChoiceValues,
          },
        };
      });
    }
    if (inputType === 'inputIntegerChoice' || inputType === 'inputStringChoice' || inputType === 'inputBoolean') {
      if (newValue === 'All') {
        setFastFiltersModified(prevState => {
          const currentFilter = prevState[filterName];
          const { choiceValues, choiceValuesAfterSearch } =
            getNewChoiceValuesAndChoiceValuesAfterSearchIfNewValueIsAll(currentFilter);

          return {
            ...prevState,
            [filterName]: {
              ...currentFilter,
              choiceValues,
              choiceValuesAfterSearch,
            },
          };
        });
      } else {
        setFastFiltersModified(prevState => {
          const currentFilter = prevState[filterName];

          const { choiceValues, choiceValuesAfterSearch } =
            inputType !== 'inputBoolean'
              ? getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAll(currentFilter, newValue)
              : getNewChoiceValuesAndChoiceValuesAfterSearchIfNotAllAndTypeBoolean(currentFilter, newValue);

          return {
            ...prevState,
            [filterName]: {
              ...currentFilter,
              choiceValues,
              choiceValuesAfterSearch,
            },
          };
        });
      }
    }
  };

  useEffect(() => {
    onChange(customValueOfOneFilter);
  }, [customValueOfOneFilter]);
  return (
    <FastFilters
      getFastFiltersFunc={getFastFiltersFunc}
      fastFiltersSelector={fastFiltersSelector}
      fastFiltersModified={fastFiltersModified}
      setFastFiltersModified={setFastFiltersModified}
      onChange={onChange}
    />
  );
};

FastFiltersWrapper.propTypes = {
  getFastFiltersFunc: PropTypes.object.isRequired,
  fastFiltersSelector: PropTypes.func.isRequired,
};

FastFilters.propTypes = {
  fastFiltersModified: PropTypes.object.isRequired,
  setFastFiltersModified: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  getFastFiltersFunc: PropTypes.object.isRequired,
  fastFiltersSelector: PropTypes.func.isRequired,
};

export default FastFiltersWrapper;
