import css from 'styled-jsx/css'

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .FunnelChart {
    padding: 40px 40px 15px 25px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.06);
    width: 65%;
    @include respond-to(lg) {
      padding: 30px 30px 15px 15px;
    }
    @include respond-to(sm) {
      padding: 20px 20px 15px 5px;
      width: 100%;
    }
  }

  .FunnelChart__chart-description-text {
    position: relative;
    bottom: 20px;
    margin-left: 15px;
    font-size: 11px;
  }

  .FunnelChart__inner {
    direction: ltr;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
  }

  .ChartInner {
    width: 70%;
    @include respond-to(lg) {
      width: 65%;
    }
    @include respond-to(sm) {
      width: 100%;
    }
  }

  .FunnelChart__chart-description {
    width: 30%;
    padding: 40px 0;
    @include respond-to(lg) {
      width: 35%;
    }
    @include respond-to(sm) {
      display: none;
    }
    .FunnelChart__text {
      padding-bottom: 3px;
      margin-bottom: 9px;
      position: relative;
      height: 49px;
      &:after {
        content: '';
        position: absolute;
        left: -300%;
        bottom: 0;
        width: 100vw;
        height: 1px;
        opacity: .5;
      }
      p {
        font-size: 13px;
        white-space: nowrap;
        @include respond-to(md) {
          font-size: 11px;
        }
      }
      strong {
        font-size: 20px;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }

  :global(.FunnelChart__chart) {
    :global(span) {
      position: relative;
      top: 2px;
    }
  }
`

const getDynamicStyles = ({ colors, border }) => {
  return css`
    .FunnelChart {
      background: ${colors.white.hex};
      border-radius: ${border.radius};
    }

    .FunnelChart__chart-description-text {
      color: ${colors.lightGray.hex};
    }

    .FunnelChart__chart-description {
      .FunnelChart__text {
        text-align: right;
        &:after {
          background: ${colors.lightGray.hex};
        }
        p {
          color: ${colors.gray.hex};
        }
        strong {
          color: ${colors.primary.hex};
        }
      }
    }
  `
}

export { staticStyles, getDynamicStyles }
