import css from 'styled-jsx/css';
import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  :global([data-for='leftNavigationTooltip'].Tooltip__text) {
    position: absolute;
    left: ${isRTL ? 'auto' : '0'};
    right: ${isRTL ? '0' : 'auto'};
  }

  .LeftNavigation {
    padding-top: 95px;
    width: 240px;
    height: 100%;
    position: absolute;
    left: ${isRTL ? 'auto' : '0'};
    right: ${isRTL ? '0' : 'auto'};
    @include respond-to(lg) {
      width: 200px;
    }
    @include respond-to(md) {
      padding: 100px 0 50px;
      width: 250px;
      max-height: 100vh;
      overflow-y: auto;
      transition: all 0.5s ease;
      z-index: 30;
      top: 0;
      height: 100%;
      box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.05);
      transform: translateX(${isRTL ? '200%' : '-200%'});
      position: fixed;
    }

    &__crm {
      @include min-width(991) {
        width: 41px;
        background: #f8faff;
      }
    }
  }

  .LeftNavigation__disabled {
    &:after {
      content: '';
      z-index: 3;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: calc(100vh - 95px);
      cursor: not-allowed;
      @include respond-to(md) {
        display: none;
      }
    }
    .LeftNavigationLink,
    .LeftNavigation__caption {
      filter: blur(2px);
      user-select: none;
    }
    .LeftNavigation__mobile-link {
      .LeftNavigationLink {
        filter: blur(0);
        user-select: auto;
      }
    }
  }

  .LeftNavigation__caption {
    font-size: 11px;
    display: block;
    line-height: 18px;
    margin-top: 25px;
    margin-bottom: 10px;
    text-transform: uppercase;
    &:first-child {
      margin-top: 0;
    }
  }

  .LeftNavigation__mobile-inner {
    @include respond-to(md) {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      display: block;
      height: 65px;
    }
  }

  .LeftNavigation__interface-switcher {
    .TopMenu__switch-interface-inner {
      display: none;
      transition: all 0.2s ease;
      @include respond-to(md) {
        opacity: 0;
        display: block;
        position: absolute;
        top: 36%;
        left: ${isRTL ? 'auto' : '60px'};
        right: ${isRTL ? '45px' : '0'};
      }
      .Select__menu {
        top: 42px !important;
      }
    }
  }

  .LeftNavigation__logo {
    display: none;
  }

  .mobileOpen {
    @include respond-to(md) {
      transform: translateX(0%);
      .LeftNavigation__interface-switcher {
        .TopMenu__switch-interface-inner {
          @include respond-to(md) {
            opacity: 1;
          }
        }
      }

      .LeftNavigation__mobile-inner {
        .LeftNavigation__logo {
          position: absolute;
          left: 85px;
          top: 50%;
          transform: translateY(-44%);
          display: block;
          text-align: center;
          @include respond-to(md) {
            display: none;
          }
          svg,
          img {
            width: auto;
            max-height: 45px;
            max-width: 130px;
            border: none;
            line-height: 1;
          }
          svg {
            height: 45px;
          }
        }
      }
      .LeftNavigation__caption {
        font-weight: 500;
      }
    }
  }

  .LeftNavigation__mobile-icons,
  .LeftNavigation__absolute-link {
    display: none;
    @include respond-to(md) {
      display: block;
    }
  }

  .LeftNavigation__absolute-link {
    margin-top: rem(20px);
  }

  .LeftNavigation__mobile-link {
    display: none;
  }

  .LeftNavigation__client {
    .LeftNavigation__mobile-link {
      @include respond-to(md) {
        display: block;
        margin-top: 30px;
      }
    }
  }

  .LeftNavigation__admin,
  .LeftNavigation__client {
    @include respond-to(md) {
      min-height: 100vh;
    }

    .LeftNavigation__interface-switcher {
      .TopMenu__switch-interface-inner {
        @include respond-to(md) {
          width: 140px;
        }
      }
    }
  }

  .LeftNavigation__backoffice {
    .LeftNavigation__interface-switcher {
      .TopMenu__switch-interface-inner {
        @include respond-to(md) {
          width: 169px;
        }
        .Select__menu {
          width: 95% !important;
        }
      }
    }
  }

  .LeftNavigation__favorites {
    position: relative;
    margin-top: 60px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .LeftNavigation {
      @include respond-to(md) {
        background-color: ${colors.mobileMenu.hex};
        &:after {
          background-color: ${colors.mobileMenu.hex};
        }
      }
    }

    .LeftNavigation__caption {
      padding-left: ${isRTL ? '0' : '35px'};
      padding-right: ${isRTL ? '35px' : '0'};
      color: ${colors.gray.hex};
    }

    .LeftNavigation__mobile-inner {
      @include respond-to(md) {
        border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.4])};
      }
    }

    .LeftNavigationLink {
      &::after {
        right: ${isRTL ? '0' : 'auto'};
      }
      p {
        @include respond-to(md) {
          color: ${colors.white.hex};
        }
      }
    }

    .mobileOpen {
      @include respond-to(md) {
        .LeftNavigation__mobile-inner {
          .LeftNavigation__logo {
            svg {
              .white {
                fill: ${colors.white.hex} !important;
              }
            }
          }
        }
        .LeftNavigation__caption {
          color: ${colors.gray.hex};
        }
      }
    }

    .LeftNavigation__backoffice,
    .LeftNavigation__admin {
      .hamburger {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          background: ${colors.white.hex};
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
