import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .AllTasks {
    &__name {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin: 0 0 20px 0;
    }

    &__progress-plan-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -25px calc(40px - 13px) -25px;

      @include respond-to(md) {
        margin: 0 -15px calc(40px - 13px) -15px;
      }

      @include respond-to(sm) {
        flex-direction: column;
        margin: 0 0 calc(20px - 13px) 0;
      }
    }

    &__progress-plan-item {
      margin: 0 25px 13px 25px;
      width: calc(50% - 50px);

      @include respond-to(md) {
        width: calc(50% - 30px);
        margin: 0 15px 13px 15px;
      }

      @include respond-to(sm) {
        width: 100%;
        margin: 0 0 13px 0;
      }

      &:nth-of-type(1) {
        @include respond-to(sm) {
          order: 1;
        }
      }

      &:nth-of-type(2) {
        @include respond-to(sm) {
          order: 4;
        }
      }

      &:nth-of-type(3) {
        @include respond-to(sm) {
          order: 2;
        }
      }

      &:nth-of-type(4) {
        @include respond-to(sm) {
          order: 4;
        }
      }

      &:nth-of-type(5) {
        @include respond-to(sm) {
          order: 3;
        }
      }

      &:nth-of-type(6) {
        @include respond-to(sm) {
          order: 6;
        }
      }
    }

    &__pagination {
      margin: 15px 0 0 0;
    }
  }
`;

export { staticStyles };
