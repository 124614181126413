import { FAILURE, REQUEST, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';
import { createFormAction } from 'redux-form-saga';

export const GET_MANAGERS_SAMPLE_LIST = createRequestTypes('GET_MANAGERS_SAMPLE_LIST');
export const getManagersSampleList = {
  request: data => action(GET_MANAGERS_SAMPLE_LIST[REQUEST], data),
  success: data => action(GET_MANAGERS_SAMPLE_LIST[SUCCESS], { data }),
  failure: error => action(GET_MANAGERS_SAMPLE_LIST[FAILURE], { error }),
};

export const GET_MANAGER_INFO = createRequestTypes('GET_MANAGER_INFO');
export const getManagerInfo = {
  request: data => action(GET_MANAGER_INFO[REQUEST], data),
  success: data => action(GET_MANAGER_INFO[SUCCESS], { data }),
  failure: error => action(GET_MANAGER_INFO[FAILURE], { error }),
};

export const GET_MANAGER_SAMPLE = createRequestTypes('GET_MANAGER_SAMPLE');
export const getManagerSample = {
  request: data => action(GET_MANAGER_SAMPLE[REQUEST], data),
  success: data => action(GET_MANAGER_SAMPLE[SUCCESS], { data }),
  failure: error => action(GET_MANAGER_SAMPLE[FAILURE], { error }),
};

export const createManagerSample = createFormAction('CREATE_MANAGER_SAMPLE');

export const editManagerSample = createFormAction('EDIT_MANAGER_SAMPLE');

export const GET_MANAGERS_GROUP_LIST = createRequestTypes('GET_MANAGERS_GROUP_LIST');
export const getManagersGroupList = {
  request: data => action(GET_MANAGERS_GROUP_LIST[REQUEST], data),
  success: data => action(GET_MANAGERS_GROUP_LIST[SUCCESS], { data }),
  failure: error => action(GET_MANAGERS_GROUP_LIST[FAILURE], { error }),
};

export const createManagerGroup = createFormAction('CREATE_MANAGER_GROUP');

export const GET_MANAGER_GROUP_DETAILS = createRequestTypes('GET_MANAGER_GROUP_DETAILS');
export const getManagerGroupDetails = {
  request: data => action(GET_MANAGER_GROUP_DETAILS[REQUEST], data),
  success: data => action(GET_MANAGER_GROUP_DETAILS[SUCCESS], { data }),
  failure: error => action(GET_MANAGER_GROUP_DETAILS[FAILURE], { error }),
};

export const editManagerGroup = createFormAction('EDIT_MANAGER_GROUP');

export const DELETE_MANAGER_GROUP = createRequestTypes('DELETE_MANAGER_GROUP');
export const deleteManagerGroup = {
  request: data => action(DELETE_MANAGER_GROUP[REQUEST], data),
  success: () => action(DELETE_MANAGER_GROUP[SUCCESS]),
  failure: error => action(DELETE_MANAGER_GROUP[FAILURE], { error }),
};

export const GET_FILTERS_LIST = createRequestTypes('GET_FILTERS_LIST');
export const getFiltersList = {
  request: () => action(GET_FILTERS_LIST[REQUEST]),
  success: data => action(GET_FILTERS_LIST[SUCCESS], { data }),
  failure: error => action(GET_FILTERS_LIST[FAILURE], { error }),
};
