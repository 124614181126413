import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .SymbolsChart {
    margin-bottom: rem(30px);

    .SymbolsChart__description {
      font-size: rem(15px);
      line-height: 1.6;
    }

    .SymbolsChart__chart-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 360px;
      padding: rem(20px);
      margin-bottom: rem(30px);
    }

    :global(.SymbolsChart__inner) {
      width: 100%;
    }
    :global(.apexcharts-legend-series) {
      margin: rem(5px) !important;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    @import 'src/common/styling/_mixins-scss/rem';

    .SymbolsChart__chart-wrapper {
      background: ${colors.white.hex};
    }

    :global(.apexcharts-legend-text) {
      margin-left: rem(5px) !important;
      color: ${colors.black.hex} !important;
    }
  `;
};
export { staticStyles, getDynamicStyles };
