import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import { DateCell, ActionsButton } from 'common/components';
import { EditIcon, BasketIcon } from 'common/icons';
import { withTheme } from 'common/styling/theme';
import ContactNoteDocuments from '../ContactNoteDocuments';
import { staticStyles, getDynamicStyles } from './style';

import { NoteIcon } from './icons';

const ContactNote = ({
  noteId,
  created,
  createdBy,
  title,
  text,
  documents,
  handleDownloadNote,
  handleEditNote,
  handleDeleteNote,
  handleRemoveNoteDocument,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  return (
    <div className="ContactNote">
      <div className="ContactNote__content">
        <div className="ContactNote__NoteIcon">
          <NoteIcon />
        </div>
        <div className="ContactNote__Info">
          <p className="ContactNote__Tilte">{title}</p> <pre className="ContactNote__Text">{text}</pre>
        </div>
        <div className="ContactNote__Author">
          <p>{createdBy.email}</p>
        </div>
        <div className="ContactNote__DateCreated">
          <DateCell inLine value={created} />
        </div>

        <div className="ContactNote__Files">
          {documents.length ? (
            <>
              <FM id="crmFiles">{txt => <span className="ContactNote__FilesText">{txt}</span>}</FM>
              <ContactNoteDocuments
                documents={documents}
                onDownload={handleDownloadNote}
                onRemove={handleRemoveNoteDocument}
              />
            </>
          ) : null}
        </div>

        <div className="ContactNote__Actions">
          <ActionsButton
            tooltipId="Edit__tooltip"
            tooltipTextId="justEditNote"
            edit
            onClickFunc={() => handleEditNote(noteId)}>
            <EditIcon />
            <FM id="justEdit" />
          </ActionsButton>
          <ActionsButton
            tooltipId="Delete__tooltip"
            tooltipTextId="justDeleteNote"
            deleteType
            onClickFunc={() => handleDeleteNote(noteId)}>
            <BasketIcon /> <FM id="doRemove" />
          </ActionsButton>
        </div>
      </div>

      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

ContactNote.propTypes = {
  noteId: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  handleDownloadNote: PropTypes.func.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired,
  handleRemoveNoteDocument: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

ContactNote.defaultProps = {
  theme: {},
};

export default withTheme()(ContactNote);
export { ContactNote };
