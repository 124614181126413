import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  initialization: {
    ready: false,
    error: null,
  },
  lang: 'no-lang',
  availableLangs: [],
  clientThemeConfig: undefined,
  gtmId: undefined,
  partnership: false,
  files: false,
  internalTransfers: {},
  emailConfirmation: {},
  addons: [],
  features: [],
  kycSettings: {
    mode: {
      type: 'account',
    },
  },
  registration: {
    agreements: [],
  },
  loadedLangs: {},
  cssConfig: {},
  cssConfigIsLoaded: false,
  customJs: {},
  customJsIsLoaded: false,
  messages: {},
  loading: false,
  error: false,
  isLanguageChanging: false,
  authBanners: [],
  companyNews: [],
  companyNewsAreLoaded: false,
  contractSpecs: false,
  baseValue: 'balance',
  invest: {
    enabled: false,
  },
  disclamer: {},
  paymentSettings: {
    isDepositEnabled: false,
    isWithdrawEnabled: false,
  },
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE_APP.SUCCESS:
      return { ...state, initialization: { ready: true } };
    case ActionTypes.INITIALIZE_APP.FAILURE:
      return { ...state, initialization: { error: action.error } };

    case ActionTypes.GET_INTERFACE_CONFIG.REQUEST:
      return { ...state, loading: true, error: false };
    case ActionTypes.GET_INTERFACE_CONFIG.SUCCESS:
      return {
        ...state,
        loading: false,
        availableLangs: action.data.locales,
        clientThemeConfig: action.data.interfaceSettings,
        gtmId: action.data.gtmId,
        partnership: action.data.partnership,
        kycSettings: action.data.kycSettings,
        registration: action.data.registration,
        other: action.data.other,
        banners: action.data.banners,
        authBanners: action.data.auth_banners,
        files: action.data.files,
        internalTransfers: action.data.internalTransfers,
        emailConfirmation: action.data.emailConfirmation,
        addons: action.data.addons,
        features: action.data.features,
        contractSpecs: action.data.contractSpecs,
        invest: action.data.invest,
        baseValue: action.data.baseValue,
        equityTradingHistoryDisplay: action.data.equityTradingHistoryDisplay,
        defaultBonusType: action.data.defaultBonusType,
        snapEngage: action.data.snapEngage,
        disclamer: action.data.disclamer,
        paymentSettings: {
          isDepositEnabled: action.data.payment_settings.deposit_enabled,
          isWithdrawEnabled: action.data.payment_settings.withdraw_enabled,
        },
      };
    case ActionTypes.GET_INTERFACE_CONFIG.FAILURE:
      return { ...state, loading: false, error: action.error };

    case ActionTypes.GET_CUSTOM_CSS.REQUEST:
      return { ...state, cssConfigIsLoaded: false };
    case ActionTypes.GET_CUSTOM_CSS.SUCCESS:
      return { ...state, cssConfig: action.data, cssConfigIsLoaded: true };
    case ActionTypes.GET_CUSTOM_CSS.FAILURE:
      return { ...state, cssConfigIsLoaded: false, error: action.error };

    case ActionTypes.GET_CUSTOM_JS.REQUEST:
      return { ...state, customJsIsLoaded: false };
    case ActionTypes.GET_CUSTOM_JS.SUCCESS:
      return { ...state, customJs: action.data, customJsIsLoaded: true };
    case ActionTypes.GET_CUSTOM_JS.FAILURE:
      return { ...state, customJsIsLoaded: false, error: action.error };

    case ActionTypes.LOAD_LANGUAGE.REQUEST:
      return { ...state, loading: true, error: false };
    case ActionTypes.LOAD_LANGUAGE.SUCCESS:
      return {
        ...state,
        loading: false,
        loadedLangs: { ...state.loadedLangs, [action.lang]: action.messages },
      };
    case ActionTypes.LOAD_LANGUAGE.FAILURE:
      return { ...state, loading: false, error: action.error };

    case ActionTypes.CHANGE_LANGUAGE.REQUEST:
      return { ...state, isLanguageChanging: true };
    case ActionTypes.CHANGE_LANGUAGE.SUCCESS:
      if (state.availableLangs.find(item => item.slug === action.lang) && action.lang in state.loadedLangs) {
        return {
          ...state,
          lang: action.lang,
          isLanguageChanging: false,
          messages: { ...state.loadedLangs[action.lang] },
        };
      }
      return state;
    case ActionTypes.CHANGE_LANGUAGE.FAILURE:
      return { ...state, isLanguageChanging: false, error: action.error };

    case ActionTypes.GET_COMPANY_NEWS[REQUEST]:
      return { ...state, companyNewsAreLoaded: false };

    case ActionTypes.GET_COMPANY_NEWS[SUCCESS]:
      return {
        ...state,
        companyNews: action.data,
        companyNewsAreLoaded: true,
      };

    case ActionTypes.GET_COMPANY_NEWS[FAILURE]:
      return {
        ...state,
        error: action.error,
        companyNewsAreLoaded: false,
      };

    default:
      return state;
  }
};

export const stateSelector = state => state.interfaceConfig;

export default langReducer;
