import React from 'react';

import { withTheme } from 'common/styling/theme';
import { FormattedMessage as FM } from 'react-intl';

import { staticStyles } from './style';

const UnderChartInfo = ({ textId, values }) => (
  <div className="UnderChartInfo">
    <span className="UnderChartInfo__Title">
      <FM id={textId}>{txt => txt}</FM>
    </span>
    {values.map(({ textId, value, color }, ind) => (
      <div className="UnderChartInfo__Item" key={`${textId}+${ind}-UnderChartInfo`} style={{ background: color }}>
        <span className="UnderChartInfo__ItemTitle">
          <FM id={textId}>{txt => txt}</FM>
        </span>
        <span className="UnderChartInfo__ItemValue">{value}</span>
      </div>
    ))}

    <style jsx global>
      {staticStyles}
    </style>
  </div>
);

export default withTheme()(UnderChartInfo);
