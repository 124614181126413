import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Picker from 'react-month-picker';
import moment from 'moment';

import { staticStyles } from './style';

const MonthPicker = ({ monthValue, years, handleChange }) => {
  const [isMonthPickerOpen, toggleMonthPicker] = useState(false);
  const refPickAMonth = useRef(null);

  useEffect(() => {
    if (isMonthPickerOpen) {
      refPickAMonth.current.show();
    }
  }, [isMonthPickerOpen]);

  const pickerLang = {
    months: moment.monthsShort(),
  };

  const makeText = m => {
    if (m && m.year && m.month) return `${pickerLang.months[m.month - 1]}. ${m.year}`;
    return '?';
  };

  const handleClickMonthBox = () => {
    toggleMonthPicker(true);
  };

  const handleMonthChange = (year, month) => {
    handleChange({ year, month });
    toggleMonthPicker(false);
  };

  const handleMonthDismiss = () => {
    toggleMonthPicker(false);
  };

  return (
    <>
      <Picker
        ref={refPickAMonth}
        years={years}
        value={monthValue}
        lang={pickerLang.months}
        onChange={handleMonthChange}
        onDismiss={handleMonthDismiss}>
        <button
          type="button"
          className={`month-picker__box ${isMonthPickerOpen ? 'month-picker__box_open' : ''}`}
          onClick={handleClickMonthBox}>
          <span>{makeText(monthValue)}</span>
          <i className="month-picker__icon" />
        </button>
      </Picker>
      <style jsx>{staticStyles}</style>
    </>
  );
};

MonthPicker.propTypes = {
  years: PropTypes.object,
  monthValue: PropTypes.object,
  handleChange: PropTypes.func,
};

MonthPicker.defaultProps = {
  years: { min: 2010, max: 2019 },
  monthValue: { year: 2018, month: 1 },
  handleChange: null,
};

export default MonthPicker;
