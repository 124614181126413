import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { BaseTable, Collapsible, NoItemsPanel, MoneyCell, TableLoader, DateCell } from 'common/components';

import { withTheme } from 'common/styling/theme';
import noPaymentsImage from 'common/images/noPayments.svg';
import { getEditHistory } from '../../payments/_redux';
import { staticStyles, getDynamicStyles } from './style';

const UserAmountEditHistory = ({ userEditHistory, userEditHistoryIsLoaded, getEditHistory, accountId, theme }) => {
  const handleOpenCollapse = () => {
    getEditHistory(accountId);
  };

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  /* eslint-disable */
  const columns = [
    {
      key: 'justDate',
      Cell: ({ original: { created } }) => <DateCell value={created} />,
      isPreviewCell: true,
    },
    {
      key: 'justAmount',
      Cell: ({ original: { id, amount } }) => (
        <MoneyCell tooltipId={`backoffice-UserAmountEdit-history-justAmount-${id}`} inTable value={amount} />
      ),
      isPreviewCell: true,
    },
    {
      key: 'justPreviousAmount',
      Cell: ({ original: { id, prevAmount } }) => (
        <MoneyCell
          tooltipId={`backoffice-UserAmountEdit-history-justPreviousAmount-${id}`}
          inTable
          value={prevAmount}
        />
      ),
      isPreviewCell: true,
    },
    {
      key: 'justAuthor',
      Cell: ({ original: { actor } }) => (
        <div className="actor">
          <span>{actor.firstName}</span>
          <span>{actor.lastName}</span>
        </div>
      ),
    },
    {
      key: 'justComment',
      Cell: ({ original: { comment } }) => <div className="comment">{comment}</div>,
    },
  ];
  /* eslint-enable */

  const onRowClick = () => {};

  return (
    <div className="UserAmountEditHistory">
      <Collapsible captionId="justAmountEditHistory" handleOpen={handleOpenCollapse}>
        {!userEditHistoryIsLoaded && <TableLoader />}
        {userEditHistoryIsLoaded && (
          <div>
            {userEditHistory.length ? (
              <BaseTable columns={columns} data={userEditHistory} onRowClick={onRowClick} isResponsive />
            ) : (
              <NoItemsPanel icon={noPaymentsImage} captionSlug="noData" />
            )}
          </div>
        )}
      </Collapsible>
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </div>
  );
};

UserAmountEditHistory.propTypes = {
  userEditHistory: PropTypes.array.isRequired,
  userEditHistoryIsLoaded: PropTypes.bool.isRequired,
  getEditHistory: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withTheme(),
  connect(
    state => ({
      userEditHistory: state.backoffice.payments.userEditHistory,
      userEditHistoryIsLoaded: state.backoffice.payments.userEditHistoryIsLoaded,
    }),
    {
      getEditHistory: id => getEditHistory.request(id),
    }
  )
)(UserAmountEditHistory);
export { UserAmountEditHistory };
