import React from 'react';
import PropTypes from 'prop-types';
import { AllTasksTableCalls } from 'crm/tasks/_components';

import { useHistory } from 'react-router-dom';
import arrowBack from './images/arrowBack.svg';
import { staticStyles, getDynamicStyles } from './style';

const TasksCallsPage = ({ theme }) => {
  const dynamicStyles = Object.keys({}).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();

  return (
    <div className="TasksCallsPage">
      <div className="TasksCallsPage__back">
        <img className="TasksCallsPage__back-icon" src={arrowBack} alt="arrow back" />
        <button type="button" className="TasksCallsPage__back-text" onClick={() => history.back()}>
          Назад
        </button>
      </div>
      <div className="TasksCallsPage__manager-name">Manager Name (Calls)</div>
      <AllTasksTableCalls />
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

TasksCallsPage.propTypes = {
  theme: PropTypes.object,
};

TasksCallsPage.defaultProps = {
  theme: {},
};

export default TasksCallsPage;
