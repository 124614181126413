import React, { useState } from 'react';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ActionsButton } from 'common/components';
import { FormMessageHOC } from 'common';

import { SelectActionAndTextField } from 'crm/_newComponents';

import { withTheme } from 'common/styling/theme';
import retryIcon from './retryIcon.svg';

import { staticStyles } from './style';

const CommentCell = ({ id, status, comment, commentedBy, profile }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  if (isEditOpen || !status) {
    return <SelectActionAndTextField taskId={id} managerId={profile.id} setIsEditOpen={setIsEditOpen} />;
  }
  return (
    <div className="CommentCell">
      <div className="CommentCell__Comment">{comment ?? <FM id="justNoComment">{txt => txt}</FM>}</div>
      <div className="CommentCell__CommentedBy">{commentedBy ?? '---'}</div>
      <div className="CommentCell__Retry">
        <ActionsButton edit onClickFunc={() => setIsEditOpen(true)}>
          <img src={retryIcon} alt="retryIcon" />
        </ActionsButton>
      </div>
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

CommentCell.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  comment: PropTypes.string,
  commentedBy: PropTypes.string,
  profile: PropTypes.object.isRequired,
};

CommentCell.defaultProps = {
  comment: null,
  commentedBy: null,
};

export default compose(
  injectIntl,
  withTheme(),
  FormMessageHOC,
  connect(state => ({ profile: state.user.profile }))
)(CommentCell);
