import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .InvestRatingTable {
    :global(.ReactDataTable__wrapper) {
      :global(.ReactDataTable) {
        :global(.ReactDataTable__thead) {
          :global(tr) {
            :global(th) {
              :global(.ReactDataTable__thead-caption) {
                text-align: center;
              }
            }
          }
        }
        :global(tr) {
          grid-template-columns:
            minmax(100px, 1.5fr) repeat(4, minmax(50px, 1fr)) minmax(100px, 2fr)
            repeat(2, minmax(50px, 1fr));
        }

        :global(.ReactDataTable__tbody-row) {
          height: 100px;
          transition: 0.4s transform;
          &:hover {
            transform: scale(1.02);
          }
        }

        :global(.ReactDataTable__thead-cell:not(:first-child), .ReactDataTable__tbody-cell:not(:first-child)) {
          display: flex;
          justify-content: center;
        }

        :global(.ReactDataTable__thead-cell:first-child, .ReactDataTable__tbody-cell:first-child) {
          padding-left: rem(20px) !important;
        }

        :global(.InvestRatingTable__strategy-caption) {
          margin-bottom: rem(5px);
        }

        :global(.InvestRatingTable__strategy-symbols) {
          font-size: rem(12px);
        }
      }
    }

    :global(.InvestRatingTable__strategy) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    :global(.InvestRatingTable__yield) {
      flex-direction: column;
    }
    :global(.InvestRatingTable__totalYield) {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css`
    :global(.InvestRatingTable__income--neg) {
      color: ${colors.error.hex};
    }

    :global(.InvestRatingTable__income--pos) {
      color: ${colors.success.hex};
    }

    :global(.InvestRatingTable__strategy-symbols) {
      color: ${colors.gray.hex};
    }
  `;
};

export { staticStyles, getDynamicStyles };
