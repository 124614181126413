import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import { withTheme, taggedStylesToString } from 'common/styling/theme';
import classNames from 'classnames';
import { staticStyles, getDynamicStyles } from './style';

const TextFormField = ({ externalClass, theme, labelTextId, input, meta, placeholderId, intl, ...props }) => {
  const error = meta.error && meta.touched;
  const dynamicStyles = Object.keys(theme).length ? taggedStylesToString(getDynamicStyles(theme)) : ` `;

  return (
    <div className={classNames('TextFormField', externalClass)}>
      {labelTextId && (
        <div className={classNames('TextFormField__label', { 'TextFormField__label--error': error })}>
          <FM id={labelTextId} />
        </div>
      )}
      <input
        className={classNames('TextFormField__input', { 'TextFormField__input--error': error })}
        type="text"
        placeholder={placeholderId ? intl.formatMessage({ id: placeholderId }) : ''}
        {...input}
        {...props}
      />
      {error && <div className="TextFormField__error">{meta.error}</div>}
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

TextFormField.propTypes = {
  intl: PropTypes.object.isRequired,
  externalClass: PropTypes.string,
  theme: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  placeholderId: PropTypes.string,
  labelTextId: PropTypes.string,
};

TextFormField.defaultProps = {
  externalClass: '',
  placeholderId: '',
  theme: {},
  input: {},
  meta: {},
  labelTextId: '',
};

export default withTheme()(injectIntl(TextFormField));
