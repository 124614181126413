/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';
import DropDown from './_components/DropDown';
import Triangle from './icons/Triangle';
import { staticStyles } from './style';

const BaseStaticSelect = ({ labelTextId, isDisabled, options, onSelect, multi, isNew, withInput, onInputChange }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    onInputChange(inputValue);
  }, [inputValue]);

  const innerLabel = useMemo(() => {
    const selectedOptions = options.filter(({ isSelected }) => isSelected);
    const selectedOptionsLength = selectedOptions.length;

    if (selectedOptionsLength === 0) {
      return 'All';
    }

    if (selectedOptionsLength === 1) {
      const thatOneLabel = options.find(({ isSelected }) => isSelected).label;
      return <FM id={thatOneLabel}>{txt => txt}</FM>;
    }

    const arrOfOptions = selectedOptions.map(({ label }, ind) => (
      <FM key={`FastFilter-${labelTextId}-elem-${ind}`} id={label}>
        {txt => `${txt}${ind !== selectedOptions.length - 1 ? ', ' : ' '}`}
      </FM>
    ));
    return (
      <div className="BaseStaticSelect__Labels">
        <span className="BaseStaticSelect__LabelsSelected">
          <div className="BaseStaticSelect__LabelsSelectedCentered">{arrOfOptions}</div>
        </span>
        <span className="BaseStaticSelect__LabelsAmount">{`(${selectedOptionsLength})`}</span>
      </div>
    );
  });

  const [buttonPosition, setButtonPosition] = useState(null);

  return (
    <div ref={ref} className="BaseStaticSelect">
      <button
        className={classNames('BaseStaticSelect__OpenOptionsButton', {
          'BaseStaticSelect__OpenOptionsButton--FastFilterClosed': !isOpen,
        })}
        disabled={isDisabled || !options?.length}
        type="button"
        onClick={e => {
          setIsOpen(!isOpen);
          setButtonPosition(e.target.getBoundingClientRect());
        }}>
        {isOpen && withInput && (
          <input
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value);
            }}
            className="BaseStaticSelect__Input"
            autoFocus
          />
        )}
        {!isOpen && innerLabel}
        <Triangle />
      </button>

      <DropDown
        isOpen={isOpen}
        position={buttonPosition}
        isNew={isNew}
        options={options}
        onSelect={onSelect}
        setIsOpen={setIsOpen}
        multi={multi}
      />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

BaseStaticSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  labelTextId: PropTypes.string,
  isDisabled: PropTypes.bool,
  multi: PropTypes.bool,
  withInput: PropTypes.bool,
};

BaseStaticSelect.defaultProps = {
  isDisabled: false,
  labelTextId: '',
  multi: false,
  withInput: false,
};

export default BaseStaticSelect;
