import css from "styled-jsx/css";

const staticStyles = css.global`
  @import "src/common/styling/_mixins-scss/screen-size";
  @import "src/common/styling/_mixins-scss/rem";

  .DashboardsPage__funnel,
  .DashboardsPage__month {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .DashboardsPage__month-title {
    margin-bottom: 25px;
  }
  
  .DashboardsPage__funnel-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: rem(20px);
  }

  .DashboardsPage__month-topCharts {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: rem(25px);
  }
  .TopMonthPicker {
    position: relative;
    .month-picker {
      position: absolute;
      right: 0;
    }
    .rmp-popup {
      right: -9rem;
      @include respond-to(sm) {
        right: 0;
      }
    }
  }
`;

export { staticStyles };
