import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import md5 from 'blueimp-md5';
import Gravatar from 'react-gravatar';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'common/styling/theme';

import { EditIcon } from 'common/icons';
import { ActionsButton, StatusCell, DateCell } from 'common/components';

import InfoLine from '../InfoLine';

import arrowTopKYC from './arrowTopKYC.svg';

import { staticStyles, getDynamicStyles } from './style';

const UserInfoPanel = ({
  user,
  getStates,
  states,
  countries,
  profile,
  addons,
  onUserEmailChangeOpenModal,
  onUserPhoneChangeOpenModal,
  onUserLastNameChangeOpenModal,
  onUserFirstNameChangeOpenModal,
  onUserMiddleNameChangeOpenModal,
  onUserBirthdayChangeOpenModal,
  onUserNationalityChangeOpenModal,
  onUserTaxResidenceChangeOpenModal,
  onUserPartnerOpenModal,
  onUserExtensionNumberEditModal,
  userInfoPage,
  captionSlug,
  onClick,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const editUserInfoCondition = userInfoPage && profile.perms.viewUser && !!profile.perms.editUser && !user.isStaff;
  const editUserExtensionNumberCondition =
    userInfoPage && profile.perms.viewUser && !!profile.perms.editUser && user.isStaff;
  const [openBlock, setOpenBlock] = useState(true);

  const countryData = user.dataKyc.address?.country ?? '';
  const stateData = user.dataKyc.address?.state ?? '';
  const cityData = user.dataKyc.address?.city ?? '';
  const addressData = user.dataKyc.address?.address ?? '';

  const countryFilter = countries.filter(({ gid }) => gid === countryData);
  const country = countryFilter.length ? countryFilter[0]?.name : null;
  const rightEmpty = false;

  /* eslint-disable */
  useEffect(() => {
    if (countryData) getStates(countryData);
  }, [countryData]);
  /* eslint-enable */

  const regionNumber = stateData;
  const region = states && states.length ? states.filter(({ gid }) => gid === regionNumber)[0]?.name : null;
  return (
    <div className="PersonalInfo UserInfoPanel__inform-block PersonalInfo-block">
      {/* eslint-disable */}
      <div
        className={classNames('PersonalInfo__open-close', {
          'PersonalInfo__open-close--close': !openBlock,
        })}
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <FormattedMessage id={captionSlug}>
        {txt => (
          <h2
            className={classNames('PersonalInfo__title', {
              'PersonalInfo__title--hide': !openBlock,
            })}>
            {txt}
          </h2>
        )}
      </FormattedMessage>
      {/* eslint-disable */}
      <img
        className={classNames('PersonalInfo__arrowTopKYC', {
          'PersonalInfo__arrowTopKYC--hide': !openBlock,
        })}
        src={arrowTopKYC}
        alt="arrowTopKYC"
        onClick={() => setOpenBlock(!openBlock)}
      />
      {/* eslint-enable */}
      <div
        className={classNames('PersonalInfo__content', {
          'PersonalInfo__content--hide': !openBlock,
        })}>
        <div className="PersonalInfo__text">
          <div
            className={classNames('PersonalInfo__text-left', {
              'PersonalInfo__text-left--right-empty': rightEmpty,
            })}>
            {/* first-name */}
            <div className="PersonalInfo__first-name-wrap">
              <FormattedMessage id="kycFirstName">
                {txt => <div className="PersonalInfo__first-name-title">{txt}</div>}
              </FormattedMessage>
              <div className="PersonalInfo__first-name-data">
                <span>{user.firstName || user.dataKyc.firstName || '---'}</span>
                <ActionsButton
                  edit
                  buttonId="changeUserFirstName"
                  onClickFunc={onUserFirstNameChangeOpenModal}
                  condition={editUserInfoCondition}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* middle-name */}
            <div className="PersonalInfo__middle-name-wrap">
              <FormattedMessage id="kycMiddleName">
                {txt => <div className="PersonalInfo__middle-name-title">{txt}</div>}
              </FormattedMessage>
              <div className="PersonalInfo__middle-name-data">
                <span>{user.middleName || user.dataKyc.middleName || '---'}</span>
                <ActionsButton
                  edit
                  buttonId="changeUserMiddleName"
                  onClickFunc={onUserMiddleNameChangeOpenModal}
                  condition={editUserInfoCondition}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* last-name */}
            <div className="PersonalInfo__last-name-wrap">
              <FormattedMessage id="kycLastName">
                {txt => <div className="PersonalInfo__last-name-title">{txt}</div>}
              </FormattedMessage>
              <div className="PersonalInfo__last-name-data">
                <span>{user.lastName || user.dataKyc.lastName || '---'}</span>
                <ActionsButton
                  edit
                  buttonId="changeUserLastName"
                  onClickFunc={onUserLastNameChangeOpenModal}
                  condition={editUserInfoCondition}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* nationality */}
            <div className="PersonalInfo__nationality-wrap">
              <FormattedMessage id="kycNationality">
                {txt => <div className="PersonalInfo__nationality-title">{txt}</div>}
              </FormattedMessage>
              <div className="PersonalInfo__nationality-data">
                <span>{user.nationality || '---'}</span>
                <ActionsButton
                  edit
                  buttonId="userChangeNationality"
                  onClickFunc={onUserNationalityChangeOpenModal}
                  condition={editUserInfoCondition}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* email */}
            {onClick ? (
              <InfoLine textId="justEmail" data={user.email} wordBreak onClick={onClick} />
            ) : (
              <div className="PersonalInfo__email-wrap">
                <FormattedMessage id="justEmail">
                  {txt => <div className="PersonalInfo__email-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__email-data">
                  {user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : <p>---</p>}
                  <ActionsButton
                    edit
                    buttonId="changeUserEmail"
                    condition={editUserInfoCondition}
                    onClickFunc={onUserEmailChangeOpenModal}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}

            {/* phone */}
            <div className="PersonalInfo__phone-wrap">
              <FormattedMessage id="kycPhone">
                {txt => <div className="PersonalInfo__phone-title">{txt}</div>}
              </FormattedMessage>
              <div className="PersonalInfo__phone-data">
                {user.phone || user.dataKyc.phone ? (
                  <a href={`tel:${user.phone || user.dataKyc.phone}`}>{user.phone || user.dataKyc.phone}</a>
                ) : (
                  <p>---</p>
                )}
                <ActionsButton
                  edit
                  buttonId="changeUserPhone"
                  condition={editUserInfoCondition}
                  onClickFunc={onUserPhoneChangeOpenModal}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>
          </div>

          <div
            className={classNames('PersonalInfo__text-right', {
              'PersonalInfo__text-right--right-empty': rightEmpty,
            })}>
            {/* click2call */}
            {addons.includes('click2call') && user.isStaff && (
              <div className="PersonalInfo__extension-number-wrap">
                <FormattedMessage id="justExtensionNumber">
                  {txt => <div className="PersonalInfo__extension-number-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__extension-number-data">
                  <span>{user.extensionNumber || '---'}</span>
                  <ActionsButton
                    edit
                    buttonId="editExtensionNumber"
                    onClickFunc={onUserExtensionNumberEditModal}
                    condition={editUserExtensionNumberCondition}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}

            {/* tax-residence */}
            <div className="PersonalInfo__tax-residence-wrap">
              <FormattedMessage id="kycTaxResidence">
                {txt => <div className="PersonalInfo__tax-residence-title">{txt}</div>}
              </FormattedMessage>
              <div className="PersonalInfo__tax-residence-data">
                <span>{user.taxResidence || '---'}</span>
                <ActionsButton
                  edit
                  buttonId="userChangeTaxResidence"
                  onClickFunc={onUserTaxResidenceChangeOpenModal}
                  condition={editUserInfoCondition}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* birthday */}
            <div className="PersonalInfo__birthday-wrap">
              <FormattedMessage id="kycBirthday">
                {txt => <div className="PersonalInfo__birthday-title">{txt}</div>}
              </FormattedMessage>
              <div className="PersonalInfo__birthday-data">
                {user.dataKyc.birthday ? <DateCell value={user.dataKyc.birthday} /> : <p>---</p>}
                <ActionsButton
                  edit
                  buttonId="changeUserBirthday"
                  condition={editUserInfoCondition}
                  onClickFunc={onUserBirthdayChangeOpenModal}>
                  <EditIcon />
                </ActionsButton>
              </div>
            </div>

            {/* country */}
            {countryData && country && (
              <div className="PersonalInfo__country-wrap">
                <FormattedMessage id="kycCountry">
                  {txt => <div className="PersonalInfo__country-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__country-data">
                  <span>{country}</span>
                </div>
              </div>
            )}

            {/* region */}
            {stateData && region && (
              <div className="PersonalInfo__region-wrap">
                <FormattedMessage id="kycRegion">
                  {txt => <div className="PersonalInfo__region-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__region-data">
                  <span>{region}</span>
                </div>
              </div>
            )}

            {/* city */}
            {cityData && (
              <div className="PersonalInfo__city-wrap">
                <FormattedMessage id="kycCity">
                  {txt => <div className="PersonalInfo__city-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__city-data">
                  <span>{cityData}</span>
                </div>
              </div>
            )}

            {/* address */}
            {addressData && (
              <div className="PersonalInfo__address-wrap">
                <FormattedMessage id="kycAddress">
                  {txt => <div className="PersonalInfo__address-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__address-data">
                  <span>{addressData}</span>
                </div>
              </div>
            )}

            {/* first-deposit */}
            {user.firstDepositDate && (
              <div className="PersonalInfo__first-deposit-wrap">
                <FormattedMessage id="kycFirstDepositDate">
                  {txt => <div className="PersonalInfo__first-deposit-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__first-deposit-data">
                  <DateCell value={user.firstDepositDate} />
                </div>
              </div>
            )}

            {/* is-partner */}
            {user.isPartner && (
              <div className="PersonalInfo__is-partner-wrap">
                <FormattedMessage id="boPartnershipStatus">
                  {txt => <div className="PersonalInfo__is-partner-title">{txt}</div>}
                </FormattedMessage>
                <div className="PersonalInfo__is-partner-data">
                  <StatusCell statusCode="partner" />
                  <ActionsButton
                    edit
                    buttonId="actionPartnerStatus"
                    condition={!!profile.perms?.actionUser}
                    onClickFunc={onUserPartnerOpenModal}>
                    <EditIcon />
                  </ActionsButton>
                </div>
              </div>
            )}
          </div>
          {/* Photo */}
          <div className="PersonalInfo__profile-picture">
            <FormattedMessage id="kycPhoto">
              {txt => <div className="PersonalInfo__profile-photo-title">{txt}</div>}
            </FormattedMessage>
            <div className="PersonalInfo__photo">
              <Gravatar md5={md5(user.email)} size={170} />
            </div>
          </div>
          {!!user.lastUtm && (
            <div className="UserInfoPanel__inform">
              <FormattedMessage id="lastUtmMarkTitle">{txt => <h3>{txt}</h3>}</FormattedMessage>
              <div className="UserInfoPanel__inform-block_utm">
                <div>
                  <div className="InfoLine">
                    <FormattedMessage id="justCampaign" />
                    <div className="InfoLine__right">
                      <span>{user.lastUtm.utmCampaign ? user.lastUtm.utmCampaign : '一'}</span>
                    </div>
                  </div>

                  <div className="InfoLine">
                    <FormattedMessage id="utmSource" />
                    <div className="InfoLine__right">
                      <span>{user.lastUtm.utmSource ? user.lastUtm.utmSource : '一'}</span>
                    </div>
                  </div>

                  <div className="InfoLine">
                    <FormattedMessage id="utmMedium" />
                    <div className="InfoLine__right">
                      <span>{user.lastUtm.utmMedium ? user.lastUtm.utmMedium : '一'}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="InfoLine">
                    <FormattedMessage id="utmContent" />
                    <div className="InfoLine__right">
                      <span>{user.lastUtm.utmContent ? user.lastUtm.utmContent : '一'}</span>
                    </div>
                  </div>
                  <div className="InfoLine">
                    <FormattedMessage id="utmTerm" />
                    <div className="InfoLine__right">
                      <span>{user.lastUtm.utmTerm ? user.lastUtm.utmTerm : '一'}</span>
                    </div>
                  </div>
                  <div className="InfoLine">
                    <FormattedMessage id="utmDevice" />
                    <div className="InfoLine__right">
                      <span>{user.lastUtm.ua ? user.lastUtm.ua : '一'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

UserInfoPanel.propTypes = {
  user: PropTypes.object.isRequired,
  getStates: PropTypes.func,
  states: PropTypes.array,
  countries: PropTypes.array,
  profile: PropTypes.object,
  addons: PropTypes.array,
  onUserEmailChangeOpenModal: PropTypes.func,
  onUserPhoneChangeOpenModal: PropTypes.func,
  onUserLastNameChangeOpenModal: PropTypes.func,
  onUserFirstNameChangeOpenModal: PropTypes.func,
  onUserMiddleNameChangeOpenModal: PropTypes.func,
  onUserBirthdayChangeOpenModal: PropTypes.func,
  onUserNationalityChangeOpenModal: PropTypes.func,
  onUserTaxResidenceChangeOpenModal: PropTypes.func,
  onUserPartnerOpenModal: PropTypes.func,
  onUserExtensionNumberEditModal: PropTypes.func,
  userInfoPage: PropTypes.bool,
  onClick: PropTypes.func,
  captionSlug: PropTypes.string,
  theme: PropTypes.object,
};

UserInfoPanel.defaultProps = {
  profile: {},
  getStates: () => {},
  states: [],
  countries: [],
  addons: [],
  onUserEmailChangeOpenModal: null,
  onUserPhoneChangeOpenModal: null,
  onUserLastNameChangeOpenModal: null,
  onUserFirstNameChangeOpenModal: null,
  onUserMiddleNameChangeOpenModal: null,
  onUserBirthdayChangeOpenModal: null,
  onUserNationalityChangeOpenModal: null,
  onUserTaxResidenceChangeOpenModal: null,
  onUserPartnerOpenModal: null,
  onUserExtensionNumberEditModal: null,
  userInfoPage: false,
  onClick: null,
  captionSlug: 'persInfo',
  theme: {},
};

export default withTheme()(UserInfoPanel);
export { UserInfoPanel };
