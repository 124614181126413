import { put, call, fork, take } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { SubmissionError } from 'redux-form';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getDynamicResourceListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getDynamicResourceList, search);
    if (status < 300) {
      yield put(ActionTypes.getDynamicResourceList.success(data));
    } else {
      yield put(ActionTypes.getDynamicResourceList.failure(data.error));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getDynamicResourceList.failure());
    throw e;
  }
}
export function* getDynamicResourceListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_DYNAMIC_RESOURCE_LIST[REQUEST]);
    yield call(getDynamicResourceListSaga, data);
  }
}
export function* createDynamicResourceSaga({ slug, data: formData }) {
  try {
    const { status, data } = yield call(api.createDynamicResource, slug, formData);
    if (status < 300) {
      yield put(ActionTypes.createDynamicResource.success(data));
    } else {
      const error = data.details ? data.details : data.detail;
      yield put(ActionTypes.createDynamicResource.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createDynamicResource.failure(e));
  }
}
export function* createDynamicResourceWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.createDynamicResource.REQUEST);
    yield call(createDynamicResourceSaga, payload);
  }
}

export function* saveDynamicResourceSaga({ slug, id, data: formData }) {
  try {
    const { status, data } = yield call(api.saveDynamicResource, slug, id, formData);
    if (status < 300) {
      yield put(ActionTypes.saveDynamicResource.success(data));
    } else {
      const error = data.details ? data.details : data.detail;
      yield put(ActionTypes.saveDynamicResource.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.saveDynamicResource.failure(e));
  }
}
export function* saveDynamicResourceWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.saveDynamicResource.REQUEST);
    yield call(saveDynamicResourceSaga, payload);
  }
}

export function* saveDynamicSettingsSaga({ slug, data: formData }) {
  try {
    const { status, data } = yield call(api.saveDynamicSetting, slug, formData);
    if (status < 300) {
      yield put(ActionTypes.saveDynamicSetting.success(data));
    } else {
      const error = data.details ? data.details : data.detail;
      yield put(ActionTypes.saveDynamicSetting.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.saveDynamicSetting.failure(e));
  }
}
export function* saveDynamicSettingWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.saveDynamicSetting.REQUEST);
    yield call(saveDynamicSettingsSaga, payload);
  }
}

export default [
  fork(getDynamicResourceListWatcherSaga),
  fork(createDynamicResourceWatcherSaga),
  fork(saveDynamicResourceWatcherSaga),
  fork(saveDynamicSettingWatcherSaga),
];
