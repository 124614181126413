import { REQUEST, SUCCESS, FAILURE } from 'redux-config/reduxHelpers';
import * as ActionTypes from './actions';

export const initialState = {
  overdueTasks: {},
  overdueTasksAreLoaded: false,

  todayTasks: {},
  todayTasksAreLoaded: false,

  tomorrowTasks: {},
  tomorrowTasksAreLoaded: false,

  allTasks: {},
  allTasksAreLoaded: false,

  allReassignTasks: {},
  allReassignTasksAreLoaded: false,

  task: [],
  taskIsLoaded: false,

  managers: [],
  managersAreLoaded: false,

  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_OVERDUE_TASKS[REQUEST]:
      return { ...state, overdueTasksAreLoaded: false };
    case ActionTypes.GET_OVERDUE_TASKS[SUCCESS]:
      return { ...state, overdueTasks: action.data, overdueTasksAreLoaded: true };
    case ActionTypes.GET_OVERDUE_TASKS[FAILURE]:
      return { ...state, error: action.error, overdueTasksAreLoaded: false };

    case ActionTypes.GET_TODAY_TASKS[REQUEST]:
      return { ...state, todayTasksAreLoaded: false };
    case ActionTypes.GET_TODAY_TASKS[SUCCESS]:
      return { ...state, todayTasks: action.data, todayTasksAreLoaded: true };
    case ActionTypes.GET_TODAY_TASKS[FAILURE]:
      return { ...state, error: action.error, todayTasksAreLoaded: false };

    case ActionTypes.GET_TOMORROW_TASKS[REQUEST]:
      return { ...state, tomorrowTasksAreLoaded: false };
    case ActionTypes.GET_TOMORROW_TASKS[SUCCESS]:
      return { ...state, tomorrowTasks: action.data, tomorrowTasksAreLoaded: true };
    case ActionTypes.GET_TOMORROW_TASKS[FAILURE]:
      return { ...state, error: action.error, tomorrowTasksAreLoaded: false };

    case ActionTypes.GET_ALL_CRM_TASKS[REQUEST]:
      return { ...state, allTasksAreLoaded: false };
    case ActionTypes.GET_ALL_CRM_TASKS[SUCCESS]:
      return { ...state, allTasks: action.data, allTasksAreLoaded: true };
    case ActionTypes.GET_ALL_CRM_TASKS[FAILURE]:
      return { ...state, error: action.error, allTasksAreLoaded: false };

    case ActionTypes.GET_ALL_REASSIGN_TASKS[REQUEST]:
      return { ...state, allReassignTasks: {}, allReassignTasksAreLoaded: false };
    case ActionTypes.GET_ALL_REASSIGN_TASKS[SUCCESS]:
      return { ...state, allReassignTasks: action.data, allReassignTasksAreLoaded: true };
    case ActionTypes.GET_ALL_REASSIGN_TASKS[FAILURE]:
      return { ...state, error: action.error, allReassignTasksAreLoaded: false };

    case ActionTypes.GET_CRM_TASK[REQUEST]:
      return { ...state, taskIsLoaded: false };
    case ActionTypes.GET_CRM_TASK[SUCCESS]:
      return { ...state, task: action.data, taskIsLoaded: true };
    case ActionTypes.GET_CRM_TASK[FAILURE]:
      return { ...state, error: action.error, taskIsLoaded: false };

    case ActionTypes.GET_CRM_MANAGERS[REQUEST]:
      return { ...state, managersAreLoaded: false };
    case ActionTypes.GET_CRM_MANAGERS[SUCCESS]:
      return { ...state, managers: action.data, managersAreLoaded: true };
    case ActionTypes.GET_CRM_MANAGERS[FAILURE]:
      return { ...state, error: action.error, managersAreLoaded: false };

    default:
      return state;
  }
};

export default reducer;
