import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .IbProgramExceptionsTable {
    .ReactTable {
      width: 100%;
      border: none;

      .rt-tbody {
        .rt-tr-group {
          cursor: default;

          .rt-tr {
            margin: 0 0;
            align-items: center;
            justify-content: space-between;
            box-shadow: none;
            border: none;
            padding: rem(10px 18px);
            @include respond-to(lg) {
              padding: rem(10px);
            }
            .rt-td {
              padding: 0;
              margin: 0 3px 0 0;
            }
          }

          &:hover {
            box-shadow: none !important;
          }
        }
      }
    }

    .IbProgramExceptionsTable__cell {
      padding: rem(5px 0);
      font-size: rem(14px) !important;
    }
  }
`;

export { staticStyles };
