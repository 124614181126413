import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import classNames from 'classnames';
import { FormattedMessage as FM } from 'react-intl';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const BaseAsyncSelectField = ({
  textId,
  textValues,
  loadingPlaceholderId,
  searchPromptTextId,
  noResultsTextId,
  input,
  afterChange,
  loadOptions,
  classNamePrefix,
  minSearchLengthForLoad,
  labelTextId,
  disabled,
  meta: { touched, error },
  isMulti,
  removeArrow,
  theme,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [searchTextId, setSearchTextId] = useState(searchPromptTextId);
  const [selectedOption, setSelectedOption] = useState(input.value || '');

  const isLengthEnough = value => value.length >= minSearchLengthForLoad;

  const getOptions = (value, callback) => {
    if (isLengthEnough(value)) {
      return loadOptions(value);
    }
    callback();
  };

  const onInputChange = value => {
    const newSearchTextId = isLengthEnough(value) ? noResultsTextId : searchPromptTextId;

    if (searchTextId !== newSearchTextId) {
      setSearchTextId(newSearchTextId);
    }
  };
  const onChange = option => {
    setSelectedOption(option);
    let value;
    if (Array.isArray(option)) {
      value = option.length ? option.map(o => o.value) : null;
    } else {
      value = option ? option.value : null;
    }
    input.onChange(value);

    if (afterChange) {
      afterChange(option);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: provided => ({
      ...provided,
      height: '30px',
      padding: '0 6px',
    }),

    input: provided => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '30px',
    }),
  };

  return (
    <div
      className={classNames('BaseAsyncSelectField', {
        BaseAsyncSelectField__error: touched && error,
        'BaseAsyncSelectField--RemoveArrow': removeArrow,
      })}>
      {labelTextId && (
        <span className="BaseAsyncSelectField__label">
          <FM id={labelTextId} />
        </span>
      )}
      <Async
        {...props}
        name={input.name}
        placeholder={<FM id={searchTextId} values={{ mintextlength: minSearchLengthForLoad }} />}
        classNamePrefix="select"
        isClearable={false}
        loadOptions={getOptions}
        onChange={onChange}
        value={selectedOption}
        onInputChange={onInputChange}
        loadingPlaceholder={loadingPlaceholderId}
        isDisabled={disabled}
        onBlur={() => input.onBlur(input.value)}
        onFocus={input.onFocus}
        blurInputOnSelect={false}
        isMulti={isMulti}
        styles={customStyles}
      />
      <div className="BaseAsyncSelectField__error-text">{touched && error}</div>
      <style jsx>{staticStyles}</style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

BaseAsyncSelectField.propTypes = {
  textId: PropTypes.string.isRequired,
  textValues: PropTypes.object,
  minSearchLengthForLoad: PropTypes.number,
  loadOptions: PropTypes.func.isRequired,
  noResultsTextId: PropTypes.string,
  searchPromptTextId: PropTypes.string,
  loadingPlaceholderId: PropTypes.string,
  classNamePrefix: PropTypes.string,
  input: PropTypes.object.isRequired,
  labelTextId: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  afterChange: PropTypes.func,
  theme: PropTypes.object,
};

BaseAsyncSelectField.defaultProps = {
  textValues: {},
  minSearchLengthForLoad: 3,
  noResultsTextId: 'noResultsTextSelectField',
  searchPromptTextId: 'crmSearchTextAsyncSelectField',
  loadingPlaceholderId: 'loadingPlaceholderId',
  classNamePrefix: 'select',
  labelTextId: '',
  afterChange: null,
  disabled: false,
  isMulti: false,
  theme: {},
};

export default withTheme()(BaseAsyncSelectField);
