import React from 'react';
import PropTypes from 'prop-types';
import { AllTasksTableMonitoring } from 'crm/tasks/_components';
import { useHistory } from 'react-router-dom';
import arrowBack from './images/arrowBack.svg';

import { staticStyles, getDynamicStyles } from './style';

const TasksMonitoringPage = ({ theme }) => {
  const dynamicStyles = Object.keys({}).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();

  return (
    <div className="TasksMonitoringPage">
      <div className="TasksMonitoringPage__back">
        <img className="TasksMonitoringPage__back-icon" src={arrowBack} alt="arrow back" />
        <button type="button" className="TasksMonitoringPage__back-text" onClick={() => history.back()}>
          Назад
        </button>
      </div>
      <div className="TasksMonitoringPage__manager-name">Manager Name (Monitoring)</div>
      <AllTasksTableMonitoring />
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

TasksMonitoringPage.propTypes = {
  theme: PropTypes.object,
};

TasksMonitoringPage.defaultProps = {
  theme: {},
};

export default TasksMonitoringPage;
