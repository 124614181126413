import css from 'styled-jsx/css';
import { gradient } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .Tabs {
    &__list {
      display: flex;
    }

    &__tab {
      margin: 0 65px 20px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.1px;
      padding: 0 0 10px 0;
      cursor: pointer;
      position: relative;
      user-select: none;

      &:last-child {
        margin: 0 0 20px 0;
      }

      &:after {
        content: '';
        position: absolute;
        height: 2px;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 0;
        transition: 0.3s all ease;
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }

      &--active {
        &:after {
          width: 100%;
        }
      }

      &--disabled {
        cursor: default;

        &:after {
          content: none;
        }
      }
    }

    &__content {
      &--display-none {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .Tabs {
      &__tab {
        color: ${colors.black.hex};

        &:hover {
          color: ${colors.primary.hex};
        }

        &:after {
          background: ${gradient(73.29, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }

        &--active {
          color: ${colors.primary.hex};
        }

        &--disabled {
          color: ${colors.gray.hex};
          opacity: 0.7;

          &:hover {
            color: ${colors.gray.hex};

            &:after {
              content: none;
            }
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
