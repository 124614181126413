import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .InputDate {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media only screen and (max-width: 510px) {
      width: 100%;
    }
  }

  .InputDate__Title {
    color: #878787;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

const getDynamicStyles = () => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';
    @import 'src/common/styling/_mixins-scss/rem';
  `;
};

export { staticStyles, getDynamicStyles };
