import React from 'react';

const CrmTasksIcon = () => (
  <div>
    <svg width="14" height="20" fill="none" viewBox="0 0 13 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 7.375c0 .145.111.263.248.263h7.808c.136 0 .247-.118.247-.263 0-.146-.111-.264-.248-.264H2.498c-.137 0-.248.118-.248.264z"
        fill="#777"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.498 7.01h7.808c.188 0 .34.164.34.365 0 .2-.152.363-.34.363H2.498c-.189 0-.341-.163-.341-.363 0-.201.152-.364.34-.364zm0 .2c-.085 0-.154.074-.154.165 0 .09.069.163.154.163h7.808c.084 0 .153-.073.153-.163 0-.09-.069-.164-.153-.164H2.498z"
        fill="#777"
      />
      <path
        d="M2.498 9.73h7.808c.136 0 .247-.117.247-.263 0-.146-.111-.264-.248-.264H2.498c-.137 0-.248.118-.248.264 0 .146.111.264.248.264z"
        fill="#777"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.157 9.467c0-.2.152-.364.34-.364h7.809c.188 0 .34.163.34.364 0 .2-.152.364-.34.364H2.498c-.189 0-.341-.163-.341-.364zm.34-.164c-.084 0-.153.074-.153.164 0 .09.069.164.154.164h7.808c.084 0 .153-.074.153-.164 0-.09-.069-.164-.153-.164H2.498z"
        fill="#777"
      />
      <path
        d="M2.498 11.823h7.808c.136 0 .247-.118.247-.263 0-.146-.111-.264-.248-.264H2.498c-.137 0-.248.118-.248.264 0 .145.111.263.248.263z"
        fill="#777"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.157 11.56c0-.201.152-.364.34-.364h7.809c.188 0 .34.163.34.364 0 .2-.152.363-.34.363H2.498c-.189 0-.341-.163-.341-.364zm.34-.164c-.084 0-.153.073-.153.163 0 .091.069.164.154.164h7.808c.084 0 .153-.073.153-.164 0-.09-.069-.163-.153-.163H2.498z"
        fill="#777"
      />
      <path
        d="M2.498 13.916h3.904c.136 0 .247-.118.247-.264 0-.146-.111-.264-.247-.264H2.498c-.137 0-.248.118-.248.264 0 .146.111.264.248.264z"
        fill="#777"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.157 13.652c0-.2.152-.364.34-.364h3.904c.189 0 .341.163.341.364 0 .2-.152.364-.34.364H2.497c-.189 0-.341-.163-.341-.364zm.34-.164c-.084 0-.153.074-.153.164 0 .09.069.164.154.164H6.4c.085 0 .154-.074.154-.164 0-.09-.069-.164-.154-.164H2.498z"
        fill="#777"
      />
      <path
        d="M12.617 2.575h-.39v-.55c0-.566-.43-1.025-.96-1.025H3.402a.924.924 0 00-.679.3L.463 3.712c-.182.194-.281.45-.281.724V6.76c0 .146.11.264.247.264.136 0 .247-.118.247-.264V4.436c0-.017 0-.034.002-.05h1.86c.45 0 .816-.39.816-.87V1.53a.445.445 0 01.048-.002h7.865c.257 0 .466.222.466.496v11.011c0 .146.11.264.247.264.136 0 .247-.118.247-.264V3.102h.39c.257 0 .466.223.466.497v14.377c0 .274-.209.497-.466.497H2.492c-.257 0-.466-.223-.466-.497v-.55h9.241c.53 0 .96-.46.96-1.025v-2.133c0-.145-.11-.263-.247-.263s-.248.118-.248.263v2.133c0 .274-.208.497-.465.497H1.141c-.256 0-.465-.223-.465-.497V7.992c0-.145-.11-.263-.247-.263s-.247.118-.247.263v8.41c0 .564.43 1.023.96 1.023h.39v.55c0 .566.43 1.025.96 1.025h10.125c.53 0 .96-.46.96-1.024V3.599c0-.565-.43-1.024-.96-1.024zM2.54 3.858H1.025L2.86 1.9v1.615c0 .188-.143.342-.32.342z"
        fill="#777"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.657 1.23c.199-.213.463-.33.745-.33h7.865c.581 0 1.053.504 1.053 1.124v.45h.297c.581 0 1.054.505 1.054 1.125v14.377c0 .62-.473 1.124-1.054 1.124H2.492c-.581 0-1.054-.504-1.054-1.124v-.45h-.297c-.58 0-1.053-.505-1.053-1.125V7.992c0-.2.152-.363.34-.363.189 0 .342.163.342.363v8.41c0 .218.166.396.371.396h10.126c.205 0 .372-.178.372-.397v-2.133c0-.2.152-.363.34-.363.189 0 .341.163.341.364V16.4c0 .62-.472 1.125-1.053 1.125H2.12v.45c0 .219.167.397.372.397h10.125c.205 0 .372-.178.372-.397V3.599c0-.219-.167-.397-.372-.397h-.297v9.833c0 .201-.152.364-.34.364-.189 0-.341-.163-.341-.364V2.025c0-.22-.167-.397-.372-.397h-7.82v1.888c0 .534-.407.97-.908.97H.769v2.273c0 .201-.152.364-.34.364-.189 0-.341-.163-.341-.364V4.437c0-.3.11-.583.308-.795l2.26-2.413zm.745-.13a.833.833 0 00-.613.27L.53 3.784a.95.95 0 00-.254.654v2.322c0 .09.069.164.154.164.084 0 .153-.073.153-.164V4.437c0-.021.001-.042.003-.062l.009-.09h1.945c.397 0 .72-.345.72-.77V1.44l.085-.01a.533.533 0 01.058-.002h7.865c.309 0 .56.267.56.596v11.011c0 .09.068.164.153.164.084 0 .153-.073.153-.164V3.002h.484c.309 0 .56.268.56.597v14.377c0 .329-.251.597-.56.597H2.492c-.309 0-.56-.268-.56-.597v-.65h9.335c.478 0 .866-.415.866-.925v-2.133c0-.09-.069-.163-.153-.163-.085 0-.154.073-.154.163v2.133c0 .33-.25.597-.559.597H1.141c-.308 0-.559-.268-.559-.597V7.992c0-.09-.069-.163-.153-.163-.085 0-.154.073-.154.163v8.41c0 .509.389.924.866.924h.485v.65c0 .51.388.924.866.924h10.125c.478 0 .866-.415.866-.924V3.599c0-.51-.388-.924-.866-.924h-.484v-.65c0-.51-.388-.925-.866-.925H3.402zm-.449.56v1.856c0 .244-.186.442-.414.442H.799l2.154-2.299zM1.252 3.757h1.287c.125 0 .227-.109.227-.242V2.142L1.252 3.758z"
        fill="#777"
      />
    </svg>
  </div>
);

export default CrmTasksIcon;
