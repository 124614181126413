import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .NoContactNotes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .NoContactNotes__NoteIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background: #e9e9e9;
    margin-right: 16px;
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css``;
};

export { staticStyles, getDynamicStyles };
