import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactQueryBuilder from 'react-querybuilder';
import { withTheme } from 'common/styling/theme';
import { injectIntl } from 'react-intl';
import { FormMessageHOC } from 'common/HOCs';
import { reduxForm } from 'redux-form';
import { getCountries } from 'admin/payment-system/_redux';
import { staticStyles, getDynamicStyles } from './style';

const defaultQuery = {
  rules: [],
  combinator: 'and',
  not: false,
};

const QueryBuilder = ({ countries, getCountries, input, filters, initialQuery, onChangeQuery, theme, intl }) => {
  filters = filters.map(filter => ({ ...filter, id: filter.id.toString() }));
  const countriesFilter = countries?.map(item => ({ name: item.gid, label: item.name }));
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [query, setQuery] = useState(initialQuery ?? defaultQuery);

  const combinators = [
    { name: 'and', label: intl.formatMessage({ id: 'justAnd' }) },
    { name: 'or', label: intl.formatMessage({ id: 'justOr' }) },
  ];

  const operators = [
    { name: '=', label: '=' },
    { name: '!=', label: '!=' },
    { name: '<', label: '<' },
    { name: '>', label: '>' },
    { name: '<=', label: '<=' },
    { name: '>=', label: '>=' },
  ];

  const translations = {
    fields: {
      title: 'Fields',
    },
    operators: {
      title: 'Operators',
    },
    value: {
      title: 'Value',
    },
    removeRule: {
      label: 'x',
      title: intl.formatMessage({ id: 'justRemoveRule' }),
    },
    removeGroup: {
      label: 'x',
      title: intl.formatMessage({ id: 'justRemoveGroup' }),
    },
    addRule: {
      label: 'Add rule',
      title: intl.formatMessage({ id: 'justAddRule' }),
    },
    addGroup: {
      label: 'Add group',
      title: intl.formatMessage({ id: 'justAddGroup' }),
    },
    combinators: {
      title: 'Combinators',
    },
    notToggle: {
      label: 'not',
      title: intl.formatMessage({ id: 'invertGroup' }),
    },
  };

  const handleQueryChange = q => {
    setQuery(q);

    if (onChangeQuery) {
      onChangeQuery(q);
    }

    if (input && Object.prototype.hasOwnProperty.call(input, 'onChange')) {
      input.onChange(q);
    }
  };

  const fields = useMemo(
    () => Object.values(filters).map(item => ({ name: item.field, label: item.nice_name })),
    [filters]
  );

  const getValueEditorType = field => {
    const filterItem = filters.find(filter => filter.field === field);
    switch (filterItem?.type) {
      case 'bool':
        return 'checkbox';
      case 'date':
        return 'text';
      case 'decimal':
        return filterItem?.options[0] === 'None' ? 'text' : 'select';
      case 'str':
        return filterItem?.options[0] === 'None' ? 'text' : 'select';

      default:
        return 'select';
    }
  };

  const getInputType = field => {
    const filterItem = filters.find(filter => filter.field === field);
    switch (filterItem?.type) {
      case 'date':
        return 'date';

      case 'decimal':
        return 'number';

      default:
        return 'text';
    }
  };

  const getValues = field => {
    const filterItem = filters.find(filter => filter.field === field);
    if ((filterItem?.type === 'str' || filterItem?.type === 'decimal') && filterItem?.options[0] !== 'None') {
      if (filterItem.field === 'data_KYC__address__country') {
        return countriesFilter;
      }
      return filterItem?.options?.map(item => ({ name: item, label: item })) ?? [];
    }
    return [];
  };

  const getOperators = field => {
    const filterItem = filters.find(filter => filter.field === field);
    switch (filterItem?.type) {
      case 'bool':
        return [{ name: '=', label: '=' }];

      case 'str':
        return [
          { name: '=', label: '=' },
          { name: 'startwith', label: intl.formatMessage({ id: 'justStartsWith' }) },
          { name: 'contains', label: intl.formatMessage({ id: 'justContains' }) },
        ];

      default:
        return null;
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className="QueryBuilder__wrapper">
      <ReactQueryBuilder
        query={query}
        fields={fields}
        translations={translations}
        combinators={combinators}
        operators={operators}
        onQueryChange={handleQueryChange}
        getValues={getValues}
        getValueEditorType={getValueEditorType}
        getInputType={getInputType}
        getOperators={getOperators}
        showNotToggle
      />
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

QueryBuilder.propTypes = {
  countries: PropTypes.array.isRequired,
  getCountries: PropTypes.func.isRequired,
  input: PropTypes.object,
  intl: PropTypes.object.isRequired,
  onChangeQuery: PropTypes.func.isRequired,
  filters: PropTypes.array,
  initialQuery: PropTypes.object,
  theme: PropTypes.object,
};

QueryBuilder.defaultProps = {
  input: {},
  initialQuery: null,
  filters: [],
  theme: {},
};

export default compose(
  withTheme(),
  FormMessageHOC,
  reduxForm({
    form: 'QueryBuilder',
    onSubmitSuccess: (result, dispatch, props) => {
      props.showFormSuccessMessage(props.successMessageId);
    },
  }),
  connect(
    state => ({
      countries: state.admin.payments.countries,
    }),
    {
      getCountries: () => getCountries.request(),
    }
  )
)(injectIntl(QueryBuilder));
export { QueryBuilder };
