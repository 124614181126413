import React, { useState } from 'react';
import { FastFilters } from 'crm/_newComponents';
import { getCRMFastFilters } from 'crm/_redux/actions';
import { ContactsTable, ActionsTab } from '../../_components';
import { staticStyles } from './style';

const ContactsPage = () => {
  const [customValueOfOneFilter, setCustomValueOfOneFilter] = useState({
    filterName: 'tagContactFilter',
    inputType: 'inputIntegerChoice',
    newValue: [],
  });
  const customOnChangeForOneFilter = tagValue => {
    setCustomValueOfOneFilter(prevState => ({ ...prevState, tagValue }));
  };
  return (
    <div className="ContactsPage">
      <ActionsTab />
      <FastFilters
        getFastFiltersFunc={getCRMFastFilters}
        fastFiltersSelector={state => state.crm.common.fastFilters}
        customValueOfOneFilter={customValueOfOneFilter}
      />
      <ContactsTable onTagSelect={customOnChangeForOneFilter} />
      <style jsx global>
        {staticStyles}
      </style>
    </div>
  );
};

export default ContactsPage;
