import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .AllTasksTableCalls {
    .BaseTable {
      &__tbody-row {
        background: transparent !important;
      }

      &__tbody-row:nth-of-type(1) {
        .BaseTable__tbody-cell:last-child {
          border-top: 1px solid #ffffff !important;
        }
      }

      &__thead-cell {
        &:nth-of-type(1) {}

        &:nth-of-type(2) {
          min-width: 180px;
        }

        &:nth-of-type(3) {
          min-width: 180px;
        }

        &:nth-of-type(4) {
          min-width: 100px;
        }

        &:nth-of-type(5) {
          min-width: 100px;
        }

        &:nth-of-type(6) {
          min-width: 70px;
        }

        &:nth-of-type(7) {
          min-width: 150px;
        }
      }

      &__tbody-cell {
        position: relative;

        &:nth-of-type(1) {}

        &:nth-of-type(2) {
          min-width: 180px;
        }

        &:nth-of-type(3) {
          min-width: 180px;
        }

        &:nth-of-type(4) {
          min-width: 100px;
        }

        &:nth-of-type(5) {
          min-width: 100px;
        }

        &:nth-of-type(6) {
          min-width: 70px;
        }

        &:nth-of-type(7) {
          min-width: 150px;
        }

        &:nth-last-of-type(2) {
          border-right: 1px solid #ffffff;
        }

        &:nth-last-of-type(1) {
          border-right: 1px solid #ffffff;
          border-bottom: 1px solid #ffffff;

          BaseTable__tbody-row:nth-of-type(1) & {
            border-top: 1px solid #ffffff;
          }
        }

        .contact {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }

        .to {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }

        .description {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }

        .duration {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
        }

        .record {
          line-height: 0;
        }

        .call-status {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
          }

          &--not-success {
            &:before {
              background: #f0f0f0;
            }
          }

          &--success {
            &:before {
              background: #fcd9e7;
            }
          }

          &--not-completed {
            &:before {
              background: #eafbf4;
            }
          }

          &--bad-client {
            &:before {
              background: #fcd9e7;
            }
          }

          &__icon {
            margin: 0 5px 0 0;
            line-height: 0;
          }

          &__text {
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }, columns) => {
  return css.global``;
};

export { staticStyles, getDynamicStyles };
