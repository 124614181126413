import { put, call, fork, take, takeLatest, takeEvery } from 'redux-saga/effects';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';

import * as api from '../../_api';
import * as ActionTypes from '../actions';

export function* getTradingAccountTypesSaga() {
  try {
    const { data } = yield call(api.getTradingAccountTypes);
    yield put(ActionTypes.getTradingAccountTypes.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getTradingAccountTypes.failure(e));
  }
}

export function* getTradingAccountTypesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_TRADING_ACCOUNT_TYPES[REQUEST]);
    yield call(getTradingAccountTypesSaga);
  }
}

export function* getAccountsPrefetchSaga() {
  try {
    const { status, data } = yield call(api.getAccountsPrefetch);
    if (status < 300) {
      yield put(ActionTypes.getAccountsPrefetch.success(data));
    } else {
      yield put(ActionTypes.getAccountsPrefetch.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getAccountsPrefetch.failure());
  }
}

export function* getAccountsPrefetchWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_ACCOUNTS_PREFETCH[REQUEST]);
    yield call(getAccountsPrefetchSaga);
  }
}

export function* getTradingAccountListSaga(noDetails) {
  try {
    const { status, data } = yield call(api.getTradingAccountList);
    if (status < 300) {
      yield put(ActionTypes.getTradingAccountList.success(data));
      if (!noDetails) {
        yield call(getAccountsPrefetchSaga);
      }
    } else {
      yield put(ActionTypes.getTradingAccountList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getTradingAccountList.failure());
  }
}

export function* getTradingAccountListWatcherSaga() {
  while (true) {
    const { noDetails } = yield take(ActionTypes.GET_TRADING_ACCOUNT_LIST[REQUEST]);
    yield call(getTradingAccountListSaga, noDetails);
  }
}

export function* getTradingAccountSaga({ id }) {
  try {
    const { status, data } = yield call(api.getTradingAccount, id);
    if (status < 300) {
      yield put(ActionTypes.getTradingAccount.success(data));
    } else {
      yield put(ActionTypes.getTradingAccount.failure(status));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getTradingAccount.failure(id, e));
  }
}

export function* getTradingAccountWatcherSaga() {
  yield takeEvery(ActionTypes.GET_TRADING_ACCOUNT[REQUEST], getTradingAccountSaga);
}

export function* getTradingAccountOperationsSaga({ id, status = 'open', startDate, endDate }) {
  try {
    const response = yield call(api.getTradingAccountOperations, id, status, startDate, endDate);
    if (response.status < 300) {
      yield put(ActionTypes.getTradingAccountOperations.success(response.data));
    } else {
      yield put(ActionTypes.getTradingAccountOperations.failure(response.data));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getTradingAccountOperations.failure(id, e));
  }
}
export function* getTradingAccountOperationsWatcherSaga() {
  yield takeLatest(ActionTypes.GET_TRADING_ACCOUNT_OPERATIONS[REQUEST], getTradingAccountOperationsSaga);
}

export function* getPartnerAccountsSaga() {
  try {
    const { data } = yield call(api.getPartnerAccounts);
    yield put(ActionTypes.getPartnerAccounts.success(data));
    for (let i = 0; i < data.length; i += 1) {
      yield put(ActionTypes.getPartnerAccount.request(data[i].id));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPartnerAccounts.failure(e));
  }
}
export function* getPartnerAccountsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_PARTNER_ACCOUNTS[REQUEST]);
    yield call(getPartnerAccountsSaga);
  }
}

export function* getPartnerAccountSaga({ accountId }) {
  try {
    const { data } = yield call(api.getPartnerAccount, accountId);
    yield put(ActionTypes.getPartnerAccount.success(accountId, data));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPartnerAccount.failure(accountId, e));
  }
}
export function* getPartnerAccountWatcherSaga() {
  yield takeEvery(ActionTypes.GET_PARTNER_ACCOUNT[REQUEST], getPartnerAccountSaga);
}

export function* getPartnerAccountCommissionsSaga({ accountId, search }) {
  search = search.search ? search.search : search;
  try {
    const { data } = yield call(api.getPartnerAccountCommissions, accountId, search);
    yield put(ActionTypes.getPartnerAccountCommissions.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getPartnerAccountCommissions.failure());
  }
}
export function* getPartnerAccountCommissionsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_PARTNER_ACCOUNT_COMMISSIONS[REQUEST]);
    yield call(getPartnerAccountCommissionsSaga, data);
  }
}

export function* getMTServersSaga() {
  try {
    const { data } = yield call(api.getMTServersAPI);
    yield put(ActionTypes.getMTServersAction.success(data));
  } catch (error) {
    yield put(ActionTypes.getMTServersAction.failure(error));
  }
}
export function* getMTServersWatcherSaga() {
  yield takeEvery(ActionTypes.GET_MT_SERVERS_ACTION[REQUEST], getMTServersSaga);
}

export default [
  fork(getTradingAccountTypesWatcherSaga),
  fork(getTradingAccountListWatcherSaga),
  fork(getAccountsPrefetchWatcherSaga),
  fork(getTradingAccountWatcherSaga),
  fork(getTradingAccountOperationsWatcherSaga),
  fork(getPartnerAccountsWatcherSaga),
  fork(getPartnerAccountWatcherSaga),
  fork(getPartnerAccountCommissionsWatcherSaga),
  fork(getMTServersWatcherSaga),
];
