import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  :global(.DateFormField__caption) {
    font-size: 12px;
  }
  :global(.EditTaskForm__Reaction-button) {
    display: flex;
    align-items: center;
    gap: 4.5px;
    font-size: 12px;
    font-weight: 200;
    margin: 0;
  }
  :global(.EditTaskForm__Reaction-button.active) {
    font-weight: 500;
  }
  .EditTaskForm {
    width: 400px;
    max-width: 100%;
    padding: 0 10px;

    &__Reaction {
      display: flex;
      gap: 20px;
      margin-bottom: rem(15px);
    }

    &__title {
      text-align: center;
      font-size: rem(18px);
      font-weight: 500;
    }

    &__line {
      margin-bottom: rem(15px);
    }

    &__submit {
      text-align: center;
    }
  }
`;
const getDynamicStyles = ({ colors }) => {
  return css`
    .EditTaskForm__formError {
      color: ${colors.error.hex};
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 7px;
    }
  `;
};

export { staticStyles, getDynamicStyles };
