import css from 'styled-jsx/css';
import { gradient, rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ContactNoteCreateForm {
    max-width: 500px;
    @include respond-to(sm) {
      max-width: 100%;
    }

    &__inputs-inner {
      margin-bottom: rem(20px);
    }

    :global(.TextFormField__input) {
      margin-bottom: rem(20px);
    }

    &__attach {
      display: flex;
      align-items: center;

      &-label {
        position: relative;
        display: inline-block;
        padding: rem(9px 20px);
        cursor: pointer;
        font-size: rem(15px);
        line-height: rem(22px);
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        min-width: rem(105px);
        letter-spacing: rem(0.5px);
        margin: rem(0 20px);

        &:active {
          box-shadow: none;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      :global(.FileField) {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .ContactNoteCreateForm {
      &__attach {
        &-label {
          border-radius: ${border.radius};
          border: 1px solid ${colors.primary.hex};
          color: ${colors.primary.hex};
          &:hover {
            border-color: ${rgba([colors.primary.rgb, 0.8])};
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.15], 0, [colors.gradientEnd.rgb, 0.15], 100)};
            color: ${rgba([colors.primary.rgb, 0.8])};
          }
          &:disabled {
            border-color: transparent;
            background: ${gradient(53.07, [colors.gradientStart.rgb, 0.4], 0, [colors.gradientEnd.rgb, 0.4], 100)};
          }
        }
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
