import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { numericality, required } from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';
import { ReduxTextField, DefaultButton } from 'common/components';
import { putTarget } from 'common/dashboards/_redux';

import { withTheme } from 'common/styling/theme';
import { staticStyles, getDynamicStyles } from './style';

const TargetForm = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
  error,
  theme,
  dispatch,
  lastMonthUsers,
  lastMonthRealUsers,
  lastMonthFtd,
  perms,
  viewMode,
  isAdmin,
}) => {
  const hasBrokerAccess = (perms && (perms.viewDashboards || perms.managerViewDashboard)) || isAdmin;
  const submit = handleSubmit(values => putTarget({ target: values, hasBrokerAccess, viewMode }, dispatch));
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const TargetData = [
    {
      name: 'users',
      title: 'boRegistered',
      lastMonthValue: lastMonthUsers,
    },
    {
      name: 'realUsers',
      title: 'boOpenedLiveAccount',
      lastMonthValue: lastMonthRealUsers,
    },
    {
      name: 'depositedUsers',
      title: 'boFTD',
      lastMonthValue: lastMonthFtd,
    },
  ];
  return (
    <form className="TargetForm" onSubmit={submit}>
      <FormattedMessage id="boTargetDescription">
        {txt => <span className="TargetForm__description">{txt}</span>}
      </FormattedMessage>
      {TargetData.map(item => (
        <div className="TargetForm__row" key={item.name}>
          <div className="TargetForm__input">
            <FormattedMessage id={item.title}>
              {txt => <span className="TargetForm__input-description">{txt}</span>}
            </FormattedMessage>
            <Field
              component={ReduxTextField}
              autoComplete="off"
              type="text"
              name={item.name}
              textId="justTarget"
              validate={[required(), numericality({ int: true, '>': 0, '<=': 100000000 })]}
              disabled={submitting}
            />
          </div>
          <div className="TargetForm__last-month">
            <FormattedMessage id="lastMonth">{txt => <span>{txt}</span>}</FormattedMessage>
            <span>{item.lastMonthValue}</span>
          </div>
        </div>
      ))}
      <DefaultButton
        textId="justSave"
        type="submit"
        loading={submitting}
        disabled={invalid || pristine || submitting}
        filled
      />
      {error && <strong>{error}</strong>}
      <style jsx>{staticStyles}</style>
      <style jsx>{dynamicStyles}</style>
    </form>
  );
};

TargetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  perms: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  error: PropTypes.string,
  theme: PropTypes.object,
  lastMonthUsers: PropTypes.number,
  lastMonthRealUsers: PropTypes.number,
  lastMonthFtd: PropTypes.number,
  viewMode: PropTypes.string,
};

TargetForm.defaultProps = {
  error: '',
  theme: {},
  lastMonthUsers: 100,
  lastMonthRealUsers: 50,
  lastMonthFtd: 10,
  viewMode: null,
};

export default compose(
  withTheme(),
  connect(
    state => ({
      perms: state.user.profile.perms,
      isAdmin: state.user.profile.isAdmin,
    }),
    null
  ),
  reduxForm({
    form: 'targetForm',
    onSubmitSuccess(result, dispatch, props) {
      props.closeModal();
    },
  })
)(TargetForm);
export { TargetForm };
