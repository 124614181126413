import css from 'styled-jsx/css';
import { rgba } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  .ActionsTab {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px 31px;
    gap: 20px;
    &__filters-left {
      flex-grow: 1;
      gap: 40px;
      display: flex;
      @include respond-to(xs) {
        flex-direction: column;
        row-gap: 16px;
      }
    }
    &__filters-right {
      flex-grow: 1;
      gap: 40px;
      display: flex;
      justify-content: flex-end;
      @include respond-to(xs) {
        flex-direction: column;
        row-gap: 16px;
      }
    }
    :global(.CustomFilterSelect) {
      min-width: 150px;
      @include respond-to(xs) {
        min-width: 100%;
      }
    }
  }
  .ActionsTab__ModalButtons {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`;

export { staticStyles };
