import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';

  .UserWrapper__inner {
    position: relative;
    padding-top: 75px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    @include respond-to(md) {
      padding-top: 65px;
    }

    .UserWrapper__inner-overlay {
      display: none;
      opacity: 0;
      transition: all 0.4s ease;

      @include respond-to(md) {
        display: block;
        position: absolute;
        opacity: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 29;
        background-color: rgba(#000, 0.1);
        min-height: 100vh;
        height: 100%;
      }
    }

    .UserWrapper__inner-hidden-element-with-profile-data {
      display: none;
    }
  }
`;

export { staticStyles };
