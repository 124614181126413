import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

import DateSelector from './_components/DateSelector';

import { staticStyles } from './style';

const InputDate = ({ name, options, onSelect }) => (
  <div className="InputDate">
    <FM id={name}>{txt => <span className="InputDate__Title">{txt}</span>}</FM>
    <DateSelector options={options} onSelect={onSelect} />
    <style jsx>{staticStyles}</style>
  </div>
);

InputDate.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default InputDate;
