import { createFormAction } from 'redux-form-saga';
import { REQUEST, FAILURE, SUCCESS, action, createRequestTypes } from 'redux-config/reduxHelpers';

export const changeTradingAccountPassword = createFormAction('CHANGE_TRADING_ACCOUNT_PASSWORD');

export const changeTradingAccountLeverage = createFormAction('CHANGE_TRADING_ACCOUNT_LEVERAGE');

export const createTradingAccount = createFormAction('CREATE_TRADING_ACCOUNT');

export const createDemoDeposit = createFormAction('CREATE_DEMO_DEPOSIT');

export const GET_DEAL_STATS = createRequestTypes('GET_DEAL_STATS');
export const getDealStats = {
  request: () => action(GET_DEAL_STATS[REQUEST]),
  success: data => action(GET_DEAL_STATS[SUCCESS], { data }),
  failure: error => action(GET_DEAL_STATS[FAILURE], { error }),
};

export const GET_ACCOUNT_TRADE_LINK = createRequestTypes('GET_ACCOUNT_TRADE_LINK');
export const getAccountTradeLink = {
  request: data => action(GET_ACCOUNT_TRADE_LINK[REQUEST], data),
  success: data => action(GET_ACCOUNT_TRADE_LINK[SUCCESS], { data }),
  failure: error => action(GET_ACCOUNT_TRADE_LINK[FAILURE], { error }),
};

export const GET_CONVERTATION_OPTIONS = createRequestTypes('GET_CONVERTATION_OPTIONS');
export const getConvertationOptions = {
  request: id => action(GET_CONVERTATION_OPTIONS[REQUEST], { id }),
  success: data => action(GET_CONVERTATION_OPTIONS[SUCCESS], { data }),
  failure: error => action(GET_CONVERTATION_OPTIONS[FAILURE], { error }),
};

export const createAccountConvertation = createFormAction('CREATE_ACCOUNT_CONVERTATION');

export const TRADING_ACCOUNT_IS_CREATED = createRequestTypes('TRADING_ACCOUNT_IS_CREATED');
export const tradingAccountIsCreated = {
  request: data => action(TRADING_ACCOUNT_IS_CREATED[REQUEST], data),
  success: data => action(TRADING_ACCOUNT_IS_CREATED[SUCCESS], { data }),
  failure: error => action(TRADING_ACCOUNT_IS_CREATED[FAILURE], { error }),
};

export const GET_LPA_AGREEMENTS = createRequestTypes('GET_LPA_AGREEMENTS');
export const getLpaAgreements = {
  request: () => action(GET_LPA_AGREEMENTS[REQUEST]),
  success: data => action(GET_LPA_AGREEMENTS[SUCCESS], { data }),
  failure: error => action(GET_LPA_AGREEMENTS[FAILURE], { error }),
};

export const setLpaAgreements = createFormAction('SET_LPA_AGREEMENTS');

export const GET_SUMSUB_EXTERNAL_LINK = createRequestTypes('GET_SUMSUB_EXTERNAL_LINK');
export const getSumsubExternalLink = {
  request: () => action(GET_SUMSUB_EXTERNAL_LINK[REQUEST]),
  success: data => action(GET_SUMSUB_EXTERNAL_LINK[SUCCESS], { data }),
  failure: error => action(GET_SUMSUB_EXTERNAL_LINK[FAILURE], { error }),
};
