import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .FavoritItem {
    position: relative;
    background: #ceecf9;
    border-radius: 0px 6px 6px 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;

    &__Tooltip {
      position: absolute;
      display: flex;
      align-items: center;
      width: max-content;
      border-radius: 2px;
      height: 100%;
      z-index: 100;
      left: calc(100% + 1px);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: left;
      background: #ceecf9;
      padding-left: 7px;
      padding-right: 7px;
      color: #0aa1e2;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease-in-out;
    }

    &:hover .FavoritItem__Tooltip {
      height: 100%;
      opacity: 1;
      visibility: visible;
    }

    .FavoritItem__link {
      position: relative;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid rgba(10, 161, 226, 0.3);
      border-radius: 50%;
      font-size: rem(12px);
      font-weight: bold;
      color: #0aa1e2;
    }
  }
`;

export { staticStyles };
