/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage as FM } from 'react-intl';
import { useParams } from 'react-router-dom';
import { BasicCRMModal } from 'crm/_components';
import { getCrmUserFeed } from 'crm/users/_redux';

import noData from 'common/images/noInfo.svg';
import { PageContentPreloader } from 'common/components';
import ContactNoteEditForm from '../ContactNoteEditForm';

import { TabFeedItem } from './_components';

import { staticStyles } from './style';

const TabFeed = () => {
  const { feed, feedIsLoaded } = useSelector(state => state.crm.users);
  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [whichEditTaskOpen, setWhichEditTaskOpen] = useState(null);
  const toggleEditNoteModal = () => {
    setIsEditNoteModalOpen(!isEditNoteModalOpen);
  };

  const handleEditNote = id => {
    toggleEditNoteModal();
    setNoteId(id);
  };

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getCrmUserFeed.request({
        id,
      })
    );
  }, []);

  return (
    <div className="TabFeed">
      <PageContentPreloader ready={feedIsLoaded} type="smallLines">
        <div className="TabFeed__list">
          {feedIsLoaded &&
            feed.map((item, ind) => (
              <TabFeedItem
                key={`TabFeedItem-${ind}`}
                {...item}
                handleEditNote={handleEditNote}
                ind={ind}
                whichEditTaskOpen={whichEditTaskOpen}
                setWhichEditTaskOpen={setWhichEditTaskOpen}
              />
            ))}
        </div>
        <BasicCRMModal captionId="crmEditNote" isOpen={isEditNoteModalOpen} onRequestClose={toggleEditNoteModal}>
          <ContactNoteEditForm
            onRequestClose={toggleEditNoteModal}
            userId={id}
            noteId={noteId}
            reloadFeed
            onSubmitSuccess={toggleEditNoteModal}
            initialValues={feed.find(feedItem => feedItem.type === 'notes' && feedItem.id === noteId)}
          />
        </BasicCRMModal>
      </PageContentPreloader>
      {!feed.length && feedIsLoaded && (
        <div className="TabFeed__no-data">
          <img src={noData} alt="noData" />
          <FM id="noData" />
        </div>
      )}
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default TabFeed;
