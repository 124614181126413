import React from 'react';

const PhoneIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.34222 0.428163L1.38938 1.5026C1.3861 1.50976 1.38303 1.52205 1.3843 1.53709C1.48683 2.67424 1.91196 5.48274 4.2779 8.24689C6.59125 10.9496 8.92486 11.4655 9.90222 11.5936L10.8475 9.24884L10.8506 9.24112L10.8507 9.24115C10.8559 9.22908 10.8578 9.21169 10.8537 9.19484C10.8521 9.18794 10.8502 9.18388 10.8491 9.18193L8.94929 7.75305L8.19843 8.63061C8.1983 8.63076 8.19817 8.63091 8.19804 8.63106C8.04036 8.81627 7.79817 8.92412 7.53958 8.88542L3.34222 0.428163ZM3.34222 0.428163L4.58276 2.6785C4.58654 2.68612 4.59009 2.69974 4.58846 2.71644C4.58689 2.73253 4.58172 2.74025 4.58116 2.7411C4.5811 2.74118 4.58109 2.7412 4.58113 2.74116L4.5811 2.74118L3.78771 3.66764C3.78755 3.66783 3.78739 3.66801 3.78723 3.6682C3.63171 3.84894 3.56683 4.08868 3.59179 4.31672C3.64748 4.82836 3.88916 6.06004 4.93864 7.28606C5.99254 8.51726 7.06712 8.81512 7.53928 8.88537L3.34222 0.428163Z"
      stroke="#0AA1E2"
      strokeWidth="0.8"
    />
  </svg>
);

export default PhoneIcon;
