import React from 'react';
import PropTypes from 'prop-types';
import { AllTasksTableMails } from 'crm/tasks/_components';
import { useHistory } from 'react-router-dom';
import arrowBack from './images/arrowBack.svg';

import { staticStyles, getDynamicStyles } from './style';

const TasksMailsPage = ({ theme }) => {
  const dynamicStyles = Object.keys({}).length ? getDynamicStyles(theme) : ` `;
  const history = useHistory();

  return (
    <div className="TasksMailsPage">
      <div className="TasksMailsPage__back">
        <img className="TasksMailsPage__back-icon" src={arrowBack} alt="arrow back" />
        <button type="button" className="TasksMailsPage__back-text" onClick={() => history.back()}>
          Назад
        </button>
      </div>
      <div className="TasksMailsPage__manager-name">Manager Name (Mails)</div>
      <AllTasksTableMails />
      <style jsx>{staticStyles}</style>
      <style>{dynamicStyles}</style>
    </div>
  );
};

TasksMailsPage.propTypes = {
  theme: PropTypes.object,
};

TasksMailsPage.defaultProps = {
  theme: {},
};

export default TasksMailsPage;
