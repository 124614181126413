import css from 'styled-jsx/css';
import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .LeftNavigationLink {
    position: relative;
    display: flex;
    align-items: center;
    padding: ${isRTL ? '13px 30px 13px 15px' : '13px 15px 13px 30px'};

    &__Tooltip {
      position: absolute;
      display: flex;
      align-items: center;
      width: max-content;
      border-radius: 2px;
      height: 100%;
      z-index: 100;
      left: calc(100% + 1px);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: left;
      background: #ceecf9;
      padding-left: 7px;
      padding-right: 7px;
      color: #0aa1e2;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      .LeftNavigationLink__Tooltip {
        opacity: 1;
        visibility: visible;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      transition: all 0.4s ease;
      transform: scale(0);
      opacity: 0;
    }

    &--crm {
      width: 100%;
      padding: 10px;
      justify-content: center;
      border-radius: 0px 6px 6px 0px;

      &:after {
        content: none;
      }
    }

    &:hover {
      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }

    .LeftNavigationLink__icon {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      width: 18px;
      border: none;
      position: relative;
      @include respond-to(xl) {
        margin: 0 12px 0 0;
      }

      @include respond-to(lg) {
        display: none;
      }

      &--crm {
        @include respond-to(lg) {
          display: inline-block !important;
        }
        @include min-width(991) {
          margin: 0 !important;
        }
      }

      @include respond-to(md) {
        display: inline-block;
      }

      svg {
        width: 21px;
        height: 16px;
        position: relative;
        top: 1px;
        @include respond-to(md) {
          width: 18px;
        }
      }

      .activeIcon {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .LeftNavigationLink__p {
      display: flex;
      align-items: center;
      line-height: 1;
      width: 80%;
      &--crm {
        @include min-width(991) {
          display: none;
        }
      }
    }

    .LeftNavigationLink__menuWallet-p {
      position: relative;
    }

    .LeftNavigationLink__menuWallet-balance {
      position: absolute;
      opacity: 0.5;
      bottom: -23px;
    }

    &.active {
      &:after {
        transform: scale(1);
        opacity: 1;
      }
      .LeftNavigationLink__p {
        font-weight: 500;
      }

      .LeftNavigationLink__icon {
        .icon {
          opacity: 0;
        }
        .activeIcon {
          opacity: 1;
        }
        .CustomIcon {
          width: 21px;
          object-fit: cover;
        }
      }
    }

    &__counter {
      font-size: rem(10px);
      width: 15px;
      height: 15px;
      line-height: 1;
      position: absolute;
      left: -7px;
      top: -4px;
      border-radius: 50%;
      padding: 3px;
      z-index: 3;
    }

    .LeftNavigationLink__tooltip {
      display: flex;
      margin-left: rem(10px);

      :global(.Tooltip) {
        padding: rem(10px);
        font-size: rem(14px);
      }
    }
  }

  .LeftNavigationLink__link-wrapper {
    display: flex;
    position: relative;

    .LeftNavigationLink__info-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      border: 1px solid;
      border-radius: 50%;
      font-size: rem(14px);
    }
  }

  .LeftNavigationLinkCategory {
    .LeftNavigationLink__p {
      display: flex;
      width: auto;
      align-items: center;
      span {
        position: relative;
        flex: 1 1;
        line-height: 1.25;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          -ms-flex-preferred-size: auto !important;
          flex: auto !important;
        }
      }
      .LeftNavigationLink__arrow-icon {
        position: relative;
        width: 14px;
        height: 16px;
        margin-left: rem(10px);
        &:before,
        &:after {
          content: '';
          position: absolute;
          display: inline-block;
          transition: all 0.2s ease;
          width: 8px;
          top: 50%;
          transform: translateX(-50%);
          height: 1px;
        }
        &:before {
          left: 0;
          transform: rotate(45deg);
        }
        &:after {
          right: 0;
          transform: rotate(-45deg);
        }
      }
    }
    &.active {
      &:after {
        display: none;
      }
    }
  }

  .LeftNavigationLinkCategory__open {
    .LeftNavigationLink__p {
      .LeftNavigationLink__arrow-icon {
        &:before {
          transform: rotate(-45deg);
        }
        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }

  .LeftNavigationLinkItems {
    display: block;
    transition: all 0.3s ease;
    height: 0;
    overflow: hidden;
    a {
      opacity: 0.7;
      position: relative;
      margin-bottom: 5px;
      width: 100%;
      &:last-child {
        margin: 0;
      }
      @include respond-to(xl) {
        padding-left: 66px;
      }
      @include respond-to(lg) {
        padding-left: 35px;
        font-size: 14px;
      }
      @include respond-to(md) {
        padding-left: 65px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        transition: all 0.4s ease;
        transform: scale(0);
        opacity: 0;
      }
      &:hover {
        opacity: 1;
        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
      &.active {
        opacity: 1;
        font-weight: 500;
        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  .LeftNavigationLinkItems__open {
    padding: 5px 0 10px;
    height: auto;
  }

  .LeftNavigationLink {
    &__link-wrapper {
      &--menuWallet {
        position: relative;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include respond-to(md) {
          padding-bottom: 15px;
        }
      }
    }

    &__menuWallet-icon {
    }

    &__menuWallet-icon-img {
      position: relative;
      line-height: 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        cursor: default;
        display: none;
      }

      &--active {
        &:before {
          display: block;
        }
      }

      svg {
        /* +++ dynamic styles */
        position: relative;
        z-index: 1;

        circle {
          stroke: red;
        }

        path {
          fill: red;
        }
      }
    }

    &__menuWallet-icon-angle {
      display: none;
      position: absolute;
      top: calc(100% + 3px);
      left: 50%;
      z-index: 101;

      &--active {
        display: block;
      }
    }

    &__menuWallet-icon-info {
      display: none;
      position: absolute;
      background: #fff;
      padding: 14px 10px 10px 19px;
      left: 20px;
      top: 100%;
      z-index: 100;
      width: 434px;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

      &--active {
        display: block;
      }
    }

    &__menuWallet-icon-info-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 8px 0;
    }

    &__menuWallet-icon-info-text {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      margin: 0 0 8px 0;
    }

    &__menuWallet-icon-info-link {
      font-size: 12px;
      line-height: 16px;

      a {
        color: #005479;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .LeftNavigationLink {
      padding: ${isRTL ? '13px 30px 13px 15px' : '13px 15px 13px 30px'};
      &:after {
        background: ${gradient(0, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
      }

      .LeftNavigationLink__icon {
        margin: ${isRTL ? '0 0 0 17px' : '0 17px 0 0'};

        svg:not(.notFillIcon) {
          g,
          path {
            fill: ${rgba([colors.black.rgb, 0.6])};
            @include respond-to(md) {
              fill: ${colors.white.hex};
              opacity: 1;
            }
          }
          rect {
            stroke: ${rgba([colors.black.rgb, 0.6])};
            @include respond-to(md) {
              stroke: ${colors.white.hex};
              opacity: 1;
            }
          }
        }

        :global(.notFillIcon) {
          g,
          path {
            @include respond-to(md) {
              stroke: ${colors.white.hex};
              opacity: 1;
            }
          }
        }
      }

      .LeftNavigationLink__p {
        color: ${colors.black.hex};
        @include respond-to(md) {
          color: ${colors.white.hex};
        }
      }

      &.active {
        .LeftNavigationLink__icon {
          svg:not(.notFillIcon) {
            g,
            path {
              opacity: 1;
              fill: url(#linearGradient-4);
            }
          }

          :global(.notFillIcon) {
            g,
            path {
              @include respond-to(md) {
                stroke: url(#linearGradient-4);
                opacity: 1;
              }
            }
          }
        }
      }

      &--crm {
        @include min-width(991) {
          padding-left: 10px;
          &.active {
            background: ${colors.primary.hex};

            :global(svg:not(.notFillIcon)) {
              g,
              path {
                fill: #fff !important;
                stroke: #fff;
                opacity: 1;
              }
            }
          }
        }
      }

      &__counter {
        color: ${colors.white.hex};
        background: ${colors.error.hex};
      }

      &:hover {
        .LeftNavigationLink__counter {
          width: 20px;
          height: 20px;
        }
      }
    }

    @supports (-ms-ime-align: auto) {
      .LeftNavigationLink {
        &.active {
          .LeftNavigationLink__icon {
            svg {
              g,
              path {
                fill: ${colors.gradientStart.hex};
              }
            }
          }
        }
      }
    }

    .LeftNavigationLinkCategory {
      .LeftNavigationLink__p {
        .LeftNavigationLink__arrow-icon {
          margin-right: ${isRTL ? '0.66667rem' : '0'};
          margin-left: ${isRTL ? '0' : '0.66667rem'};
          &:before,
          &:after {
            background: ${colors.black.hex};
            @include respond-to(md) {
              background: ${colors.white.hex};
            }
          }
        }
      }
    }

    .LeftNavigationLinkItems {
      a {
        color: ${colors.black.hex};
        padding: ${isRTL ? '4px 70px 4px 5px' : '4px 5px 4px 70px'};
        @include respond-to(md) {
          color: ${colors.white.hex};
        }
        &:after {
          right: ${isRTL ? '0' : 'auto'};
          background: ${gradient(0, [colors.gradientStart.rgb, 1], 0, [colors.gradientEnd.rgb, 1], 100)};
        }
      }
    }

    .LeftNavigationLink__info-icon {
      color: ${colors.error.hex};
      border-color: ${colors.error.hex};
    }

    .LeftNavigationLink__tooltip {
      :global(.Tooltip) {
        background-color: ${colors.white.hex} !important;
      }

      .__react_component_tooltip.type-dark.place-bottom:after {
        border-bottom-color: ${colors.white.hex} !important;
      }

      .__react_component_tooltip.type-dark.place-top:after {
        border-top-color: ${colors.white.hex} !important;
      }

      .__react_component_tooltip.type-dark.place-right:after {
        border-right-color: ${colors.white.hex} !important;
      }

      .__react_component_tooltip.type-dark.place-left:after {
        border-left-color: ${colors.white.hex} !important;
      }
    }

    .LeftNavigationLink {
      &__menuWallet-icon-img {
        svg {
          circle {
            stroke: ${colors.primary.hex};
          }

          path {
            fill: ${colors.primary.hex};
          }
        }

        @include respond-to(md) {
          svg {
            circle {
              stroke: ${colors.white.hex};
            }

            path {
              fill: ${colors.white.hex};
            }
          }
        }
      }
    }
    #LeftNavigation__caption-partnershipPage-client {
    }
  `;
};

export { staticStyles, getDynamicStyles };
