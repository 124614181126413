import css from 'styled-jsx/css';

import { rgba, isRTL } from 'common/styling/theme';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .UserInfo {
    width: 100%;
  }

  .UserInfo__partnership-inner {
    padding: rem(20px 20px 20px 30px);
    margin-bottom: rem(20px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @include respond-to(sm) {
      padding: rem(15px 10px 15px 10px);
    }
    p {
      font-size: rem(20px);
      @include respond-to(sm) {
        width: 100%;
        margin-bottom: rem(15px);
      }
    }
  }

  .UserInfo__partnership-buttons {
    :global(button) {
      margin-right: ${isRTL ? '0' : '10px'};
      margin-left: ${isRTL ? '10px' : '0'};
      &:last-child {
        margin: 0;
      }
      @include respond-to(sm) {
        padding: 8px 15px !important;
        font-size: rem(13px) !important;
      }
    }
  }

  .UserInfo__inform-block {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.06);
    margin-bottom: rem(25px);
    padding: rem(25px 30px 25px);
    @include respond-to(lg) {
      padding: rem(20px 15px 15px);
    }
    h2 {
      margin-bottom: rem(25px);
    }
  }

  .UserInfo__inform-block_text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .UserInfo__line-inner {
    margin-bottom: rem(20px);
  }

  .UserInfo__inform-block_two-inners {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .Address-block,
  .FinancialDetails-block {
    width: 32%;
    padding-right: rem(20px);
    @include respond-to(lg) {
      padding-right: 0;
      width: 100%;
      border: none;
      padding-bottom: rem(15px);
      margin-bottom: rem(20px);
    }
    .UserInfoPage__inform-block_text {
      display: block;
      span {
        &:last-child {
          margin: 0;
        }
      }
      .InfoLine__right {
        white-space: break-spaces;
      }
    }
  }

  .TypeOfYourActivity-block {
    width: 32%;
    @include respond-to(lg) {
      width: 100%;
    }
    .UserInfoPage__inform-block_text {
      display: block;
    }
  }

  .TradingExperience-block {
    .UserInfoPage__inform-block_text {
      span {
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .TradingExperience__small-block {
    width: 48%;
    padding-bottom: rem(45px);
    &:nth-child(2) {
      @include respond-to(lg) {
        padding-bottom: 3px;
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      padding-bottom: 10px;
    }
    &:nth-child(1),
    &:nth-child(3) {
      @include respond-to(lg) {
        border: none;
        padding-bottom: 3px;
      }
    }
    @include respond-to(lg) {
      width: 100%;
      padding: 0;
      margin-bottom: rem(25px);
    }
  }

  .UserInfoAddress {
    position: relative;
    margin: 0 0 20px 0;
    padding: 25px 30px 25px 30px;
    display: block;

    @include respond-to(sm) {
      padding: 18px 13px 18px 13px;
      margin: 0 0 15px 0;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__title {
      margin: 0 0 30px 0;

      &--hide {
        margin: 0 0 0 0 !important;
        display: inline;
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__content {
      display: flex;

      @include respond-to(lg) {
        display: block;
      }

      &--hide {
        display: none;
      }
    }

    &__address-block {
      width: calc(100% / 3);

      @include respond-to(lg) {
        width: 100%;
        padding: 0 0 0 0;
      };
    }

    &__financial-details-block {
      width: calc(100% / 3);
      padding: 0 20px 0 20px;

      @include respond-to(lg) {
        width: 100%;
        padding: 0 0 0 0;
      }
    }

    &__type-of-your-activity-block {
      width: calc(100% / 3);
      padding: 0 20px 0 20px;

      @include respond-to(lg) {
        width: 100%;
        padding: 0 0 0 0;
      }
    }
  }

  .TradingExperience {
    position: relative;
    padding: 25px 30px 25px 30px;
    margin: 0 0 35px 0;

    @include respond-to(sm) {
      padding: 18px 13px 18px 13px;
      margin: 0 0 50px 0;
    }

    &__open-close {
      position: absolute;
      left: 0;
      top: 0;
      height: 70px;
      width: 100%;
      cursor: pointer;

      @include respond-to(sm) {
        height: 50px;
      }

      &--close {
        height: 82px;

        @include respond-to(sm) {
          height: 60px;
        }
      }
    }

    &__title {
      &--hide {
        margin: 0 0 0 0 !important;
      }
    }

    &__arrowTopKYC {
      position: absolute;
      right: 20px;
      top: 35px;
      cursor: pointer;

      @include respond-to(sm) {
        top: 25px;
      }

      &--hide {
        transform: scale(1, -1);
      }
    }

    &__content {
      &--hide {
        display: none;
      }
    }
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    @import 'src/common/styling/_mixins-scss/screen-size';

    .UserInfo__partnership-inner {
      background: ${border.radius};
      background: ${rgba([colors.warning.rgb, 0.3])};
    }

    .UserInfo__inform-block {
      background-color: ${colors.white.hex};
    }

    .Address-block,
    .FinancialDetails-block {
      border-right: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      @include respond-to(lg) {
        border: none;
        border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
      }
    }

    .TradingExperience__small-block {
      &:nth-child(2) {
        @include respond-to(lg) {
          border: none;
          border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
        }
      }
      &:nth-child(1),
      &:nth-child(3) {
        border-right: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
        @include respond-to(lg) {
          border: none;
          border-bottom: 1px solid ${rgba([colors.lightGray.rgb, 0.5])};
        }
      }
      &:last-child {
        border: none;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
