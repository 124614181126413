import css from 'styled-jsx/css';

import { gradient, rgba, isRTL } from 'common/styling/theme';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';
  @import 'src/common/styling/_mixins-scss/vars';

  .BaseButton {
    position: relative;
    display: inline-block;
    padding: rem(9px 20px);
    cursor: pointer;
    font-size: rem(12px);
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    min-width: rem(105px);

    &:active {
      box-shadow: none;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .plus {
    padding-left: rem(${isRTL ? '20px' : '40px'});
    padding-right: rem(${isRTL ? '40px' : '20px'});

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: rem(12px);
      height: rem(2px);
      top: 48%;
      transform: translateY(-48%);
      left: rem(${isRTL ? '0' : '13px'});
      right: rem(${isRTL ? '13px' : '0'});
    }

    &:after {
      transform: rotate(-90deg);
      top: 46%;
    }
  }

  .maxContent {
    width: max-content;
  }

  .noBorder {
    border-radius: 0px !important;
  }

  .fill {
    width: 100%;
  }
`;

const getDynamicStyles = ({ colors, border }) => {
  return css.global`
    .BaseButton {
      border-radius: ${border.radius};
      color: ${colors.white.hex};
      border: none;
      background: ${colors.primary.hex};

      &:hover {
        box-shadow: 0 3px 6px 0 ${rgba([colors.primary.rgb, 0.4])};
        background: ${colors.primary.hex};
        color: ${colors.white.hex};
      }

      &:disabled {
        background: ${colors.gray.hex};
      }

      &__white {
        background: ${colors.white.hex};
        color: ${colors.primary.hex};
        box-shadow: inset 0px 0px 0px 1px ${colors.primary.hex};

        &:hover {
          box-shadow: 0 3px 6px 0 ${rgba([colors.primary.rgb, 0.4])}, inset 0px 0px 0px 1px ${colors.primary.hex};
          color: ${colors.primary.hex};
          background: ${colors.white.hex};
        }

        &:disabled {
          background: ${colors.gray.hex};
        }
      }
    }

    .plus {
      &:after,
      &:before {
        background-color: ${colors.white.hex};
      }
    }
    .BaseButton__red {
      border: 1px solid #ea4c4c;
      color: ${colors.white.hex};
      background: #ea4c4c;
      &:hover {
        border: 1px solid #ea4c4c;
        color: ${colors.white.hex};
        background: #ea4c4c;
      }
      &:disabled {
        background: ${colors.gray.hex};
      }
    }
    .BaseButton__white {
      border: 1px solid #e9e9e9;
      color: #696969;
      background: ${colors.white.hex};
      &:hover {
        border: 1px solid #e9e9e9;
        color: #696969;
        background: ${colors.white.hex};
      }
      &:disabled {
        background: ${colors.gray.hex};
      }
    }

    .BaseButton__success {
      background: ${colors.success.hex};
      color: ${colors.white.hex};
      &:hover {
        background: ${rgba([colors.success.rgb, 0.5])};
        border-color: ${colors.success.hex};
      }
      &:disabled {
        border-color: transparent;
        background: ${rgba([colors.success.rgb, 0.5])};
      }
    }

    .BaseButton__error {
      background: ${colors.error.hex};
      color: ${colors.white.hex};
      &:hover {
        background: ${rgba([colors.error.rgb, 0.5])};
        border-color: ${colors.error.hex};
      }
      &:disabled {
        border-color: transparent;
        background: ${rgba([colors.error.rgb, 0.5])};
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
