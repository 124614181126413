import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { PageLayout, PageContentPreloader, BasicModal, DefaultButton } from 'common/components';

import { withTheme } from 'common/styling/theme';
import { getTradingAccountList, getTradingAccountTypes } from '../../_redux';
import { getDealStats, getLpaAgreements, getSumsubExternalLink, setLpaAgreements } from '../_redux';
import AccountsBlock from '../../_components/AccountsBlock';
import WarningMessage from '../../_components/WarningMessage';
import InvestBlock from '../_components/InvestBlock';
import { AccountStatisticalBlock } from '../_components';

import { staticStyles, getDynamicStyles } from './style';
import LpaAgreementsForm from '../_components/LpaAgreementsForm';

const AccountsPage = ({
  profile,
  getDealStats,
  getSumsubExternalLink,
  getTradingAccountTypes,
  getTradingAccountList,
  getLpaAgreements,
  loadingAccounts,
  accountsIsLoaded,
  accounts,
  accountTypes,
  dealStats,
  dealStatsAreLoaded,
  partnershipSystem,
  areTransfersEnabled,
  investIsEnabled,
  isSumsubEnabled,
  sumsubExternalLink,
  sumsubExternalLinkIsLoaded,
  history,
  addons,
  theme,
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;
  const [isAgreementsModalOpen, setAgreementsModalOpen] = useState(false);
  const [isAgreementSigned, setIsAgreementSigned] = useState(profile.lpaStatus?.sign_agreements);
  const toggleModal = () => {
    setAgreementsModalOpen(!isAgreementsModalOpen);
  };
  useEffect(() => {
    getDealStats();
    getTradingAccountTypes();
  }, [getDealStats, getTradingAccountTypes]);

  useEffect(() => {
    getTradingAccountList();
  }, [getTradingAccountList]);

  useEffect(() => {
    if (profile.status === 'need_income_sources') {
      getSumsubExternalLink();
    }
  }, [profile, getSumsubExternalLink]);

  const demoAccounts = accounts.filter(account => account.isDemo) || [];
  const showDemoAccounts = accountTypes.some(account => account.isDemo);
  const realAccounts = accounts.filter(
    account => !account.isDemo && account.platformTitle !== 'INVEST' && account.platformTitle !== 'INVEST_TRADER'
  );
  const showRealAccounts = accountTypes.some(account => !account.isDemo);

  const goToVerification = () => {
    if (profile.status === 'need_income_sources' && sumsubExternalLinkIsLoaded) {
      window.location.href = sumsubExternalLink?.url;
    } else {
      history.push(profile.needsVerification && !isSumsubEnabled ? '/personal_profile' : '/documents');
    }
  };

  const handleDeposit = accountId => {
    const redirectUrl = addons.includes('walletAccount')
      ? `/internalTransfer?tab=deposit&account=${accountId}`
      : `/deposit/${accountId}`;
    history.push(redirectUrl);
  };

  const handleWithdraw = accountId => {
    const redirectUrl = addons.includes('walletAccount')
      ? `/internalTransfer?tab=withdraw&account=${accountId}`
      : `/withdraw/${accountId}`;
    history.push(redirectUrl);
  };

  return (
    profile && (
      <PageLayout
        captionSlug="clientAccounts"
        showReload
        reloading={loadingAccounts}
        onReloadClick={getTradingAccountList}>
        <PageContentPreloader ready={accountsIsLoaded} type="accounts">
          {accountsIsLoaded && !accountTypes.length && <WarningMessage headerTextId="noAccountTypesCreated" />}
          {!['verified', 'verified_new_documents'].includes(profile.status) && (
            <WarningMessage
              headerTextId="verifyToUnlockAccountsTitle"
              bodyTextId="verifyToUnlockAccountsDescription"
              onButtonClick={() => goToVerification()}
              buttonTextId="verifyYourAcc"
              className="AccountsPage__warning-message"
            />
          )}

          {profile.lpaStatus?.is_lpa && (
            <div className="AccountsPage__lpa">
              {isAgreementSigned ? (
                <DefaultButton className="not-button" onClick={toggleModal} textId="justLPA" filled />
              ) : (
                <WarningMessage
                  headerTextId="justSignLpaAgreement"
                  onButtonClick={toggleModal}
                  buttonTextId="justSign"
                  className="AccountsPage__warning-message"
                />
              )}
            </div>
          )}
          {!!realAccounts.length && (
            <AccountStatisticalBlock dealStats={dealStats} statisticLoaded={dealStatsAreLoaded} />
          )}
          {partnershipSystem === 'simple' && profile.isPartner && !profile.ibSettingsChosen && (
            <div className="AccountsPage__ibSettings-warning">
              <FormattedMessage
                id="requiredIbSettingsDescription"
                values={{
                  action: (
                    <Link to="/partnership/IBPrograms/">
                      <FormattedMessage id="selectAccountForPayments" />
                    </Link>
                  ),
                }}
              />
            </div>
          )}
          {showRealAccounts && (
            <AccountsBlock
              data={realAccounts}
              captionId="realAccounts"
              buttonId="addRealAccount"
              noAccCaptionId="noRealAccsCreated"
              onAccountClick={id => history.push(`/account/${id}`)}
              onAddAccountClick={() => history.push('/create-account/real')}
              onDepositClick={handleDeposit}
              onWithdrawClick={handleWithdraw}
            />
          )}
          {showDemoAccounts && (
            <AccountsBlock
              data={demoAccounts}
              captionId="demoAccounts"
              buttonId="addDemoAccount"
              noAccCaptionId="noDemoAccsCreated"
              onAccountClick={id => history.push(`/account/${id}`)}
              onAddAccountClick={() => history.push('/create-account/demo')}
              isDemo
            />
          )}

          {investIsEnabled && (
            <InvestBlock
              onAddAccountClick={() => history.push('/create-account/real')}
              onDepositClick={handleDeposit}
              onWithdrawClick={handleWithdraw}
              areTransfersEnabled={areTransfersEnabled}
              addons={addons}
            />
          )}

          <BasicModal isOpen={isAgreementsModalOpen} captionId="justSignAgreements" onRequestClose={toggleModal}>
            <LpaAgreementsForm
              toggleModal={toggleModal}
              getLpaAgreements={getLpaAgreements}
              setLpaAgreements={setLpaAgreements}
              setIsAgreementSigned={setIsAgreementSigned}
            />
          </BasicModal>
        </PageContentPreloader>
        <style jsx>{staticStyles}</style>
        <style>{dynamicStyles}</style>
      </PageLayout>
    )
  );
};

AccountsPage.propTypes = {
  profile: PropTypes.object.isRequired,
  getTradingAccountList: PropTypes.func.isRequired,
  getTradingAccountTypes: PropTypes.func.isRequired,
  getLpaAgreements: PropTypes.func.isRequired,
  dealStats: PropTypes.object.isRequired,
  loadingAccounts: PropTypes.bool,
  accountsIsLoaded: PropTypes.bool,
  dealStatsAreLoaded: PropTypes.bool,
  accounts: PropTypes.array.isRequired,
  accountTypes: PropTypes.array.isRequired,
  getDealStats: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  partnershipSystem: PropTypes.string.isRequired,
  addons: PropTypes.array.isRequired,
  areTransfersEnabled: PropTypes.bool.isRequired,
  isSumsubEnabled: PropTypes.bool.isRequired,
  investIsEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

AccountsPage.defaultProps = {
  loadingAccounts: false,
  accountsIsLoaded: false,
  dealStatsAreLoaded: false,
  theme: {},
};

export default compose(
  withTheme(),
  withRouter,
  connect(
    state => ({
      profile: state.user.profile,
      partnershipSystem: state.interfaceConfig.partnership.partnershipSystem,
      loadingAccounts: state.client.common.loadingAccounts,
      accountsIsLoaded: state.client.common.accountsIsLoaded,
      accounts: state.client.common.accounts,
      accountTypes: state.client.common.accountTypes,
      dealStats: state.client.accounts.dealStats,
      dealStatsAreLoaded: state.client.accounts.dealStatsAreLoaded,
      addons: state.interfaceConfig.addons,
      areTransfersEnabled: state.interfaceConfig.internalTransfers.accounts_enabled,
      investIsEnabled: state.interfaceConfig.invest.enabled,
      isSumsubEnabled: state.interfaceConfig.kycSettings.sumsub,
      sumsubExternalLink: state.client.accounts.sumsubExternalLink,
      sumsubExternalLinkIsLoaded: state.client.accounts.sumsubExternalLinkIsLoaded,
    }),
    {
      getTradingAccountTypes: () => getTradingAccountTypes.request(),
      getTradingAccountList: () => getTradingAccountList.request(),
      getLpaAgreements: () => getLpaAgreements.request(),
      setLpaAgreements: () => setLpaAgreements.request(),
      getDealStats: () => getDealStats.request(),
      getSumsubExternalLink: () => getSumsubExternalLink.request(),
    }
  )
)(AccountsPage);
export { AccountsPage };
