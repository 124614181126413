import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .CustomFilterSelect {
    min-width: 250px;
    display: flex;
    align-items: center;

    &__inner {
      width: 100%;
    }

    .Select__control {
      border: none;
      padding: 0;
    }

    .Select__menu {
      @include respond-to(xs) {
        width: 100%;
      }
      width: calc(100% + 100px);
      left: -30px;
      top: 155%;
    }
    .Select__menu-list {
      .Select__option {
        padding: rem(10px) !important;
        font-size: rem(14px);

        &--is-focused {
          background: #ceecf9;
        }
      }
    }

    .Select__indicators {
      display: none;
    }

    .Select__placeholder,
    .Select__single-value {
      color: #000;
      margin-left: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;

      color: #000000;
    }
  }
  .CustomFilterSelect__inner {
    display: flex;
    align-items: center;
    gap: 10px;
    & > div {
      width: 100%;
    }
  }
  .CustomFilterSelect__option-buttons {
    padding-right: 10px;
    display: flex;
    gap: rem(10px);
  }
  .CustomFilterSelect__option {
    background: #fff;
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    &:hover,
    &:focus {
      background: #ceecf9;
    }
    &--selected {
      .CustomFilterSelect__option-buttons {
        background: #cef4f5;
      }
      &:hover,
      &:focus {
        .CustomFilterSelect__option-buttons {
          background: #ceecf9;
        }
      }
    }
    &--focused {
      .CustomFilterSelect__option-buttons {
        background: #ceecf9;
      }
    }
  }
  .CustomFilterSelect__delete-icon {
    transform: scale(0.9);
  }
  .CustomFilterSelect__option-text {
    width: 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &--whole {
      max-width: 100%;
      font-weight: 500;
    }
  }
`;

const getDynamicStyles = ({ colors }) => {
  return css.global`
    .CustomFilterSelect {
      .Select__menu {
        border-top: 2px solid ${colors.primary.hex} !important;
      }
    }
  `;
};

export { staticStyles, getDynamicStyles };
