import css from 'styled-jsx/css';

const staticStyles = css.global`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .BaseStaticInput {
    position: relative;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;

    &__OpenOptionsButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      border: 1px solid #e7e7e7;
      border-radius: 3px;
      height: 100%;
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: gray;
      &:disabled {
        color: #e9e9e9;
        border-color: #e9e9e9;
        > svg > path {
          fill: #e9e9e9;
        }
      }
    }

    &__OptionMulti--Selected {
      background: rgba(10, 161, 226, 0.4);
    }

    &__OpenOptionsButton--FastFilterClosed {
      border: 1px solid #e7e7e7;
      color: gray;
      border-color: #e7e7e7;
      > svg > path {
        fill: #e7e7e7;
      }
    }
    &__Option {
      padding-left: 12px;
      display: flex;
      align-items: center;
      height: 30px;
      position: relative;
      &:hover {
        background: #ceecf9;
      }
    }

    &__Option--FastFilter {
      height: 34px;
      min-height: 34px !important;
    }

    &__Input {
      border: 0;
      background: transparent;
      width: 100%;
      height: 100%;
      padding-top: 9px;
      padding-left: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.2px;
      color: gray;
    }

    &__NewOption {
      display: flex;
      padding: 2px 8px;
      align-items: center;
      border-radius: 20px;
      background: #91a782;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
      background: gray;
      color: white;

      &:hover {
        background: #ceecf9;
        color: black;
      }
    }

    &__Labels {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
    }

    &__LabelsSelected {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
    }

    &__LabelsSelectedCentered {
      flex: 1; /* Expand to fill remaining space */
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__LabelsAmount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 4px 0 4px;
    }

    &__NewOption--isSelected {
      background: #0aa1e2;
    }
  }
`;

export { staticStyles };
