/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

const FXCAUnlimitedLeverage = ({ leverage }) => {
  if (leverage != 2000000000) {
    return `1:${leverage}`;
  }
  return <FM id="justUnlimited">{txt => txt}</FM>;
};

FXCAUnlimitedLeverage.propTypes = {
  leverage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FXCAUnlimitedLeverage;
