import React from 'react';

const MailIcon = () => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21746 0.00634766H11.0226C11.1514 0.00634766 11.256 0.123685 11.256 0.268848V8.14385C11.256 8.28901 11.1514 8.40635 11.0226 8.40635H1.21746C1.08836 8.40635 0.984009 8.28901 0.984009 8.14385V0.268848C0.984009 0.123685 1.08836 0.00634766 1.21746 0.00634766ZM7.89719 3.99482L10.7892 7.2464V0.531394H1.45104V7.2464L4.34308 3.99482L4.67318 4.36599L1.54676 7.88139H10.6935L7.56709 4.36599L7.89719 3.99482Z"
      fill="#0AA1E2"
    />
    <path
      d="M10.737 0.00629997L6.12001 4.76702L1.49761 -1.14441e-06L1.24081 0.00629883L0.984009 0.219988L5.93845 5.32865C6.03886 5.43218 6.20116 5.43218 6.30157 5.32865L11.256 0.219988L10.9992 0.00629883L10.737 0.00629997Z"
      fill="#0AA1E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21746 0.00634766H11.0226C11.1514 0.00634766 11.256 0.123685 11.256 0.268848V8.14385C11.256 8.28901 11.1514 8.40635 11.0226 8.40635H1.21746C1.08836 8.40635 0.984009 8.28901 0.984009 8.14385V0.268848C0.984009 0.123685 1.08836 0.00634766 1.21746 0.00634766ZM7.89719 3.99482L10.7892 7.24641V0.531394H1.45104V7.24641L4.34308 3.99482L4.67318 4.36599L1.54676 7.88139H10.6935L7.56709 4.36599L7.89719 3.99482Z"
      fill="#0AA1E2"
    />
  </svg>
);

export default MailIcon;
