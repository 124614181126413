import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useTable, useSortBy, useExpanded } from 'react-table';
import { withTheme } from 'common/styling/theme';
import { throttle } from 'common/utils/helpers';
import noInfo from 'common/images/noInfo.svg';
import ResponsiveTable from '../ResponsiveTable';

import { staticStyles, getDynamicStyles } from './style';

const ReactDataTable = ({
  columns,
  data,
  onRowClick,
  getTrProps,
  renderRowSubComponent,
  isLoading,
  theme,
  initialSortBy,
  onChangeSort,
  noResponsive,
  withoutSort,
  isInfinite,
  ...props
}) => {
  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme, columns) : ` `;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const ITEMS_PER_PAGE = 20;
  const [hasMore, setHasMore] = useState(data.length > ITEMS_PER_PAGE);
  const [records, setRecords] = useState(ITEMS_PER_PAGE);
  const [newData, setNewData] = useState(data.slice(0, ITEMS_PER_PAGE));
  const [bottom, setBottom] = useState(false);

  const tableRef = useRef(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    footerGroups,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data: isInfinite ? newData : data,
      initialState: { sortBy: initialSortBy },
      manualSortBy: true,
    },
    useSortBy,
    useExpanded
  );

  /* eslint-disable */
  useEffect(() => {
    onChangeSort(sortBy);
  }, [sortBy]);
  /* eslint-enable */

  const hasFooter = columns.some(column => column.Footer);

  // Infinite scroll
  const showItems = from => {
    const items = [];
    for (let i = from; i < records + ITEMS_PER_PAGE; i += 1) {
      items.push(data[i]);
    }
    return items;
  };

  const loadMore = () => {
    if (records + ITEMS_PER_PAGE >= data.length) {
      setHasMore(false);
      setNewData(data);
    } else {
      setRecords(records + ITEMS_PER_PAGE);
      setNewData([...newData, ...showItems(records)]);
      setBottom(false);
    }
  };

  const throttledScrollHandler = throttle(e => {
    setBottom(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight);
  }, 500);

  useEffect(() => {
    if (isInfinite) {
      tableRef.current.addEventListener('scroll', throttledScrollHandler);
    }
  }, [newData, isInfinite]);

  useEffect(() => {
    if (bottom) {
      loadMore();
    }
  }, [bottom]);

  // Infinite scroll

  return (
    <div className="ReactDataTable__wrapper" ref={tableRef}>
      {!isTabletOrMobile || noResponsive ? (
        <>
          <table {...getTableProps()} className="ReactDataTable">
            <thead className="ReactDataTable__thead">
              {headerGroups.map((headerGroup, groupId) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={`thead_${groupId}`}
                  className="ReactDataTable__thead-row">
                  {headerGroup.headers.map((column, columnId) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={`thead_${groupId}_${columnId}`}
                      className={classNames('ReactDataTable__thead-cell', {
                        'ReactDataTable__thead-cell--sortable': column.canSort,
                      })}>
                      {typeof column.render('Header') === 'string' && (
                        <>
                          <FormattedMessage id={column.render('Header')}>
                            {txt => <span className="ReactDataTable__thead-caption">{txt}</span>}
                          </FormattedMessage>
                          {column.canSort && !withoutSort && (
                            <i
                              className={classNames('ReactDataTable__arrow', {
                                'ReactDataTable__arrow--active': column.isSorted,
                                'ReactDataTable__arrow--up': column.isSortedDesc,
                                'ReactDataTable__arrow--down': !column.isSortedDesc,
                              })}
                            />
                          )}
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="ReactDataTable__tbody">
              {rows.map((row, rowId) => {
                prepareRow(row);
                return (
                  <React.Fragment key={`tbody_${rowId}`}>
                    <tr
                      {...getTrProps(row.getRowProps(), row)}
                      className={classNames('ReactDataTable__tbody-row', getTrProps(row.getRowProps(), row).className, {
                        'ReactDataTable__tbody-row--unclickable': row.isExpanded || !onRowClick,
                      })}
                      onClick={() => onRowClick?.(+row.id + 1, row)}>
                      {row.cells.map((cell, cellId) => (
                        <td
                          {...cell.getCellProps()}
                          key={`tbody_${rowId}_${cellId}`}
                          className="ReactDataTable__tbody-cell">
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                    {row.isExpanded && (
                      <tr className="ReactDataTable__expanded-row">
                        <td>{renderRowSubComponent({ row })}</td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
              {!data.length && (
                <FormattedMessage id="noData">
                  {txt => (
                    <tr className="ReactDataTable__noData">
                      <td>
                        <img src={noInfo} className="ReactDataTable__noData-img" alt="nodata" />
                        <span>{txt}</span>
                      </td>
                    </tr>
                  )}
                </FormattedMessage>
              )}
              {isLoading && (
                <FormattedMessage id="justLoading">
                  {txt => (
                    <tr className="ReactDataTable__loading">
                      <td>{txt}</td>
                    </tr>
                  )}
                </FormattedMessage>
              )}
            </tbody>
            {hasFooter && (
              <tfoot className="ReactDataTable__tfoot">
                {footerGroups.map((group, groupId) => (
                  <tr {...group.getFooterGroupProps()} key={`tfoot_${groupId}`} className="ReactDataTable__tfoot-row">
                    {group.headers.map((column, columnId) => (
                      <td
                        {...column.getFooterProps()}
                        key={`tfoot_${groupId}_${columnId}`}
                        className="ReactDataTable__tfoot-cell">
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
          {hasMore && isInfinite ? <div className="ReactDataTable__loader">Loading...</div> : null}
        </>
      ) : (
        <ResponsiveTable {...props} onRowClick={onRowClick} columns={columns} data={data} isNewTable />
      )}
      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </div>
  );
};

ReactDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      key: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  isLoading: PropTypes.bool,
  renderRowSubComponent: PropTypes.func,
  initialSortBy: PropTypes.array,
  onChangeSort: PropTypes.func,
  onRowClick: PropTypes.func,
  getTrProps: PropTypes.func,
  noResponsive: PropTypes.bool,
  withoutSort: PropTypes.bool,
  isInfinite: PropTypes.bool,
  theme: PropTypes.object,
};

ReactDataTable.defaultProps = {
  data: [],
  isLoading: false,
  renderRowSubComponent: null,
  initialSortBy: [],
  getTrProps: props => props,
  onChangeSort: () => {},
  onRowClick: null,
  noResponsive: false,
  withoutSort: false,
  isInfinite: false,
  theme: {},
};

export default withTheme()(ReactDataTable);
export { ReactDataTable };
