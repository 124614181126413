import css from 'styled-jsx/css';

const staticStyles = css`
  @import 'src/common/styling/_mixins-scss/screen-size';
  @import 'src/common/styling/_mixins-scss/rem';

  .ContactPageInfoBlock {
    width: 100%;
    margin: 0 0 60px 0;
    &__TitleWrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;
      cursor: pointer;
      user-select: none;
    }

    &__Title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.2px;
    }

    &__ContentWrap {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__TitleArrow {
      &--Show {
        transform: rotate(180deg);
      }
    }

    &__Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 7px 0;
      border-bottom: 1px solid #cdcfd2;
      margin: 0 0 9px 0;

      &:last-child {
        margin: 0 0 0 0;
      }
    }

    &__ItemTitle {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #696969;
    }

    &__ItemContent {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: 0.2px;
      display: flex;
      gap: 10px;
    }
  }
`;

export { staticStyles };
