import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';

import { closeModal, openModal } from 'interface/user/_redux/actions';
import {
  PageLayout,
  PageTabs,
  TranslatedNavLink,
  PageContentPreloader,
  Calendar,
  BasicModal,
  NoItemsPanel,
  PasswordChangeForm,
} from 'common/components';
import noOrdersImage from 'common/images/noOrders.svg';
import { withTheme } from 'common/styling/theme';
import { changeTradingAccountPassword } from '../_redux';
import { getTradingAccount, getTradingAccountOperations } from '../../_redux/actions';

import TradeOperationsTable from '../../_components/TradeOperationsTable';
import AccountInfoPanel from '../../_components/AccountInfoPanel';
import { AccountLeverageChangeForm } from '../_components';

import { staticStyles, getDynamicStyles } from './style';

const startDate = moment().add(0, 'day').startOf('day').unix();

const endDate = moment().add(1, 'day').startOf('day').unix();

const startCalendar = moment().add(0, 'day').startOf('day');

const endCalendar = moment().add(1, 'day').startOf('day');

const AccountPage = ({
  account,
  getTradingAccount,
  getOperations,
  operationsAreLoaded,
  operations,
  history,
  match,
  accountIsLoaded,
  modals,
  openModal,
  closeModal,
  addons,
  openOrders,
  closedOrders,
  delayedOrders,
  equityExist,
  redirect,
  theme,
}) => {
  const { accountId } = match.params;

  useEffect(() => {
    getTradingAccount(accountId);
  }, [getTradingAccount, accountId]);

  const isMt5Account = account.platformSlug === 'mt5';
  const isCfhAccount = account.platformSlug === 'cfh';
  const isMt4Account = account.platformSlug === 'mt4';

  const OPERATION_STATUSES_BY_SLUG = {
    cfh: 'closed',
    mt5: 'closed',
  };

  const getOperationStatus = useCallback(
    slug => match.params.operationStatus ?? OPERATION_STATUSES_BY_SLUG[slug],
    [OPERATION_STATUSES_BY_SLUG, match.params.operationStatus]
  );

  const convertedPendingStatus = isMt5Account ? 'deferred' : 'delayed';

  const operationStatus = useMemo(() => {
    const status = getOperationStatus(account.platformSlug);

    return status === 'pending' ? convertedPendingStatus : status;
  }, [getOperationStatus, account.platformSlug, convertedPendingStatus]);

  const memoizedOnChangeParams = useMemo(
    () => ({ id: Number(accountId), operationStatus }),
    [accountId, operationStatus]
  );

  useEffect(() => {
    if (accountIsLoaded) getOperations(accountId, operationStatus, startDate, endDate);
  }, [getOperations, accountId, operationStatus, accountIsLoaded]);

  const dynamicStyles = Object.keys(theme).length ? getDynamicStyles(theme) : ` `;

  const handleDeposit = () => {
    const redirectUrl = addons.includes('walletAccount')
      ? `/internalTransfer?tab=deposit&account=${accountId}`
      : `/deposit/${accountId}`;
    history.push(redirectUrl);
  };

  const handleWithdraw = () => {
    const redirectUrl = addons.includes('walletAccount')
      ? `/internalTransfer?tab=withdraw&account=${accountId}`
      : `/withdraw/${accountId}`;
    history.push(redirectUrl);
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <PageLayout
      captionSlug="clientAccount"
      extraCaptionText={account && account.login ? ` #${account.login}` : ''}
      showReload
      onReloadClick={() => getTradingAccount(accountId)}
      reloading={!accountIsLoaded}>
      <PageContentPreloader ready={accountIsLoaded}>
        {!!account.id && (
          <AccountInfoPanel
            {...account}
            onLeverageChangeClick={() => openModal('accountChangeLeverage')}
            onPasswordChangeClick={() => openModal('accountChangePassword')}
            onDepositClick={handleDeposit}
            onWithdrawClick={handleWithdraw}
            modals={modals}
            closeModal={closeModal}
            openModal={openModal}
            isBinary={account.platformTitle === 'Binary Options'}
          />
        )}
        {equityExist && (
          <>
            <div className="AccountPage__top-line">
              <PageTabs>
                {!(isMt5Account || isCfhAccount) && openOrders && (
                  <TranslatedNavLink id="justOpen" to={`/account/${accountId}`} />
                )}
                {closedOrders && (
                  <TranslatedNavLink
                    id={isMt5Account || isCfhAccount ? 'justDeals' : 'justClosed'}
                    to={isMt5Account || isCfhAccount ? `/account/${accountId}` : `/account/${accountId}/closed`}
                  />
                )}
                {delayedOrders && <TranslatedNavLink id="justPending" to={`/account/${accountId}/pending`} />}
              </PageTabs>
              <div className="AccountPage__date">
                {operationStatus !== 'delayed' && (
                  <Calendar
                    // only mt4
                    daysLimit={operationStatus === 'closed' && isMt4Account ? 1 : 31}
                    onChangeFunc={getOperations}
                    onChangeParams={memoizedOnChangeParams}
                    defaultStartDate={startCalendar}
                    defaultEndDate={endCalendar}
                  />
                )}
              </div>
            </div>
            <div className="AccountPage__table">
              <PageContentPreloader ready={operationsAreLoaded} type="smallLines">
                {operations?.trades?.length ? (
                  <TradeOperationsTable
                    data={operations.trades}
                    summary={operations.summary}
                    operationStatus={operationStatus}
                    isClient
                  />
                ) : (
                  <NoItemsPanel icon={noOrdersImage} captionSlug="yotHaveNoOrdersYet" />
                )}
              </PageContentPreloader>
            </div>
          </>
        )}
      </PageContentPreloader>

      <BasicModal
        captionId="authChangePassword"
        isOpen={modals.accountChangePassword}
        onRequestClose={() => closeModal('accountChangePassword')}>
        {modals.accountChangePassword && (
          <PasswordChangeForm
            id={accountId}
            changePasswordFunc={changeTradingAccountPassword}
            onSubmitSuccess={() => closeModal('accountChangePassword')}
          />
        )}
      </BasicModal>

      <BasicModal
        captionId="changeLeverage"
        isOpen={modals.accountChangeLeverage}
        onRequestClose={() => closeModal('accountChangeLeverage')}>
        {modals.accountChangeLeverage && (
          <AccountLeverageChangeForm
            accountId={accountId}
            options={account.availableLeverages}
            onSubmitSuccess={() => {
              closeModal('accountChangeLeverage');
              getTradingAccount(accountId);
            }}
            initialValues={{ leverage: account.leverage }}
          />
        )}
      </BasicModal>

      <style jsx global>
        {staticStyles}
      </style>
      <style jsx global>
        {dynamicStyles}
      </style>
    </PageLayout>
  );
};

AccountPage.propTypes = {
  account: PropTypes.object.isRequired,
  operationsAreLoaded: PropTypes.bool.isRequired,
  operations: PropTypes.object,
  getTradingAccount: PropTypes.func.isRequired,
  getOperations: PropTypes.func.isRequired,
  openOrders: PropTypes.bool.isRequired,
  closedOrders: PropTypes.bool.isRequired,
  delayedOrders: PropTypes.bool.isRequired,
  accountIsLoaded: PropTypes.bool.isRequired,
  equityExist: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  addons: PropTypes.array.isRequired,
  redirect: PropTypes.bool.isRequired,
  theme: PropTypes.object,
};

AccountPage.defaultProps = {
  operations: {},
  theme: {},
};

export default compose(
  withRouter,
  withTheme(),
  connect(
    state => {
      const { openOrders, closedOrders, delayedOrders } = state.interfaceConfig.equityTradingHistoryDisplay;

      const equityExist = [openOrders, closedOrders, delayedOrders].some(display => display);

      const account = state.client.common.currentAccount;
      const accountIsLoaded = state.client.common.currentAccountIsLoaded;

      const operations = state.client.common.currentAccountOperations;
      const operationsAreLoaded = state.client.common.currentAccountOperationsAreLoaded;

      return {
        account,
        accountIsLoaded,
        operations,
        operationsAreLoaded,
        openOrders,
        closedOrders,
        delayedOrders,
        equityExist,
        modals: state.user.modals,
        addons: state.interfaceConfig.addons,
        redirect: state.client.common.redirect,
      };
    },
    {
      getTradingAccount: id => getTradingAccount.request(id),
      getOperations: (id, operationStatus, startDate, endDate) =>
        getTradingAccountOperations.request(id, operationStatus, startDate, endDate),
      openModal: name => openModal(name),
      closeModal: name => closeModal(name),
    }
  )
)(AccountPage);
export { AccountPage };
